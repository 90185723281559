export const calculateConversion = (g, p) => {
  let cr = (g / Number(p)) * 100;
  return Math.round((cr + Number.EPSILON) * 100) / 100;
};

// Filter Conversion Ratio data for selected year
export const filterAgencyDataBySelectedYear = (data, currYear = 2020) => {
  currYear = Number(currYear);
  let pastYear = currYear - 1;
  let nextYear = currYear + 1;
  let unitConversions = [];
  const yearKeyMap = {};
  yearKeyMap[pastYear] = 'open';
  yearKeyMap[currYear] = 'revised';
  yearKeyMap[nextYear] = 'transition';
  // Loop through 3 agencies Data to build 3 objects for each agency
  // one for Name , Direct , Indirect. Will be used toooo fill data in Table Columns
  for (const agency in data) {
    let agencyObj = [
      {
        name: '',
        open: '',
        revised: '',
        transition: '',
        2021: '',
        2022: '',
        2023: '',
        2024: '',
      },
      {
        name: 'Directs',
        open: 0,
        revised: 0,
        transition: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
      {
        name: 'Indirects',
        open: 0,
        revised: 0,
        transition: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
    ];
    // Add agency name
    const agencyNameKeyMap = {
      collegeOtherFed: 'Other Fed',
      collegeNonFed: 'Non Fed',
    };
    // agency is the key in data we are looping for
    agencyObj[0].name =
      agency === 'collegeAgency' ? 'NIH' : agencyNameKeyMap[agency];
    // loop through data in agency key to add data to Direct and Indirect and to corresponding year key
    // open = pastYear revised = currYear nextYear = transition
    for (let i = 0; i < data[agency].length; i++) {
      // data[agency] is data for :  agency or nonfed or otherFed
      // Each agency key will contain One Obj with Conversion Ratio data for each Year data[agency][0]

      agencyObj[1][yearKeyMap[pastYear]] = data[agency][0][pastYear];
      agencyObj[2][yearKeyMap[pastYear]] = data[agency][0][pastYear];

      agencyObj[1][yearKeyMap[currYear]] = data[agency][0][currYear];
      agencyObj[2][yearKeyMap[currYear]] = data[agency][0][currYear];

      agencyObj[1][yearKeyMap[nextYear]] = data[agency][0][nextYear];
      agencyObj[2][yearKeyMap[nextYear]] = data[agency][0][nextYear];
    }
    unitConversions.push(...agencyObj);
  }
  return unitConversions;
};
