let AA = new (function () {
  var me = this;
  // Constant Containers
  var i = new (function () {
    this.obj = {};
    this.array = [];
    this.function = function () {};
    this.string = 'ABC';
    this.integer = 0;
    this.decimalStandard = 1;
    this.setConditions = function (object) {
      return object != null && object != undefined && object != 'NaN';
    };
    this.defaultConditions = function (object) {
      return Boolean(object);
    };
  })();
  me.i = i;
  // me.formats = new (function () {
  //     this.clear = (value) => {
  //         var sample = value.toString();
  //         var temp = parseFloat(value.toString().toLowerCase().whiteout([ '$', '%', ',', 't', 'b', 'm', 'k' ]));
  //         if(sample.contains('%'))
  //             temp = temp / 100;
  //         if(sample.contains('T'))
  //             temp = temp * 1000000000000;
  //         if(sample.contains('B'))
  //             temp = temp * 1000000000;
  //         if(sample.contains('M'))
  //             temp = temp * 1000000;
  //         if(sample.contains('K'))
  //             temp = temp * 1000;
  //         return temp;
  //     };
  //     this.currency = function (value) {
  //         if(me.is.set(value)) {
  //             value = Number(value);
  //             if (value == undefined) {
  //                 return "$0.00";
  //             }
  //             if (value.indexOf != undefined && value.indexOf('$') != -1) {
  //                 return value;
  //             }
  //             return '$' + value.toFixed(2).replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  //         }
  //         return '';
  //     };
  //     this.shortcurrency = function (value) {
  //         if(me.is.set(value)) {
  //             value = Number(value);
  //             if (value >= 1000)
  //                 return '$' + me.formats.shortnumber(value);
  //             return '$' + value.toFixed().replace(/(\d)(?=(\d{3})+\.)/g, '$1,');
  //         }
  //         return '';
  //     };
  //     this.shortnumber = function (value) {
  //         if (value >= 1000000000000)
  //             return Math.round(value / 1000000000000) + 'T';
  //         if (value >= 1000000000)
  //             return Math.round(value / 1000000000) + 'B';
  //         if (value >= 1000000)
  //             return Math.round(value / 1000000) + 'M';
  //         if (value >= 1000)
  //             return Math.round(value / 1000) + 'K';
  //         return value;
  //     };
  //     this.percent = function (value) {
  //         return Math.round(value * 100) + '%';
  //     };
  //     this.fixedcurrency = function(value) {
  //         if(me.is.object(value))
  //             return '$' + parseFloat(value.value).toFixed(value.decimal).replace(/\B(?=(\d{3})+(?!\d))/g, ","); //    /(\d)(?=(\d{3})+\.)/g, '$1,'
  //         else
  //             return '$' + parseFloat(value).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //     };
  //     this.fixedpercent = function (value) {
  //         return parseFloat((value.value * 100)).toFixed(value.decimal) + '%';
  //     };
  //     this.pad = function(value) {
  //         var filler = value.filler;
  //         var p = value.places;
  //         var val = (me.is.set(value.value)) ? value.value.toString() : '';
  //         var retval = '';
  //         if(val.length < p) {
  //             var i = p - val.length;
  //             while(i--)
  //                 retval += filler;
  //         }
  //         return retval + val;
  //     };
  //     /**
  //      *  Truncates a long decimal to however many places you would like
  //      *
  //      *  @param      value   obj     { this.value = float, this.places = int }
  //      *
  //      *  @returns    float
  //      */
  //     this.truncatedecimal = function(value) {
  //         var re = new RegExp("(\\d+\\.\\d{" + value.places + "})(\\d)"),
  //             m = value.value.toString().match(re);
  //         return m ? parseFloat(m[1]) : value.value.valueOf();
  //     }
  //     this.fixednumber = function (value) {
  //         return parseFloat((value.value)).toFixed(value.decimal);
  //     };
  //     this.fixedsmartnumber = function(value) {
  //         if(me.is.object(value)) {
  //             if(parseFloat(value.value) % 1 != 0)
  //                 return parseFloat(parseFloat(value.value).toFixed(value.decimal));
  //             else
  //                 return me.format(parseFloat(value.value), 'wholecomma');
  //         } else {
  //             if(parseFloat(value) % 1 != 0)
  //                 return parseFloat(parseFloat(value).toFixed(i.decimalStandard));
  //             else
  //                 return me.format(parseFloat(value), 'wholecomma');
  //         }
  //     };
  //     this.fixedexpandnumber = function(value) {
  //         var sample = me.formats.fixednumber(value);
  //         if(value.value != 0) {
  //             while(parseFloat(sample) == 0) {
  //                 value.decimal++;
  //                 sample = me.formats.fixednumber(value);
  //             }
  //         }
  //         return sample;
  //     };
  //     this.fixedcurrencyspecial = function(value) {
  //         if(value.value != '' || ((me.is.string(value.value) && value.value == '0') || (me.is.numeric(value.value) && value.value == 0))) {
  //             if(me.formats.whole(value.value).toString().length > value.places)
  //                 return '$' + me.formats.shortnumber(parseFloat(value.value).toFixed(value.decimal));
  //             return '$' + me.formats.comma(parseFloat(value.value).toFixed(value.decimal));
  //         }
  //         return value.value;
  //     };
  //     this.comma = function (value) {
  //         return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //     };
  //     this.whole = function (value) {
  //         return parseInt(parseFloat(Math.round(value).toFixed(0)), 10);
  //     };
  //     this.wholecomma = function (value) {
  //         return parseInt(parseFloat(Math.round(value).toFixed(0)), 10).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  //     };
  //     this.shorttime = function (value) {
  //         return value.Hours + ":" + value.Minutes + ":" + value.Seconds;
  //     };
  //     this.extendedtime = function (value) {
  //         return me.formats.shorttime(value) + ":" + value.MiliSeconds;
  //     };
  //     this.facultyrank = function(value) {
  //         var rankObj = {
  //             0: 'Unknown',
  //             1: 'Professor',
  //             2: 'Associate',
  //             3: 'Assistant',
  //             4: 'Instructor',
  //             5: 'Lecturer',
  //             9: 'Other',
  //         };
  //         return rankObj[value];
  //     };
  //     this.padleft = function(params) {
  //         var temp = params.value.toString();
  //         if (temp.length < params.places)
  //             return me.formats.padleft({ value: params.delim + temp, places: params.places, delim: params.delim });
  //         else
  //             return temp;
  //     };
  //     this.padRight = function(params) {
  //         var temp = params.value.toString();
  //         if (temp.length < params.places)
  //             return me.formats.padRight({ value: temp + params.delim, places: params.places, delim: params.delim });
  //         else
  //             return temp;
  //     };
  // })();
  // Base Methods
  // me.format = function (value, type) {
  //     // For the Format Rework
  //     // Need to go through all the format functions and standardize to the default for that type of function
  //     /*
  //     var defaults = { value: 0, decimal: 2, places: 6, showZeroValue: true };
  //     if(me.is.set(value)) {
  //         if(me.is.object(value))
  //             defaults = me.tools.defaultOrUpdate(defaults, value);
  //         else
  //             defaults = me.tools.defaultOrUpdate(defaults, { value: value });
  //     }
  //     */
  //     if(type) {
  //         var temp = me.formats[type.toLowerCase()];
  //         if (me.is.set(value) && me.is.set(type) && me.is.set(temp))
  //             return temp(value);
  //     }
  //     return value;
  // };
  // me.numeral = function(value, format) {
  //     return numeral(value).format(format);
  // };
  // me.moment = function(value, format) {
  //     return moment(value).format(format);
  // };
  // me.padLeft = function(value, places, delim) {
  //     var temp = value.toString();
  //     if(temp.length < places)
  //         return me.padLeft(delim + temp, places, delim);
  //     else
  //         return temp;
  // };
  // me.formPost = function (url, data, method) {
  //     //url and data options required
  //     if (url && data) {
  //         //data can be string of parameters or array/object
  //         data = typeof data === 'string' ? data : $.param(data);
  //         //split params into form inputs
  //         var inputs = '';
  //         $.each(data.split('&'), function () {
  //             var pair = this.split('=');
  //             inputs += '<input type="hidden" name="' + pair[0] + '" value="' + pair[1] + '" />';
  //         });

  //         //send request
  //         $('<form style="display: none;" action="' + url + '" method="' + (method || 'post') + '">' + inputs + '</form>')
  //         .appendTo('body').submit().remove();
  //     }
  // };
  // me.get = function (url, data, successCallback, errorCallback) {
  //     $.ajax({
  //         type: "GET",
  //         url: url,
  //         data: data,
  //         traditional: true,
  //         success: successCallback,
  //         error: errorCallback
  //     });
  // };
  // me.post = function (url, data, successCallback, errorCallback) {
  //     $.ajax({
  //         type: "POST",
  //         url: url,
  //         data: data,
  //         traditional: true,
  //         success: successCallback,
  //         error: errorCallback
  //     });
  // };
  // me.download = function(content, fileName, type) {
  //     var fileMap = {
  //         'csv': { ext: '.csv', encoding: 'data:application/csv;charset=utf-8' },
  //     };
  //     var fileType = fileMap[type.toLowerCase()];
  //     var data = (Array.isArray(content)) ? content.join('') : content;

  //     // variable rename
  //     data = data.replaceAll('pfsp', 'index_percentile');

  //     if (window.navigator.userAgent.contains("MSIE") || window.navigator.userAgent.contains("Edge") || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
  //         content = me.gather(content, x => x.replace('\n', ''));
  //         me.post('/Downloads/ClientCsv', { results: content }, (data) => {
  //             window.open('/Downloads/MemoryDownload?fileGuid={0}&fileName={1}{2}'.format(data.fileGuid, fileName, fileType.ext));
  //         });
  //     } else {
  //         var uri = fileType.encoding + ',' + encodeURIComponent(data);
  //         var link = document.createElement("a");
  //         link.href = uri;
  //         link.style = "visibility:hidden";
  //         link.download = fileName + fileType.ext;
  //         document.body.appendChild(link);
  //         link.click();
  //         document.body.removeChild(link);
  //     }
  // };
  // me.move = function(obj, key1, key2) {
  //     if(me.is.array(obj)) {
  //         obj.splice(key2, 0, obj.splice(key1, 1)[0]);
  //     } else {
  //         obj[key2] = obj[key1];
  //         delete obj[key1];
  //     }
  //     return obj;
  // };
  // me.alert = function(options) {
  //     var guid = 'alert-' + me.new.guid();
  //     var opt = me.tools.defaultOrUpdate({ Id: 'body', Title: '', Text: '', Type: 'alert-success', Timed: true, Timeout: 3000, Style: '' }, options);
  //     $(opt.Id).append('<div id="{0}" class="alert {1} fade in" role="alert" style="{2}">{3}{4}<button type="button" class="close" data-dismiss="alert">&times;</button></div>'
  //         .format(guid, opt.Type, opt.Style, ((opt.Title != '') ? '<h4>' + opt.Title + '</h4>' : ''), opt.Text));
  //     if(opt.Timed)
  //         setTimeout(() => { $('#' + guid).remove(); }, opt.Timeout);
  // };
  //   me.popover = function (options) {
  //     if (me.is.definedType(options, i.obj) && me.is.defined(options.element)) {
  //       var guid = me.new.guid();
  //       var template = '<div class="popover {3}" role="tooltip" style="border-radius: 5px; {2}"><div class="arrow"></div><div style="{0}" class="popover-title toolTipCaption"></div><div class="popover-content" style="font-size: 12px; {1}"></div></div>'.format(
  //         options.headerStyle,
  //         options.contentStyle,
  //         options.bodyStyle,
  //         'popover-' + guid
  //       );
  //       var $element = $(options.element);
  //       delete options.element;
  //       var opt = {
  //         animation: true,
  //         container: 'body',
  //         content: '',
  //         delay: 0,
  //         html: true,
  //         placement: 'auto',
  //         selector: false,
  //         template: template,
  //         title: '',
  //         trigger: 'hover',
  //         viewport: { selector: 'body', padding: 0 },
  //         onShow: null,
  //         onHide: null,
  //         onShown: null,
  //         onHidden: null,
  //         onRender: null,
  //         inject: null,
  //       };
  //       me.tools.fuse(opt, options);
  //       if (me.is.definedType(opt.inject, i.function)) opt.content = ' ';
  //       $element.popover(opt);
  //       if (me.is.definedType(opt.onShow, i.function))
  //         $element.on('show.bs.popover', opt.onShow);
  //       if (me.is.definedType(opt.onHide, i.function))
  //         $element.on('hide.bs.popover', opt.onHide);
  //       if (me.is.definedType(opt.onShown, i.function))
  //         $element.on('shown.bs.popover', opt.onShown);
  //       if (me.is.definedType(opt.onHidden, i.function))
  //         $element.on('hidden.bs.popover', opt.onHidden);
  //       if (me.is.definedType(opt.onRender, i.function))
  //         $element.on('inserted.bs.popover', opt.onRender);
  //       if (me.is.definedType(opt.inject, i.function)) {
  //         $element.on('inserted.bs.popover', () => {
  //           var $el = $('.popover-' + guid);
  //           var sample = { title: '', content: '' };
  //           me.tools.fuse(sample, opt.inject());
  //           $el.find('.popover-title').html(sample.title);
  //           $el.find('.popover-content').html(sample.content);
  //         });
  //       }
  //     }
  //   };
  // me.infoBox = function(options) {
  //     if(me.is.definedType(options, i.obj)) {
  //         var guid = me.new.guid();
  //         var videoId = 'video' + guid;
  //         var videoLink = options.videoLink ? '<div style="background-color: #f7f7f7; border-top: 1px solid #ebebeb; padding: 9px 14px; font-size: 14px;"><a id="{0}" title="{1}" style="cursor: pointer;"><i class="fa fa-video-camera"></i> {1}</a></div>'.format(videoId, options.videoTitle) : '';
  //         var template = '<div class="widget-info-popout popover {0}" style="position: absolute; cursor: move; max-width: 600px; z-index: 999999;"><div class="popover-title"><h4 style="display: inline;" class="widget-info-header"></h4><a class="widget-info-close" style="margin-left: 10px; float: right; cursor: pointer;"><i class="fa fa-times-circle"></i></a></div><div class="widget-info-body popover-content" style="font-size: 12px; max-height: 400px; overflow-y: auto;"></div>{1}</div>'.format(guid, videoLink);
  //         var opt = {
  //             container: 'html',
  //             description: '',
  //             template: template,
  //             displayTitle: '',
  //             onHide: null,
  //             onShow: null
  //         };
  //         me.tools.fuse(opt, options);

  //         // Build
  //         var infobox = $(opt.template);
  //         infobox.find('.widget-info-header').html(opt.displayTitle);
  //         infobox.find('.widget-info-body').html(opt.description);
  //         return {
  //             css: (c) => {
  //                 infobox.css(c);
  //             },
  //             show: () => {
  //                 $(opt.container).append(infobox);
  //                 infobox = $(opt.container).find('.' + guid);
  //                 infobox.draggable();
  //                 infobox.find('.widget-info-close').click(() => {
  //                     infobox = infobox.detach();
  //                     if(me.is.function(opt.onHide))
  //                         opt.onHide();
  //                 });
  //                 infobox.find('#' + videoId).click(() => {
  //                     options.videoCallback(options.videoLink);
  //                 });
  //                 infobox.show();
  //                 if(me.is.function(opt.onShow))
  //                     opt.onShow();
  //             },
  //             hide: () => {
  //                 infobox = infobox.detach();
  //                 if(me.is.function(opt.onHide))
  //                     opt.onHide();
  //             },
  //             find: (c) => {
  //                 return infobox.find(c);
  //             },
  //             height: () => {
  //                 return infobox.height();
  //             },
  //             width: () => {
  //                 return infobox.width();
  //             }
  //         };
  //     }
  // };
  // me.print = function(selection) {
  //     //var $el = $(selection);
  //     //$el.addClass('myDivToPrint');
  //     //window.print();
  //     //$el.removeClass('myDivToPrint');

  //     //var restorepage = $('body').html();
  //     //var printcontent = $(selection).clone();
  //     //$('body').empty().html(printcontent);
  //     //window.print();
  //     //$('body').html(restorepage);

  //     //var divToPrint = $(selection)[0];
  //     //var popupWin = window.open('', '_blank');
  //     //var cssFiles = [ 'Site', 'main', 'bootstrap.min', 'w2ui-1.4.2' ];
  //     //var styles = '';
  //     //me.tools.all(cssFiles, (v) => { styles += '<link href="Content/{0}.css" rel="stylesheet" type="text/css" />'.format(v); });
  //     //popupWin.document.open();
  //     //popupWin.document.write('<html><head>{0}</head><body onload="window.print()">{1}</html>'.format(styles, divToPrint.innerHTML));
  //     //popupWin.document.close();

  //     var element = $(selection);
  //     element.find('.no-print').hide();
  //     var contents = $(selection).html();
  //     var frame1 = document.createElement('iframe');
  //     frame1.name = "frame1";
  //     frame1.style.position = "absolute";
  //     frame1.style.top = "-1000000px";
  //     document.body.appendChild(frame1);
  //     var frameDoc = frame1.contentWindow ? frame1.contentWindow : frame1.contentDocument.document ? frame1.contentDocument.document : frame1.contentDocument;
  //     frameDoc.document.open();
  //     frameDoc.document.write('<html><head><title>Print</title>');
  //     ///////////////////////////////////////////////////////////////////////
  //     $('style').each(function(idx, node) {
  //         frameDoc.document.write('<style>{0}</style>'.format(node));
  //     });
  //     ///////////////////////////////////////////////////////////////////////
  //     frameDoc.document.write('</head><body>');
  //     frameDoc.document.write(contents);
  //     frameDoc.document.write('</body></html>');
  //     frameDoc.document.close();
  //     setTimeout(function () {
  //         window.frames["frame1"].focus();
  //         window.frames["frame1"].print();
  //         document.body.removeChild(frame1);
  //         element.find('.no-print').show();
  //     }, 500);
  //     return false;
  // };
  // me.dispose = function(obj) {
  //     if(me.is.array(obj)) {
  //         me.tools.all(obj, (v) => { me.dispose(v); });
  //     } else if(me.is.defined(obj)) {
  //         if(me.is.definedType(obj.Dispose, i.function))
  //             obj.Dispose();
  //         else if(me.is.definedType(obj.dispose, i.function))
  //             obj.dispose();
  //         else if(me.is.definedType(obj.Destroy, i.function))
  //             obj.Destroy();
  //         else if(me.is.definedType(obj.destroy, i.function))
  //             obj.destroy();
  //     }
  // };
  // me.throttle = function(func, time) {
  //     time || (time = 250);
  //     var last,
  //         timer;
  //     return () => {
  //         var context = this,
  //             now = +(new Date),
  //             args = arguments;

  //         if(last && now < last + time) {
  //             clearTimeout(timer);
  //             timer = setTimeout(() => {
  //                 last = now;
  //                 func.apply(context, args);
  //             }, time);
  //         } else {
  //             last = now;
  //             func.apply(context, args);
  //         }
  //     };
  // };
  // me.debounce = function(func, time) {
  //     time || (time = 250);
  //     var timer = null;
  //     var context = this;
  //     return (...args) => {
  //         clearTimeout(timer);
  //         timer = setTimeout(() => {
  //             func.apply(context, args);
  //         }, time);
  //     };
  // };

  // Function Containers

  // me.math = new (function () {
  //     this.password = function(plength) {
  //         var keylistalpha = "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWYXZ";
  //         var keylistint = "123456789";
  //         var keylistspec = "!@#$()?";
  //         var temp = '';
  //         var len = plength / 2;
  //         var len = len - 1;
  //         var lenspec = plength - len - len;

  //         for (var i = 0; i < len; i++)
  //             temp += keylistalpha.charAt(Math.floor(Math.random() * keylistalpha.length));

  //         for (var i = 0; i < lenspec; i++)
  //             temp += keylistspec.charAt(Math.floor(Math.random() * keylistspec.length));

  //         for (var i = 0; i < len; i++)
  //             temp += keylistint.charAt(Math.floor(Math.random() * keylistint.length));

  //         temp = temp.split('').sort(function() { return 0.5 - Math.random(); }).join('');
  //         return temp.trim();
  //     };
  //     this.random = function(max, min, noround) {
  //         max = Math.ceil(AA.is.set(max) ? max : 100);
  //         min = Math.floor(AA.is.set(min) ? min : 0);
  //         if(!noround) {
  //             max = Math.ceil(max);
  //             min = Math.floor(min);
  //             return Math.floor(Math.random() * (max - min + 1)) + min;
  //         }
  //         return Math.random() * (max - min + 1) + min;
  //     };
  //     this.r16 = function () {
  //         return Math.floor((1 + Math.random()) * 0x10000).toString(16).substring(1);
  //     };
  //     this.roundUpTo = function (value, step) {
  //         if((value < 1 && value > 0) || (value > -1 && value < 0))
  //             return me.math.roundUpTo(value * 10, step) / 10;
  //         return Math.ceil(value / step) * step;
  //     };
  //     this.median = function (values) {
  //         values.sort(function (a, b) { return a - b; });
  //         var half = Math.floor(values.length / 2);
  //         if (values.length % 2)
  //             return values[half];
  //         else
  //             return (values[half - 1] + values[half]) / 2.0;
  //     };
  //     this.sum = function (values, func) {
  //         var sum = 0,
  //             toAdd = null;
  //         for (var i = 0; i < values.length; i++) {
  //             toAdd = (func) ? func(values[i]) : values[i];
  //             if(me.is.set(toAdd))
  //                 sum += toAdd;
  //         }
  //         return sum;
  //     };
  //     this.average = function (values, func) {
  //         var sum = me.math.sum(values, func);
  //         if (sum > 0 || sum < 0)
  //             return sum / values.length;
  //         return 0;
  //     };
  //     this.max = function (values, func) {
  //         var ret = null;
  //         me.tools.all(values, function(x) {
  //             if(ret == null)
  //                 ret = x;
  //             if(func) {
  //                 var temp = func(x);
  //                 if (temp > ret)
  //                     ret = temp;
  //             } else {
  //                 if (x > ret)
  //                     ret = x;
  //             }
  //         });
  //         return ret;
  //     };
  //     this.min = function (values, func) {
  //         var ret = null;
  //         me.tools.all(values, function(x) {
  //             if(ret == null)
  //                 ret = x;
  //             if(func) {
  //                 var temp = func(x);
  //                 if (temp < ret)
  //                     ret = temp;
  //             } else {
  //                 if (x < ret)
  //                     ret = x;
  //             }
  //         });
  //         return ret;
  //     };
  //     this.percentages = function(values, func) {
  //         var retVal = [];
  //         var total = me.math.sum(values, func);
  //         if(total != 0) {
  //             for(var val in values) {
  //                 var value = 0;
  //                 if (func == undefined)
  //                     value = values[val];
  //                 else
  //                     value = func(values[val]);
  //                 retVal.push(value / total);
  //             }
  //         }
  //         return retVal;
  //     };
  //     this.scale = function(value, domain, range) {
  //         return ( value - domain[0] ) * ( range[1] - range[0] ) / ( domain[1] - domain[0] ) + range[0];
  //     };
  // })();
  // me.cms = new (function() {

  //     this.cmsUrl = "https://cms.academicanalytics.com/";
  //     // can't get this to work
  //     this.addCMSUrl = function(posts) {
  //         for( var i = 0; i < posts.length; i++ ) {
  //             for(var prop in posts[i]) {
  //                 if((prop == "attachedFile") || (prop == "content") && (posts[i][prop] != null)) {
  //                     posts[i][prop].replace('src="/', 'src="'+me.cms.cmsUrl);
  //                     posts[i][prop].replace('href="/', 'href="'+me.cms.cmsUrl);
  //                     console.log(me.cms.cmsUrl);
  //                     console.log(posts[i][prop]);
  //                 }
  //             }
  //         }
  //         return posts;
  //     };

  //     this.filterByYear = function(year, postArray, propertyName) {
  //         var filteredPosts = [];

  //         for( var i = 0; i < postArray.length; i++ ) {
  //             for( var prop in postArray[i] ) {
  //                 if( (prop == propertyName) ) {
  //                     if(year == parseInt(postArray[i][propertyName])) {
  //                         filteredPosts.push(postArray[i]);
  //                     }
  //                 }
  //             }
  //         }
  //         return filteredPosts;
  //     };

  //     this.createArrayOfObjectProperties = function(object, propertyName) {
  //         var arrayOfObjectProperties = [];

  //         for(var i = 0; i < object.length; i++) {
  //             for(var prop in object[i]) {
  //                 if(prop == propertyName) {
  //                     if( $.inArray( parseInt( object[i][prop] ), arrayOfObjectProperties ) == -1 ) {
  //                         arrayOfObjectProperties.push( parseInt( object[i][prop] ) );
  //                     }
  //                 }
  //             }
  //         }

  //         arrayOfObjectProperties.sort(function(a,b){return b-a;});
  //         return arrayOfObjectProperties;
  //     };

  //     this.filterByArraySortDescending = function(array) {
  //         array.sort(function(a,b){return a-b});
  //         return array;
  //     };

  //     this.filterByArraySortAscending = function(array) {
  //         array.sort(function(a,b){return b-a;});
  //         return array;
  //     };

  // })();
  me.tools = new (function () {
    /**
     * Attempt to freeze object properties in place recursively so values can't be changed with the goal of becoming like an enum
     *
     * @param {any} obj Object to freeze
     *
     * @returns {any} Frozen object
     */
    this.enum = function (obj) {
      // Deep lock layered objects
      me.all(obj, (value, key) => {
        if (me.is.object(value)) obj[key] = me.enum(value);
      });
      // Freeze if it is availible
      if (Object.freeze) return Object.freeze(obj);
      // Otherwise lock with getters
      var retval = {};
      me.all(obj, function (value, key) {
        Object.defineProperty(retval, key, {
          get: function () {
            return value;
          },
          enumerable: true,
          configurable: false,
        });
      });
      // Seal if it is availible
      if (Object.seal) retval = Object.seal(retval);
      return retval;
    };
    /**
     * Safe property getter AA.prop(someobj, 'sub.value.here');
     *
     * @param {any} obj Any object of which you want to safely get the property of
     * @param {string} path String path to given property use dots for each sub property like: 'sub.value.here'
     * @param {any=} value Optional, will attempt to overwrite the target property
     *
     * @returns {any | null} Will return the value if it can navigate to it, otherwise it will return null if it can't
     */
    this.prop = function (obj, path, value) {
      if (me.is.set(path) && me.is.set(obj) && path != '') {
        if (value !== undefined) {
          var paths = path.split('.');
          if (paths.length > 0) {
            var fragment = paths.pop();
            me.all(paths, function (x) {
              if (!me.is.set(obj[x])) obj[x] = {};
              obj = obj[x];
            });
            obj[fragment] = value;
          }
        } else {
          var current = obj,
            paths = path.split('.');
          me.all(paths, function (p, i, e) {
            current = current[p];
            if (!me.is.set(current)) e.Stop = true;
          });
          return current;
        }
      } else return obj;
    };
    this.getter = function (obj, name, cb) {
      Object.defineProperty(obj, name, { get: cb });
    };
    /**
     * Function shortcut to convert any iterable object into an object map using two callbacks for key/value pairs
     *
     * @param {any} obj Any Iterable Object
     * @param {(value: any, key: string) => string} key Function to get the key
     * @param {(value: any, key: string) => any} value Function to get the value
     */
    this.index = function (obj, key, value) {
      key = me.is.function(key)
        ? key
        : function (x) {
            return x.id;
          };
      value = me.is.function(value)
        ? value
        : function (x) {
            return x;
          };
      return AA.gather(
        obj,
        (x, y) => ({ key: key(x, y), value: value(x, y) }),
        { Build: 'Object' }
      );
    };
    this.switch = function (value, hash, def) {
      var retval = hash[value];
      if (!me.is.defined(retval)) retval = def;
      if (me.is.function(retval)) retval = retval(value, hash, def);
      return retval;
    };
    /**
     * @deprecated Use AA.prop instead
     */
    // this.findProperty = function(obj, path) {
    //     if(me.is.set(path) && me.is.set(obj) && path != '') {
    //         var current = obj,
    //             paths = path.split('.');
    //         me.tools.all(paths, (p, y, e) => {
    //             current = current[p];
    //             if(!me.is.set(current))
    //                 e.Stop = true;
    //         });
    //         return current;
    //     } else
    //         return obj;
    // };
    /**
     * Copy object using JSON builtins, this will not preserve functions
     *
     * @param {any} obj Object to copy
     *
     * @returns {any} Copied object
     */
    // this.clone = function (obj) {
    //     return JSON.parse(JSON.stringify(obj));
    // };
    /**
     * Copy object using AA.fuse, this will preserve functions, but not getters/setters
     *
     * @param {array | object} obj Object to copy
     *
     * @returns {any} Copied object
     */
    // this.copy = function (obj) {
    //     var base = AA.is.array(obj) ? [] : {};
    //     return AA.fuse(base, obj, true);
    // };
    /**
     * Fuse two objects together
     *
     * @param {any} obj1 Target object for fusion, note that this will modify obj1 in the process
     * @param {any} obj2 Object from which to pull properties for fusion
     * @param {boolean} [deep=false] Flag to enable deep fusion, iterating over sub arrays and sub objects
     *
     * @returns {any} Fused obj1
     */
    // this.fuse = function (obj1, obj2, deep) {
    //     var deepMode = me.is.definedState(deep, true);
    //     me.tools.all(obj2, function (object, key) {
    //         if (me.is.definedType(object, i.obj) && me.is.defined(obj1[key]) && me.is.definedState(obj1[key].Identifier, 'Config Object'))
    //             obj1[key].Update(object, true);
    //         else if(me.is.definedType(object, i.obj) && me.is.definedState(object.Identifier, 'Replace Object'))
    //             obj1[key] = object.Content();
    //         else if(me.is.object(obj1) && me.is.definedState(AA.findProperty(obj1[key], '_identifier'), 'Config Object'))
    //             obj1[key].update(object, true);
    //         else {
    //             if (deepMode && (me.is.sameType(object, i.obj) || me.is.sameType(object, i.array))) {
    //                 if(!me.is.defined(obj1[key])) {
    //                     if(me.is.sameType(object, i.obj))
    //                         obj1[key] = {};
    //                     else if(me.is.sameType(object, i.array))
    //                         obj1[key] = [];
    //                 }
    //                 me.tools.fuse(obj1[key], object, true);
    //             }
    //             else
    //                 obj1[key] = object;
    //         }
    //     });
    //     return obj1;
    // };
    /**
     * Settings for iterating array
     *
     * @typedef {object} iterationSettings
     * @property {boolean} [Stop=false] Set iteration to stop after handler completes
     *
     */
    /**
     * Iterate over anything
     *
     * @param {any} obj Target for iteration
     * @param {(value?: any, key?: string, settings?: iterationSettings) => any} func Function to handle iteration
     * @param {boolean} [getAll=false] Ignore hasOwnProperty() check on iteration
     */
    this.all = function (obj, func, getAll) {
      var event = { Stop: false };
      var all = me.is.definedState(getAll, true);
      for (var val in obj) {
        if (all || obj.hasOwnProperty(val)) func(obj[val], val, event);
        if (event.Stop) break;
      }
    };
    // this.contains = function(obj, func) { // obj can be array or object, func just needs to return true or false or be the value you are searching for
    //     return (me.is.defined(me.tools.search(obj, func)));
    // };
    // this.count = function(obj) {
    //     var count = 0;
    //     for(var val in obj) {
    //         if (obj.hasOwnProperty(val)) {
    //             count++;
    //         }
    //     }
    //     return count;
    // };
    // this.first = function(obj, key) {
    //     var retVal = 0;
    //     for(var val in obj) {
    //         if (obj.hasOwnProperty(val)) {
    //             if(me.is.definedState(key, true))
    //                 retVal = val;
    //             else
    //                 retVal = obj[val];
    //             break;
    //         }
    //     }
    //     return retVal;
    // };
    /**
     * A more in depth check to see whether two objects match rather than a simple == or ===
     *
     * @param {any} obj1
     * @param {any} obj2
     * @param {object=} options
     * @param {boolean} [options.checkType=false] Check type of both objects for match
     * @param {boolean} [options.recursive=true] Recursivly iterate through sub arrays and objects
     * @param {boolean} [options.explicit=false] Check properties with === instead of ==
     *
     * @returns {boolean} Result for match
     */
    // this.match = function(obj1, obj2, options) {
    //     var event = me.tools.defaultOrUpdate({ checkType: false, recursive: true, explicit: false }, options);
    //     var flag = true;
    //     if(event.checkType)
    //         if(!me.is.sameType(obj1, obj2))
    //             return false;
    //     if((!me.is.set(obj1) && me.is.set(obj2)) || (me.is.set(obj1) && !me.is.set(obj2)))
    //         return false;
    //     me.all(obj1, function(x, y, z) {
    //         if(event.recursive && (me.is.object(x) || me.is.array(x))) {
    //             if(!me.match(x, obj2[y], event)) {
    //                 z.Stop = true;
    //                 flag = false;
    //             }
    //         } else if((event.explicit) ? obj2[y] !== x : obj2[y] != x) {
    //             z.Stop = true;
    //             flag = false;
    //         }
    //     });
    //     if(flag) {
    //         me.all(obj2, function(x, y, z) {
    //             if(event.recursive && (me.is.object(x) || me.is.array(x))) {
    //                 if(!me.match(x, obj1[y], event)) {
    //                     z.Stop = true;
    //                     flag = false;
    //                 }
    //             } else if((event.explicit) ? obj1[y] !== x : obj1[y] != x) {
    //                 z.Stop = true;
    //                 flag = false;
    //             }
    //         });
    //     }
    //     return flag;
    // };
    /**
     * Settings for iterating array
     *
     * @typedef {object} gatherSettings
     * @property {boolean} [StopAfter=false] Set iteration to stop and to include the value returned by the handler
     * @property {boolean} [StopBefore=false] Set iteration to stop and not include the value returned by the handler
     * @property {boolean} [Skip=false] Ignore the value returned by the handler for one iteration
     * @property {boolean} [PushMultiple=false] Spread the array / object returned by the handler over the return object rather than setting one key as that value
     *
     */
    /**
     * Function to convert any iterable into another iterable, defaults to return array
     *
     * @param {any} obj Any Iterable Object
     * @param {(value?: any, key?: string, settings?: gatherSettings) => any} func Function to handle iteration
     * @param {object=} e Optional Settings
     * @param {string} e.Build Build target either 'array' or 'object'
     *
     * @returns {Array | Object} Returns a given result based on e.Build selection
     */
    // this.gather = function (obj, func, e) {
    //     var event = me.tools.defaultOrUpdate({ StopAfter: false, StopBefore: false, Skip: false, PushMultiple: false, Build: 'array' }, e);
    //     event.Build = event.Build.toLowerCase();
    //     var ret = me.tools.switch(event.Build, { 'array': [], 'object': {} }, []);
    //     // iterable is array
    //     if(me.is.array(ret)) {
    //         for (var val in obj) {
    //             if (obj.hasOwnProperty(val)) {
    //                 var value = func(obj[val], val, event);
    //                 if (event.StopBefore)
    //                     break;
    //                 if (!event.Skip) {
    //                     if (event.PushMultiple) {
    //                         me.tools.all(value, function (v) { ret.push(v); });
    //                         event.PushMultiple = false;
    //                     }
    //                     else
    //                         ret.push(value);
    //                 }
    //                 else
    //                     event.Skip = false;
    //             }
    //             if (event.StopAfter)
    //                 break;
    //         }
    //     // iterable is object
    //     } else if(me.is.object(ret)) {
    //         for (var val in obj) {
    //             if (obj.hasOwnProperty(val)) {
    //                 var value = func(obj[val], val, event);
    //                 if (event.StopBefore)
    //                     break;
    //                 if (!event.Skip) {
    //                     if (event.PushMultiple) {
    //                         me.tools.all(value, function (v) { ret[value.key] = value.value; });
    //                         event.PushMultiple = false;
    //                     }
    //                     else
    //                         ret[value.key] = value.value;
    //                 }
    //                 else
    //                     event.Skip = false;
    //             }
    //             if (event.StopAfter)
    //                 break;
    //         }
    //     }
    //     return ret;
    // };
    // this.filter = function (obj, func) {
    //     var event = { StopAfter: false, StopBefore: false };
    //     var ret = null;
    //     if(me.is.array(obj)) {
    //         ret = [];
    //         for (var val in obj) {
    //             if (obj.hasOwnProperty(val)) {
    //                 if (func(obj[val], val, event)) {
    //                     if (event.StopBefore)
    //                         break;
    //                     ret.push(obj[val]);
    //                 }
    //             }
    //             if (event.StopAfter)
    //                 break;
    //         }
    //     } else if(me.is.object(obj)) {
    //         ret = {};
    //         for (var val in obj) {
    //             if (obj.hasOwnProperty(val)) {
    //                 if (func(obj[val], val, event)) {
    //                     if (event.StopBefore)
    //                         break;
    //                     ret[val] = obj[val];
    //                 }
    //             }
    //             if (event.StopAfter)
    //                 break;
    //         }
    //     }
    //     return ret;
    // };
    // this.group = function (obj, func) {
    //     var key = me.is.function(func) ? func : function (v) { return me.prop(v, func); },
    //         retVal = {},
    //         value = null;
    //     me.all(obj, function (v) {
    //         value = key(v);
    //         if (me.is.string(value) || me.is.numeric(value)) {
    //             if (me.is.set(retVal[value]))
    //                 retVal[value].push(v);
    //             else
    //                 retVal[value] = [v];
    //         }
    //     });
    //     return retVal;
    // };
    // this.search = function (obj, func) {
    //     var ret = null;
    //     if(me.is.function(func)) {
    //         for (var val in obj) {
    //             if (obj.hasOwnProperty(val)) {
    //                 if (func(obj[val], val)) {
    //                     ret = obj[val];
    //                     break;
    //                 }
    //             }
    //         }
    //     } else if(me.is.array(obj)) {
    //         var idx = obj.indexOf(func);
    //         if(idx > -1)
    //             ret = obj[idx];
    //     } else if(me.is.object(obj)) {
    //         for (var val in obj) {
    //             if (obj.hasOwnProperty(val)) {
    //                 if (obj[val] == func) {
    //                     ret = obj[val];
    //                     break;
    //                 }
    //             }
    //         }
    //     }
    //     return ret;
    // };
    /**
     * Get an Array Index by a specific Object Property
     * @param       array   array
     * @param       attr    string
     * @param       value   mixed type
     *
     * @returns     i       int
     *
     **/
    this.findWithAttr = function (array, attr, value) {
      for (var i = 0; i < array.length; i += 1) {
        if (array[i][attr] === value) {
          return i;
        } else {
          continue;
        }
      }
    };
    this.binary = function (arr, value) {
      if (me.is.sameType(arr, i.array)) {
        var min = 0,
          mid = Math.floor(arr.length / 2),
          max = arr.length - 1;
        if (arr[mid] === value) return arr[mid];
        else if (arr[mid] < value && arr.length > 1)
          return me.tools.binary(arr.splice(mid, Number.MAX_VALUE), value);
        else if (arr[mid] > value && arr.length > 1)
          return me.tools.binary(arr.splice(0, mid), value);
      }
      return null;
    };
    /**
     * Settings for sort
     *
     * @typedef {object} sortSettings
     * @property {string} [dir='asc'] Sort direction for given key, defaults to ascending
     * @property {(value: any) => any} [key=(x)=>x] Function to find the value to be sorted upon, defaults to return the current value from iteration
     *
     */
    /**
     * Sort array given options
     *
     * @param {array} array Target for sorting
     * @param {sortSettings[] | sortSettings} options Optional settings, can be an array of properties to sort upon or a single property to sort upon
     *
     * @returns {array} Sorted array
     */
    this.sort = function (array, options) {
      if (me.is.array(options)) {
        var o = me.tools.gather(options, function (x) {
          var opt = {
            dir: 'asc',
            key: function key(v) {
              return v;
            },
          };
          if (me.is.set(x)) me.fuse(opt, x);
          return opt;
        });
        var rev, result, A, B;
        return array.slice().sort(function (a, b) {
          me.tools.all(o, function (x, y, z) {
            rev = x.dir == 'asc' ? true : false;
            result = 0;
            A = x.key(a);
            B = x.key(b);

            result = (A < B ? -1 : A > B ? 1 : 0) * [-1, 1][+!!rev];

            if (result != 0) z.Stop = true;
          });
          return result;
        });
      } else {
        var o = {
          dir: 'asc',
          key: function key(v) {
            return v;
          },
        };
        if (me.is.set(options)) me.fuse(o, options);
        var rev = o.dir == 'asc' ? true : false;
        return array.slice().sort(function (a, b) {
          var A = o.key(a),
            B = o.key(b);
          return (A < B ? -1 : A > B ? 1 : 0) * [-1, 1][+!!rev];
        });
      }
    };
    /**
     * @deprecated Use AA.sort instead
     */
    this.sortNumerical = function (values, state) {
      if (state != undefined && state.toLowerCase() === 'asc')
        return values.slice().sort(function (a, b) {
          return a - b;
        });
      else
        return values.slice().sort(function (a, b) {
          return b - a;
        });
    };
    /**
     * @deprecated Use AA.sort instead
     */
    this.sortStrings = function (values, state) {
      var ret = values.slice().sort();
      if (state == undefined && state.toLowerCase() === 'desc') ret.reverse();
      return ret;
    };
    this.rank = function (values, state) {
      var sorted = [];
      if (me.is.defined(state)) sorted = me.tools.sort(values, { dir: state });
      else sorted = me.tools.sort(values, { dir: 'desc' });
      return values.slice().map(function (v) {
        var idx = sorted.indexOf(v);
        if (idx == 0) return idx + 1;
        return idx;
      });
    };
    this.rearrange = function (a1, a2) {
      var ret = [];
      var flag = me.is.sameType(a1, []); // if array
      for (var key in a2) {
        if (flag || a2.hasOwnProperty(key)) {
          ret[key] = a1[a2[key]];
        }
      }
      return ret;
    };
    this.getType = function (obj) {
      return {}.toString.call(obj).match(/\s([a-zA-Z]+)/)[1];
    };
    this.test = function (tryFunc, catchFunc, finFunc) {
      // http://www.w3schools.com/js/js_errors.asp
      try {
        if (me.is.definedType(tryFunc, i.function)) tryFunc();
        else throw 'No Try Function defined';
      } catch (error) {
        if (me.is.definedType(catchFunc, i.function)) console.log(error);
        else catchFunc(error);
      } finally {
        if (me.is.definedType(finFunc, i.function)) finFunc();
      }
    };
    this.setByCascade = function (obj, props, def) {
      var retVal = null;
      me.tools.all(props, function (p, key, e) {
        if (me.is.defined(obj[p])) {
          retVal = obj[p];
          e.Stop = true;
        }
      });
      if (!me.is.defined(retVal)) return def;
      return retVal;
    };
    this.defaultProp = function (obj, defaultValue, returnVar) {
      if (!me.is.defined(obj)) return defaultValue;
      if (obj[returnVar] !== null && obj[returnVar] !== undefined)
        return obj[returnVar];
      return obj;
    };
    this.defaultOrUpdate = function (def, opt) {
      var holder = def;
      if (me.is.defined(opt)) me.tools.fuse(holder, opt);
      return holder;
    };
    this.defaultOrAppend = function (def, opt) {
      if (me.is.defined(opt)) return opt + def;
      return def;
    };
    this.blendColors = function (c0, c1, p) {
      // c0 = Primary color hex, c1 = Hex to be Blended, p = percentage blend
      var f = parseInt(c0.slice(1), 16),
        t = parseInt(c1.slice(1), 16),
        R1 = f >> 16,
        G1 = (f >> 8) & 0x00ff,
        B1 = f & 0x0000ff,
        R2 = t >> 16,
        G2 = (t >> 8) & 0x00ff,
        B2 = t & 0x0000ff;
      return (
        '#' +
        (
          0x1000000 +
          (Math.round((R2 - R1) * p) + R1) * 0x10000 +
          (Math.round((G2 - G1) * p) + G1) * 0x100 +
          (Math.round((B2 - B1) * p) + B1)
        )
          .toString(16)
          .slice(1)
      );
    };
    this.getHexComponent = function (value) {
      let hex = value.toString(16);
      return hex.length == 1 ? '0' + hex : hex;
    };
    this.getRGB = function (hex) {
      // Expand shorthand form (e.g. "03F") to full form (e.g. "0033FF")
      var shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
      hex = hex.replace(shorthandRegex, function (m, r, g, b) {
        return r + r + g + g + b + b;
      });

      var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
      return result
        ? {
            r: parseInt(result[1], 16),
            g: parseInt(result[2], 16),
            b: parseInt(result[3], 16),
          }
        : null;
    };
    this.getHex = function (r, g, b) {
      return `#${me.getHexComponent(r)}${me.getHexComponent(
        g
      )}${me.getHexComponent(b)}`;
    };
    this.getRGBFromString = function (str) {
      let values = str
        .replace(/ /g, '')
        .replace('rgb', '')
        .replace(')', '')
        .replace('(', '')
        .split(',');
      return {
        r: parseInt(values[0]),
        g: parseInt(values[1]),
        b: parseInt(values[2]),
      };
    };
    this.getFontColorFromBackground = function (
      hex,
      strict = false,
      lightHex = '#fff',
      darkHex = '#000'
    ) {
      if (hex == lightHex) return darkHex;
      else if (hex == darkHex) return lightHex;
      var color = me.getRGB(hex);
      if (strict) {
        var uicolors = [color.r / 255, color.g / 255, color.b / 255];
        var c = AA.gather(uicolors, col => {
          if (col <= 0.03928) {
            return col / 12.92;
          }
          return Math.pow((col + 0.055) / 1.055, 2.4);
        });
        var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2];
        return L > 0.179 ? darkHex : lightHex;
      } else {
        return color.r * 0.299 + color.g * 0.587 + color.b * 0.114 > 186
          ? darkHex
          : lightHex;
      }
    };
    // Truncates String without cutting off a word
    // @param   str           string
    // @param   maxLength     max # of chars
    // @param   suffix        string that appends the truncated
    this.truncate = function (str, maxLength, suffix) {
      if (str.length > maxLength) {
        str = str.substring(0, maxLength + 1);
        str = str.substring(0, Math.min(str.length, str.lastIndexOf(' ')));
        str = str + suffix;
      }
      return str;
    };
  })();
  me.is = new (function () {
    this.set = function () {
      var ret = true;
      for (var a in arguments) {
        var obj = arguments[a];
        if (!i.setConditions(obj)) ret = false;
      }
      return ret;
    };
    this.setReturn = function (obj, def) {
      return me.is.set(obj) ? obj : def;
    };
    this.defined = function () {
      var ret = true;
      for (var a in arguments) {
        var obj = arguments[a];
        if (!i.defaultConditions(obj)) ret = false;
      }
      return ret;
    };
    this.definedExec = function (obj, func) {
      if (me.is.defined(obj)) func(obj);
    };
    this.definedState = function (obj, state) {
      return me.is.defined(obj) && obj === state;
    };
    this.definedType = function (obj, type) {
      return me.is.defined(obj) && me.is.sameType(obj, type);
    };
    this.definedReturn = function (obj, def) {
      if (me.is.defined(obj)) return obj;
      return def;
    };
    this.arguments = function (object) {
      return me.tools.getType(object) == 'Arguments';
    };
    this.array = function (object) {
      return Array.isArray(object);
    };
    this.function = function (object) {
      var getType = {};
      return object && getType.toString.call(object) === '[object Function]';
    };
    this.string = function (object) {
      return {}.toString.call(object) == '[object String]';
    };
    this.numeric = function (object) {
      return !me.is.array(object) && object - parseFloat(object) + 1 >= 0;
    };
    this.boolean = function (object) {
      return typeof object === 'boolean';
    };
    this.object = function (object) {
      return me.is.sameType(object, i.obj);
    };
    this.sameType = function (var1, var2) {
      return me.tools.getType(var1) === me.tools.getType(var2);
    };
    this.validDate = function (object) {
      if (
        !me.is.object(object) &&
        !me.is.set(object.year) &&
        !me.is.set(object.month) &&
        !me.is.set(day)
      )
        return false;

      var day = parseInt(object.day),
        month = parseInt(object.month),
        year = parseInt(object.year);

      // Check the ranges of month and year
      if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

      var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      // Adjust for leap years
      if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthLength[1] = 29;

      // Check the range of the day
      return day > 0 && day <= monthLength[month - 1];
    };
    this.any = function (obj, func) {
      var flag = false,
        condition = me.is.function(func)
          ? func
          : function (x) {
              return x == func;
            };

      me.all(obj, function (value, key, event) {
        if (condition(value, key, event)) {
          flag = true;
          event.Stop = true;
        }
      });
      return flag;
    };
    this.all = function (obj, func) {
      var flag = true,
        condition = me.is.function(func)
          ? func
          : function (x) {
              return x == func;
            };

      me.all(obj, function (value, key, event) {
        if (!condition(value, key, event)) {
          flag = false;
          event.Stop = true;
        }
      });
      return flag;
    };
  })();
  me.parse = new (function () {
    this.letterGroups = function (items, nameField, whiteout) {
      var retVal = [];
      // allow to pass whiteout var as null to make it an optional argument
      if (whiteout != null) {
        me.tools.all(items, v => {
          var letter = v[nameField].whiteout(whiteout)[0].toUpperCase();
          var item = me.tools.search(retVal, s => {
            return s.header == letter;
          });
          if (letter != '') {
            if (item != null) {
              if (
                !me.tools.contains(item.subItems, x => {
                  return x[nameField] == v[nameField];
                })
              )
                item.subItems.push(v);
            } else {
              var array = [];
              array.push(v);
              retVal.push({ header: letter, subItems: array });
            }
          }
        });
      } else {
        me.tools.all(items, v => {
          var letter = v[nameField].slice(0, 1);
          var item = me.tools.search(retVal, s => {
            return s.header == letter;
          });
          if (letter != '') {
            if (item != null) {
              if (
                !me.tools.contains(item.subItems, x => {
                  return x[nameField] == v[nameField];
                })
              )
                item.subItems.push(v);
            } else {
              var array = [];
              array.push(v);
              retVal.push({ header: letter, subItems: array });
            }
          }
        });
      }
      retVal = me.tools.sort(retVal, {
        key: v => {
          return v.header;
        },
      });
      return retVal;
    };
    this.url = function (url) {
      var navItems = me.tools.filter(url.split('/'), v => {
          return v != '';
        }),
        params = [];
      if (url.contains('?')) {
        var temp = navItems[navItems.length - 1].split('?');
        navItems[navItems.length - 1] = temp[0];
        params = temp[1].split('&');
        for (var p in params) {
          var t = params[p].split('=');
          var obj = { key: t[0], value: t[1] };
          params[p] = obj;
        }
      }
      return { nav: navItems, params: params };
    };
    this.csv = function (options) {
      var opt = me.tools.defaultOrUpdate(
        { headers: [], rows: [], title: '' },
        options
      );
      var retVal = [],
        max = 10000,
        string = '';
      if (opt.title != '') string += opt.title + '\n\n';
      me.tools.all(opt.headers, function (header) {
        string += me.parse.escape(header) + ',';
      });
      string += '\n';
      me.tools.all(opt.rows, function (row) {
        me.tools.all(row, function (rec) {
          string += me.parse.escape(rec) + ',';
        });
        string += '\n';
        if (string.length > max) {
          retVal.push(string.slice());
          string = '';
        }
      });
      if (string.length > 0) retVal.push(string.slice());
      return retVal;
    };
    this.escape = value => {
      if (!me.is.set(value)) return '';
      var retVal = value.toString();
      if (retVal.indexOf('"') != -1) {
        retVal = retVal.replace(/"/g, '""');
      }
      if (retVal.match(/"|,/)) {
        retVal = '"' + retVal + '"';
      }
      return retVal;
    };
  })();
  me.new = new (function () {
    this.guid = function () {
      return (
        me.math.r16() +
        me.math.r16() +
        '-' +
        me.math.r16() +
        '-' +
        me.math.r16() +
        '-' +
        me.math.r16() +
        '-' +
        me.math.r16() +
        me.math.r16() +
        me.math.r16()
      );
    };
    this.stopWatch = function (o) {
      var obj = new (function () {
        var watch = this;
        this.Init = function (options) {
          if ('undefined' !== typeof options) {
            for (var i in options) {
              if ('undefined' !== typeof options[i]) {
                watch.Options[i] = options[i];
              }
            }
          }
        };
        this.Start = function () {
          watch.Var.Clock = setInterval(watch.Update, watch.Options.TickRate);
          watch.Var.LastStarted = watch.Var.LastStarted
            ? watch.Var.LastStarted
            : watch.Now();
        };
        this.Stop = function () {
          watch.Var.LapsedTime = watch.Var.LastStarted
            ? watch.Var.LapsedTime + watch.Now() - watch.Var.LastStarted
            : watch.Var.LapsedTime;
          watch.Var.LastStarted = 0;
          clearInterval(watch.Var.Clock);
        };
        this.Reset = function () {
          watch.Stop();
          watch.Var.LapsedTime = watch.Var.LastStarted = 0;
          watch.Update();
        };
        this.Update = function () {
          watch.Options.OnTick(watch.GetTime());
        };
        this.GetRawTime = function () {
          return (
            watch.Var.LapsedTime +
            (watch.Var.LastStarted ? watch.Now() - watch.Var.LastStarted : 0)
          );
        };
        this.GetTime = function () {
          var h = 0,
            m = 0,
            s = 0,
            ms = 0;
          var time = watch.GetRawTime();

          h = Math.floor(time / (60 * 60 * 1000));
          time = time % (60 * 60 * 1000);
          m = Math.floor(time / (60 * 1000));
          time = time % (60 * 1000);
          s = Math.floor(time / 1000);
          ms = time % 1000;

          return {
            Hours: watch.Pad(h, 2),
            Minutes: watch.Pad(m, 2),
            Seconds: watch.Pad(s, 2),
            MiliSeconds: watch.Pad(ms, 3),
            Raw: time,
          };
        };
        this.Pad = function (num, size) {
          var s = '0000' + num;
          return s.substr(s.length - size);
        };
        this.Now = function () {
          return new Date().getTime();
        };
        this.Var = new (function () {
          this.LastStarted = 0;
          this.LapsedTime = 0;
          this.Clock = null;
        })();
        this.Options = new (function () {
          this.OnTick = function (time) {};
          this.TickRate = 1;
        })();
      })();

      obj.Init(o);
      return obj;
    };
    this.config = function (obj) {
      var conf = new (function () {
        var config = this;
        var h = new (function () {
          this.registry = {};
        })();

        this.Update = function (c, deep) {
          if (me.is.defined(c)) {
            if (me.is.defined(deep) && deep) me.tools.fuse(config.i, c, true);
            else me.tools.fuse(config.i, c);
            me.tools.all(h.registry, function (func, key, event) {
              if (me.is.defined(func)) func(config.i, c);
            });
          }
        };
        this.CopyTo = function (o) {
          if (me.is.defined(o))
            if (me.is.defined(o.i)) return me.tools.fuse(o.i, config.i);
            else return me.tools.fuse(o, config.i);
          else return me.tools.fuse({}, config.i);
        };
        this.Get = function (key) {
          if (me.is.defined(key)) {
            return config.i[key];
          }
        };
        this.Set = function (key, value) {
          if (me.is.defined(key)) {
            config.i[key] = value;
          }
        };
        this.AddHandler = function (func, key) {
          if (h.hasOwnProperty(key)) h.registry[key] = func;
        };
        this.RemoveHandler = function (key) {
          if (h.hasOwnProperty(key)) delete h[key];
        };
        this.i = {};
        this.Identifier = 'Config Object';
      })();
      if (me.is.defined(obj)) conf.Update(obj);
      return conf;
    };
    this.viewMaster = function (obj) {
      var viewMaster = new (function () {
        var viewer = this;
        var internal = me.new.config(
          new (function () {
            this.Views = [];
            this.Update = function (view) {};
            this.Current = null;
            this.CurrentIndex = null;
            this.Default = '';
          })()
        );

        this.Get = function (viewName) {
          return me.tools.search(internal.i.Views, function (value, idx) {
            value.Index = idx;
            return value.Name == viewName;
          });
        };
        this.Set = function (viewName) {
          if (me.is.defined(viewName)) {
            internal.i.Current = viewer.Get(viewName);
            if (internal.i.Current) {
              internal.i.Update(internal.i.Current);
              me.is.definedExec(internal.i.Current.Update, function (v) {
                v(internal.i.Current);
              });
            }
          } else if (me.is.defined(internal.i.Default))
            viewer.Set(internal.i.Default);
        };
        this.Modify = function (viewName, viewData, deep) {
          var view = viewer.Get(viewName);
          me.tools.fuse(internal.i.Views[view.Index], viewData, deep);
        };
        this.CurrentView = function () {
          return internal.i.Current;
        };
        this.Update = function (options, deep) {
          internal.Update(options, deep);
          me.tools.all(internal.i.Views, function (view, idx) {
            view.Index = idx;
          });
        };
        this.HasViews = function () {
          return internal.i.Views.length > 0;
        };
        this.GetViews = function () {
          return internal.i.Views;
        };
        this.GetInternal = function () {
          return {
            Views: internal.i.Views,
            Current: internal.i.Current,
            CurrentIndex: internal.i.CurrentIndex,
            Default: internal.i.Default,
          };
        };
        this.Identifier = 'Config Object';
      })();
      viewMaster.Update(obj);
      return viewMaster;
    };
    this.eventMaster = function (obj) {
      var eventMaster = new (function () {
        var event = this;
        var internal = me.new.config({});

        this.Trigger = function (eventName, data) {
          var bData = event.EventData(data);
          event.TriggerSpecific('Base' + eventName, bData);

          if (bData.isCancelled) {
            data.isCancelled = true;
            return;
          }

          var aData = event.EventData(data);
          event.TriggerSpecific(eventName, aData);

          if (aData.isCancelled) {
            data.isCancelled = true;
            return;
          }

          data.onComplete = function (data2) {
            me.is.definedExec(bData.onComplete, function (v) {
              v(data2);
            });
            me.is.definedExec(aData.onComplete, function (v) {
              v(data2);
            });
          };
        };
        this.TriggerSpecific = function (eventName, data) {
          var func = internal.i[eventName];
          if (func != undefined) func(data);
        };
        this.EventData = function (obj) {
          var data = {
            onComplete: function (data) {},
            isCancelled: false,
            isDelayed: false,
            delayTime: 0,
            phase: '',
          };
          if (me.is.defined(obj)) me.tools.fuse(data, obj);
          return data;
        };
        this.Update = function (options) {
          internal.Update(options);
        };
        this.GetInternal = function () {
          return internal.i;
        };
        this.Identifier = 'Config Object';
      })();
      eventMaster.Update(obj);
      return eventMaster;
    };
    this.simpleRender = function (obj) {
      var render = new (function () {
        var internal = me.new.config(
          new (function () {
            this.Data = {};
            this.Generate = function (data, methods) {};
            this.Remove = function (data, methods) {};
          })()
        );

        this.Generate = function (para) {
          internal.i.Generate(internal.i, para);
        };
        this.Remove = function (para) {
          internal.i.Remove(internal.i, para);
        };
        this.Update = function (obj) {
          internal.Update(obj);
        };
        this.Identifier = 'Simple Render Object';
      })();

      render.Update(obj);
      return render;
    };
    this.indexedArray = function (obj) {
      var ia = new (function () {
        var idxArray = this;
        var internal = me.new.config({
          list: [],
          index: [],
          _index: {},
        });
        this.add = item => {
          internal.i.list.push(item);
          var location = internal.i.list.indexOf(item);
          me.tools.all(internal.i._index, (x, idx) => {
            x[item[idx]] = location;
          });
        };
        this.addAll = items => {
          me.tools.all(items, i => idxArray.add(i));
        };
        this.addIndex = index => {
          internal.i.index.push(index);
          internal.i._index[index] = {};
          me.tools.all(internal.i.list, (x, idx) => {
            internal.i._index[index][x[index]] = idx;
          });
        };
        this.clear = () => {
          internal.i.list = [];
          internal.i._index = {};
        };
        this.contains = item => {
          return idxArray.indexOf(item) > -1;
        };
        this.count = () => {
          return internal.i.list.length;
        };
        this.forEach = func => {
          me.tools.all(internal.i.list, func);
        };
        this.indexOf = item => {
          return internal.i.list.indexOf(item);
        };
        this.lookUp = (id, key) => {
          if (me.is.defined(key))
            return internal.i.list[internal.i._index[key][id]];
          else if (me.tools.count(internal.i._index) > 0) {
            var keyFrag = me.tools.first(internal.i._index, true);
            if (keyFrag != null)
              return internal.i.list[internal.i._index[keyFrag][id]];
          }
        };
        this.remove = item => {
          me.tools.all(internal.i._index, (x, key) => {
            delete internal.i.list[internal.i._index[key][item[key]]];
          });
          internal.i.list.splice(idxArray.indexOf(item), 1);
        };
        this.removeAll = items => {
          me.tools.all(items, i => idxArray.remove(i));
        };
        this.removeIndex = index => {
          internal.i.index.splice(internal.i.index.indexOf(index), 1);
          delete internal.i._index[index];
        };
        this.reIndex = () => {
          internal.i._index = {};
          me.tools.all(internal.i.index, id => {
            internal.i._index[id] = {};
            me.tools.all(internal.i.list, (x, idx) => {
              internal.i._index[id][x[id]] = idx;
            });
          });
        };
        this.update = obj => {
          internal.Update(obj);
        };
      })();

      ia.update(obj);
      ia.reIndex();
      return ia;
    };
    this.linkedList = function (obj) {
      var list = new (function () {
        var ll = this;
        this.start = null;
        this.end = null;

        this.add = function (data) {
          if (ll.start === null) {
            ll.start = ll.newNode();
            ll.end = ll.start;
          } else {
            ll.end.next = ll.newNode();
            ll.end = ll.end.next;
          }
          ll.end.data = data;
        };
        this.delete = function (data) {
          var current = ll.start;
          var previous = ll.start;
          while (current !== null) {
            if (data === current.data) {
              if (current === ll.start) {
                ll.start = current.next;
                return;
              }
              if (current === ll.end) ll.end = previous;
              previous.next = current.next;
              return;
            }
            previous = current;
            current = current.next;
          }
        };
        this.each = function (f) {
          var current = ll.start;
          while (current !== null) {
            f(current);
            current = current.next;
          }
        };
        this.insertFirst = function (data) {
          var temp = ll.newNode();
          temp.next = ll.start;
          ll.start = temp;
          temp.data = data;
        };
        this.insertAfter = function (node, data) {
          var current = ll.start;
          while (current !== null) {
            if (current.data === node) {
              var temp = ll.newNode();
              temp.data = data;
              temp.next = current.next;
              if (current === ll.end) ll.end = temp;
              current.next = temp;
              return;
            }
            current = current.next;
          }
        };
        this.item = function (i) {
          var current = ll.start;
          while (current !== null) {
            i--;
            if (i === 0) return current;
            current = current.next;
          }
          return null;
        };
        this.newNode = function () {
          return { data: null, next: null };
        };
      })();
      return list;
    };
    this.overwrite = function (obj) {
      var replace = new (function () {
        var internal = {
          Content: null,
        };
        this.Content = function () {
          return internal.Content;
        };
        this.Update = function (obj) {
          internal.Content = obj;
        };
        this.Identifier = 'Replace Object';
      })();
      replace.Update(obj);
      return replace;
    };
    this.el = function (obj) {
      var element = new (function () {
        var self = this;
        var internal = {
          noEndtag: ['img', 'br', 'input', 'link'],
        };
        this.tag = 'div';
        this.html = [];
        this.style = me.new.styleParser({});
        this.attr = me.new.attrParser({});
        this.identifier = 'Element Parser';

        this.set = function (options) {
          me.is.definedExec(options.tag, v => {
            self.tag = v;
          });
          me.is.definedExec(options.html, v => {
            self.setHtml(v);
          });
          me.is.definedExec(options.style, v => {
            self.setStyle(v);
          });
          me.is.definedExec(options.attr, v => {
            self.setAttr(v);
          });
        };
        this.setHtml = function (html) {
          if (me.is.set(html)) {
            if (me.is.array(html)) this.html = html;
            else this.html = [html];
          }
        };
        this.setStyle = function (style) {
          this.style.clear();
          this.style.merge(style);
        };
        this.setAttr = function (attr) {
          this.attr.clear();
          this.attr.merge(attr);
        };
        this.append = function (item) {
          if (
            me.is.definedType(item, i.obj) &&
            item.identifier != self.identifier
          )
            this.html.push(me.new.el(item));
          else this.html.push(item);
          return self;
        };
        this.appendEach = function (list, func) {
          if (me.is.definedType(func, i.function))
            me.tools.all(list, v => {
              self.append(func(v));
            });
          else
            me.tools.all(list, v => {
              self.append(v);
            });
          return self;
        };
        this.toString = function () {
          var content = '';
          me.tools.all(this.html, h => {
            if (me.is.definedType(h, i.obj) && h.identifier != self.identifier)
              content += me.new.el(h).toString();
            else content += h.toString();
          });
          this.attr.set('style', this.style.toString());
          if (
            me.tools.contains(internal.noEndtag, t => {
              return self.tag == t;
            }) &&
            this.html.length == 0
          )
            return '<{0} {1}" />'.format(this.tag, this.attr.toString());
          return '<{0} {1}>{2}</{3}>'.format(
            this.tag,
            this.attr.toString(),
            content,
            this.tag
          );
        };
      })();

      element.set(obj);
      return element;
    };
    this.styleParser = function (style) {
      var parser = new (function () {
        var internal = {};

        this.clear = function () {
          internal = {};
        };
        this.toString = function () {
          var retVal = '';
          me.tools.all(internal, (v, k) => {
            if (me.is.defined(v)) retVal += k + ':' + v + ';';
          });
          return retVal;
        };
        this.toObject = function () {
          return internal;
        };
        this.set = function (prop, value) {
          internal[prop] = value;
        };
        this.get = function (prop) {
          return internal[prop];
        };
        this.remove = function (prop) {
          delete internal[prop];
        };
        this.merge = function (style) {
          if (me.is.defined(style)) {
            if (me.is.sameType(style, i.string)) {
              var values = style.split(';');
              me.tools.all(values, p => {
                if (p != '') {
                  var a = p.split(/:(.+)/);
                  internal[a[0].trim()] = a[1].trim();
                }
              });
            } else if (me.is.sameType(style, i.obj))
              me.tools.fuse(internal, style);
          }
        };
      })();
      parser.merge(style);
      return parser;
    };
    this.attrParser = function (attr) {
      var parser = new (function () {
        var internal = {};

        this.clear = function () {
          internal = {};
        };
        this.toString = function () {
          var retVal = '';
          me.tools.all(internal, (v, k) => {
            if (me.is.defined(v)) retVal += k + '="' + v + '" ';
          });
          return retVal;
        };
        this.toObject = function () {
          return internal;
        };
        this.append = function (prop, value) {
          internal[prop] += ' ' + value;
        };
        this.set = function (prop, value) {
          internal[prop] = value;
        };
        this.get = function (prop) {
          return internal[prop];
        };
        this.remove = function (prop) {
          delete internal[prop];
        };
        this.merge = function (style) {
          if (me.is.defined(style)) {
            if (me.is.sameType(style, i.string)) {
              var values = style.split(' ');
              me.tools.all(values, p => {
                if (p != '') {
                  var a = p.split('=');
                  internal[a[0].trim()] = a[1].replace('"', '').trim();
                }
              });
            } else if (me.is.sameType(style, i.obj))
              me.tools.fuse(internal, style);
          }
        };
      })();
      parser.merge(attr);
      return parser;
    };
    this.treeNode = function (obj, hidden) {
      var retVal = me.tools.defaultOrUpdate(
        {
          text: '',
          style: '',
          icon: '',
          checkbox: false,
          isChecked: false,
          visible: me.is.definedState(hidden, true) ? false : true,
          expanded: me.is.definedState(hidden, true) ? false : true,
          expandIcon: 'fa-chevron-down',
          minimizeIcon: 'fa-chevron-right',
          children: [],
          textStyle: 'cursor: pointer;',
          style: 'list-style-type: none;',
        },
        obj
      );
      retVal.toggleIcon = retVal.expanded
        ? 'fa-chevron-down'
        : 'fa-chevron-right';
      return retVal;
    };
    this.customEvent = function (eventName, data, cancelable = false) {
      var e;
      if (window.CustomEvent) {
        e = new CustomEvent(eventName, {
          bubbles: true,
          details: data,
          cancelable: cancelable,
        });
      } else {
        e = document.createEvent('CustomEvent');
        e.initCustomEvent(eventName, true, true, data);
      }
      return e;
    };
    this.contract = function (action, delay, maxTime) {
      var contract = new (function () {
        var self = this;
        var opt = {
          list: [],
          func: action,
          waitPeriod: delay ? delay : 1000,
          maxTime: maxTime ? maxTime : 20000,
          elapsedTime: 0,
        };
        this.newTask = () => {
          var item = { complete: false };
          opt.list.push(item);
          return item;
        };
        this.hasUncompleteTasks = () => {
          var retVal = false;
          me.all(opt.list, task => {
            if (!task.complete) retVal = true;
          });
          return retVal;
        };
        this.process = () => {
          if (opt.elapsedTime < opt.maxTime) {
            opt.elapsedTime += opt.waitPeriod;
            if (self.hasUncompleteTasks())
              setTimeout(() => {
                self.process();
              }, opt.waitPeriod);
            else
              setTimeout(() => {
                opt.func();
              }, opt.waitPeriod);
          }
        };
      })();
      return contract;
    };
  })();
  me.workarounds = new (function () {
    this.getBBox = function (element) {
      if (navigator.userAgent.contains('firefox', true)) {
        return { width: 110, height: 12 };
        //return element.getBoundingClientRect();
      } else {
        return element.getBBox();
      }
    };
  })();
})();

// shorter access while preserving old subclassing
AA.tools.all(AA.tools, (f, k) => {
  if (AA[k])
    console.log('AA Error - Replacing Existing Function: {0}'.format(k));
  AA[k] = f;
});

export default AA;
