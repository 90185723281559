import React from 'react';

function Dropdown({ items, selection, setSelection }) {
  return (
    <form className="career-chart-dropdown">
      <select
        className="select"
        value={selection}
        onChange={e => {
          setSelection(e.target.value);
        }}
      >
        <option value={items[0].value}>{items[0].name}</option>
        <option value={items[1].value}>{items[1].name}</option>
      </select>
    </form>
  );
}

export default Dropdown;
