const eventsList = [
  {
    header: 'ICARE Academy: Refining IACUC Function (IA Refining)',
    date: 'Wednesday, December 02, 2020',
    dateTime:
      'Tuesday, October 27, 2020 - 1:00pm to Friday, October 30, 2020 - 4:45pm',
    audience:
      'You should attend the IA Refining if you are an Institutional Animal Care and Use Committee (IACUC) member or staff, Institutional Official, veterinarian, animal facility personnel, administrator, consultant, or compliance and regulatory personnel, and are already familiar with federal animal welfare oversight standards.',
    purpose:
      'The Interagency Collaborative Animal Research Education (ICARE) Project provides training for U.S. IACUC members and animal program personnel. IA Refining (Refining IACUC Function) is a fast-paced 3-day workshop that provides participants with an understanding of animal welfare oversight concepts, enables them to correct misunderstandings of federal standards, and learn methods for more efficient and effective animal program and IACUC functioning.',
    location: 'Denver, CO',
    webLinks: 'https://olaw.nih.gov/education/icare-interagency',
    eventURL: 'https://events.espinc-usa.com/icareia042020',
    className: 'event-link',
  },
  {
    header: 'NIH Virtual Seminar on Program Funding and Grants Administration',
    date: 'Tuesday, October 27, 2020',
    dateTime:
      'Tuesday, October 27, 2020 - 1:00pm to Friday, October 30, 2020 - 4:45pm',
    audience: '',
    purpose: '',
    location: 'Online',
    webLinks: 'https://grants.nih.gov/news/contact-in-person/seminars.htm',
    eventURL: 'https://nihvirtualseminar2020.vfairs.com/',
    className: 'event-link',
  },
];

export default eventsList;
