/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useContext } from 'react';
import { Link, Redirect } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import UnitProposalsTable from './unitProposals/UnitProposalsTable';
import ProjectionsPlatformNav from './unitProjections/projectionsPlatformNav/ProjectionsPlatformNav';
import CareerProgression from '../careerprogression/CareerProgressions';
import styles from './styles/Unit.module.scss';
import './styles/unit.css';
// CareerProgression Data
import nursingData from './careerProgressionData/nursing/nursing';

import vBiosciences from './careerProgressionData/veterinary/veterinaryBioscience';
import vClinicalSciences from './careerProgressionData/veterinary/veterinaryClinicalSciences';
import vPreventiveMedicine from './careerProgressionData/veterinary/veterinaryPreventiveMedicine';

import medicinalChemistryPharmacognosy from './careerProgressionData/pharmacy/medicalChemistryPharmacognosy';
import pharmaceuticsPharmaceuticalChemistry from './careerProgressionData/pharmacy/pharmaceuticsPharmaceuticalChemistry';
import pharmacyPracticeScience from './careerProgressionData/pharmacy/pharmacyPracticeScience';
import pharmacology from './careerProgressionData/pharmacy/pharmacology';

import biomedicalInformatics from './careerProgressionData/medicine/biomedicalInformatics';
import healthRehabilitationSciences from './careerProgressionData/medicine/healthRehabilitationSciences';
import biologicalChemistryPharmacology from './careerProgressionData/medicine/biologicalChemistryPharmacology';
import neuroscience from './careerProgressionData/medicine/neuroscience';
import pathology from './careerProgressionData/medicine/pathology';
import physicalMedicineRehabilitation from './careerProgressionData/medicine/physicalMedicineRehabilitation';
import physiologyCellBiology from './careerProgressionData/medicine/physiologyCellBiology';
import biomedicalEducationAnatomy from './careerProgressionData/medicine/biomedicalEducationAnatomy';

const careerProgressionDataMap = {
  'Nursing, College of': nursingData,
  'Veterinary Biosciences, Department of': vBiosciences,
  'Veterinary Clinical Sciences, Department of': vClinicalSciences,
  'Veterinary Preventive Medicine, Department of': vPreventiveMedicine,
  'Medicinal Chemistry and Pharmacognosy, Division of': medicinalChemistryPharmacognosy,
  'Pharmaceutics and Pharmaceutical Chemistry, Division of': pharmaceuticsPharmaceuticalChemistry,
  'Pharmacy Practice and Science, Division of': pharmacyPracticeScience,
  'Pharmacology, Division of': pharmacology,
  'Biomedical Informatics, Department of': biomedicalInformatics,
  'Health and Rehabilitation Sciences, School of': healthRehabilitationSciences,
  'Biological Chemistry and Pharmacology, Department of': biologicalChemistryPharmacology,
  'Neuroscience, Department of': neuroscience,
  'Pathology, Department of': pathology,
  'Physical Medicine and Rehabilitation, Department of': physicalMedicineRehabilitation,
  'Physiology and Cell Biology, Department of': physiologyCellBiology,
  'Biomedical Education and Anatomy, Department of': biomedicalEducationAnatomy,
};

const Unit = props => {
  const formatName = name => {
    if (name.indexOf(',') > -1) {
      return name.split(',').reverse().join(' ');
    }
  };

  const tabs = [
    {
      id: 1,
      label: 'Proposals',
      description: <UnitProposalsTable unit={props.unit} />,
    },
    {
      id: 2,
      label: 'Projections of Core Proposal Totals and Conversion Ratios',
      description: <ProjectionsPlatformNav unit={props.unit} />,
    },
    {
      id: 3,
      label: 'Career Progression',
      description: (
        <CareerProgression
          unit={props.unit}
          chartData={careerProgressionDataMap[props.unit.Name]}
        />
      ),
    },
  ];

  const CustomToggleBtn = ({ eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext); // <-- Will update every time the eventKey changes.

    const toggleOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <Button onClick={toggleOnClick}>
        {isCurrentEventKey ? 'Click to hide' : 'Click to show'}
      </Button>
    );
  };

  return (
    <>
      {props.unit.Id ? (
        <div className={styles.container}>
          {props.unit.Name && (
            <>
              <Link
                className={styles.breadcrumbs}
                to={{
                  pathname: `/college/${props.unit.CollegeId}`,
                }}
              >
                {formatName(props.unit.CollegeName)}
              </Link>
              <FontAwesomeIcon
                icon={faCaretRight}
                className={styles.breadcrumbs}
              />
              <span className={styles.breadcrumbs}>
                {formatName(props.unit.Name)}
              </span>
            </>
          )}
          {props.unit && (
            <>
              <h1 className={styles.header}>
                {props.unit.Name ? formatName(props.unit.Name) : <></>}
              </h1>
              {tabs.map(tab => (
                <Accordion key={tab.id} defaultActiveKey={tab.id}>
                  <Card>
                    <Card.Header>
                      <CustomToggleBtn eventKey={tab.id} />
                      <h5
                        className={
                          tab.id === 1
                            ? 'acr-unit-proposals-header'
                            : 'acr-unit-projections-header'
                        }
                      >
                        {tab.label}
                      </h5>
                    </Card.Header>
                    <Accordion.Collapse eventKey={tab.id}>
                      <Card.Body>{tab.description}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              ))}
            </>
          )}
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default Unit;
