import React, { useState } from 'react';
import './styles/Navigation.css';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link } from 'react-router-dom';
import osuLogo from '../../assets/images/osu-logo.png';
import 'bootstrap/dist/css/bootstrap.min.css';

export default function Navigation(props) {
  const [expanded, setExpanded] = useState(false);
  const formatName = name => {
    if (name.indexOf(',') > -1) {
      return name.split(',').reverse().join(' ');
    }
  };

  return (
    <>
      <Navbar expand="lg" variant="dark" expanded={expanded}>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          onClick={() => setExpanded(expanded ? false : 'expanded')}
        />
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="mr-auto">
            <Link
              id="logo"
              className="college-list"
              to="/"
              onClick={() => setExpanded(false)}
            >
              Funding Forecast
            </Link>
            <NavDropdown title="Colleges" id="basic-nav-dropdown">
              {props.colleges &&
                props.colleges.map((college, index) => {
                  return (
                    <Link
                      to={{
                        pathname: `/college/${college.Id}`,
                      }}
                      className="college-list"
                      id={
                        props.college && props.college.id === college.Id
                          ? 'selected-college'
                          : ''
                      }
                      key={index}
                      onClick={() => {
                        setExpanded(false);
                        document.body.click();
                        props.handleOnClickCollege(college);
                      }}
                    >
                      {formatName(college.Name)}
                    </Link>
                  );
                })}
            </NavDropdown>

            {props.unit.Id ? (
              <>
                <Link to={`/unit/${props.unit.Id}`} className="nav-link">
                  Department
                </Link>
              </>
            ) : (
              <></>
            )}
          </Nav>
        </Navbar.Collapse>
        <img className="universityLogo" src={osuLogo} alt="university logo" />
      </Navbar>
    </>
  );
}
