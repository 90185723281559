const data = {
  maxObservations: 0,
  dataPoints: [],
  facultyDataPoints: [],
  unit: {
    indexScore: 0,
  },
};

export default data;
