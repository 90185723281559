const data = {
  maxObservations: 82,
  dataPoints: [
    {
      yearsSincePhD: 0,
      observations: 1,
      index: -1.74,
      awards: -0.58,
      books: -0.29,
      grants: -0.93,
      grantDol: -0.74,
      articles: -0.77,
      citations: -0.51,
      confProc: -0.24,
      articlesActual: 1,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 1,
      observations: 3,
      index: -0.576666,
      awards: -0.443333,
      books: -0.29,
      grants: -0.93,
      grantDol: -0.74,
      articles: -0.04,
      citations: -0.403333,
      confProc: -0.003333,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 81,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 2,
      observations: 7,
      index: -0.75,
      awards: -0.462857,
      books: -0.29,
      grants: -0.93,
      grantDol: -0.74,
      articles: -0.048571,
      citations: -0.175714,
      confProc: -0.24,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 258,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 3,
      observations: 6,
      index: -0.74,
      awards: -0.511666,
      books: -0.29,
      grants: -0.93,
      grantDol: -0.74,
      articles: -0.343333,
      citations: -0.356666,
      confProc: -0.121666,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 117,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 4,
      observations: 10,
      index: -0.469,
      awards: -0.457,
      books: 0.059,
      grants: -0.93,
      grantDol: -0.74,
      articles: -0.214,
      citations: 0.441,
      confProc: -0.24,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 742,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 5,
      observations: 12,
      index: -0.61,
      awards: -0.4775,
      books: -0.29,
      grants: -0.838333,
      grantDol: -0.71,
      articles: -0.345,
      citations: 0.064166,
      confProc: -0.121666,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 446,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 23301.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 6,
      observations: 24,
      index: -0.504583,
      awards: -0.443333,
      books: -0.29,
      grants: -0.724166,
      grantDol: -0.62,
      articles: -0.391666,
      citations: -0.185,
      confProc: -0.003333,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 250,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 92949.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 7,
      observations: 62,
      index: -0.218709,
      awards: -0.101451,
      books: -0.252258,
      grants: -0.550161,
      grantDol: -0.467096,
      articles: -0.165322,
      citations: -0.021129,
      confProc: -0.068225,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 379,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 211141.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 8,
      observations: 49,
      index: -0.099591,
      awards: -0.084693,
      books: -0.242244,
      grants: -0.438163,
      grantDol: -0.33,
      articles: -0.231632,
      citations: -0.108979,
      confProc: -0.18204,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 310,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 316778.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 9,
      observations: 58,
      index: -0.20431,
      awards: -0.126206,
      books: -0.209482,
      grants: -0.43,
      grantDol: -0.390862,
      articles: -0.174827,
      citations: -0.155,
      confProc: 0.02931,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 274,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 270178.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 10,
      observations: 79,
      index: -0.084683,
      awards: 0.139493,
      books: -0.216202,
      grants: -0.299493,
      grantDol: -0.170126,
      articles: -0.150506,
      citations: 0.198987,
      confProc: 0.047721,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 552,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 440360.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 11,
      observations: 68,
      index: -0.070294,
      awards: 0.086323,
      books: -0.272794,
      grants: -0.141323,
      grantDol: -0.109117,
      articles: -0.187794,
      citations: -0.015147,
      confProc: -0.198235,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 384,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 487789.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 12,
      observations: 70,
      index: -0.036571,
      awards: -0.056285,
      books: -0.206714,
      grants: -0.085857,
      grantDol: -0.033428,
      articles: -0.125,
      citations: -0.084571,
      confProc: 0.023714,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 329,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 546077.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 13,
      observations: 67,
      index: -0.022835,
      awards: -0.070447,
      books: -0.185373,
      grants: -0.039552,
      grantDol: -0.122537,
      articles: -0.183283,
      citations: -0.188208,
      confProc: 0.056865,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 248,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 477593.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 14,
      observations: 67,
      index: -0.006865,
      awards: -0.094328,
      books: -0.203134,
      grants: 0.11597,
      grantDol: 0.124179,
      articles: 0.131641,
      citations: 0.27,
      confProc: 0.194626,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 608,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 667504.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 15,
      observations: 66,
      index: 0.256818,
      awards: 0.050454,
      books: -0.183787,
      grants: 0.089545,
      grantDol: 0.06303,
      articles: 0.026515,
      citations: -0.024393,
      confProc: -0.089393,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 376,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 620932.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 16,
      observations: 63,
      index: 0.19746,
      awards: -0.083492,
      books: -0.234285,
      grants: 0.190317,
      grantDol: 0.202539,
      articles: 0.111746,
      citations: 0.044285,
      confProc: 0.301428,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 431,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 728636.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 17,
      observations: 64,
      index: 0.335781,
      awards: -0.02,
      books: -0.071093,
      grants: 0.138281,
      grantDol: 0.146406,
      articles: 0.143906,
      citations: 0.073593,
      confProc: 0.204218,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 453,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 684419.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 18,
      observations: 70,
      index: 0.158571,
      awards: -0.197857,
      books: -0.239857,
      grants: 0.101714,
      grantDol: 0.026285,
      articles: 0.095857,
      citations: -0.093,
      confProc: 0.186285,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 323,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 592264.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 19,
      observations: 76,
      index: 0.17,
      awards: -0.113815,
      books: -0.075394,
      grants: 0.222236,
      grantDol: 0.164473,
      articles: 0.132105,
      citations: -0.051184,
      confProc: -0.071842,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 356,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 698523.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 20,
      observations: 55,
      index: 0.004,
      awards: -0.152727,
      books: -0.056545,
      grants: 0.065272,
      grantDol: -0.016,
      articles: -0.042909,
      citations: -0.040363,
      confProc: 0.39309,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 364,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 559648.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 21,
      observations: 82,
      index: 0.362682,
      awards: 0.00817,
      books: 0.008414,
      grants: 0.532195,
      grantDol: 0.426951,
      articles: 0.129024,
      citations: 0.035975,
      confProc: 0.072073,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 424,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 901459.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 22,
      observations: 66,
      index: 0.282727,
      awards: -0.093636,
      books: 0.133636,
      grants: 0.488333,
      grantDol: 0.332424,
      articles: -0.01803,
      citations: -0.113636,
      confProc: -0.110909,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 307,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 828578.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 23,
      observations: 69,
      index: 0.03913,
      awards: -0.227826,
      books: -0.053768,
      grants: 0.18,
      grantDol: 0.125362,
      articles: 0.082173,
      citations: 0.005072,
      confProc: -0.106231,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 400,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 668975.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 24,
      observations: 57,
      index: 0.178596,
      awards: 0.005964,
      books: -0.085438,
      grants: 0.328245,
      grantDol: 0.171228,
      articles: 0.102807,
      citations: 0.019649,
      confProc: -0.028245,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 412,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 704056.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 25,
      observations: 55,
      index: 0.368727,
      awards: -0.026181,
      books: 0.197454,
      grants: 0.334,
      grantDol: 0.18509,
      articles: 0.482363,
      citations: 0.208181,
      confProc: 0.108545,
      articlesActual: 22,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 559,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 715169.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 26,
      observations: 77,
      index: -0.120779,
      awards: -0.152077,
      books: 0.058441,
      grants: 0.015844,
      grantDol: -0.100909,
      articles: -0.135844,
      citations: -0.087922,
      confProc: 0.0,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 327,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 493810.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 27,
      observations: 49,
      index: -0.071428,
      awards: -0.126326,
      books: -0.028163,
      grants: 0.052653,
      grantDol: -0.023673,
      articles: -0.07653,
      citations: -0.149795,
      confProc: -0.036938,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 279,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 553615.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 28,
      observations: 62,
      index: 0.31129,
      awards: 0.205161,
      books: 0.067903,
      grants: 0.571129,
      grantDol: 0.444677,
      articles: 0.089677,
      citations: -0.034032,
      confProc: -0.091129,
      articlesActual: 15,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 369,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 914933.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 29,
      observations: 56,
      index: -0.11625,
      awards: 0.075714,
      books: 0.001607,
      grants: 0.066785,
      grantDol: 0.162678,
      articles: -0.207321,
      citations: 0.007857,
      confProc: 0.1025,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 402,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 697306.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 30,
      observations: 59,
      index: 0.052711,
      awards: 0.027627,
      books: 0.302203,
      grants: 0.378305,
      grantDol: 0.294576,
      articles: -0.001186,
      citations: -0.07661,
      confProc: -0.083559,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 336,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 799420.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 31,
      observations: 46,
      index: 0.105869,
      awards: 0.12826,
      books: 0.343695,
      grants: -0.001739,
      grantDol: 0.055869,
      articles: 0.227173,
      citations: 0.167173,
      confProc: 0.161521,
      articlesActual: 18,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 527,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 614862.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 32,
      observations: 41,
      index: 0.11439,
      awards: 0.023658,
      books: 0.08,
      grants: 0.271463,
      grantDol: 0.112195,
      articles: 0.028292,
      citations: -0.026585,
      confProc: -0.11878,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 375,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 659084.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 33,
      observations: 41,
      index: 0.062682,
      awards: 0.10439,
      books: -0.090487,
      grants: 0.231463,
      grantDol: 0.193658,
      articles: 0.002439,
      citations: -0.029024,
      confProc: -0.032195,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 374,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 721149.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 34,
      observations: 51,
      index: -0.154313,
      awards: -0.143921,
      books: 0.190588,
      grants: -0.018039,
      grantDol: -0.04745,
      articles: 0.095294,
      citations: -0.008431,
      confProc: -0.100784,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 390,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 534521.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 35,
      observations: 46,
      index: 0.026304,
      awards: 0.11,
      books: 0.191521,
      grants: 0.045217,
      grantDol: 0.302826,
      articles: 0.190217,
      citations: 0.25413,
      confProc: -0.054782,
      articlesActual: 17,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 595,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 805626.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 36,
      observations: 40,
      index: -0.00425,
      awards: -0.106,
      books: 0.0015,
      grants: -0.09575,
      grantDol: -0.01475,
      articles: 0.1725,
      citations: 0.03225,
      confProc: 0.062,
      articlesActual: 17,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 421,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 560015.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 37,
      observations: 40,
      index: 0.11,
      awards: 0.224,
      books: 0.29225,
      grants: 0.1105,
      grantDol: 0.19275,
      articles: 0.30425,
      citations: 0.11225,
      confProc: -0.15125,
      articlesActual: 19,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 484,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 719895.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 38,
      observations: 39,
      index: 0.033333,
      awards: -0.114615,
      books: 0.00923,
      grants: 0.080769,
      grantDol: 0.025641,
      articles: 0.037692,
      citations: -0.032051,
      confProc: -0.130769,
      articlesActual: 15,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 370,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 591701.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 39,
      observations: 51,
      index: -0.127254,
      awards: 0.10745,
      books: 0.144313,
      grants: -0.006862,
      grantDol: 0.003921,
      articles: -0.01098,
      citations: -0.087647,
      confProc: -0.128627,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 328,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 575131.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 40,
      observations: 43,
      index: -0.104651,
      awards: 0.321627,
      books: -0.018837,
      grants: -0.127674,
      grantDol: -0.066511,
      articles: 0.009302,
      citations: 0.078139,
      confProc: 0.255813,
      articlesActual: 14,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 458,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 520372.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 41,
      observations: 41,
      index: -0.193414,
      awards: 0.073414,
      books: 0.476829,
      grants: -0.195853,
      grantDol: -0.051951,
      articles: 0.379756,
      citations: 0.434878,
      confProc: 0.02,
      articlesActual: 20,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 737,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 531264.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 42,
      observations: 36,
      index: 0.174444,
      awards: 0.543055,
      books: 0.163055,
      grants: 0.226388,
      grantDol: 0.245833,
      articles: 0.143611,
      citations: 0.185,
      confProc: 0.292777,
      articlesActual: 16,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 541,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 761297.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 43,
      observations: 24,
      index: -0.007083,
      awards: 0.332083,
      books: 0.24375,
      grants: 0.005416,
      grantDol: 0.193333,
      articles: 0.060416,
      citations: 0.02375,
      confProc: 0.085833,
      articlesActual: 15,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 414,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 721332.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 44,
      observations: 27,
      index: -0.345555,
      awards: -0.029629,
      books: 0.487037,
      grants: -0.444074,
      grantDol: -0.339259,
      articles: -0.107037,
      citations: -0.101111,
      confProc: -0.213703,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 317,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 310144.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 45,
      observations: 18,
      index: 0.034444,
      awards: 0.726666,
      books: 0.099444,
      grants: 0.043888,
      grantDol: 0.101111,
      articles: 0.002777,
      citations: 0.037222,
      confProc: -0.121666,
      articlesActual: 14,
      awardsActual: 3,
      booksActual: 0,
      citationsActual: 426,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 649733.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 46,
      observations: 20,
      index: 0.1345,
      awards: 0.079,
      books: 0.874,
      grants: -0.055,
      grantDol: -0.201,
      articles: 0.3465,
      citations: 0.015,
      confProc: -0.2045,
      articlesActual: 20,
      awardsActual: 1,
      booksActual: 1,
      citationsActual: 408,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 416969.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 47,
      observations: 19,
      index: -0.024736,
      awards: 0.571052,
      books: 0.873684,
      grants: -0.296842,
      grantDol: -0.222631,
      articles: 0.168421,
      citations: 0.335263,
      confProc: -0.053157,
      articlesActual: 17,
      awardsActual: 2,
      booksActual: 1,
      citationsActual: 659,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 400450.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 48,
      observations: 14,
      index: -0.36,
      awards: 0.510714,
      books: -0.039285,
      grants: -0.266428,
      grantDol: -0.142142,
      articles: -0.265714,
      citations: 0.092142,
      confProc: -0.189285,
      articlesActual: 9,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 469,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 461287.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 49,
      observations: 13,
      index: -0.007692,
      awards: 1.04,
      books: 0.247692,
      grants: -0.12923,
      grantDol: -0.226923,
      articles: -0.033846,
      citations: 0.000769,
      confProc: 0.033076,
      articlesActual: 13,
      awardsActual: 3,
      booksActual: 0,
      citationsActual: 396,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 397048.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 50,
      observations: 12,
      index: -0.175,
      awards: 0.726666,
      books: 0.4875,
      grants: -0.199166,
      grantDol: -0.225,
      articles: -0.215,
      citations: -0.233333,
      confProc: -0.003333,
      articlesActual: 10,
      awardsActual: 3,
      booksActual: 0,
      citationsActual: 213,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 397590.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 51,
      observations: 16,
      index: 0.175,
      awards: 0.865,
      books: 0.438125,
      grants: -0.04125,
      grantDol: 0.05,
      articles: 0.175,
      citations: 0.13,
      confProc: -0.24,
      articlesActual: 17,
      awardsActual: 3,
      booksActual: 0,
      citationsActual: 498,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 611034.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 52,
      observations: 7,
      index: -0.925714,
      awards: -0.11,
      books: -0.122857,
      grants: -0.617142,
      grantDol: -0.58,
      articles: -0.468571,
      citations: -0.424285,
      confProc: -0.24,
      articlesActual: 6,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 63,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 123944.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 53,
      observations: 3,
      index: -0.25,
      awards: 1.343333,
      books: -0.29,
      grants: -0.203333,
      grantDol: -0.166666,
      articles: -0.406666,
      citations: -0.316666,
      confProc: -0.24,
      articlesActual: 7,
      awardsActual: 4,
      booksActual: 0,
      citationsActual: 148,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 442311.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 54,
      observations: 3,
      index: -0.683333,
      awards: 0.383333,
      books: 1.263333,
      grants: -0.746666,
      grantDol: -0.676666,
      articles: -0.523333,
      citations: -0.273333,
      confProc: -0.003333,
      articlesActual: 5,
      awardsActual: 2,
      booksActual: 1,
      citationsActual: 182,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 48809.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 55,
      observations: 1,
      index: -0.51,
      awards: 0.66,
      books: -0.29,
      grants: -0.93,
      grantDol: -0.74,
      articles: -0.08,
      citations: -0.45,
      confProc: 1.18,
      articlesActual: 13,
      awardsActual: 3,
      booksActual: 0,
      citationsActual: 45,
      confProcActual: 2,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 2.0,
    },
  ],
  facultyDataPoints: [
    {
      facultyId: 1088605,
      name: 'KURELA, LEANNE',
      rank: 3,
      awards: -0.6,
      books: -0.3,
      citations: -0.5,
      grants: -0.9,
      grantDol: -0.7,
      articles: -0.7,
      confProc: -0.2,
      yearsSincePhD: 2,
      index: -1.6,
      articlesActual: 2.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 9.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 960468,
      name: 'WESTER, JASON',
      rank: 3,
      awards: -0.6,
      books: -0.3,
      citations: -0.2,
      grants: -0.9,
      grantDol: -0.7,
      articles: -0.5,
      confProc: -0.2,
      yearsSincePhD: 7,
      index: -0.7,
      articlesActual: 5.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 214.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887259,
      name: 'GAO, JIE',
      rank: 3,
      awards: -0.2,
      books: -0.3,
      citations: -0.4,
      grants: -0.4,
      grantDol: -0.4,
      articles: -0.7,
      confProc: -0.2,
      yearsSincePhD: 7,
      index: -1.1,
      articlesActual: 2.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 44.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 284571.0,
      indexActual: 0.0,
    },
    {
      facultyId: 802606,
      name: 'KIRBY, ELIZABETH',
      rank: 3,
      awards: -0.2,
      books: -0.3,
      citations: -0.3,
      grants: 0.2,
      grantDol: -0.1,
      articles: -0.5,
      confProc: -0.2,
      yearsSincePhD: 8,
      index: -0.2,
      articlesActual: 5.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 192.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 503440.0,
      indexActual: 0.0,
    },
    {
      facultyId: 609497,
      name: 'GUO, LIANG',
      rank: 3,
      awards: 0.2,
      books: -0.3,
      citations: -0.3,
      grants: -0.4,
      grantDol: -0.6,
      articles: 0.2,
      confProc: 1.2,
      yearsSincePhD: 8,
      index: 0.2,
      articlesActual: 17.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 153.0,
      confProcActual: 2.0,
      grantsActual: 1.0,
      grantDolActual: 101694.0,
      indexActual: 0.0,
    },
    {
      facultyId: 1088606,
      name: 'SUN, WENJING',
      rank: 3,
      awards: -0.6,
      books: -0.3,
      citations: -0.4,
      grants: -0.9,
      grantDol: -0.7,
      articles: -0.7,
      confProc: -0.2,
      yearsSincePhD: 9,
      index: -1.5,
      articlesActual: 2.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 40.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 848070,
      name: 'WAGNON, JACY LEE',
      rank: 3,
      awards: -0.2,
      books: -0.3,
      citations: 0.0,
      grants: -0.4,
      grantDol: -0.6,
      articles: -0.1,
      confProc: -0.2,
      yearsSincePhD: 10,
      index: 0.4,
      articlesActual: 13.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 425.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 146442.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638186,
      name: 'YOUNG, NICOLE ADELE',
      rank: 3,
      awards: -0.6,
      books: -0.3,
      citations: -0.4,
      grants: -0.9,
      grantDol: -0.7,
      articles: -0.4,
      confProc: -0.2,
      yearsSincePhD: 10,
      index: -1.0,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 50.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 802574,
      name: 'KOKIKO-COCHRAN, OLGA',
      rank: 3,
      awards: -0.6,
      books: -0.3,
      citations: 0.4,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.2,
      confProc: -0.2,
      yearsSincePhD: 11,
      index: 0.7,
      articlesActual: 17.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 693.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 328692.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638789,
      name: 'GUR, TAMAR L',
      rank: 3,
      awards: -0.2,
      books: -0.3,
      citations: -0.3,
      grants: -0.4,
      grantDol: -0.4,
      articles: -0.3,
      confProc: -0.2,
      yearsSincePhD: 11,
      index: -0.1,
      articlesActual: 9.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 182.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 245102.0,
      indexActual: 0.0,
    },
    {
      facultyId: 877160,
      name: 'FU, HONGJUN',
      rank: 3,
      awards: -0.6,
      books: -0.3,
      citations: 0.1,
      grants: -0.9,
      grantDol: -0.7,
      articles: -0.5,
      confProc: -0.2,
      yearsSincePhD: 12,
      index: -0.2,
      articlesActual: 6.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 503.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 301736,
      name: 'HARTWICK, ANDREW',
      rank: 2,
      awards: -0.2,
      books: -0.3,
      citations: -0.5,
      grants: -0.9,
      grantDol: -0.7,
      articles: -0.5,
      confProc: -0.2,
      yearsSincePhD: 13,
      index: -1.1,
      articlesActual: 6.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 30.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 520724,
      name: 'OTERO, JOSE JAVIER',
      rank: 2,
      awards: -0.6,
      books: -0.3,
      citations: -0.1,
      grants: -0.4,
      grantDol: -0.1,
      articles: 0.6,
      confProc: 0.5,
      yearsSincePhD: 15,
      index: 0.7,
      articlesActual: 25.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 332.0,
      confProcActual: 1.0,
      grantsActual: 1.0,
      grantDolActual: 513227.0,
      indexActual: 0.0,
    },
    {
      facultyId: 40064,
      name: 'GODBOUT, JONATHAN PHILIP',
      rank: 1,
      awards: -0.6,
      books: -0.3,
      citations: 1.8,
      grants: 0.7,
      grantDol: 0.5,
      articles: 2.1,
      confProc: -0.2,
      yearsSincePhD: 18,
      index: 1.6,
      articlesActual: 50.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 1781.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 951930.0,
      indexActual: 0.0,
    },
    {
      facultyId: 38204,
      name: 'FISCHER, ANDREW J',
      rank: 1,
      awards: -0.2,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.1,
      confProc: 1.2,
      yearsSincePhD: 21,
      index: 0.3,
      articlesActual: 13.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 247.0,
      confProcActual: 2.0,
      grantsActual: 1.0,
      grantDolActual: 339837.0,
      indexActual: 0.0,
    },
    {
      facultyId: 378861,
      name: 'KOLB, STEPHEN J',
      rank: 2,
      awards: -0.6,
      books: -0.3,
      citations: 0.3,
      grants: -0.9,
      grantDol: -0.7,
      articles: 0.4,
      confProc: -0.2,
      yearsSincePhD: 21,
      index: 0.5,
      articlesActual: 22.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 650.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 435824,
      name: 'ZHOU, MIN',
      rank: 2,
      awards: -0.6,
      books: -0.3,
      citations: -0.2,
      grants: 0.2,
      grantDol: -0.1,
      articles: -0.2,
      confProc: -0.2,
      yearsSincePhD: 21,
      index: 0.2,
      articlesActual: 10.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 225.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 469968.0,
      indexActual: 0.0,
    },
    {
      facultyId: 63266,
      name: 'JONTES, JAMES DAVID',
      rank: 2,
      awards: -0.6,
      books: -0.3,
      citations: -0.4,
      grants: 1.3,
      grantDol: 0.3,
      articles: -0.5,
      confProc: -0.2,
      yearsSincePhD: 22,
      index: -0.2,
      articlesActual: 5.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 111.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 823386.0,
      indexActual: 0.0,
    },
    {
      facultyId: 87400,
      name: 'OBRIETAN, KARL H',
      rank: 1,
      awards: -0.6,
      books: -0.3,
      citations: -0.2,
      grants: 1.3,
      grantDol: 0.6,
      articles: 0.0,
      confProc: -0.2,
      yearsSincePhD: 23,
      index: 0.7,
      articlesActual: 15.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 227.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 1066096.0,
      indexActual: 0.0,
    },
    {
      facultyId: 160874,
      name: 'POPOVICH, PHILLIP GORDON',
      rank: 1,
      awards: 3.1,
      books: -0.3,
      citations: 1.2,
      grants: 5.1,
      grantDol: 3.2,
      articles: 1.1,
      confProc: -0.2,
      yearsSincePhD: 24,
      index: 2.0,
      articlesActual: 33.0,
      awardsActual: 9.0,
      booksActual: 0.0,
      citationsActual: 1304.0,
      confProcActual: 0.0,
      grantsActual: 11.0,
      grantDolActual: 3043842.0,
      indexActual: 0.0,
    },
    {
      facultyId: 18569,
      name: 'LIN, CHIEN-LIANG GLENN',
      rank: 1,
      awards: 0.7,
      books: -0.3,
      citations: -0.3,
      grants: -0.4,
      grantDol: -0.4,
      articles: -0.7,
      confProc: -0.2,
      yearsSincePhD: 24,
      index: -0.6,
      articlesActual: 3.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 127.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 264000.0,
      indexActual: 0.0,
    },
    {
      facultyId: 156022,
      name: 'MCTIGUE, DANA MARIE',
      rank: 1,
      awards: 0.7,
      books: -0.3,
      citations: 0.1,
      grants: 1.3,
      grantDol: 0.9,
      articles: 0.3,
      confProc: -0.2,
      yearsSincePhD: 24,
      index: 1.3,
      articlesActual: 19.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 464.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 1238727.0,
      indexActual: 0.0,
    },
    {
      facultyId: 542181,
      name: 'LONSER, RUSSELL ROLAND',
      rank: 1,
      awards: -0.6,
      books: 4.4,
      citations: 0.0,
      grants: -0.4,
      grantDol: -0.7,
      articles: 1.2,
      confProc: -0.2,
      yearsSincePhD: 25,
      index: 0.7,
      articlesActual: 35.0,
      awardsActual: 0.0,
      booksActual: 4.0,
      citationsActual: 405.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 26928.0,
      indexActual: 0.0,
    },
    {
      facultyId: 95088,
      name: 'LOVETT-RACKE, AMY ELIZABETH',
      rank: 1,
      awards: -0.6,
      books: -0.3,
      citations: 0.3,
      grants: 1.3,
      grantDol: 0.5,
      articles: 0.0,
      confProc: -0.2,
      yearsSincePhD: 26,
      index: 1.1,
      articlesActual: 14.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 637.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 963738.0,
      indexActual: 0.0,
    },
    {
      facultyId: 111836,
      name: 'OBERDICK, JOHN DAVID',
      rank: 2,
      awards: -0.6,
      books: -0.3,
      citations: -0.5,
      grants: -0.4,
      grantDol: -0.6,
      articles: -0.7,
      confProc: -0.2,
      yearsSincePhD: 31,
      index: -1.4,
      articlesActual: 2.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 5.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 150824.0,
      indexActual: 0.0,
    },
    {
      facultyId: 300956,
      name: 'BROWN, ANTHONY',
      rank: 1,
      awards: -0.6,
      books: -0.3,
      citations: -0.4,
      grants: 1.3,
      grantDol: 0.6,
      articles: -0.5,
      confProc: -0.2,
      yearsSincePhD: 32,
      index: -0.4,
      articlesActual: 5.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 52.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 1047147.0,
      indexActual: 0.0,
    },
    {
      facultyId: 12092,
      name: 'BOYD, ROBERT THOMAS',
      rank: 2,
      awards: -0.6,
      books: -0.3,
      citations: -0.5,
      grants: -0.9,
      grantDol: -0.7,
      articles: -0.7,
      confProc: -0.2,
      yearsSincePhD: 33,
      index: -1.7,
      articlesActual: 2.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 2.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 65652,
      name: 'MANGEL, STUART C',
      rank: 1,
      awards: -0.6,
      books: -0.3,
      citations: -0.5,
      grants: -0.4,
      grantDol: -0.2,
      articles: -0.7,
      confProc: -0.2,
      yearsSincePhD: 38,
      index: -1.3,
      articlesActual: 2.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 9.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 399335.0,
      indexActual: 0.0,
    },
    {
      facultyId: 133747,
      name: 'BISHOP, GEORGIA ANN',
      rank: 1,
      awards: -0.6,
      books: -0.3,
      citations: -0.5,
      grants: -0.9,
      grantDol: -0.7,
      articles: -0.8,
      confProc: -0.2,
      yearsSincePhD: 42,
      index: -1.7,
      articlesActual: 1.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 3.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 3220,
      name: 'ASKWITH, CANDICE C',
      rank: 2,
      awards: -0.6,
      books: -0.3,
      citations: -0.3,
      grants: -0.4,
      grantDol: -0.4,
      articles: -0.3,
      confProc: -0.2,
      yearsSincePhD: 42,
      index: -0.3,
      articlesActual: 9.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 152.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 300597.0,
      indexActual: 0.0,
    },
  ],
  unit: {
    indexScore: -0.1,
  },
};
export default data;
