import React from 'react';
import { Bar } from 'react-chartjs-2';
import '../../styles/conversionProposals.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';

// Chart options to customize the chart
const makeOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        display: false,
        gridLines: {
          display: false,
        },
        stacked: true,
      },
    ],
    xAxes: [
      {
        gridLines: {
          display: false,
        },
        stacked: true,
        ticks: {
          fontSize: 10,
        },
      },
    ],
  },
  // Display points on chart using ChartDataLabels library
  plugins: {
    datalabels: {
      color: 'black',
      font: {
        weight: 'bold',
        size: 10.5,
      },
      // Formats DataLables
      formatter: function (value) {
        let formatedValue = new Intl.NumberFormat().format(value);
        return `$${formatedValue}`;
      },
    },
    options: {
      legend: {
        labels: {
          defaultFontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
        },
      },
    },
  },
  title: {
    display: true,
    text: 'Historical and Projected Core Proposal Totals ',
    fontStyle: 'bold',
    fontSize: 13,
    fontColor: '#153b7d',
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        const formatter = new Intl.NumberFormat('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
        });

        return (
          data.datasets[tooltipItem.datasetIndex].label +
          ':' +
          '  ' +
          formatter.format(tooltipItem.yLabel)
        );
      },
    },
    bodyFontSize: 14,
  },
};

export default function ChartProposalTotals(props) {
  const [chartData, setChartData] = React.useState();
  const [chartYearStart, setChartYearStart] = React.useState(
    Number(props.currentYear)
  );

  const filterDataForChart = (data, index, name, currYear) => {
    let year = Number(currYear) - 1;

    let agencyDirectsIndirectsData = {
      directs: null,
      indirects: null,
    };
    agencyDirectsIndirectsData.directs = data[index + 1];
    agencyDirectsIndirectsData.indirects = data[index + 2];

    setChartData(agencyDirectsIndirectsData);
    setChartYearStart(year);
  };

  React.useEffect(() => {
    filterDataForChart(
      props.chartData,
      props.currAgency.index,
      props.currAgency.name,
      props.currentYear
    );
  }, [props.chartData, props.currAgency, props.currentYear]);

  const yearLabels = selectedYear => {
    let projectedYearsToDisplay = 5;
    let currYear = selectedYear;
    let yearsForChartLables = [currYear];
    for (let i = 0; i < projectedYearsToDisplay; i++) {
      currYear++;
      yearsForChartLables.push(currYear);
    }
    return yearsForChartLables;
  };
  // Sort data to pass into Chart
  const createLablesDataSet = (data, agency, yearToStart) => {
    // generate array of years to display as lables on chart as X axis
    let labelsArr = yearLabels(yearToStart);

    // build chart data with lables and datasets
    let chartDataSetLabels = {
      labels: labelsArr,
      datasets: [
        {
          label: 'Indirects',
          backgroundColor: '#537ec5', // blue
          barThickness: 'flex',
          data: [],
        },
        {
          label: 'Directs',
          backgroundColor: '#94b752', // green
          barThickness: 'flex',
          data: [],
        },
      ],
    };

    // Add Indirects data to chart Indirects dataset
    for (let key in data.indirects) {
      if (key === 'open' || key === 'revised') {
        chartDataSetLabels.datasets[0].data.push(data.indirects[key]);
      }
      // change chart value for Transition based on value from 2021
      if (key === 'transition') {
        let val = data.indirects['2021'] + data.indirects['transition'];
        chartDataSetLabels.datasets[0].data.push(val);
      }
    }
    // Adding value for 22 23 24 columns from transition column
    for (let key in data.indirects) {
      if (key === '2022' || key === '2023' || key === '2024') {
        let val = data.indirects[key] + data.indirects['transition'];
        chartDataSetLabels.datasets[0].data.push(val);
      }
    }

    // Add Directs data to chart Directs dataset
    for (let key in data.directs) {
      if (key === 'open' || key === 'revised') {
        chartDataSetLabels.datasets[1].data.push(data.directs[key]);
      }
      if (key === 'transition') {
        let val = data.directs['2021'] + data.directs['transition'];
        chartDataSetLabels.datasets[1].data.push(val);
      }
    }
    // Adding value for 22 23 24 columns from transition column
    for (let key in data.directs) {
      if (key === '2022' || key === '2023' || key === '2024') {
        let val = data.directs[key] + data.directs['transition'];
        chartDataSetLabels.datasets[1].data.push(val);
      }
    }

    return chartDataSetLabels;
  };

  return (
    <>
      {chartData && (
        <Bar
          data={createLablesDataSet(
            chartData,
            props.currAgency,
            chartYearStart
          )}
          options={makeOptions}
        />
      )}
    </>
  );
}
