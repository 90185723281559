import React from 'react';
import { Line } from 'react-chartjs-2';
import '../../styles/conversionProposal.css';
import ChartDataLabels from 'chartjs-plugin-datalabels';
// ChartDataLabels used for showing data labels on the chart

// Chart options to customize the chart
const makeOptions = {
  maintainAspectRatio: false,
  scales: {
    yAxes: [
      {
        ticks: {
          min: 0,
          max: 50,
          fontSize: 10,
          // Include a % sign in the ticks
          callback: function (value, index, values) {
            return `${value}%`;
          },
          stepSize: 5,
        },
      },
    ],
    xAxes: [
      {
        ticks: {
          fontSize: 10,
        },
      },
    ],
  },
  // Display points on chart
  plugins: {
    datalabels: {
      color: 'black',
      formatter: function (value) {
        let roundVal = Math.round((value + Number.EPSILON) * 100) / 100;
        return `\n\n\n${roundVal}%`;
      },
      font: {
        weight: 'bold',
        size: 11,
      },
    },
    options: {
      legend: {
        labels: {
          defaultFontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
        },
      },
    },
  },
  title: {
    display: true,
    text: 'Core Conversions Ratios - Directs',
    fontStyle: 'bold',
    fontSize: 12,
    fontColor: '#153b7d',
  },
  tooltips: {
    callbacks: {
      label: function (tooltipItem, data) {
        return (
          data.datasets[tooltipItem.datasetIndex].label +
          ':' +
          '  ' +
          tooltipItem.yLabel +
          '%'
        );
      },
    },
    bodyFontSize: 14,
  },
};

export default function ChartConversionRatios(props) {
  const [chartData, setChartData] = React.useState();
  const [chartYearStart, setChartYearStart] = React.useState(props.currentYear);

  const filterDataForChart = (data, index, name, currYear) => {
    let year = Number(currYear) - 1;
    // get direct agency data, index plus one to get to Directs obj of that agency in data
    const directsAgencyData = data[index + 1];
    setChartData(directsAgencyData);
    setChartYearStart(year);
  };

  React.useEffect(() => {
    filterDataForChart(
      props.chartData,
      props.currAgency.index,
      props.currAgency.name,
      props.currentYear
    );
  }, [props.chartData, props.currAgency, props.currentYear]);

  // Generate year labels array to show on the Chart
  const yearLabels = selectedYear => {
    let projectedYearsToDisplay = 5;
    let currYear = selectedYear;
    let yearsForChartLables = [currYear];
    for (let i = 0; i < projectedYearsToDisplay; i++) {
      currYear++;
      yearsForChartLables.push(currYear);
    }
    return yearsForChartLables;
  };

  // Sort data to pass into Chart
  const createLablesDataSet = (data, agency, yearToStart) => {
    // generate array of years to display as lables on chart as X axis
    let labelsArr = yearLabels(yearToStart);

    // build chart data with lables and datasets
    let chartDataSetLabels = {
      labels: labelsArr,
      datasets: [
        {
          label: agency.name,
          fill: false,
          lineTension: 0.1,
          backgroundColor: 'white',
          borderColor: '#537ec5',
          borderCapStyle: 'butt',
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: 'round',
          pointBorderColor: '#537ec5',
          pointBackgroundColor: 'white',
          pointBorderWidth: 2,
          pointHoverRadius: 5,
          pointHoverBorderWidth: 2,
          pointRadius: 3,
          pointHitRadius: 10,
          data: [],
        },
      ],
    };

    for (let key in data) {
      if (key === 'open' || key === 'revised') {
        chartDataSetLabels.datasets[0].data.push(data[key]);
      }
      // change chart value for Transition based on value from 2021
      if (key === 'transition') {
        let val = data['2021'] + data['transition'];
        chartDataSetLabels.datasets[0].data.push(val);
      }
    }
    // addding value for 22 23 24 columns from transition column
    for (let key in data) {
      if (key === '2022' || key === '2023' || key === '2024') {
        let val = data[key] + data['transition'];
        chartDataSetLabels.datasets[0].data.push(val);
      }
    }

    return chartDataSetLabels;
  };

  return (
    <>
      {chartData && (
        <Line
          data={createLablesDataSet(
            chartData,
            props.currAgency,
            chartYearStart
          )}
          options={makeOptions}
        />
      )}
    </>
  );
}
