import React, { useState, useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { useTable } from 'react-table';
import Table from 'react-bootstrap/Table';
import axios from 'axios';
import { Link, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import {
  formatCurrency,
  formatDate,
  formatDepartment,
  capitalizeFirstLetter,
  formatName,
  formatInstitutionName,
  strToUpperCamelCase,
} from './utils';
import styles from './styles/PiGrants.module.scss';
import './styles/piGrants.css';

const PiGrants = props => {
  const { personId } = useParams();
  const [person, setPerson] = useState({
    personInfo: {},
    grants: [],
    unit: '',
  });

  const url = process.env.REACT_APP_API_URL;

  useEffect(() => {
    axios
      .all([
        axios.get(`${url}/api/persons/${personId}`),
        axios.get(`${url}/api/persons/${personId}/grants`),
        axios.get(`${url}/api/persons/${personId}/unit`),
      ])
      .then(
        axios.spread((personResults, grantsResults, unitResults) => {
          setPerson({
            personInfo: {
              ...personResults.data,
              FullName: capitalizeFirstLetter(personResults.data.FullName),
              DegreeDate: formatDate(personResults.data.DegreeDate),
            },
            grants: grantsResults.data,
            unit: formatDepartment(unitResults.data.Name),
          });
        })
      );
  }, [personId]);

  const data = React.useMemo(
    () =>
      person.grants.map(grant => {
        return {
          grant: strToUpperCamelCase(grant.Name),
          start: formatDate(grant.StartDate),
          end: formatDate(grant.EndDate),
          agency: grant.Agency.Name,
          duration: grant.DurationInYears,
          total: formatCurrency(grant.TotalDollars),
          dollarsPerYear: formatCurrency(grant.DollarsPerYear),
          directDollarsPerYear: formatCurrency(grant.DirectDollarsPerYear),
          indirectDollarsPerYear: formatCurrency(grant.IndirectDollarsPerYear),
        };
      }),
    [person.grants]
  );

  const columns = React.useMemo(
    () => [
      {
        Header: 'PI Grants',
        columns: [
          {
            Header: 'Grant Name',
            accessor: 'grant', // accessor is the "key" in the data
          },
          {
            Header: 'Start',
            accessor: 'start',
          },
          {
            Header: 'End',
            accessor: 'end',
          },
          {
            Header: 'Duration (years)',
            accessor: 'duration',
          },
          {
            Header: 'Total',
            accessor: 'total',
          },
          {
            Header: 'Per Year',
            accessor: 'dollarsPerYear',
          },
          {
            Header: 'Direct Per Year',
            accessor: 'directDollarsPerYear',
          },
          {
            Header: 'Indirect Per Year',
            accessor: 'indirectDollarsPerYear',
          },
          {
            Header: 'Agency',
            accessor: 'agency',
          },
        ],
      },
    ],
    []
  );

  const tableInstance = useTable({ columns, data });

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = tableInstance;

  return (
    <>
      {props.college.id ? (
        <div className={styles.container}>
          <Link
            className={styles.breadcrumbs}
            to={{
              pathname: `/college/${props.college.id}`,
            }}
          >
            {formatName(props.college.name)}
          </Link>
          <FontAwesomeIcon icon={faCaretRight} className={styles.breadcrumbs} />
          {props.unit.Id ? (
            <>
              <Link
                className={styles.breadcrumbs}
                to={{
                  pathname: `/unit/${props.unit.Id}`,
                }}
              >
                {person.unit}
              </Link>
              <FontAwesomeIcon
                icon={faCaretRight}
                className={styles.breadcrumbs}
              />
            </>
          ) : (
            <></>
          )}

          <span className={styles.breadcrumbs}>
            {person.personInfo.FullName}
          </span>

          <div className={styles.tableContainer}>
            <h2 className={styles.name}>{person.personInfo.FullName}</h2>
            <div className={styles.personInfo}>
              <div className={styles.infoColumn}>
                <p className={styles.infoColumnTitle}>Degree Type</p>
                <p>{person.personInfo.DegreeType}</p>
              </div>
              <div className={styles.infoColumn}>
                <p className={styles.infoColumnTitle}>Title</p>
                <p>{person.personInfo.Title}</p>
              </div>
              <div className={styles.infoColumn}>
                <p className={styles.infoColumnTitle}>Degree Date</p>
                <p>{person.personInfo.DegreeDate}</p>
              </div>
              <div className={styles.infoColumn}>
                <p className={styles.infoColumnTitle}>Degree Institution</p>
                <p>
                  {person.personInfo.DegreeInstName ? (
                    formatInstitutionName(person.personInfo.DegreeInstName)
                  ) : (
                    <></>
                  )}
                </p>
              </div>
            </div>
            <Table
              striped
              bordered
              responsive
              size="sm"
              {...getTableProps()}
              className="pigrants-table"
            >
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        className="pigrants-table-header"
                        {...column.getHeaderProps()}
                      >
                        {column.render('Header')}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map(row => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map(cell => {
                        return (
                          <td {...cell.getCellProps()}>
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </div>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

export default PiGrants;
