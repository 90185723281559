/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { Redirect, Link } from 'react-router-dom';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionToggle } from 'react-bootstrap/AccordionToggle';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretRight } from '@fortawesome/free-solid-svg-icons';
import UnitsList from '../unit/UnitsList';
import CollegeProposalsTable from './collegeProposals/CollegeProposlasTable';
import ProjectionsNav from './collegeProjections/projectionsNav/ProjectionsNav';
import { buildPersonProposalsData } from './utils';
import './styles/college.css';

const College = props => {
  const url = process.env.REACT_APP_API_URL;

  const [proposals, setProposals] = useState([]);

  // Get College all people's proposals for all units from 2015 to 2019
  useEffect(() => {
    let mounted = true;
    axios.get(`${url}/api/colleges/${props.college.id}/proposals`).then(res => {
      if (mounted) {
        setProposals(buildPersonProposalsData(res.data));
      }
    });
    return () => (mounted = false);
  }, []);

  const formatName = name => {
    if (name.indexOf(',') > -1) {
      return name.split(',').reverse().join(' ');
    }
  };

  //Tabs for Accordion
  const tabs = [
    {
      id: 1,
      label: 'Proposals',
      description: (
        <CollegeProposalsTable
          proposals={proposals}
          collegeID={props.college.id}
        />
      ),
    },
    {
      id: 2,
      label: 'Projections of Core Proposal Totals and Conversion Ratios',
      description: <ProjectionsNav collegeID={props.college.id} />,
    },
  ];
  const CustomToggleBtn = ({ eventKey, callback }) => {
    const currentEventKey = useContext(AccordionContext); // <-- Will update every time the eventKey changes.

    const toggleOnClick = useAccordionToggle(
      eventKey,
      () => callback && callback(eventKey)
    );
    const isCurrentEventKey = currentEventKey === eventKey;

    return (
      <Button onClick={toggleOnClick}>
        {isCurrentEventKey ? 'Click to hide' : 'Click to show'}
      </Button>
    );
  };

  return (
    <div className="college-view">
      {props.college.id ? (
        <>
          <Link
            className="breadcrumbs"
            to={{
              pathname: '/',
            }}
          >
            Colleges
          </Link>
          <FontAwesomeIcon icon={faCaretRight} className="breadcrumbs" />
          <span className="breadcrumbs">{formatName(props.college.name)}</span>
          <h1 className="header">{formatName(props.college.name)}</h1>
          <div className="college-units-accordion">
            <div className="college-units-list">
              <UnitsList
                collegeID={props.college.id}
                handleOnClick={props.handleOnClick}
              />
            </div>
            <div className="college-accordion-container">
              {tabs.map(tab => (
                <Accordion key={tab.id} defaultActiveKey={tab.id}>
                  <Card>
                    <Card.Header>
                      <CustomToggleBtn eventKey={tab.id} />
                      <h5
                        className={
                          tab.id === 1
                            ? 'acr-proposals-header'
                            : 'acr-projections-header'
                        }
                      >
                        {tab.label}
                      </h5>
                    </Card.Header>
                    <Accordion.Collapse eventKey={tab.id}>
                      <Card.Body>{tab.description}</Card.Body>
                    </Accordion.Collapse>
                  </Card>
                </Accordion>
              ))}
            </div>
          </div>
        </>
      ) : (
        <Redirect to="/" />
      )}
    </div>
  );
};

export default College;
