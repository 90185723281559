import AA from './aa.js';

export const COLORS = AA.enum({
  MEDIAN: 'grey',
  ARTICLE: '#3366ff',
  AWARD: '#E0A51B',
  BOOK: '#660099',
  CHAPTER: '#ad5cd6',
  CITATION: '#990033',
  CONFPROC: '#DF7401',
  GRANT: '#009900',
  SCOREDARTICLE: '#3366ff',
  COPI: '#009900',
  PATENT: '#8e5600',
  CLINICALTRIAL: '#52e0b1',
  EXHIBIT: '#50c8d2',
  PERFORMANCE: '#e26bb2',
  PROF: '#9467BD',
  ASSOC: '#FF7F0E',
  ASST: '#2CA02C',
  OTHER: '#254467',
  INDEX: '#254467',
  UNKNOWN: '#404040',
  ROW_HIGHLIGHT: 'background-color: slategrey; color: white;',
  NON_COMP_FACULTY: 'lightgrey',
  COMP_FAC_STYLE: 'font-weight: 700;',
  NON_COMP_FAC_STYLE: 'font-weight: 400;',
  INVERTED: {
    MEDIAN: '#fff',
    BOOK: '#fff',
    AWARD: '#000',
    ARTICLE: '#000',
    GRANT: '#fff',
    CITATION: '#fff',
    CONFPROC: '#fff',
    CHAPTER: '#fff',
    SCOREDARTICLE: '#fff',
    COPI: '#fff',
    PATENT: '#fff',
    PROF: '#000',
    ASSOC: '#000',
    ASST: '#fff',
    OTHER: '#000',
    INDEX: '#fff',
  },
});

export const LABELS = AA.enum({
  INDEX: {
    DESC: 'Scholarly Research Index',
    DESC_WITH_ABBR: 'Scholarly Research Index (SRI)',
    ABBR: 'SRI',
  },
  ARTICLE_CLASSIFICATION_BY_ID: {
    0: 'Unknown',
    1: 'Research',
    4: 'Non-Research',
    5: 'Book Review',
    6: 'Law',
  },
  WORKS_BY_ID: {
    0: 'Unknown',
    1: 'Awards',
    2: 'Books',
    3: 'Grants',
    4: 'Articles',
    5: 'Citations',
    6: 'Conference Proceedings',
    7: 'Book Chapters',
    8: 'Scored Articles',
    10: 'All',
    11: 'CoPI',
    12: 'Patents',
    30: 'Performances',
    31: 'Exhibit',
    60: 'Clinical Trials',
    99: 'Other',
  },
  RANKS_BY_ID: {
    0: 'Unknown',
    1: 'Professor',
    2: 'Associate',
    3: 'Assistant',
    4: 'Instructor',
    5: 'Lecturer',
    9: 'Other',
  },
});
