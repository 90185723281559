const data = {
  maxObservations: 152,
  dataPoints: [
    {
      yearsSincePhD: 0,
      observations: 32,
      index: -0.700625,
      awards: -0.410625,
      books: -0.27,
      grants: -0.38,
      grantDol: -0.3,
      articles: -0.414062,
      citations: -0.2325,
      confProc: -0.158125,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 34,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 1,
      observations: 34,
      index: -0.628529,
      awards: -0.343529,
      books: -0.27,
      grants: -0.38,
      grantDol: -0.3,
      articles: -0.374705,
      citations: -0.19147,
      confProc: -0.112352,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 58,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 2,
      observations: 71,
      index: -0.479718,
      awards: -0.393098,
      books: -0.22169,
      grants: -0.38,
      grantDol: -0.3,
      articles: -0.365352,
      citations: -0.216056,
      confProc: -0.186619,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 44,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 3,
      observations: 99,
      index: -0.025959,
      awards: -0.32404,
      books: -0.258484,
      grants: -0.346363,
      grantDol: -0.276161,
      articles: -0.121313,
      citations: -0.115252,
      confProc: -0.050707,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 103,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 8576.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 4,
      observations: 125,
      index: -0.026,
      awards: -0.28888,
      books: -0.2244,
      grants: -0.30008,
      grantDol: -0.26552,
      articles: -0.10072,
      citations: -0.0936,
      confProc: -0.07056,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 116,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 12351.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 5,
      observations: 145,
      index: 0.053448,
      awards: -0.225172,
      books: -0.238482,
      grants: -0.265172,
      grantDol: -0.198551,
      articles: 0.037931,
      citations: 0.039034,
      confProc: -0.026827,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 194,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 36480.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 6,
      observations: 137,
      index: -0.012627,
      awards: -0.298248,
      books: -0.169854,
      grants: -0.201751,
      grantDol: -0.17854,
      articles: -0.101094,
      citations: -0.106204,
      confProc: -0.016131,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 109,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 43678.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 7,
      observations: 145,
      index: 0.112137,
      awards: -0.126758,
      books: -0.191241,
      grants: -0.249862,
      grantDol: -0.220413,
      articles: -0.015931,
      citations: 0.104413,
      confProc: 0.116275,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 233,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 28555.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 8,
      observations: 152,
      index: 0.148815,
      awards: -0.162828,
      books: -0.232434,
      grants: -0.117105,
      grantDol: -0.133355,
      articles: 0.106052,
      citations: -0.019736,
      confProc: 0.008421,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 160,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 59862.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 9,
      observations: 130,
      index: 0.085769,
      awards: -0.167846,
      books: -0.190846,
      grants: -0.089692,
      grantDol: -0.077076,
      articles: -0.032461,
      citations: -0.087538,
      confProc: 0.249615,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 119,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 80176.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 10,
      observations: 125,
      index: 0.15936,
      awards: -0.14336,
      books: -0.10528,
      grants: 0.0196,
      grantDol: -0.07976,
      articles: 0.08096,
      citations: -0.03584,
      confProc: 0.03568,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 150,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 79070.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 11,
      observations: 108,
      index: 0.026388,
      awards: -0.225092,
      books: -0.185462,
      grants: -0.1025,
      grantDol: -0.105092,
      articles: -0.019814,
      citations: -0.004074,
      confProc: -0.010185,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 169,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 70013.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 12,
      observations: 144,
      index: 0.065833,
      awards: -0.124375,
      books: -0.087361,
      grants: -0.002291,
      grantDol: -0.025972,
      articles: 0.048055,
      citations: -0.034444,
      confProc: 0.020555,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 151,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 98554.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 13,
      observations: 120,
      index: -0.136583,
      awards: -0.222333,
      books: -0.117583,
      grants: -0.01,
      grantDol: -0.079333,
      articles: -0.089333,
      citations: -0.080833,
      confProc: -0.050916,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 124,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 79427.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 14,
      observations: 111,
      index: 0.112702,
      awards: -0.01063,
      books: -0.043333,
      grants: 0.05,
      grantDol: 0.022792,
      articles: 0.088288,
      citations: -0.032432,
      confProc: 0.006846,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 152,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 116167.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 15,
      observations: 107,
      index: 0.087102,
      awards: -0.022803,
      books: -0.013738,
      grants: 0.097196,
      grantDol: 0.142056,
      articles: 0.006542,
      citations: -0.053738,
      confProc: -0.093644,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 139,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 159059.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 16,
      observations: 107,
      index: 0.022056,
      awards: -0.097196,
      books: -0.056074,
      grants: 0.014205,
      grantDol: 0.035046,
      articles: -0.050467,
      citations: -0.047757,
      confProc: -0.039345,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 143,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 120588.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 17,
      observations: 98,
      index: 0.059081,
      awards: 0.065408,
      books: 0.232653,
      grants: 0.152346,
      grantDol: 0.102857,
      articles: 0.122959,
      citations: 0.05051,
      confProc: 0.179489,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 201,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 144793.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 18,
      observations: 78,
      index: 0.057435,
      awards: 0.048333,
      books: 0.052307,
      grants: 0.36,
      grantDol: 0.308205,
      articles: 0.14,
      citations: 0.135769,
      confProc: 0.098589,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 251,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 218784.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 19,
      observations: 100,
      index: 0.2534,
      awards: 0.0948,
      books: 0.062,
      grants: 0.3415,
      grantDol: 0.3056,
      articles: 0.1818,
      citations: 0.1719,
      confProc: 0.0556,
      articlesActual: 17,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 273,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 217889.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 20,
      observations: 78,
      index: 0.069743,
      awards: 0.047692,
      books: -0.00641,
      grants: -0.038461,
      grantDol: 0.214102,
      articles: 0.256538,
      citations: 0.632179,
      confProc: 0.013461,
      articlesActual: 19,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 545,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 185251.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 21,
      observations: 74,
      index: 0.231216,
      awards: 0.332297,
      books: -0.053648,
      grants: 0.28,
      grantDol: 0.238243,
      articles: 0.299459,
      citations: 0.194054,
      confProc: -0.086621,
      articlesActual: 20,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 286,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 193724.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 22,
      observations: 74,
      index: 0.049864,
      awards: 0.032702,
      books: 0.039189,
      grants: 0.175,
      grantDol: 0.131351,
      articles: -0.005,
      citations: 0.012972,
      confProc: -0.097837,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 179,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 155095.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 23,
      observations: 63,
      index: 0.46365,
      awards: 0.671746,
      books: 0.220476,
      grants: 0.624285,
      grantDol: 0.612063,
      articles: 0.438571,
      citations: 0.177619,
      confProc: 0.396031,
      articlesActual: 22,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 276,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 328550.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 24,
      observations: 77,
      index: -0.041168,
      awards: 0.22922,
      books: 0.250259,
      grants: 0.023636,
      grantDol: 0.043506,
      articles: 0.117272,
      citations: 0.108701,
      confProc: -0.037532,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 235,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 123708.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 25,
      observations: 50,
      index: -0.0048,
      awards: 0.1896,
      books: 0.5544,
      grants: -0.0026,
      grantDol: -0.0524,
      articles: -0.0984,
      citations: -0.015,
      confProc: -0.0938,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 162,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 89044.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 26,
      observations: 68,
      index: 0.066029,
      awards: 0.332058,
      books: 0.386764,
      grants: 0.060735,
      grantDol: 0.088676,
      articles: -0.051911,
      citations: -0.060735,
      confProc: -0.112352,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 136,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 139816.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 27,
      observations: 48,
      index: 0.055833,
      awards: 0.645833,
      books: 0.398333,
      grants: 0.521875,
      grantDol: 0.243958,
      articles: -0.000416,
      citations: 0.00875,
      confProc: 0.083958,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 176,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 195674.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 28,
      observations: 50,
      index: -0.0686,
      awards: 0.1576,
      books: 0.2332,
      grants: 0.2194,
      grantDol: 0.0408,
      articles: -0.0618,
      citations: -0.0638,
      confProc: 0.2712,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 134,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 122540.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 29,
      observations: 53,
      index: -0.174339,
      awards: 0.481132,
      books: 0.443018,
      grants: 0.227358,
      grantDol: 0.203207,
      articles: -0.05283,
      citations: -0.020188,
      confProc: -0.163018,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 159,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 181208.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 30,
      observations: 50,
      index: 0.2022,
      awards: 0.3484,
      books: 0.4856,
      grants: 0.4414,
      grantDol: 0.4282,
      articles: 0.2846,
      citations: 0.1038,
      confProc: -0.1104,
      articlesActual: 19,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 233,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 262289.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 31,
      observations: 49,
      index: -0.054693,
      awards: 0.478163,
      books: 0.220408,
      grants: -0.108163,
      grantDol: -0.130204,
      articles: 0.004489,
      citations: -0.004693,
      confProc: 0.027142,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 169,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 61094.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 32,
      observations: 37,
      index: 0.003243,
      awards: 0.246756,
      books: -0.115405,
      grants: 0.52,
      grantDol: 0.639189,
      articles: 0.371891,
      citations: 0.112162,
      confProc: 0.171351,
      articlesActual: 21,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 237,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 338253.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 33,
      observations: 39,
      index: 0.097179,
      awards: 0.291538,
      books: 0.523076,
      grants: 0.046923,
      grantDol: 0.16282,
      articles: 0.106923,
      citations: 0.06923,
      confProc: 0.130512,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 212,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 166603.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 34,
      observations: 38,
      index: 0.015263,
      awards: 0.519473,
      books: 0.725526,
      grants: 0.175,
      grantDol: 0.101578,
      articles: 0.030526,
      citations: 0.077631,
      confProc: -0.100789,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 217,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 144504.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 35,
      observations: 29,
      index: 0.023448,
      awards: 0.304827,
      books: 0.480344,
      grants: -0.150344,
      grantDol: -0.135172,
      articles: 0.141724,
      citations: 0.145862,
      confProc: -0.038275,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 258,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 59375.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 36,
      observations: 21,
      index: -0.242857,
      awards: 0.10619,
      books: 0.87619,
      grants: -0.01,
      grantDol: 0.009523,
      articles: -0.057142,
      citations: -0.039523,
      confProc: -0.01238,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 1,
      citationsActual: 148,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 111118.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 37,
      observations: 25,
      index: -0.0424,
      awards: 0.8064,
      books: 0.28,
      grants: 0.5524,
      grantDol: 0.2632,
      articles: 0.004,
      citations: -0.0228,
      confProc: 0.0224,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 158,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 202724.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 38,
      observations: 26,
      index: 0.432307,
      awards: 0.455,
      books: 0.303076,
      grants: 0.772692,
      grantDol: 0.652307,
      articles: 0.132692,
      citations: 0.118846,
      confProc: -0.146153,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 241,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 343025.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 39,
      observations: 13,
      index: -0.031538,
      awards: 0.516153,
      books: 1.053076,
      grants: -0.123846,
      grantDol: -0.103846,
      articles: -0.055384,
      citations: -0.05923,
      confProc: -0.146153,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 1,
      citationsActual: 135,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 70611.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 40,
      observations: 10,
      index: -0.333,
      awards: 0.412,
      books: 0.302,
      grants: 0.175,
      grantDol: -0.007,
      articles: -0.253,
      citations: -0.021,
      confProc: -0.127,
      articlesActual: 9,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 158,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 105367.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 41,
      observations: 20,
      index: -0.013,
      awards: 0.4515,
      books: 0.36,
      grants: 0.064,
      grantDol: 0.117,
      articles: -0.049,
      citations: -0.0205,
      confProc: -0.0855,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 159,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 150222.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 42,
      observations: 12,
      index: -0.1575,
      awards: 0.726666,
      books: 0.2075,
      grants: -0.01,
      grantDol: -0.149166,
      articles: 0.088333,
      citations: 0.053333,
      confProc: -0.21,
      articlesActual: 15,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 203,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 54271.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 43,
      observations: 21,
      index: -0.418571,
      awards: 0.257619,
      books: 0.711904,
      grants: 0.201428,
      grantDol: 0.285714,
      articles: -0.341428,
      citations: -0.17238,
      confProc: -0.21,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 69,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 211105.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 44,
      observations: 4,
      index: -0.6625,
      awards: 0.135,
      books: 1.1625,
      grants: -0.38,
      grantDol: -0.3,
      articles: -0.5525,
      citations: -0.135,
      confProc: -0.21,
      articlesActual: 3,
      awardsActual: 0,
      booksActual: 1,
      citationsActual: 93,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 45,
      observations: 6,
      index: 0.118333,
      awards: 0.86,
      books: 0.685,
      grants: -0.38,
      grantDol: -0.3,
      articles: -0.03,
      citations: -0.071666,
      confProc: -0.21,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 129,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 46,
      observations: 4,
      index: 0.17,
      awards: 1.52,
      books: 0.015,
      grants: 0.175,
      grantDol: 0.1475,
      articles: 0.105,
      citations: 0.175,
      confProc: 1.45,
      articlesActual: 16,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 274,
      confProcActual: 2,
      grantsActual: 0,
      grantDolActual: 160797.0,
      indexActual: 2.0,
    },
    {
      yearsSincePhD: 47,
      observations: 6,
      index: 0.713333,
      awards: 2.84,
      books: 0.11,
      grants: 2.025,
      grantDol: 2.38,
      articles: 0.925,
      citations: 0.748333,
      confProc: 0.066666,
      articlesActual: 32,
      awardsActual: 4,
      booksActual: 0,
      citationsActual: 613,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 966253.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 48,
      observations: 3,
      index: -0.046666,
      awards: -0.196666,
      books: 0.876666,
      grants: -0.38,
      grantDol: -0.3,
      articles: -0.2,
      citations: -0.116666,
      confProc: -0.21,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 1,
      citationsActual: 104,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 49,
      observations: 3,
      index: 0.056666,
      awards: 0.066666,
      books: 1.64,
      grants: -0.01,
      grantDol: -0.07,
      articles: -0.08,
      citations: 0.306666,
      confProc: -0.21,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 1,
      citationsActual: 352,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 82196.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 50,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 51,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 52,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 53,
      observations: 1,
      index: -0.51,
      awards: 6.66,
      books: -0.27,
      grants: -0.38,
      grantDol: -0.3,
      articles: -0.68,
      citations: -0.21,
      confProc: -0.21,
      articlesActual: 1,
      awardsActual: 9,
      booksActual: 0,
      citationsActual: 49,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 54,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 55,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  facultyDataPoints: [
    {
      facultyId: 359374,
      name: 'PRAKASH, RUCHIKA S',
      rank: 2,
      awards: 0.3,
      books: -0.3,
      citations: 0.4,
      grants: 0.7,
      grantDol: 0.8,
      articles: -0.1,
      confProc: -0.2,
      yearsSincePhD: 10,
      index: 1.0,
      articlesActual: 12.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 440.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 388124.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638242,
      name: 'BOCKBRADER, MARCIA AILEEN',
      rank: 3,
      awards: -0.5,
      books: -0.3,
      citations: 0.6,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.4,
      confProc: 1.4,
      yearsSincePhD: 16,
      index: 1.2,
      articlesActual: 21.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 545.0,
      confProcActual: 2.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 505906,
      name: 'PAGE, STEPHEN JOHN',
      rank: 1,
      awards: 4.3,
      books: -0.3,
      citations: 0.2,
      grants: 0.7,
      grantDol: 1.1,
      articles: 1.0,
      confProc: -0.2,
      yearsSincePhD: 21,
      index: 1.5,
      articlesActual: 33.0,
      awardsActual: 6.0,
      booksActual: 0.0,
      citationsActual: 261.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 513949.0,
      indexActual: 0.0,
    },
    {
      facultyId: 7945,
      name: 'BASSO, D MICHELE',
      rank: 1,
      awards: 1.9,
      books: -0.3,
      citations: 0.3,
      grants: 4.1,
      grantDol: 1.6,
      articles: 0.2,
      confProc: -0.2,
      yearsSincePhD: 28,
      index: 1.4,
      articlesActual: 19.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 339.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 697235.0,
      indexActual: 0.0,
    },
    {
      facultyId: 302910,
      name: 'JACKSON, REBECCA DOROTHY',
      rank: 1,
      awards: 0.3,
      books: -0.3,
      citations: 4.0,
      grants: -0.4,
      grantDol: -0.3,
      articles: 3.2,
      confProc: -0.2,
      yearsSincePhD: 30,
      index: 1.8,
      articlesActual: 77.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 2549.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 378954,
      name: 'BOGNER, JENNIFER ANN',
      rank: 1,
      awards: 0.3,
      books: -0.3,
      citations: 0.4,
      grants: 0.7,
      grantDol: 0.5,
      articles: 1.1,
      confProc: 0.6,
      yearsSincePhD: 30,
      index: 1.5,
      articlesActual: 35.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 426.0,
      confProcActual: 1.0,
      grantsActual: 1.0,
      grantDolActual: 277800.0,
      indexActual: 0.0,
    },
    {
      facultyId: 379164,
      name: 'LOWES, LINDA',
      rank: 2,
      awards: -0.5,
      books: -0.3,
      citations: 2.2,
      grants: 0.7,
      grantDol: 0.0,
      articles: 3.4,
      confProc: 5.6,
      yearsSincePhD: 32,
      index: 1.7,
      articlesActual: 81.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 1460.0,
      confProcActual: 7.0,
      grantsActual: 1.0,
      grantDolActual: 105775.0,
      indexActual: 0.0,
    },
    {
      facultyId: 154897,
      name: 'MARRAS, WILLIAM STEVEN',
      rank: 1,
      awards: 4.3,
      books: 0.9,
      citations: 0.1,
      grants: 3.0,
      grantDol: 0.4,
      articles: 0.9,
      confProc: 3.9,
      yearsSincePhD: 37,
      index: 1.4,
      articlesActual: 32.0,
      awardsActual: 6.0,
      booksActual: 1.0,
      citationsActual: 218.0,
      confProcActual: 5.0,
      grantsActual: 3.0,
      grantDolActual: 236681.0,
      indexActual: 0.0,
    },
    {
      facultyId: 379000,
      name: 'MYSIW, WALTER JERRY',
      rank: 1,
      awards: 0.3,
      books: -0.3,
      citations: 0.5,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.1,
      confProc: 0.6,
      yearsSincePhD: 38,
      index: 1.0,
      articlesActual: 12.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 471.0,
      confProcActual: 1.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 74357,
      name: 'MATKOVIC, VELIMIR',
      rank: 1,
      awards: -0.5,
      books: -0.3,
      citations: -0.3,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.7,
      confProc: -0.2,
      yearsSincePhD: 43,
      index: -1.4,
      articlesActual: 0.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 0.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  unit: {
    indexScore: 1.1,
  },
};
export default data;
