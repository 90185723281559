import React from 'react';
import newsList from './newsList';
import UpcomingEvents from '../upcomingEvents/UpcomingEvents';
import '../styles/newsHighlights.css';

const NewsHighlights = () => {
  return (
    <div className="news-highlights-wrapper">
      <h3 className="news-highlights-header">News Hightlights</h3>
      <div className="news-highlights-container">
        {newsList.map((newsItem, index) => {
          return (
            <div className="news-highlights-card" key={index}>
              <p className="news-highlights-date">{newsItem.date}</p>
              <a
                href={newsItem.link}
                target="_blank"
                className={newsItem.className}
                key={index}
              >
                {newsItem.header}
              </a>

              <p className="news-highlights-summary">{newsItem.summary}</p>
            </div>
          );
        })}
      </div>
      <UpcomingEvents />
    </div>
  );
};

export default NewsHighlights;
