const data = {
  maxObservations: 38,
  dataPoints: [
    {
      yearsSincePhD: 0,
      observations: 2,
      index: -1.505,
      awards: -0.46,
      books: -0.21,
      grants: -0.73,
      grantDol: -0.62,
      articles: -0.745,
      citations: -0.51,
      confProc: -0.28,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 1,
      observations: 3,
      index: -0.936666,
      awards: -0.46,
      books: -0.21,
      grants: -0.73,
      grantDol: -0.62,
      articles: -0.45,
      citations: -0.456666,
      confProc: -0.28,
      articlesActual: 4,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 23,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 2,
      observations: 5,
      index: -1.1,
      awards: -0.46,
      books: -0.21,
      grants: -0.592,
      grantDol: -0.534,
      articles: -0.552,
      citations: -0.48,
      confProc: -0.28,
      articlesActual: 3,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 13,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 42353.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 3,
      observations: 6,
      index: -0.301666,
      awards: -0.18,
      books: -0.21,
      grants: -0.615,
      grantDol: -0.36,
      articles: -0.28,
      citations: -0.275,
      confProc: -0.28,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 99,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 127561.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 4,
      observations: 5,
      index: -0.194,
      awards: -0.46,
      books: -0.21,
      grants: -0.73,
      grantDol: -0.62,
      articles: -0.104,
      citations: -0.254,
      confProc: -0.28,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 107,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 5,
      observations: 10,
      index: -0.803,
      awards: -0.125,
      books: -0.21,
      grants: -0.661,
      grantDol: -0.569,
      articles: -0.446,
      citations: -0.247,
      confProc: -0.28,
      articlesActual: 4,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 110,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 24900.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 6,
      observations: 6,
      index: 0.078333,
      awards: -0.32,
      books: -0.21,
      grants: -0.27,
      grantDol: -0.426666,
      articles: 0.078333,
      citations: 0.111666,
      confProc: -0.28,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 261,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 94686.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 7,
      observations: 11,
      index: -0.111818,
      awards: -0.230909,
      books: -0.21,
      grants: -0.47909,
      grantDol: -0.366363,
      articles: -0.172727,
      citations: 0.147272,
      confProc: 0.297272,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 275,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 123974.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 8,
      observations: 17,
      index: 0.044117,
      awards: 0.181764,
      books: -0.143529,
      grants: -0.445882,
      grantDol: -0.455294,
      articles: -0.223529,
      citations: 0.187647,
      confProc: 0.259411,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 292,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 80511.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 9,
      observations: 25,
      index: -0.0032,
      awards: 0.2448,
      books: -0.21,
      grants: -0.2332,
      grantDol: -0.1764,
      articles: -0.1904,
      citations: -0.0108,
      confProc: 0.3696,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 209,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 216724.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 10,
      observations: 28,
      index: 0.044285,
      awards: 0.139285,
      books: -0.21,
      grants: -0.065,
      grantDol: -0.004642,
      articles: -0.059642,
      citations: -0.101071,
      confProc: -0.179285,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 171,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 300814.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 11,
      observations: 21,
      index: 0.099047,
      awards: 0.298571,
      books: -0.049047,
      grants: -0.072857,
      grantDol: -0.123809,
      articles: -0.147142,
      citations: -0.004285,
      confProc: -0.21238,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 212,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 242654.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 12,
      observations: 37,
      index: -0.029729,
      awards: 0.015945,
      books: -0.088108,
      grants: 0.09027,
      grantDol: 0.138378,
      articles: -0.271621,
      citations: -0.133513,
      confProc: -0.05081,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 157,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 370861.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 13,
      observations: 23,
      index: 0.439565,
      awards: 0.123913,
      books: -0.160869,
      grants: 0.38,
      grantDol: 0.194347,
      articles: 0.147391,
      citations: 0.05,
      confProc: 0.180434,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 234,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 398521.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 14,
      observations: 29,
      index: 0.20862,
      awards: 0.089655,
      books: -0.132068,
      grants: -0.04,
      grantDol: -0.080344,
      articles: 0.205172,
      citations: -0.133103,
      confProc: -0.231034,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 158,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 263780.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 15,
      observations: 28,
      index: 0.083214,
      awards: -0.040357,
      books: -0.089285,
      grants: 0.082857,
      grantDol: 0.221428,
      articles: 0.0,
      citations: -0.182142,
      confProc: -0.103571,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 137,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 411653.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 16,
      observations: 20,
      index: 0.3435,
      awards: 0.002,
      books: 0.862,
      grants: 0.236,
      grantDol: 0.031,
      articles: 0.241,
      citations: 0.009,
      confProc: 0.0025,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 217,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 318607.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 17,
      observations: 23,
      index: 0.132608,
      awards: -0.131304,
      books: -0.111739,
      grants: 0.29,
      grantDol: 0.283913,
      articles: -0.017391,
      citations: -0.145652,
      confProc: -0.065217,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 152,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 442591.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 18,
      observations: 20,
      index: 0.186,
      awards: 0.1695,
      books: -0.041,
      grants: 0.1325,
      grantDol: -0.004,
      articles: -0.065,
      citations: -0.1175,
      confProc: -0.209,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 164,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 301136.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 19,
      observations: 30,
      index: 0.355333,
      awards: 0.015333,
      books: -0.135,
      grants: 0.442333,
      grantDol: 0.320666,
      articles: 0.09,
      citations: -0.037,
      confProc: 0.073333,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 198,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 460072.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 20,
      observations: 31,
      index: 0.119354,
      awards: -0.243548,
      books: -0.173548,
      grants: -0.017741,
      grantDol: 0.098387,
      articles: 0.146774,
      citations: 0.229354,
      confProc: 0.107419,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 310,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 351445.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 21,
      observations: 23,
      index: 0.371739,
      awards: 0.305217,
      books: 0.084347,
      grants: 0.41,
      grantDol: 0.323043,
      articles: 0.103913,
      citations: -0.036521,
      confProc: 0.180434,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 198,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 461625.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 22,
      observations: 27,
      index: 0.244074,
      awards: 0.036666,
      books: -0.001111,
      grants: 0.24074,
      grantDol: 0.27037,
      articles: 0.118148,
      citations: -0.056296,
      confProc: 0.034444,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 189,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 435681.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 23,
      observations: 32,
      index: 0.098437,
      awards: 0.06375,
      books: -0.104375,
      grants: 0.218125,
      grantDol: 0.204062,
      articles: -0.022187,
      citations: -0.037187,
      confProc: -0.169375,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 198,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 403027.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 24,
      observations: 38,
      index: 0.248421,
      awards: -0.173157,
      books: -0.21,
      grants: 0.213947,
      grantDol: 0.127631,
      articles: 0.195263,
      citations: 0.022105,
      confProc: -0.075526,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 223,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 366003.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 25,
      observations: 32,
      index: 0.4175,
      awards: 0.169062,
      books: -0.033437,
      grants: 0.563437,
      grantDol: 0.4075,
      articles: 0.393125,
      citations: 0.469062,
      confProc: -0.21375,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 410,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 502848.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 26,
      observations: 19,
      index: -0.09421,
      awards: -0.371578,
      books: -0.091052,
      grants: -0.04,
      grantDol: -0.189473,
      articles: -0.145263,
      citations: -0.16421,
      confProc: 0.351052,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 145,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 210427.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 27,
      observations: 32,
      index: 0.28625,
      awards: -0.17125,
      books: -0.104062,
      grants: 0.412812,
      grantDol: 0.244687,
      articles: 0.1025,
      citations: -0.004062,
      confProc: 0.095,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 212,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 422907.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 28,
      observations: 33,
      index: -0.065454,
      awards: -0.231212,
      books: 0.097575,
      grants: -0.060909,
      grantDol: -0.124848,
      articles: -0.027272,
      citations: -0.131818,
      confProc: -0.13,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 158,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 242132.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 29,
      observations: 14,
      index: -0.078571,
      awards: 0.078571,
      books: -0.21,
      grants: 0.402857,
      grantDol: 0.557142,
      articles: 0.076428,
      citations: 0.037142,
      confProc: 0.123571,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 229,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 575712.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 30,
      observations: 24,
      index: -0.21125,
      awards: -0.180833,
      books: -0.115833,
      grants: 0.0175,
      grantDol: -0.020416,
      articles: -0.190833,
      citations: -0.234583,
      confProc: 0.190833,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 115,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 293365.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 31,
      observations: 34,
      index: 0.139411,
      awards: -0.065294,
      books: -0.044411,
      grants: 0.324411,
      grantDol: 0.310294,
      articles: 0.37,
      citations: 0.365294,
      confProc: 0.011176,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 366,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 455127.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 32,
      observations: 23,
      index: 0.348695,
      awards: 0.232173,
      books: 0.623913,
      grants: 0.379565,
      grantDol: 0.375652,
      articles: 1.041739,
      citations: 0.52,
      confProc: -0.064782,
      articlesActual: 23,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 431,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 486823.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 33,
      observations: 15,
      index: 0.062,
      awards: 0.154666,
      books: 0.090666,
      grants: -0.132,
      grantDol: 0.017333,
      articles: 0.368,
      citations: 0.584,
      confProc: 0.002,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 458,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 312188.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 34,
      observations: 17,
      index: 0.135882,
      awards: -0.361176,
      books: 0.188823,
      grants: 0.122352,
      grantDol: 0.157647,
      articles: 0.198823,
      citations: 0.054117,
      confProc: -0.071764,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 236,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 380436.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 35,
      observations: 24,
      index: -0.346666,
      awards: -0.110416,
      books: 0.165416,
      grants: -0.27,
      grantDol: -0.19625,
      articles: -0.315833,
      citations: -0.29375,
      confProc: -0.250416,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 90,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 207372.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 36,
      observations: 22,
      index: -0.152727,
      awards: -0.07909,
      books: -0.005,
      grants: -0.102727,
      grantDol: -0.085454,
      articles: -0.063181,
      citations: -0.214545,
      confProc: -0.215909,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 123,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 261461.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 37,
      observations: 19,
      index: -0.29,
      awards: -0.371578,
      books: -0.150526,
      grants: -0.403157,
      grantDol: -0.41,
      articles: -0.078947,
      citations: -0.214736,
      confProc: 0.389473,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 124,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 102890.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 38,
      observations: 25,
      index: 0.0172,
      awards: 0.4784,
      books: 0.1056,
      grants: -0.0128,
      grantDol: -0.0856,
      articles: 0.0092,
      citations: -0.0344,
      confProc: -0.2232,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 199,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 261517.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 39,
      observations: 26,
      index: -0.565,
      awards: -0.298461,
      books: 0.006923,
      grants: -0.438076,
      grantDol: -0.385,
      articles: -0.341153,
      citations: -0.353076,
      confProc: 0.018846,
      articlesActual: 5,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 65,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 114815.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 40,
      observations: 14,
      index: -0.285714,
      awards: 0.437857,
      books: 0.192857,
      grants: -0.089285,
      grantDol: -0.367857,
      articles: -0.154285,
      citations: -0.214285,
      confProc: 0.576428,
      articlesActual: 8,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 123,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 123146.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 41,
      observations: 18,
      index: 0.003333,
      awards: 0.006111,
      books: 0.291111,
      grants: -0.116666,
      grantDol: -0.089444,
      articles: -0.002777,
      citations: 0.035,
      confProc: -0.122777,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 228,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 259528.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 42,
      observations: 20,
      index: 0.1675,
      awards: 0.0845,
      books: -0.097,
      grants: 0.098,
      grantDol: 0.1215,
      articles: 0.059,
      citations: 0.402,
      confProc: 0.2845,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 382,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 362844.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 43,
      observations: 10,
      index: -0.056,
      awards: -0.124,
      books: 0.918,
      grants: -0.109,
      grantDol: -0.009,
      articles: 0.026,
      citations: -0.098,
      confProc: -0.28,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 1,
      citationsActual: 173,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 298598.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 44,
      observations: 12,
      index: -0.124166,
      awards: 0.308333,
      books: -0.21,
      grants: 0.075,
      grantDol: 0.05,
      articles: 0.144166,
      citations: 0.430833,
      confProc: 1.073333,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 394,
      confProcActual: 1,
      grantsActual: 1,
      grantDolActual: 327643.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 45,
      observations: 14,
      index: -0.949285,
      awards: -0.16,
      books: 0.595714,
      grants: -0.434285,
      grantDol: -0.271428,
      articles: -0.561428,
      citations: -0.384285,
      confProc: -0.28,
      articlesActual: 2,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 52,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 170798.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 46,
      observations: 9,
      index: -0.724444,
      awards: -0.273333,
      books: 0.165555,
      grants: -0.04,
      grantDol: 0.044444,
      articles: -0.497777,
      citations: -0.317777,
      confProc: -0.201111,
      articlesActual: 3,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 80,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 325243.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 47,
      observations: 5,
      index: 0.768,
      awards: 3.056,
      books: -0.21,
      grants: 0.786,
      grantDol: 1.22,
      articles: 0.206,
      citations: 0.27,
      confProc: 1.696,
      articlesActual: 12,
      awardsActual: 4,
      booksActual: 0,
      citationsActual: 326,
      confProcActual: 2,
      grantsActual: 2,
      grantDolActual: 900600.0,
      indexActual: 2.0,
    },
    {
      yearsSincePhD: 48,
      observations: 12,
      index: -0.164166,
      awards: 0.795833,
      books: 0.354166,
      grants: -0.155,
      grantDol: 0.191666,
      articles: -0.061666,
      citations: 0.826666,
      confProc: -0.103333,
      articlesActual: 9,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 560,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 397039.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 49,
      observations: 5,
      index: -0.382,
      awards: 0.042,
      books: 0.692,
      grants: -0.178,
      grantDol: 0.058,
      articles: 0.114,
      citations: 0.856,
      confProc: -0.28,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 572,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 331666.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 50,
      observations: 6,
      index: -0.185,
      awards: -0.18,
      books: 0.165,
      grants: -0.5,
      grantDol: -0.448333,
      articles: -0.163333,
      citations: 0.085,
      confProc: -0.043333,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 249,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 83937.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 51,
      observations: 4,
      index: 0.4275,
      awards: 0.38,
      books: 0.0725,
      grants: -0.2125,
      grantDol: -0.0175,
      articles: 0.1625,
      citations: -0.0925,
      confProc: -0.28,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 174,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 294027.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 52,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 53,
      observations: 4,
      index: -0.07,
      awards: 0.17,
      books: 0.0725,
      grants: -0.2125,
      grantDol: -0.5425,
      articles: 0.895,
      citations: 2.2525,
      confProc: 0.075,
      articlesActual: 21,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 1156,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 37500.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 54,
      observations: 3,
      index: 0.183333,
      awards: -0.46,
      books: 0.916666,
      grants: 0.19,
      grantDol: 0.17,
      articles: 0.273333,
      citations: 0.433333,
      confProc: -0.28,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 1,
      citationsActual: 395,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 387323.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 55,
      observations: 1,
      index: -1.37,
      awards: -0.46,
      books: -0.21,
      grants: -0.73,
      grantDol: -0.62,
      articles: -0.71,
      citations: -0.49,
      confProc: -0.28,
      articlesActual: 1,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 7,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  facultyDataPoints: [
    {
      facultyId: 802462,
      name: 'VEST, MATTHEW S',
      rank: 9,
      awards: -0.5,
      books: -0.2,
      citations: -0.5,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.6,
      confProc: -0.3,
      yearsSincePhD: 2,
      index: -1.4,
      articlesActual: 2.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 1.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 802461,
      name: 'THIELE, COURTNEY E',
      rank: 9,
      awards: -0.5,
      books: -0.2,
      citations: -0.5,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.7,
      confProc: -0.3,
      yearsSincePhD: 5,
      index: -1.4,
      articlesActual: 1.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 0.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 855667,
      name: 'HOWARD, DANA SARAH',
      rank: 9,
      awards: -0.5,
      books: -0.2,
      citations: -0.5,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.3,
      confProc: -0.3,
      yearsSincePhD: 5,
      index: -0.8,
      articlesActual: 6.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 18.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 757987,
      name: 'CRAY, JAMES J',
      rank: 2,
      awards: 0.4,
      books: -0.2,
      citations: -0.2,
      grants: 0.6,
      grantDol: -0.3,
      articles: 1.8,
      confProc: -0.3,
      yearsSincePhD: 10,
      index: 1.0,
      articlesActual: 33.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 133.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 161698.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384102,
      name: 'CLINCHOT, DANIEL MICHAEL',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: -0.7,
      grantDol: -0.6,
      articles: 0.1,
      confProc: -0.3,
      yearsSincePhD: 31,
      index: 0.0,
      articlesActual: 11.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 96.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 155804,
      name: 'MCHUGH, KIRK MARSHALL',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: 0.0,
      grants: 0.0,
      grantDol: -0.2,
      articles: 0.4,
      confProc: -0.3,
      yearsSincePhD: 33,
      index: 0.8,
      articlesActual: 15.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 214.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 228327.0,
      indexActual: 0.0,
    },
  ],
  unit: {
    indexScore: -0.4,
  },
};
export default data;
