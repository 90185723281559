import React, { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tabs';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProposalTotals from '../proposalTotals/ProposalTotals';
import Conversions from '../conversionRatios/Conversions';
import '../../styles/projectionsNav.css';

function ProjectionsNav(props) {
  // Conversions table state
  const [conversionCurrYear, setConversionCurrYear] = useState(2020);
  const [currConversionAgency, setCurrConversionAgency] = useState({
    name: '',
    index: 0,
  });
  // filtered original data based on current year
  const [conversionFilteredData, setConversionFilteredData] = useState([]);

  // Proposal Totals table state
  // current ui selected year to filter agencies data
  const [proposalCurrentYear, setProposalCurrentYear] = useState(2020);
  // sets agency name and index to display in the chart
  const [proposalCurrChartAgency, setProposalCurrChartAgency] = useState({
    name: '',
    index: 0,
  });
  // filtered original data based on current year
  const [proposalFilteredData, setProposalFilteredData] = useState([]);

  return (
    <>
      <div className="projections-container">
        <Tabs id="projections-tabs-nav" className="projections-tabs-nav">
          <Tab
            eventKey="conversions"
            title="Conversion Ratios"
            className="conversions-tab-nav"
          >
            <Conversions
              collegeID={props.collegeID}
              currentYear={conversionCurrYear}
              setCurrentYear={setConversionCurrYear}
              currChartAgency={currConversionAgency}
              setCurrChartAgency={setCurrConversionAgency}
              filteredData={conversionFilteredData}
              setFilteredData={setConversionFilteredData}
              proposalYear={proposalCurrentYear}
              proposalAgency={proposalCurrChartAgency}
              proposalData={proposalFilteredData}
            />
          </Tab>
          <Tab
            eventKey="core-proposal"
            title="Proposal Totals"
            className="proposal-totals-tab-nav"
          >
            <ProposalTotals
              collegeID={props.collegeID}
              currentYear={proposalCurrentYear}
              setCurrentYear={setProposalCurrentYear}
              currChartAgency={proposalCurrChartAgency}
              setCurrChartAgency={setProposalCurrChartAgency}
              filteredData={proposalFilteredData}
              setFilteredData={setProposalFilteredData}
              converionYear={conversionCurrYear}
              conversionAgency={currConversionAgency}
              conversionData={conversionFilteredData}
            />
          </Tab>
        </Tabs>
      </div>
    </>
  );
}

export default ProjectionsNav;
