import React, { useState, useEffect } from 'react';
import { Link, Redirect } from 'react-router-dom';
import axios from 'axios';
import { useTable, useSortBy } from 'react-table';
import BTable from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import './unitProposalsTable.css';

function Table({ columns, data }) {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const applyClassNameStyles = cell => {
    let classNameStyle = '';
    const isCellNegative = cell.value < 0;
    const cellHeaderCheck =
      cell.column.Header === 'Directs' ||
      cell.column.Header === 'Indirects' ||
      cell.column.Header === 'Total';
    if (cellHeaderCheck && isCellNegative) {
      classNameStyle = 'negative-number';
    }
    return classNameStyle;
  };

  return (
    <BTable
      size="md"
      borderless="true"
      className="proposals-table"
      responsive
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <td {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                {column.depth > 0 ? (
                  <span className="caret-icon">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faCaretDown} />
                      ) : (
                        <FontAwesomeIcon icon={faCaretUp} />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </span>
                ) : (
                  ''
                )}
              </td>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    className={applyClassNameStyles(cell)}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </BTable>
  );
}

function UnitProposalsTable(props) {
  const url = process.env.REACT_APP_API_URL;

  // all Unit Persons Proposals and Grants persons data from 2015 to 2019
  const [unitPersonsData, setUnitPersonsData] = useState([]);

  const formatNumber = num => {
    let strNumWithSeparators = '';
    let strNumNegative = '';
    if (num < 0) {
      strNumWithSeparators = new Intl.NumberFormat().format(num);
      strNumNegative = strNumWithSeparators.substring(1);
      strNumNegative = `($${strNumNegative})`;
      return strNumNegative;
    } else {
      strNumWithSeparators = new Intl.NumberFormat().format(num);
      strNumWithSeparators = `$${strNumWithSeparators}`;
      return strNumWithSeparators;
    }
  };

  const averagePerYear = (propos, grants, yearsAmount = 5) => {
    // yearsLength from 2015-2019
    const sumOfAllProposalsGrants = propos + grants;
    const average = sumOfAllProposalsGrants / yearsAmount;
    return average;
  };

  const hitRate = (propos, grants) => {
    let hitRateRounded = null;
    if ((propos === 0 && grants === 0) || grants === 0) return hitRateRounded;
    const sumOfAllProposalsGrants = propos + grants;
    const hitRate = (grants * 100) / sumOfAllProposalsGrants;
    // Number.EPSILON to ensure things like 1.005 round correctly
    hitRateRounded = Math.round((hitRate + Number.EPSILON) * 100) / 100;
    return hitRateRounded;
  };

  const buildPersonData = data => {
    let unitProposalsPersons = [];
    // loop over proposals
    for (let i = 0; i < data.proposals.length; i++) {
      let person = {
        id: null,
        fullName: '',
        avgYear: null,
        funded: 0,
        notFunded: 0,
        hitRate: null,
        directs: 0,
        indirects: 0,
        total: 0,
      };
      person.id = data.proposals[i].PersonId;
      person.fullName = data.proposals[i].FullName;
      person.notFunded = Number(data.proposals[i].ProposalsTotal);
      // loop over grants
      for (let j = 0; j < data.grants.length; j++) {
        // if curr person from proposlas in the grants data, then add grants data to the curr person
        if (person.id === data.grants[j].PersonID) {
          person.funded = data.grants[j].GrantsTotal;
          person.directs = data.grants[j].DirectsTotal;
          person.indirects = data.grants[j].IndirectsTotal;
          person.total = data.grants[j].Total;
        }
      }
      // calculate avg and hitrate for curr person
      person.avgYear = averagePerYear(person.notFunded, person.funded);
      person.hitRate = hitRate(person.notFunded, person.funded);
      unitProposalsPersons.push(person);
    }
    //alphabetically sorted
    let sortedAlphabet = unitProposalsPersons.sort(function (a, b) {
      if (a.fullName < b.fullName) {
        return -1;
      }
      if (a.fullName > b.fullName) {
        return 1;
      }
      return 0;
    });

    return sortedAlphabet;
  };

  useEffect(() => {
    axios
      .get(`${url}/api/units/${props.unit.Id}/proposals`)
      .then(res => {
        setUnitPersonsData(buildPersonData(res.data));
      })
      .catch(err => console.log(err.response));
  }, []);

  const columns = React.useMemo(
    () => [
      {
        Header: ' ',
        columns: [
          {
            Header: 'Name',
            accessor: 'fullName',
            Cell: ({ row, cell }) => {
              return (
                <Link to={`/pigrants/${row.original.id}`} key={row.original.id}>
                  {cell.value}
                </Link>
              );
            },
          },
        ],
      },
      {
        Header: 'Proposals 2015 to 2019',
        columns: [
          {
            Header: 'Avg/Year',
            accessor: 'avgYear',
          },
          {
            Header: 'Funded',
            accessor: 'funded',
          },
          {
            Header: 'Not Funded',
            accessor: 'notFunded',
          },
          {
            Header: 'Hit Rate',
            accessor: 'hitRate',
            Cell: ({ cell: { value } }) => (value ? `${value}%` : '-'),
          },
        ],
      },
      {
        Header: 'Actuals 2019',
        columns: [
          {
            Header: 'Directs',
            accessor: 'directs',
            Cell: ({ cell: { value } }) => formatNumber(value),
          },
          {
            Header: 'Indirects',
            accessor: 'indirects',
            Cell: ({ cell: { value } }) => formatNumber(value),
          },
          {
            Header: 'Total',
            accessor: 'total',
            Cell: ({ cell: { value } }) => formatNumber(value),
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      {props.unit.Id ? (
        <div className="proposals-container">
          <div className="proposals-table-wrapper">
            {unitPersonsData && (
              <Table
                responsive
                size="sm"
                columns={columns}
                data={unitPersonsData}
              />
            )}
          </div>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

export default UnitProposalsTable;
