const data = {
  maxObservations: 90,
  dataPoints: [
    {
      yearsSincePhD: 0,
      observations: 2,
      index: -1.395,
      awards: -0.53,
      books: -0.22,
      grants: -0.9,
      grantDol: -0.76,
      articles: -0.66,
      citations: -0.435,
      confProc: -0.28,
      articlesActual: 2,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 15,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 1,
      observations: 1,
      index: -0.54,
      awards: -0.07,
      books: -0.22,
      grants: -0.9,
      grantDol: -0.76,
      articles: -0.29,
      citations: -0.35,
      confProc: -0.28,
      articlesActual: 8,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 85,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 2,
      observations: 1,
      index: -1.54,
      awards: -0.53,
      books: -0.22,
      grants: -0.9,
      grantDol: -0.76,
      articles: -0.7,
      citations: -0.45,
      confProc: -0.28,
      articlesActual: 2,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 3,
      observations: 6,
      index: -0.358333,
      awards: -0.141666,
      books: -0.22,
      grants: -0.608333,
      grantDol: -0.311666,
      articles: -0.231666,
      citations: 0.378333,
      confProc: -0.28,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 676,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 287195.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 4,
      observations: 8,
      index: -0.26125,
      awards: -0.18125,
      books: -0.22,
      grants: -0.755,
      grantDol: -0.63,
      articles: -0.30625,
      citations: -0.055,
      confProc: -0.185,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 325,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 83335.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 5,
      observations: 14,
      index: -0.278571,
      awards: -0.166428,
      books: -0.22,
      grants: -0.692857,
      grantDol: -0.652857,
      articles: -0.166428,
      citations: 0.542857,
      confProc: 0.858571,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 811,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 69105.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 6,
      observations: 32,
      index: -0.277812,
      awards: -0.152812,
      books: -0.22,
      grants: -0.700312,
      grantDol: -0.594062,
      articles: -0.26875,
      citations: -0.168125,
      confProc: -0.25625,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 233,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 106366.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 7,
      observations: 50,
      index: -0.3174,
      awards: -0.3084,
      books: -0.22,
      grants: -0.6912,
      grantDol: -0.5548,
      articles: -0.3512,
      citations: 0.0316,
      confProc: -0.2496,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 395,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 131755.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 8,
      observations: 59,
      index: -0.178135,
      awards: -0.105254,
      books: -0.141694,
      grants: -0.495084,
      grantDol: -0.419661,
      articles: -0.317796,
      citations: -0.101525,
      confProc: -0.022542,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 286,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 218456.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 9,
      observations: 72,
      index: -0.212916,
      awards: -0.059027,
      books: -0.22,
      grants: -0.430416,
      grantDol: -0.419305,
      articles: -0.301527,
      citations: -0.105694,
      confProc: -0.09,
      articlesActual: 7,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 283,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 218845.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 10,
      observations: 77,
      index: -0.128441,
      awards: -0.138571,
      books: -0.2,
      grants: -0.30974,
      grantDol: -0.253636,
      articles: -0.214415,
      citations: -0.145714,
      confProc: -0.181298,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 251,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 324758.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 11,
      observations: 90,
      index: -0.076555,
      awards: -0.034222,
      books: -0.22,
      grants: -0.258555,
      grantDol: -0.248222,
      articles: -0.178222,
      citations: -0.095111,
      confProc: -0.060555,
      articlesActual: 9,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 292,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 328257.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 12,
      observations: 83,
      index: 0.064578,
      awards: -0.13265,
      books: -0.182891,
      grants: -0.113614,
      grantDol: -0.090361,
      articles: 0.012168,
      citations: 0.000602,
      confProc: -0.005421,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 369,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 429154.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 13,
      observations: 81,
      index: 0.249382,
      awards: 0.061234,
      books: -0.162962,
      grants: 0.092839,
      grantDol: 0.063827,
      articles: 0.042469,
      citations: 0.008888,
      confProc: 0.029506,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 377,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 527823.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 14,
      observations: 73,
      index: 0.145205,
      awards: -0.199726,
      books: -0.22,
      grants: -0.054109,
      grantDol: -0.088767,
      articles: 0.168356,
      citations: -0.044109,
      confProc: 0.302602,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 334,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 430574.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 15,
      observations: 76,
      index: 0.122105,
      awards: -0.022763,
      books: -0.22,
      grants: 0.035657,
      grantDol: -0.061184,
      articles: -0.025131,
      citations: -0.132763,
      confProc: 0.08,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 261,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 447872.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 16,
      observations: 61,
      index: 0.155737,
      awards: -0.004262,
      books: -0.09377,
      grants: 0.122622,
      grantDol: 0.107377,
      articles: 0.035409,
      citations: -0.097868,
      confProc: -0.130491,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 290,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 555360.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 17,
      observations: 67,
      index: 0.300597,
      awards: 0.080149,
      books: -0.082089,
      grants: 0.109104,
      grantDol: 0.083582,
      articles: 0.126119,
      citations: 0.196567,
      confProc: -0.121194,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 529,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 540805.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 18,
      observations: 72,
      index: 0.13,
      awards: -0.111111,
      books: -0.091666,
      grants: 0.185277,
      grantDol: 0.048472,
      articles: -0.028194,
      citations: -0.099305,
      confProc: -0.01625,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 289,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 517971.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 19,
      observations: 83,
      index: 0.293132,
      awards: 0.085662,
      books: -0.164337,
      grants: 0.356506,
      grantDol: 0.274216,
      articles: 0.041445,
      citations: 0.014457,
      confProc: -0.041927,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 380,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 662826.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 20,
      observations: 77,
      index: 0.210779,
      awards: 0.07987,
      books: -0.040129,
      grants: 0.317792,
      grantDol: 0.341038,
      articles: 0.048701,
      citations: 0.006493,
      confProc: -0.003636,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 374,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 704976.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 21,
      observations: 80,
      index: 0.360875,
      awards: 0.10375,
      books: 0.04925,
      grants: 0.44725,
      grantDol: 0.369,
      articles: 0.162,
      citations: 0.194625,
      confProc: 0.03325,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 527,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 723237.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 22,
      observations: 82,
      index: 0.445853,
      awards: 0.071097,
      books: -0.069756,
      grants: 0.485243,
      grantDol: 0.384512,
      articles: 0.402804,
      citations: 0.126463,
      confProc: 0.109024,
      articlesActual: 18,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 472,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 732886.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 23,
      observations: 78,
      index: -0.025641,
      awards: -0.131025,
      books: -0.00282,
      grants: 0.04141,
      grantDol: 0.000512,
      articles: 0.169871,
      citations: 0.02141,
      confProc: -0.153333,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 386,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 487489.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 24,
      observations: 83,
      index: 0.12265,
      awards: -0.189036,
      books: -0.053132,
      grants: 0.188433,
      grantDol: 0.119277,
      articles: 0.089759,
      citations: -0.058072,
      confProc: 0.241445,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 322,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 563123.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 25,
      observations: 77,
      index: 0.028571,
      awards: -0.126103,
      books: 0.03987,
      grants: 0.174805,
      grantDol: 0.040649,
      articles: 0.006233,
      citations: -0.070649,
      confProc: -0.191168,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 312,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 512744.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 26,
      observations: 74,
      index: -0.064864,
      awards: 0.023108,
      books: -0.115945,
      grants: 0.265,
      grantDol: 0.086216,
      articles: -0.147837,
      citations: -0.017567,
      confProc: -0.064594,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 355,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 542198.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 27,
      observations: 78,
      index: 0.125,
      awards: -0.005256,
      books: -0.042307,
      grants: 0.123333,
      grantDol: 0.098076,
      articles: 0.118333,
      citations: -0.002051,
      confProc: 0.128974,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 367,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 549368.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 28,
      observations: 75,
      index: -0.040266,
      awards: -0.096533,
      books: -0.0764,
      grants: 0.001866,
      grantDol: -0.0364,
      articles: 0.015466,
      citations: -0.031466,
      confProc: 0.084533,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 343,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 463627.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 29,
      observations: 63,
      index: 0.087142,
      awards: 0.023333,
      books: -0.024444,
      grants: 0.071111,
      grantDol: 0.054126,
      articles: 0.097936,
      citations: -0.022857,
      confProc: 0.262222,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 351,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 521342.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 30,
      observations: 80,
      index: -0.066875,
      awards: 0.116125,
      books: 0.049375,
      grants: 0.229,
      grantDol: 0.202,
      articles: -0.0425,
      citations: 0.019,
      confProc: -0.137625,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 384,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 616075.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 31,
      observations: 86,
      index: -0.028139,
      awards: -0.016279,
      books: -0.058953,
      grants: 0.272093,
      grantDol: 0.259534,
      articles: 0.117209,
      citations: 0.127674,
      confProc: 0.038023,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 472,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 652518.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 32,
      observations: 76,
      index: 0.317763,
      awards: 0.106447,
      books: 0.205263,
      grants: 0.326184,
      grantDol: 0.316447,
      articles: 0.304605,
      citations: 0.173157,
      confProc: 0.0,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 509,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 689461.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 33,
      observations: 78,
      index: -0.098589,
      awards: 0.006923,
      books: 0.016794,
      grants: 0.018717,
      grantDol: 0.099743,
      articles: 0.043076,
      citations: -0.025384,
      confProc: -0.133846,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 348,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 550566.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 34,
      observations: 65,
      index: -0.016615,
      awards: 0.020461,
      books: 0.088,
      grants: 0.139692,
      grantDol: 0.094615,
      articles: 0.040307,
      citations: 0.179692,
      confProc: 0.140615,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 515,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 547319.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 35,
      observations: 63,
      index: 0.03365,
      awards: 0.008253,
      books: 0.0,
      grants: 0.163333,
      grantDol: 0.141269,
      articles: 0.297619,
      citations: 0.450793,
      confProc: 0.226349,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 735,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 577163.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 36,
      observations: 67,
      index: -0.214626,
      awards: -0.092388,
      books: 0.561194,
      grants: 0.100149,
      grantDol: 0.16,
      articles: -0.052985,
      citations: -0.054029,
      confProc: -0.07597,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 325,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 589163.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 37,
      observations: 54,
      index: -0.152777,
      awards: -0.021851,
      books: -0.105925,
      grants: -0.100555,
      grantDol: -0.137592,
      articles: -0.086296,
      citations: -0.151111,
      confProc: 0.114074,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 246,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 398768.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 38,
      observations: 54,
      index: -0.196481,
      awards: 0.09074,
      books: 0.407037,
      grants: -0.004259,
      grantDol: 0.007037,
      articles: -0.072777,
      citations: -0.122037,
      confProc: -0.153333,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 269,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 491514.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 39,
      observations: 63,
      index: -0.215079,
      awards: -0.005396,
      books: 0.097777,
      grants: -0.021269,
      grantDol: 0.036349,
      articles: -0.118253,
      citations: 0.011587,
      confProc: 0.057619,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 378,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 509718.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 40,
      observations: 37,
      index: -0.175945,
      awards: 0.288108,
      books: 0.029729,
      grants: -0.332972,
      grantDol: -0.185945,
      articles: 0.003243,
      citations: -0.044594,
      confProc: -0.074594,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 332,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 367754.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 41,
      observations: 42,
      index: -0.160238,
      awards: 0.123571,
      books: 0.329523,
      grants: -0.025714,
      grantDol: 0.159047,
      articles: -0.04119,
      citations: 0.068809,
      confProc: 0.02738,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 424,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 588472.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 42,
      observations: 30,
      index: -0.075666,
      awards: 0.152333,
      books: 0.139,
      grants: 0.089666,
      grantDol: 0.204333,
      articles: 0.125333,
      citations: 0.091333,
      confProc: 0.049333,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 443,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 617433.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 43,
      observations: 30,
      index: 0.244666,
      awards: 0.276333,
      books: 0.292666,
      grants: -0.026,
      grantDol: 0.028333,
      articles: 0.261,
      citations: 0.356666,
      confProc: 0.226333,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 659,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 504717.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 44,
      observations: 34,
      index: -0.062352,
      awards: 0.675,
      books: 0.730588,
      grants: -0.076764,
      grantDol: -0.063235,
      articles: 0.112647,
      citations: 0.007941,
      confProc: 0.144411,
      articlesActual: 13,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 375,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 446484.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 45,
      observations: 27,
      index: -0.205555,
      awards: 0.124814,
      books: 0.521111,
      grants: -0.165555,
      grantDol: -0.079259,
      articles: -0.23,
      citations: -0.176296,
      confProc: -0.139259,
      articlesActual: 8,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 226,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 436204.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 46,
      observations: 21,
      index: -0.31619,
      awards: -0.042857,
      books: 0.146666,
      grants: 0.043809,
      grantDol: 0.037142,
      articles: -0.103809,
      citations: -0.171428,
      confProc: -0.207619,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 229,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 510691.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 47,
      observations: 15,
      index: 0.65,
      awards: 0.866666,
      books: 1.114,
      grants: 0.265333,
      grantDol: 0.62,
      articles: 0.578666,
      citations: 0.300666,
      confProc: 0.934666,
      articlesActual: 20,
      awardsActual: 3,
      booksActual: 0,
      citationsActual: 613,
      confProcActual: 1,
      grantsActual: 2,
      grantDolActual: 883364.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 48,
      observations: 18,
      index: -0.717777,
      awards: 0.247222,
      books: 0.72,
      grants: -0.446666,
      grantDol: -0.408888,
      articles: -0.445555,
      citations: -0.291666,
      confProc: 0.015555,
      articlesActual: 5,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 130,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 225328.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 49,
      observations: 17,
      index: -0.387058,
      awards: 0.264117,
      books: 0.232352,
      grants: -0.281764,
      grantDol: -0.153529,
      articles: -0.218823,
      citations: -0.097647,
      confProc: -0.235294,
      articlesActual: 9,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 289,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 388170.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 50,
      observations: 10,
      index: 0.237,
      awards: 0.636,
      books: 0.396,
      grants: 0.091,
      grantDol: 0.116,
      articles: -0.151,
      citations: -0.069,
      confProc: -0.052,
      articlesActual: 10,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 312,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 561969.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 51,
      observations: 13,
      index: 0.038461,
      awards: 1.083076,
      books: 0.253076,
      grants: -0.137692,
      grantDol: -0.03923,
      articles: -0.036153,
      citations: 0.114615,
      confProc: -0.163076,
      articlesActual: 11,
      awardsActual: 3,
      booksActual: 0,
      citationsActual: 462,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 461970.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 52,
      observations: 8,
      index: 0.10875,
      awards: 0.9825,
      books: 0.7425,
      grants: -0.1725,
      grantDol: -0.0825,
      articles: -0.21,
      citations: 0.03875,
      confProc: -0.28,
      articlesActual: 9,
      awardsActual: 3,
      booksActual: 0,
      citationsActual: 401,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 434650.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 53,
      observations: 9,
      index: -0.507777,
      awards: 0.143333,
      books: -0.22,
      grants: -0.576666,
      grantDol: -0.416666,
      articles: -0.285555,
      citations: -0.257777,
      confProc: -0.026666,
      articlesActual: 8,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 159,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 219521.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 54,
      observations: 5,
      index: -0.442,
      awards: -0.158,
      books: 0.702,
      grants: 0.266,
      grantDol: 0.53,
      articles: -0.314,
      citations: -0.332,
      confProc: -0.28,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 98,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 825688.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 55,
      observations: 3,
      index: 0.136666,
      awards: 1.336666,
      books: 2.856666,
      grants: -0.51,
      grantDol: -0.333333,
      articles: 4.316666,
      citations: 3.496666,
      confProc: -0.28,
      articlesActual: 75,
      awardsActual: 4,
      booksActual: 2,
      citationsActual: 3212,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 272862.0,
      indexActual: 0.0,
    },
  ],
  facultyDataPoints: [
    {
      facultyId: 887262,
      name: 'GORR, MATTHEW W',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: -0.9,
      grantDol: -0.8,
      articles: -0.7,
      confProc: -0.3,
      yearsSincePhD: 4,
      index: -1.1,
      articlesActual: 2.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 103.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 855773,
      name: 'ELNAKISH, MOHAMMAD TOUKHY MOSLEH',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: -0.9,
      grantDol: -0.8,
      articles: -0.1,
      confProc: -0.3,
      yearsSincePhD: 6,
      index: -0.4,
      articlesActual: 11.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 103.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887265,
      name: 'BASKIN, KEDRYN KJESTINE',
      rank: 3,
      awards: -0.1,
      books: -0.2,
      citations: -0.2,
      grants: -0.9,
      grantDol: -0.8,
      articles: -0.4,
      confProc: -0.3,
      yearsSincePhD: 7,
      index: -0.4,
      articlesActual: 6.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 174.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 1088615,
      name: 'CHINTHALAPUDI, KRISHNA',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: -0.9,
      grantDol: -0.8,
      articles: -0.4,
      confProc: -0.3,
      yearsSincePhD: 8,
      index: -0.6,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 108.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 855772,
      name: 'BANSAL, SHYAM',
      rank: 3,
      awards: -0.1,
      books: -0.2,
      citations: -0.1,
      grants: -0.3,
      grantDol: -0.4,
      articles: -0.3,
      confProc: -0.3,
      yearsSincePhD: 8,
      index: 0.2,
      articlesActual: 8.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 271.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 258455.0,
      indexActual: 0.0,
    },
    {
      facultyId: 604222,
      name: 'GURURAJA RAO, SHUBHA',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.9,
      grantDol: -0.8,
      articles: 0.1,
      confProc: -0.3,
      yearsSincePhD: 11,
      index: -0.3,
      articlesActual: 14.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 85.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 769837,
      name: 'ACCORNERO, FEDERICA',
      rank: 3,
      awards: 0.9,
      books: -0.2,
      citations: -0.2,
      grants: 0.3,
      grantDol: 0.1,
      articles: 0.2,
      confProc: -0.3,
      yearsSincePhD: 12,
      index: 0.8,
      articlesActual: 15.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 229.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 570690.0,
      indexActual: 0.0,
    },
    {
      facultyId: 604228,
      name: 'SINGH, HARPREET',
      rank: 2,
      awards: -0.1,
      books: 1.3,
      citations: 0.0,
      grants: -0.3,
      grantDol: -0.1,
      articles: 0.8,
      confProc: -0.3,
      yearsSincePhD: 12,
      index: 0.9,
      articlesActual: 24.0,
      awardsActual: 1.0,
      booksActual: 1.0,
      citationsActual: 327.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 423406.0,
      indexActual: 0.0,
    },
    {
      facultyId: 778742,
      name: 'FU, JIDONG',
      rank: 2,
      awards: -0.1,
      books: -0.2,
      citations: -0.2,
      grants: -0.9,
      grantDol: -0.8,
      articles: -0.4,
      confProc: -0.3,
      yearsSincePhD: 13,
      index: -0.3,
      articlesActual: 6.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 223.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 505938,
      name: 'SARKAR, ANASUYA',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: -0.3,
      grantDol: -0.1,
      articles: -0.5,
      confProc: 2.0,
      yearsSincePhD: 15,
      index: -0.4,
      articlesActual: 5.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 138.0,
      confProcActual: 3.0,
      grantsActual: 1.0,
      grantDolActual: 412500.0,
      indexActual: 0.0,
    },
    {
      facultyId: 542283,
      name: 'WEISLEDER, NOAH L',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: -0.1,
      grants: 1.4,
      grantDol: 1.5,
      articles: 0.3,
      confProc: -0.3,
      yearsSincePhD: 16,
      index: 1.1,
      articlesActual: 16.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 302.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 1425433.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384697,
      name: 'WOLD, LOREN EUGENE',
      rank: 1,
      awards: 0.4,
      books: -0.2,
      citations: -0.1,
      grants: 1.4,
      grantDol: 2.5,
      articles: 0.0,
      confProc: -0.3,
      yearsSincePhD: 16,
      index: 1.1,
      articlesActual: 13.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 298.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 2072083.0,
      indexActual: 0.0,
    },
    {
      facultyId: 359715,
      name: 'BIESIADECKI, BRANDON J',
      rank: 2,
      awards: -0.1,
      books: -0.2,
      citations: -0.1,
      grants: -0.3,
      grantDol: -0.1,
      articles: 0.7,
      confProc: -0.3,
      yearsSincePhD: 16,
      index: 0.8,
      articlesActual: 23.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 266.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 415897.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887270,
      name: 'ZHAO, JING',
      rank: 2,
      awards: -0.1,
      books: -0.2,
      citations: -0.1,
      grants: -0.3,
      grantDol: -0.3,
      articles: 0.9,
      confProc: 1.2,
      yearsSincePhD: 18,
      index: 0.8,
      articlesActual: 25.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 294.0,
      confProcActual: 2.0,
      grantsActual: 1.0,
      grantDolActual: 310911.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384435,
      name: 'MOHLER, PETER JOHN',
      rank: 1,
      awards: 2.7,
      books: -0.2,
      citations: 1.3,
      grants: 2.0,
      grantDol: 3.6,
      articles: 2.9,
      confProc: -0.3,
      yearsSincePhD: 19,
      index: 2.0,
      articlesActual: 55.0,
      awardsActual: 7.0,
      booksActual: 0.0,
      citationsActual: 1451.0,
      confProcActual: 0.0,
      grantsActual: 5.0,
      grantDolActual: 2780491.0,
      indexActual: 0.0,
    },
    {
      facultyId: 289537,
      name: 'TIKUNOVA, SVETLANA BORISOVNA',
      rank: 3,
      awards: -0.1,
      books: -0.2,
      citations: -0.4,
      grants: -0.3,
      grantDol: -0.5,
      articles: -0.2,
      confProc: -0.3,
      yearsSincePhD: 19,
      index: -0.3,
      articlesActual: 10.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 60.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 154685.0,
      indexActual: 0.0,
    },
    {
      facultyId: 231153,
      name: 'DESCHENES, ISABELLE',
      rank: 1,
      awards: 0.9,
      books: -0.2,
      citations: -0.1,
      grants: 2.0,
      grantDol: 2.8,
      articles: 0.8,
      confProc: -0.3,
      yearsSincePhD: 20,
      index: 1.4,
      articlesActual: 24.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 254.0,
      confProcActual: 0.0,
      grantsActual: 5.0,
      grantDolActual: 2277419.0,
      indexActual: 0.0,
    },
    {
      facultyId: 378833,
      name: 'FEDOROV, VADIM V',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: 0.6,
      grants: 0.3,
      grantDol: 1.1,
      articles: 1.1,
      confProc: -0.3,
      yearsSincePhD: 20,
      index: 1.4,
      articlesActual: 28.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 883.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 1194288.0,
      indexActual: 0.0,
    },
    {
      facultyId: 691999,
      name: 'TERENTYEV, DMITRY',
      rank: 2,
      awards: -0.1,
      books: -0.2,
      citations: -0.2,
      grants: 0.3,
      grantDol: 0.8,
      articles: 0.8,
      confProc: -0.3,
      yearsSincePhD: 20,
      index: 0.9,
      articlesActual: 24.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 176.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 1033167.0,
      indexActual: 0.0,
    },
    {
      facultyId: 1088614,
      name: 'BELEVYCH, ANDRIY',
      rank: 3,
      awards: -0.1,
      books: -0.2,
      citations: -0.2,
      grants: -0.9,
      grantDol: -0.8,
      articles: -0.4,
      confProc: -0.3,
      yearsSincePhD: 20,
      index: -0.3,
      articlesActual: 6.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 219.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 131128,
      name: 'ZIOLO, MARK T',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: -0.2,
      grants: -0.3,
      grantDol: -0.4,
      articles: 0.3,
      confProc: -0.3,
      yearsSincePhD: 20,
      index: 0.5,
      articlesActual: 17.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 234.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 242000.0,
      indexActual: 0.0,
    },
    {
      facultyId: 24989,
      name: 'DAVIS, JONATHAN PAUL',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: -0.1,
      grants: 0.8,
      grantDol: 0.8,
      articles: 1.4,
      confProc: -0.3,
      yearsSincePhD: 21,
      index: 1.3,
      articlesActual: 33.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 309.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 978678.0,
      indexActual: 0.0,
    },
    {
      facultyId: 50899,
      name: 'JANSSEN, PAUL M',
      rank: 1,
      awards: 0.4,
      books: -0.2,
      citations: 0.9,
      grants: -0.3,
      grantDol: -0.2,
      articles: 3.4,
      confProc: -0.3,
      yearsSincePhD: 22,
      index: 1.4,
      articlesActual: 62.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 1111.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 383160.0,
      indexActual: 0.0,
    },
    {
      facultyId: 17834,
      name: 'CARNES, CYNTHIA ANN',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: -0.3,
      grants: -0.9,
      grantDol: -0.8,
      articles: -0.2,
      confProc: -0.3,
      yearsSincePhD: 23,
      index: -0.2,
      articlesActual: 10.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 159.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 161484,
      name: 'RAFAEL-FORTNEY, JILL A',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: 0.0,
      grants: 0.8,
      grantDol: 0.7,
      articles: 0.0,
      confProc: -0.3,
      yearsSincePhD: 23,
      index: 0.8,
      articlesActual: 12.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 350.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 947241.0,
      indexActual: 0.0,
    },
    {
      facultyId: 606253,
      name: 'GUO, LIANWANG',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: 0.2,
      grants: 0.8,
      grantDol: 0.9,
      articles: 0.8,
      confProc: -0.3,
      yearsSincePhD: 24,
      index: 1.3,
      articlesActual: 24.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 552.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 1089650.0,
      indexActual: 0.0,
    },
    {
      facultyId: 372397,
      name: 'MARTIN, PAUL TAYLOR',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: 2.0,
      grantDol: 1.4,
      articles: 0.1,
      confProc: -0.3,
      yearsSincePhD: 27,
      index: 0.5,
      articlesActual: 14.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 91.0,
      confProcActual: 0.0,
      grantsActual: 5.0,
      grantDolActual: 1366143.0,
      indexActual: 0.0,
    },
    {
      facultyId: 542061,
      name: 'BUFORD, JOHN ANDERSON',
      rank: 1,
      awards: 0.4,
      books: 1.3,
      citations: -0.2,
      grants: -0.3,
      grantDol: -0.3,
      articles: -0.2,
      confProc: -0.3,
      yearsSincePhD: 29,
      index: 0.1,
      articlesActual: 9.0,
      awardsActual: 2.0,
      booksActual: 1.0,
      citationsActual: 165.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 268314.0,
      indexActual: 0.0,
    },
    {
      facultyId: 506012,
      name: 'MA, JIANJIE',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: 1.1,
      grants: 3.2,
      grantDol: 4.4,
      articles: 3.4,
      confProc: 0.5,
      yearsSincePhD: 30,
      index: 1.8,
      articlesActual: 63.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 1228.0,
      confProcActual: 1.0,
      grantsActual: 7.0,
      grantDolActual: 3312055.0,
      indexActual: 0.0,
    },
    {
      facultyId: 86556,
      name: 'LEE, BETH SUZANNE',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.9,
      grantDol: -0.8,
      articles: -0.5,
      confProc: -0.3,
      yearsSincePhD: 31,
      index: -0.8,
      articlesActual: 5.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 86.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384095,
      name: 'CHRISTOFI, FEDIAS LEONTIN',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: -0.2,
      grants: 0.3,
      grantDol: 0.4,
      articles: 0.3,
      confProc: -0.3,
      yearsSincePhD: 31,
      index: 0.7,
      articlesActual: 16.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 194.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 742995.0,
      indexActual: 0.0,
    },
    {
      facultyId: 49302,
      name: 'GYORKE, SANDOR',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: 0.0,
      grants: 0.8,
      grantDol: 1.3,
      articles: 0.2,
      confProc: -0.3,
      yearsSincePhD: 32,
      index: 1.0,
      articlesActual: 15.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 395.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 1315128.0,
      indexActual: 0.0,
    },
    {
      facultyId: 372526,
      name: 'GUO, PEIXUAN',
      rank: 1,
      awards: -0.5,
      books: 4.4,
      citations: 1.1,
      grants: 1.4,
      grantDol: 0.4,
      articles: 2.5,
      confProc: 2.0,
      yearsSincePhD: 32,
      index: 1.6,
      articlesActual: 49.0,
      awardsActual: 0.0,
      booksActual: 3.0,
      citationsActual: 1282.0,
      confProcActual: 3.0,
      grantsActual: 4.0,
      grantDolActual: 766358.0,
      indexActual: 0.0,
    },
    {
      facultyId: 51451,
      name: 'JHIANG, SISSY MEIHUA',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: -0.4,
      grants: -0.9,
      grantDol: -0.8,
      articles: -0.2,
      confProc: -0.3,
      yearsSincePhD: 32,
      index: -0.6,
      articlesActual: 9.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 50.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 31665,
      name: 'ELTON, TERRY S',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.9,
      grantDol: -0.8,
      articles: -0.7,
      confProc: -0.3,
      yearsSincePhD: 33,
      index: -1.5,
      articlesActual: 2.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 8.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 155804,
      name: 'MCHUGH, KIRK MARSHALL',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: -0.3,
      grantDol: -0.4,
      articles: 0.2,
      confProc: -0.3,
      yearsSincePhD: 33,
      index: 0.3,
      articlesActual: 15.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 214.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 228327.0,
      indexActual: 0.0,
    },
    {
      facultyId: 505928,
      name: 'ABRAHAM, WILLIAM TOBER',
      rank: 1,
      awards: 0.9,
      books: -0.2,
      citations: 4.1,
      grants: -0.9,
      grantDol: -0.8,
      articles: 7.0,
      confProc: 1.2,
      yearsSincePhD: 33,
      index: 1.2,
      articlesActual: 115.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 3736.0,
      confProcActual: 2.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 101947,
      name: 'ZWEIER, JAY LOUIS',
      rank: 1,
      awards: -0.1,
      books: 1.3,
      citations: 0.4,
      grants: 0.8,
      grantDol: 1.8,
      articles: 1.3,
      confProc: 0.5,
      yearsSincePhD: 33,
      index: 1.6,
      articlesActual: 31.0,
      awardsActual: 1.0,
      booksActual: 1.0,
      citationsActual: 708.0,
      confProcActual: 1.0,
      grantsActual: 3.0,
      grantDolActual: 1620674.0,
      indexActual: 0.0,
    },
    {
      facultyId: 156248,
      name: 'MENQ, CHIA-HSIANG',
      rank: 1,
      awards: 0.9,
      books: -0.2,
      citations: -0.4,
      grants: 0.3,
      grantDol: -0.4,
      articles: -0.4,
      confProc: -0.3,
      yearsSincePhD: 34,
      index: -0.5,
      articlesActual: 7.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 14.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 213880.0,
      indexActual: 0.0,
    },
    {
      facultyId: 393546,
      name: 'REISER, PETER JACOB',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: -0.9,
      grantDol: -0.8,
      articles: -0.2,
      confProc: -0.3,
      yearsSincePhD: 38,
      index: -0.3,
      articlesActual: 9.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 172.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 74668,
      name: 'OTTOBRE, JOSEPH STEVEN',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: -0.4,
      grants: -0.9,
      grantDol: -0.8,
      articles: -0.8,
      confProc: -0.3,
      yearsSincePhD: 38,
      index: -1.5,
      articlesActual: 0.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 19.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  unit: {
    indexScore: 0.4,
  },
};
export default data;
