import React from 'react';
import '../styles/upcomingEvents.css';

const EventDetails = ({ event }) => {
  const [showEventDetails, setshowEventDetails] = React.useState(false);
  const onClick = () => setshowEventDetails(!showEventDetails);
  return (
    <div className="upcoming-event-wrapper">
      <div>
        <button className="event-details-btn" onClick={onClick}>
          {showEventDetails ? 'Hide details' : 'Show details'}
        </button>
        {showEventDetails ? (
          <div className="event-detail-wrapper">
            <div className="event-details-title">Day / Time (ET):</div>
            <span className="event-details-content">{event.dateTime}</span>
            {event.audience.length ? (
              <>
                <div className="event-details-title">Audience:</div>
                <span className="event-details-content">{event.audience}</span>
              </>
            ) : (
              <></>
            )}
            {event.purpose.length ? (
              <>
                <div className="event-details-title">Purpose:</div>
                <span className="event-details-content">{event.purpose}</span>
              </>
            ) : (
              <></>
            )}
            <div className="event-details-title">Location:</div>
            <span className="event-details-content">{event.location}</span>
            <div className="event-details-title">Web Links:</div>
            <a
              href={event.webLinks}
              target="_blank"
              className="event-details-content"
            >
              {event.webLinks}
            </a>
            <div className="event-details-title">Event URL:</div>
            <a
              href={event.eventURL}
              target="_blank"
              className="event-details-content"
            >
              {event.eventURL}
            </a>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default EventDetails;
