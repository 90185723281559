export const formatDate = date => {
  const newDate = new Date(date);
  const options = { year: 'numeric', month: 'short', day: '2-digit' };
  const resultDate = new Intl.DateTimeFormat('en-US', options).format(newDate);
  return resultDate;
};

export const formatCurrency = amount => {
  const currencyFormatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });

  return currencyFormatter.format(amount);
};

export const formatDepartment = dept => {
  return dept.split(',').reverse().join(' ');
};

export const formatDepartmentShort = dept => {
  if (dept.split(',').includes(' Department of')) {
    return dept.split(', Department of').join('');
  }
  if (dept.split(',').includes(' Division of')) {
    return dept.split(', Division of').join('');
  }
  if (dept.split(',').includes(' School of')) {
    return dept.split(', School of').join('');
  }
};

export const capitalizeFirstLetter = name => {
  const output = name.split(' ').map(item => {
    return item.charAt(0) + item.slice(1).toLowerCase();
  });
  return output.join(' ');
};

export const formatName = name => {
  if (name.indexOf(',') > -1) {
    return name.split(',').reverse().join(' ');
  }
};

export const formatInstitutionName = name => {
  if (name.indexOf(', The') > -1) {
    return name.split(',').reverse().join(' ');
  }
  return name;
};

const prepositionsThreeLetters = {
  and: 1,
  for: 1,
  but: 1,
  out: 1,
  off: 1,
};
export const strToUpperCamelCase = str => {
  let grantNameStr = [];
  let arrOfWords = [];
  if (str === str.toUpperCase()) {
    arrOfWords = str.split(' ');
    for (let i = 0; i < arrOfWords.length; i++) {
      if (
        arrOfWords[i].length > 2 &&
        !prepositionsThreeLetters[arrOfWords[i].toLowerCase()]
      ) {
        let wordLowerCase = arrOfWords[i].toLowerCase();
        let arrOfWordLetters = wordLowerCase.split('');
        let firstLetterUpperCase = arrOfWordLetters[0].toUpperCase();
        arrOfWordLetters[0] = firstLetterUpperCase;
        let strUpperCamelCase = arrOfWordLetters.join('');
        grantNameStr.push(strUpperCamelCase);
      } else {
        grantNameStr.push(arrOfWords[i].toLowerCase());
      }
    }
    return grantNameStr.join(' ');
  } else {
    return str;
  }
};
