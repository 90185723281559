const data = {
  maxObservations: 60,
  dataPoints: [
    {
      yearsSincePhD: 0,
      observations: 2,
      index: -0.465,
      awards: -0.5,
      books: -0.25,
      grants: -0.69,
      grantDol: -0.58,
      articles: -0.335,
      citations: -0.33,
      confProc: -0.3,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 91,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 1,
      observations: 1,
      index: -0.51,
      awards: -0.5,
      books: -0.25,
      grants: -0.69,
      grantDol: -0.58,
      articles: -0.37,
      citations: -0.36,
      confProc: -0.3,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 79,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 2,
      observations: 8,
      index: -0.73375,
      awards: -0.41,
      books: -0.25,
      grants: -0.69,
      grantDol: -0.58,
      articles: -0.3175,
      citations: -0.405,
      confProc: -0.3,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 51,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 3,
      observations: 10,
      index: -0.096,
      awards: -0.214,
      books: -0.25,
      grants: -0.623,
      grantDol: -0.502,
      articles: 0.11,
      citations: 0.514,
      confProc: -0.1,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 559,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 40372.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 4,
      observations: 23,
      index: -0.613043,
      awards: -0.406521,
      books: -0.185217,
      grants: -0.573913,
      grantDol: -0.484782,
      articles: -0.465217,
      citations: -0.256521,
      confProc: -0.3,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 133,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 49005.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 5,
      observations: 14,
      index: -0.534285,
      awards: -0.345714,
      books: -0.25,
      grants: -0.594285,
      grantDol: -0.492857,
      articles: -0.543571,
      citations: -0.249285,
      confProc: 0.413571,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 138,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 45223.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 6,
      observations: 25,
      index: -0.144,
      awards: -0.2988,
      books: -0.25,
      grants: -0.5028,
      grantDol: -0.4612,
      articles: -0.1188,
      citations: -0.0668,
      confProc: 0.1796,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 238,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 61526.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 7,
      observations: 28,
      index: -0.332857,
      awards: -0.295357,
      books: -0.25,
      grants: -0.427857,
      grantDol: -0.389285,
      articles: -0.1825,
      citations: -0.269285,
      confProc: -0.192857,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 127,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 98242.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 8,
      observations: 37,
      index: -0.38,
      awards: -0.093783,
      books: -0.209729,
      grants: -0.455135,
      grantDol: -0.361621,
      articles: -0.287567,
      citations: -0.269729,
      confProc: -0.245945,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 126,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 112903.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 9,
      observations: 40,
      index: -0.00475,
      awards: -0.24875,
      books: -0.21275,
      grants: -0.40625,
      grantDol: -0.37125,
      articles: -0.06275,
      citations: 0.135,
      confProc: -0.2,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 350,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 107945.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 10,
      observations: 50,
      index: -0.037,
      awards: -0.1704,
      books: -0.25,
      grants: -0.3964,
      grantDol: -0.3206,
      articles: -0.1814,
      citations: 0.0648,
      confProc: 0.02,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 311,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 133851.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 11,
      observations: 44,
      index: 0.345,
      awards: -0.011363,
      books: 0.121818,
      grants: 0.037272,
      grantDol: 0.04659,
      articles: 0.067954,
      citations: 0.160454,
      confProc: 0.13159,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 363,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 323545.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 12,
      observations: 50,
      index: 0.1344,
      awards: -0.127,
      books: -0.1012,
      grants: 0.1098,
      grantDol: 0.115,
      articles: -0.1038,
      citations: -0.035,
      confProc: -0.0402,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 255,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 358586.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 13,
      observations: 44,
      index: 0.291818,
      awards: -0.011363,
      books: -0.182272,
      grants: 0.30909,
      grantDol: 0.25409,
      articles: 0.16909,
      citations: 0.098636,
      confProc: -0.072727,
      articlesActual: 17,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 329,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 430607.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 14,
      observations: 51,
      index: 0.119215,
      awards: 0.07549,
      books: -0.133333,
      grants: 0.159411,
      grantDol: 0.201568,
      articles: 0.15549,
      citations: 0.053529,
      confProc: 0.111372,
      articlesActual: 17,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 304,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 403192.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 15,
      observations: 60,
      index: 0.255,
      awards: -0.13,
      books: -0.225166,
      grants: 0.198833,
      grantDol: 0.158666,
      articles: 0.211333,
      citations: 0.174833,
      confProc: 0.216333,
      articlesActual: 18,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 371,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 381128.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 16,
      observations: 51,
      index: 0.394313,
      awards: 0.075882,
      books: -0.074901,
      grants: 0.29,
      grantDol: 0.078823,
      articles: 0.257647,
      citations: 0.065882,
      confProc: 0.327058,
      articlesActual: 18,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 311,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 339844.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 17,
      observations: 54,
      index: 0.273333,
      awards: 0.136111,
      books: -0.029814,
      grants: 0.038333,
      grantDol: -0.03537,
      articles: 0.183148,
      citations: 0.096666,
      confProc: 0.218333,
      articlesActual: 17,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 328,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 281040.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 18,
      observations: 47,
      index: 0.097659,
      awards: -0.240851,
      books: -0.06,
      grants: 0.019361,
      grantDol: 0.019361,
      articles: 0.150638,
      citations: 0.02,
      confProc: 0.189361,
      articlesActual: 17,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 286,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 309264.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 19,
      observations: 44,
      index: 0.173409,
      awards: -0.01159,
      books: -0.114545,
      grants: 0.173636,
      grantDol: 0.066818,
      articles: 0.124318,
      citations: 0.015909,
      confProc: -0.186363,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 284,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 333589.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 20,
      observations: 38,
      index: 0.396578,
      awards: 0.027105,
      books: 0.02421,
      grants: 0.187368,
      grantDol: 0.163684,
      articles: 0.36921,
      citations: 0.087631,
      confProc: -0.036842,
      articlesActual: 20,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 324,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 384075.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 21,
      observations: 50,
      index: 0.1042,
      awards: -0.2276,
      books: -0.1012,
      grants: 0.2162,
      grantDol: 0.1154,
      articles: 0.072,
      citations: -0.0804,
      confProc: -0.16,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 231,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 358991.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 22,
      observations: 39,
      index: 0.098461,
      awards: -0.040769,
      books: 0.055128,
      grants: 0.36923,
      grantDol: 0.263333,
      articles: 0.096153,
      citations: 0.126666,
      confProc: 0.033333,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 345,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 434817.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 23,
      observations: 53,
      index: 0.192075,
      awards: -0.080943,
      books: -0.109622,
      grants: 0.115094,
      grantDol: 0.156603,
      articles: 0.166037,
      citations: 0.478113,
      confProc: 0.228113,
      articlesActual: 17,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 539,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 380375.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 24,
      observations: 41,
      index: -0.102682,
      awards: -0.097804,
      books: 0.25756,
      grants: 0.025365,
      grantDol: -0.084634,
      articles: -0.05,
      citations: -0.132195,
      confProc: -0.251219,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 202,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 255463.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 25,
      observations: 35,
      index: 0.301714,
      awards: 0.194857,
      books: 0.004857,
      grants: 0.110285,
      grantDol: -0.026857,
      articles: 0.374571,
      citations: 0.055428,
      confProc: 0.299714,
      articlesActual: 20,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 305,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 285482.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 26,
      observations: 44,
      index: 0.064545,
      awards: -0.060227,
      books: -0.148409,
      grants: 0.157954,
      grantDol: 0.139545,
      articles: 0.158636,
      citations: -0.06909,
      confProc: -0.072727,
      articlesActual: 17,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 237,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 371171.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 27,
      observations: 31,
      index: 0.361935,
      awards: 0.147419,
      books: 0.707741,
      grants: 0.19129,
      grantDol: 0.106451,
      articles: 0.413548,
      citations: 0.239677,
      confProc: 0.119354,
      articlesActual: 21,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 407,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 354293.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 28,
      observations: 30,
      index: 0.011666,
      awards: -0.261,
      books: -0.001666,
      grants: 0.598333,
      grantDol: 0.311,
      articles: -0.076,
      citations: -0.006666,
      confProc: -0.1,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 271,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 459885.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 29,
      observations: 38,
      index: -0.020263,
      awards: 0.008421,
      books: 0.180526,
      grants: -0.076052,
      grantDol: -0.052368,
      articles: -0.164736,
      citations: -0.117368,
      confProc: -0.168421,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 210,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 271952.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 30,
      observations: 40,
      index: -0.14275,
      awards: -0.05175,
      books: -0.02675,
      grants: 0.1765,
      grantDol: 0.1325,
      articles: -0.08325,
      citations: -0.03475,
      confProc: 0.0,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 256,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 367747.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 31,
      observations: 34,
      index: -0.310588,
      awards: 0.047058,
      books: 0.143529,
      grants: -0.042941,
      grantDol: 0.150294,
      articles: -0.248235,
      citations: -0.10147,
      confProc: -0.094117,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 219,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 376381.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 32,
      observations: 33,
      index: 0.17909,
      awards: 0.193333,
      books: 0.155151,
      grants: 0.098484,
      grantDol: 0.120606,
      articles: 0.183636,
      citations: 0.08303,
      confProc: -0.087878,
      articlesActual: 17,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 321,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 361214.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 33,
      observations: 37,
      index: 0.038648,
      awards: -0.035135,
      books: 0.232162,
      grants: -0.095405,
      grantDol: 0.077567,
      articles: 0.21027,
      citations: 0.088918,
      confProc: 0.078108,
      articlesActual: 18,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 324,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 338962.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 34,
      observations: 31,
      index: -0.41,
      awards: 0.054516,
      books: 0.086129,
      grants: 0.040645,
      grantDol: 0.063548,
      articles: -0.272258,
      citations: -0.08,
      confProc: -0.235483,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 231,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 331978.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 35,
      observations: 37,
      index: -0.02054,
      awards: 0.293243,
      books: 0.031351,
      grants: 0.138918,
      grantDol: 0.139189,
      articles: -0.106216,
      citations: -0.02054,
      confProc: -0.002702,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 263,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 371133.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 36,
      observations: 26,
      index: -0.316923,
      awards: -0.196923,
      books: -0.078461,
      grants: 0.155,
      grantDol: 0.255384,
      articles: -0.288846,
      citations: -0.203076,
      confProc: -0.223076,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 163,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 430751.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 37,
      observations: 23,
      index: 0.431739,
      awards: -0.063478,
      books: 0.073913,
      grants: 0.526086,
      grantDol: 0.450434,
      articles: 0.240869,
      citations: 0.232173,
      confProc: -0.169565,
      articlesActual: 18,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 403,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 531701.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 38,
      observations: 23,
      index: 0.482608,
      awards: 0.836956,
      books: 0.008695,
      grants: 0.526521,
      grantDol: 0.41826,
      articles: 0.786956,
      citations: 0.27913,
      confProc: 0.43826,
      articlesActual: 27,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 429,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 514792.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 39,
      observations: 24,
      index: -0.44875,
      awards: -0.083333,
      books: -0.064166,
      grants: -0.19,
      grantDol: -0.124166,
      articles: -0.308333,
      citations: -0.295833,
      confProc: -0.05,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 111,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 235109.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 40,
      observations: 18,
      index: -0.078888,
      awards: 0.850555,
      books: 0.08,
      grants: -0.207777,
      grantDol: -0.137222,
      articles: -0.016111,
      citations: -0.067777,
      confProc: 0.532777,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 238,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 228670.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 41,
      observations: 13,
      index: -0.300769,
      awards: -0.06,
      books: -0.135384,
      grants: -0.075384,
      grantDol: 0.017692,
      articles: -0.244615,
      citations: -0.18923,
      confProc: 0.237692,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 170,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 308147.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 42,
      observations: 12,
      index: -0.563333,
      awards: 0.095,
      books: 0.74,
      grants: -0.079166,
      grantDol: -0.000833,
      articles: -0.540833,
      citations: -0.300833,
      confProc: -0.3,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 109,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 298464.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 43,
      observations: 8,
      index: -0.44,
      awards: 0.485,
      books: -0.25,
      grants: 0.225,
      grantDol: 0.0075,
      articles: -0.28,
      citations: -0.23875,
      confProc: -0.175,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 143,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 303042.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 44,
      observations: 10,
      index: -0.011,
      awards: 0.287,
      books: 2.719,
      grants: 0.376,
      grantDol: 0.136,
      articles: -0.137,
      citations: -0.14,
      confProc: -0.2,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 2,
      citationsActual: 198,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 369042.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 45,
      observations: 10,
      index: -0.642,
      awards: -0.356,
      books: -0.25,
      grants: -0.49,
      grantDol: -0.455,
      articles: -0.474,
      citations: -0.349,
      confProc: -0.1,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 82,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 64336.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 46,
      observations: 9,
      index: -0.135555,
      awards: 0.215555,
      books: -0.25,
      grants: -0.393333,
      grantDol: -0.234444,
      articles: -0.162222,
      citations: -0.068888,
      confProc: -0.3,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 237,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 178806.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 47,
      observations: 9,
      index: -0.16,
      awards: 0.85,
      books: 1.068888,
      grants: 0.05,
      grantDol: 0.658888,
      articles: -0.014444,
      citations: 0.108888,
      confProc: -0.077777,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 335,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 639287.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 48,
      observations: 8,
      index: 0.50625,
      awards: 0.93,
      books: 1.23375,
      grants: 0.1425,
      grantDol: -0.02375,
      articles: 0.3075,
      citations: 0.1125,
      confProc: 0.2,
      articlesActual: 19,
      awardsActual: 2,
      booksActual: 1,
      citationsActual: 337,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 287195.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 49,
      observations: 5,
      index: 0.59,
      awards: 2.786,
      books: 0.346,
      grants: 0.242,
      grantDol: 0.706,
      articles: 0.334,
      citations: -0.04,
      confProc: -0.3,
      articlesActual: 20,
      awardsActual: 4,
      booksActual: 0,
      citationsActual: 252,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 662674.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 50,
      observations: 5,
      index: 0.566,
      awards: 2.216,
      books: 1.236,
      grants: 0.908,
      grantDol: 0.656,
      articles: -0.084,
      citations: -0.08,
      confProc: -0.1,
      articlesActual: 13,
      awardsActual: 3,
      booksActual: 1,
      citationsActual: 232,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 637684.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 51,
      observations: 5,
      index: 0.112,
      awards: 1.358,
      books: 1.236,
      grants: 0.242,
      grantDol: 0.526,
      articles: 0.282,
      citations: 0.204,
      confProc: 0.1,
      articlesActual: 19,
      awardsActual: 2,
      booksActual: 1,
      citationsActual: 387,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 570805.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 52,
      observations: 3,
      index: 0.786666,
      awards: 5.216666,
      books: -0.25,
      grants: 1.973333,
      grantDol: 2.47,
      articles: 0.923333,
      citations: 0.69,
      confProc: 0.366666,
      articlesActual: 29,
      awardsActual: 8,
      booksActual: 0,
      citationsActual: 656,
      confProcActual: 0,
      grantsActual: 4,
      grantDolActual: 1573885.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 53,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 54,
      observations: 2,
      index: 0.325,
      awards: 2.715,
      books: -0.25,
      grants: 0.64,
      grantDol: 0.62,
      articles: -0.24,
      citations: 0.355,
      confProc: -0.3,
      articlesActual: 11,
      awardsActual: 4,
      booksActual: 0,
      citationsActual: 471,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 617903.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 55,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  facultyDataPoints: [
    {
      facultyId: 769827,
      name: 'PABLA, NAVJOTSINGH PARGATSINGH',
      rank: 3,
      awards: 0.2,
      books: -0.2,
      citations: 0.0,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.6,
      confProc: -0.3,
      yearsSincePhD: 10,
      index: -0.1,
      articlesActual: 6.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 260.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638769,
      name: 'DONG, YIZHOU',
      rank: 2,
      awards: 1.6,
      books: -0.2,
      citations: 1.7,
      grants: 0.6,
      grantDol: 1.2,
      articles: 0.7,
      confProc: -0.3,
      yearsSincePhD: 10,
      index: 1.7,
      articlesActual: 26.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 1191.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 897326.0,
      indexActual: 0.0,
    },
    {
      facultyId: 826980,
      name: 'COCUCCI, EMANUELE F',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 2.9,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.5,
      confProc: -0.3,
      yearsSincePhD: 11,
      index: 0.3,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 1859.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638236,
      name: 'COSS, CHRISTOPHER CHARLES',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 0.0,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.1,
      confProc: -0.3,
      yearsSincePhD: 11,
      index: 0.2,
      articlesActual: 13.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 287.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 247911,
      name: 'GOVINDARAJAN, RAJGOPAL',
      rank: 1,
      awards: 0.2,
      books: -0.2,
      citations: -0.3,
      grants: 1.3,
      grantDol: 0.3,
      articles: 0.0,
      confProc: -0.3,
      yearsSincePhD: 14,
      index: 0.5,
      articlesActual: 14.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 127.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 441062.0,
      indexActual: 0.0,
    },
    {
      facultyId: 301841,
      name: 'PHELPS, A MITCHELL',
      rank: 1,
      awards: 0.2,
      books: -0.2,
      citations: 0.7,
      grants: 0.6,
      grantDol: 0.7,
      articles: 1.7,
      confProc: -0.3,
      yearsSincePhD: 14,
      index: 1.6,
      articlesActual: 41.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 674.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 640464.0,
      indexActual: 0.0,
    },
    {
      facultyId: 855771,
      name: 'HU, SHUIYING',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 0.0,
      grants: -0.7,
      grantDol: -0.6,
      articles: 0.3,
      confProc: -0.3,
      yearsSincePhD: 15,
      index: 0.4,
      articlesActual: 20.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 279.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 769824,
      name: 'BAKER, SHARYN D',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: 0.2,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.6,
      confProc: -0.3,
      yearsSincePhD: 15,
      index: 1.0,
      articlesActual: 25.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 377.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 321489.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638237,
      name: 'LARUE, ROSS CARSON',
      rank: 3,
      awards: 0.2,
      books: -0.2,
      citations: -0.3,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.5,
      confProc: -0.3,
      yearsSincePhD: 18,
      index: -0.4,
      articlesActual: 7.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 118.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 147848,
      name: 'HU, KELI',
      rank: 2,
      awards: 0.2,
      books: -0.2,
      citations: -0.5,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.8,
      confProc: -0.3,
      yearsSincePhD: 21,
      index: -1.3,
      articlesActual: 2.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 4.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 147797,
      name: 'HOYT, KARI R',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.4,
      confProc: -0.3,
      yearsSincePhD: 23,
      index: -0.5,
      articlesActual: 9.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 83.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 511443,
      name: 'SPARREBOOM, ALEXANDER',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: 0.3,
      grants: 1.3,
      grantDol: 1.8,
      articles: 0.6,
      confProc: -0.3,
      yearsSincePhD: 23,
      index: 1.4,
      articlesActual: 25.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 418.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 1218497.0,
      indexActual: 0.0,
    },
    {
      facultyId: 193840,
      name: 'CAMPBELL, MORAY J',
      rank: 2,
      awards: -0.5,
      books: 1.2,
      citations: -0.1,
      grants: 0.0,
      grantDol: -0.2,
      articles: 0.4,
      confProc: -0.3,
      yearsSincePhD: 25,
      index: 0.6,
      articlesActual: 21.0,
      awardsActual: 0.0,
      booksActual: 1.0,
      citationsActual: 243.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 212123.0,
      indexActual: 0.0,
    },
    {
      facultyId: 302911,
      name: 'LEE, ROBERT J',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: 1.9,
      grants: -0.7,
      grantDol: -0.6,
      articles: 4.8,
      confProc: -0.3,
      yearsSincePhD: 25,
      index: 1.2,
      articlesActual: 91.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 1315.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 599344,
      name: 'SHU, DAN',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: 0.4,
      grants: 0.0,
      grantDol: -0.4,
      articles: -0.2,
      confProc: -0.3,
      yearsSincePhD: 29,
      index: 0.6,
      articlesActual: 12.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 515.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 106636.0,
      indexActual: 0.0,
    },
    {
      facultyId: 372526,
      name: 'GUO, PEIXUAN',
      rank: 1,
      awards: -0.5,
      books: 4.2,
      citations: 1.8,
      grants: 2.0,
      grantDol: 0.9,
      articles: 2.2,
      confProc: 2.7,
      yearsSincePhD: 32,
      index: 1.8,
      articlesActual: 49.0,
      awardsActual: 0.0,
      booksActual: 3.0,
      citationsActual: 1282.0,
      confProcActual: 3.0,
      grantsActual: 4.0,
      grantDolActual: 766358.0,
      indexActual: 0.0,
    },
    {
      facultyId: 31665,
      name: 'ELTON, TERRY S',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.5,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.8,
      confProc: -0.3,
      yearsSincePhD: 33,
      index: -1.4,
      articlesActual: 2.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 8.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 48454,
      name: 'HOYT, DALE GERALD',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.5,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.8,
      confProc: -0.3,
      yearsSincePhD: 34,
      index: -1.3,
      articlesActual: 2.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 21.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 393565,
      name: 'YALOWICH, JACK CHARLES',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: 0.0,
      grantDol: 0.1,
      articles: -0.4,
      confProc: 1.7,
      yearsSincePhD: 39,
      index: -0.2,
      articlesActual: 8.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 107.0,
      confProcActual: 2.0,
      grantsActual: 1.0,
      grantDolActual: 350271.0,
      indexActual: 0.0,
    },
  ],
  unit: {
    indexScore: 0.3,
  },
};
export default data;
