import React, { useState } from 'react';
import { Redirect } from 'react-router-dom';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tabs';
import 'bootstrap/dist/css/bootstrap.min.css';
import ProposalTotals from '../proposalTotals/ProposalTotals';
import Conversions from '../conversionRatios/Conversions';
import '../../styles/projectionsNav.css';

function ProjectionsPlatformNav(props) {
  // Conversions table state
  const [conversionCurrYear, setConversionCurrYear] = useState(2020);
  const [currConversionAgency, setCurrConversionAgency] = useState({
    name: '',
    index: 0,
  });
  // filtered original data based on current year
  const [conversionFilteredData, setConversionFilteredData] = useState([]);

  // Proposal totals table state
  // current ui selected year to filter agencies data
  const [proposalCurrentYear, setProposalCurrentYear] = useState(2020);
  // sets agency name and index to display in the chart
  const [proposalCurrChartAgency, setProposalCurrChartAgency] = useState({
    name: '',
    index: 0,
  });
  // filtered original data based on current year
  const [proposalFilteredData, setProposalFilteredData] = useState([]);

  const formatName = name => {
    if (name.indexOf(',') > -1) {
      return name.split(',').reverse().join(' ');
    }
  };

  return (
    <>
      {props.unit.Id ? (
        <div className="projections-container">
          <Tabs id="projections-tabs-nav" className="projections-tabs-nav">
            <Tab
              eventKey="conversions"
              title="Conversion Ratios"
              className="conversions-tab-nav"
            >
              <Conversions
                unit={props.unit}
                currentYear={conversionCurrYear}
                setCurrentYear={setConversionCurrYear}
                currChartAgency={currConversionAgency}
                setCurrChartAgency={setCurrConversionAgency}
                filteredData={conversionFilteredData}
                setFilteredData={setConversionFilteredData}
                proposalYear={proposalCurrentYear}
                proposalAgency={proposalCurrChartAgency}
                proposalData={proposalFilteredData}
              />
            </Tab>
            <Tab
              eventKey="core-proposal"
              title="Proposal Totals"
              className="proposal-totals-tab-nav"
            >
              <ProposalTotals
                unit={props.unit}
                currentYear={proposalCurrentYear}
                setCurrentYear={setProposalCurrentYear}
                currChartAgency={proposalCurrChartAgency}
                setCurrChartAgency={setProposalCurrChartAgency}
                filteredData={proposalFilteredData}
                setFilteredData={setProposalFilteredData}
                converionYear={conversionCurrYear}
                conversionAgency={currConversionAgency}
                conversionData={conversionFilteredData}
              />
            </Tab>
          </Tabs>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

export default ProjectionsPlatformNav;
