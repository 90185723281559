export const hitRate = (propos, grants) => {
  let hitRateRounded = null;
  if ((propos === 0 && grants === 0) || grants === 0) return hitRateRounded;
  const sumOfAllProposalsGrants = propos + grants;
  const hitRate = (grants * 100) / sumOfAllProposalsGrants;
  // round to at most 2 decimal places if needed
  // Number.EPSILON to ensure things like 1.005 round correctly
  hitRateRounded = Math.round((hitRate + Number.EPSILON) * 100) / 100;
  return hitRateRounded;
};

export const averagePerYear = (propos, grants, yearsAmount = 5) => {
  // yearsLength from 2015-2019
  const sumOfAllProposalsGrants = propos + grants;
  const average = sumOfAllProposalsGrants / yearsAmount;
  return average;
};

export const buildPersonProposalsData = data => {
  let unitProposalsPersons = [];
  // loop over proposals
  for (let i = 0; i < data.proposals.length; i++) {
    let person = {
      id: null,
      fullName: '',
      avgYear: null,
      funded: 0,
      notFunded: 0,
      hitRate: null,
      directs: 0,
      indirects: 0,
      total: 0,
    };
    person.id = data.proposals[i].PersonId;
    person.fullName = data.proposals[i].FullName;
    person.notFunded = Number(data.proposals[i].ProposalsTotal);
    // loop over grants
    for (let j = 0; j < data.grants.length; j++) {
      // if curr person from proposlas in the grants data, then add grants data to the curr person
      if (person.id === data.grants[j].PersonID) {
        person.funded = data.grants[j].GrantsTotal;
        person.directs = data.grants[j].DirectsTotal;
        person.indirects = data.grants[j].IndirectsTotal;
        person.total = data.grants[j].Total;
      }
    }
    // calculate avg and hitrate for curr person
    person.avgYear = averagePerYear(person.notFunded, person.funded);
    person.hitRate = hitRate(person.notFunded, person.funded);
    unitProposalsPersons.push(person);
  }
  //alphabetically sorted
  let sortedAlphabet = unitProposalsPersons.sort(function (a, b) {
    if (a.fullName < b.fullName) {
      return -1;
    }
    if (a.fullName > b.fullName) {
      return 1;
    }
    return 0;
  });

  return sortedAlphabet;
};
