import React from 'react';
import ReactPlayer from 'react-player/youtube';
import videoList from './videosList';
import '../styles/videos.css';

const Videos = () => {
  return (
    <div className="videos-wrapper">
      <h3 className="videos-header">Videos</h3>
      <div className="video-cards-container">
        {videoList.map((video, index) => {
          return (
            <div className="video-card" key={index}>
              <ReactPlayer
                url={video.link}
                controls={true}
                className="react-player"
                width="63%"
                height="100%"
              />
              <div className="video-description">
                <h5 className={video.className} key={index}>
                  {video.header}
                </h5>
                <p className="videos-summary">{video.summary}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Videos;
