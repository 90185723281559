import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import './styles/unitsList.css';

export default function UnitsList(props) {
  const url = process.env.REACT_APP_API_URL;
  const [units, setUnits] = useState([]);

  const formatName = name => {
    if (name.indexOf(',') > -1) {
      return name.split(',').reverse().join(' ');
    }
  };

  useEffect(() => {
    axios.get(`${url}/api/colleges/${props.collegeID}/units`).then(res => {
      // Filter College of Pharmacy by removing unit "Division of Pharmacology"(has no data in DB)
      if (props.collegeID === 562) {
        const result = res.data.filter(unit => unit.Id !== 86);
        setUnits(result);
      } else {
        setUnits(res.data);
      }
    });
  }, []);

  return (
    <div className="units-list-wrapper">
      <h4 className="units-list-header">Departments</h4>
      <div className="units">
        {units.map((unit, index) => {
          return (
            <div className="units-list" key={index}>
              <Link
                className="unit-link"
                to={`/unit/${unit.Id}`}
                onClick={() => props.handleOnClick(unit)}
                key={index}
              >
                <li className="unit-name">{formatName(unit.Name)}</li>
              </Link>
            </div>
          );
        })}
      </div>
    </div>
  );
}
