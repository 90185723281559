import React, { useState, useEffect, useRef } from 'react';
import { Redirect } from 'react-router-dom';
import * as d3 from 'd3';
import AA from './aa.js';
import { LABELS, COLORS } from './enum.js';
import Dropdown from './Dropdown';
import './styles/careerProgression.css';

const chartMetrics = [
  {
    id: 1,
    name: 'Grants',
    value: 'grants',
  },
  {
    id: 2,
    name: 'Grant $',
    value: 'grantDol',
  },
];

const CareerProgression = props => {
  // Variables for Chart
  let width = 1000;
  let height = 500;
  let dataPoints = props.chartData.dataPoints;
  let facDataPoints = props.chartData.facultyDataPoints;
  let margin = { top: 20, right: 20, bottom: 40, left: 50 };
  width = width - margin.left - margin.right;
  height = height - margin.top - margin.bottom;
  let colorScale = [
    '#f7fbff',
    '#deebf7',
    '#c6dbef',
    '#9ecae1',
    '#6baed6',
    '#4292c6',
    '#2171b5',
    '#08519c',
    '#08306b',
  ];
  let yAxisHash = {
    articles: 'Z-Score Total Articles',
    citations: 'Z-Score Total Citations',
    grants: 'Z-Score Total Grants',
    grantDol: 'Z-Score Total Grant Dollars',
    awards: 'Z-Score Total Awards',
    books: 'Z-Score Total Books',
    confProc: 'Z-Score Total Conf Proc',
    index: 'Scholarly Research Index',
  };
  // Returns Color for circles on the chart
  const getRankColor = d => {
    if (d.color) return d.color;
    switch (d.rank) {
      case 1:
        return COLORS.PROF;
      case 2:
        return COLORS.ASSOC;
      case 3:
        return COLORS.ASST;
      default:
        return COLORS.OTHER;
    }
  };

  // State
  // Chart Metric Selection - set in dropdown
  let containerElRef = useRef();
  const [selectedVariableGroup, setSelectedVariableGroup] = useState('grants');

  useEffect(() => {
    getChart(
      containerElRef,
      colorScale,
      dataPoints,
      facDataPoints,
      width,
      height,
      margin,
      selectedVariableGroup,
      getRankColor,
      yAxisHash
    );
  }, [selectedVariableGroup]);

  return (
    <>
      {props.unit.Id ? (
        <div className="containerEl" ref={containerElRef}>
          <div className="career-chart-header">
            <Dropdown
              items={chartMetrics}
              selection={selectedVariableGroup}
              setSelection={setSelectedVariableGroup}
            />
          </div>

          <div className="legend-container">
            <div className="color-circle-legend">
              <div>
                <span>Unit Faculty</span>
              </div>
              <ul>
                <li class="list">
                  <span class="dot" id="dotprof"></span>Professor
                </li>
                <li class="list">
                  <div class="dot" id="dotap"></div>Associate Professor
                </li>
                <li class="list">
                  <div class="dot" id="dotassistant"></div>Assistant Professor
                </li>
                <li class="list">
                  <div class="dot" id="dotother"></div>Other
                </li>
              </ul>
            </div>

            <div className="gradient-container">
              <div>
                <span class="legend-subheader">Discipline Scholars</span>
              </div>
              <div id="gradient"></div>
              <div className="gradient-legend">
                <span>1</span>
                <span>Frequency</span>
                <span>{props.chartData.maxObservations}</span>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
};

const getChart = (
  containerElRef,
  colorScale,
  dataPoints,
  facDataPoints,
  width,
  height,
  margin,
  selectedVariableGroup,
  getRankColor,
  yAxisHash
) => {
  let containerEl = d3.select(containerElRef.current);

  let colorGradient = d3.scale
    .linear()
    .domain(d3.range(0, 1, 1.0 / (colorScale.length - 1)))
    .range(colorScale);

  let mappedColors = d3.scale
    .linear()
    .domain(
      d3.extent(dataPoints, function (d) {
        return d.observations;
      })
    )
    .range([0, 1]);

  // Width size of Bar on X axis
  let xBand = d3.scale.ordinal().rangeRoundBands([0, width], 0.1);

  xBand.domain(
    dataPoints.map(function (d) {
      return d.yearsSincePhD;
    })
  );
  // X Scale
  let x = d3.scale.linear().domain([0, 55]).range([0, width]);

  // To calculate Y scale we use selelectedVarGroup ex "Grants" and fild min and max from both keys dataPoints and facData
  // array of data from the Data obj by key dataponts and facData
  let dataDiverse = d3.merge([dataPoints, facDataPoints]);
  let ext = d3.extent(dataDiverse, function (d) {
    return d[selectedVariableGroup];
  });
  // Y axis range 0.5 will be removed and it will rounded to be for ex. 0.0 1.0 2.0
  let diff = (ext[1] - ext[0]) / 10;
  if (diff == 0) {
    diff = 1;
  }
  ext[0] -= diff;
  ext[1] += diff;

  if (ext[0] == 0 && ext[1] == 0) {
    ext[0] = -1;
    ext[1] = 1;
  }
  // Y scale
  let y = d3.scale.linear().domain(ext).range([height, 0]);

  // X and Y axis
  let xAxis = d3.svg.axis().scale(x).orient('bottom').ticks(10, '');
  let yAxis = d3.svg
    .axis() // Create a new default axis.
    .scale(y) // returns the current scale which defaults to a linear scale.
    .orient('left') // sets the orientation and returns the axis
    .ticks(10, '') // stores the specified arguments for subsequent use in generating ticks and returns the axis.
    .tickFormat(d3.format('.1f')); //Example =>  d3.format(".1f") : 1500.0

  let main;

  // Add or Replace SVG to Container
  if (
    d3.selectAll('svg')[0][9] &&
    d3.selectAll('svg')[0][9].id === 'chartsvg'
  ) {
    //  chart svg have index 9
    let svgToRem = d3.selectAll('svg')[0][9];
    d3.select(svgToRem).remove();
    main = containerEl
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('viewBox', '0 0 1000 500')
      .attr('id', 'chartsvg');
  } else {
    // Add SVG to Container
    main = containerEl
      .append('svg')
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .attr('viewBox', '0 0 1000 500')
      .attr('id', 'chartsvg');
  }

  let subsvg = main
    .append('g')
    .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');
  let svg = subsvg
    .append('g')
    .attr('transform', 'translate(0, 0), scale(1, 1)');

  //Append X axis
  subsvg
    .append('g')
    .attr('class', 'x-axis')
    .attr('transform', 'translate(0,' + height + ')')
    .call(xAxis);

  // Append Y axis
  subsvg
    .append('g')
    .attr('class', 'y-axis')
    .call(yAxis)
    .append('text')
    .attr('transform', 'rotate(-90)')
    .attr('x', 0 - height / 2)
    .attr('y', 0 - margin.left)
    .attr('dy', '1em')
    .attr('class', 'yAxisLabel')
    .style('text-anchor', 'middle')
    .text(yAxisHash[selectedVariableGroup]);

  // Name of X Axis
  subsvg
    .append('text')
    .attr('x', width / 2)
    .attr('y', height + margin.bottom - 2)
    .style('text-anchor', 'middle')
    .text('Years Since Terminal Degree');

  let tooltip = containerEl
    .append('div')
    .attr('class', 'toolTip')
    .style('display', 'none');

  // Bars
  let bars = svg.selectAll('.bar').data(dataPoints);
  bars
    .enter()
    .append('rect')
    .attr('class', 'bar')
    .attr('x', function (d) {
      return x(d.yearsSincePhD);
    })
    .attr('width', xBand.rangeBand())
    .attr('y', function (d) {
      return y(Math.max(0, d[selectedVariableGroup]));
    })
    .attr('height', function (d) {
      return Math.abs(y(d[selectedVariableGroup]) - y(0));
    })
    .attr('fill', function (d) {
      return colorGradient(mappedColors(d.observations));
    })
    .on('mouseover', function (d) {
      main.style('cursor', 'pointer');
    })
    .on('mouseout', function (d) {
      main.style('cursor', 'move');
    });
  bars.exit().remove();

  // Circles
  let circles = svg.selectAll('circle').data(facDataPoints);
  circles
    .enter()
    .append('circle')
    .attr('cx', function (d) {
      if (d.yearsSincePhD <= 0) {
        return x(0) + 7;
      } else {
        return x(d.yearsSincePhD);
      }
    })
    .attr('cy', function (d) {
      return y(d[selectedVariableGroup]);
    })
    .attr('r', 7)
    .style('stroke', 'white')
    .style('stroke-width', 2)
    .attr('fill', function (d) {
      return getRankColor(d);
    })
    .on('mouseover', function (d) {
      main.style('cursor', 'pointer');

      d3.select(this).style('stroke', 'black');

      tooltip.html('');
      for (let i = 0; i < facDataPoints.length; i++) {
        if (
          facDataPoints[i].yearsSincePhD == d.yearsSincePhD &&
          Math.abs(
            facDataPoints[i][selectedVariableGroup] - d[selectedVariableGroup]
          ) < 0.2
        ) {
          let fac = facDataPoints[i];
          let tooltipC = tooltip
            .append('div')
            .attr('class', 'toolTipContainer');
          let table = tooltipC.append('table');
          let tbody = table.append('tbody');

          let tr = tbody.append('tr');
          tr.append('td')
            .attr('class', 'toolTipCaption')
            .style('text-align', 'left')
            .html('Name:');
          tr.append('td').style('text-align', 'right').html(fac.name);

          tr = tbody.append('tr');
          tr.append('td')
            .attr('class', 'toolTipCaption')
            .style('text-align', 'left')
            .html('Years Since Terminal Degree:');
          tr.append('td').style('text-align', 'right').html(fac.yearsSincePhD);

          tr = tbody.append('tr');
          tr.append('td')
            .attr('class', 'toolTipCaption')
            .style('text-align', 'left')
            .html(
              selectedVariableGroup == 'index'
                ? '{0}'.format(LABELS.INDEX.DESC)
                : 'Z-Score Total ' + selectedVariableGroup + ':'
            );
          tr.append('td')
            .style('text-align', 'right')
            .html(d3.format('.2f')(fac[selectedVariableGroup]));

          if (selectedVariableGroup === 'grantDol') {
            const formatter = new Intl.NumberFormat('en-US', {
              style: 'currency',
              currency: 'USD',
              minimumFractionDigits: 0,
            });

            let tr = tbody.append('tr');
            tr.append('td')
              .attr('class', 'toolTipCaption')
              .style('text-align', 'left')
              .html('Total GrantDol:');
            tr.append('td')
              .style('text-align', 'right')
              .html(formatter.format(fac.grantDolActual));
          }
          if (selectedVariableGroup === 'grants') {
            let tr = tbody.append('tr');
            tr.append('td')
              .attr('class', 'toolTipCaption')
              .style('text-align', 'left')
              .html('Total Grants:');
            tr.append('td').style('text-align', 'right').html(fac.grantsActual);
          }
        }
      }

      let element = this;

      let xpos = d3.select(element).attr('cx');

      // 240 is half of the y axis
      let ypos = 240;

      tooltip
        .style('display', 'block')
        .style('top', ypos + 'px')
        .style('left', xpos + 'px')
        .style('position', 'absolute')
        .style('background', 'white');
    })
    .on('mouseout', function (d) {
      d3.select(this).style('stroke', 'white');
      tooltip.style('display', 'none');
    });

  circles.exit().remove();
};

export default CareerProgression;
