const data = {
  maxObservations: 89,
  dataPoints: [
    {
      yearsSincePhD: 0,
      observations: 9,
      index: -0.214444,
      awards: -0.287777,
      books: -0.3,
      grants: -0.45,
      grantDol: -0.37,
      articles: -0.026666,
      citations: -0.385555,
      confProc: -0.26,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 45,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 1,
      observations: 11,
      index: -0.663636,
      awards: -0.407272,
      books: -0.3,
      grants: -0.45,
      grantDol: -0.37,
      articles: -0.511818,
      citations: -0.373636,
      confProc: -0.110909,
      articlesActual: 5,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 48,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 2,
      observations: 17,
      index: -0.234705,
      awards: -0.275882,
      books: -0.3,
      grants: -0.401176,
      grantDol: -0.321176,
      articles: -0.238235,
      citations: -0.131176,
      confProc: -0.26,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 112,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 18019.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 3,
      observations: 30,
      index: -0.006,
      awards: -0.368666,
      books: -0.240666,
      grants: -0.339333,
      grantDol: -0.327666,
      articles: 0.056,
      citations: -0.166333,
      confProc: 0.231,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 103,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 15603.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 4,
      observations: 31,
      index: -0.266129,
      awards: -0.401935,
      books: -0.3,
      grants: -0.316129,
      grantDol: -0.27,
      articles: -0.250322,
      citations: -0.093225,
      confProc: -0.049032,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 122,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 36920.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 5,
      observations: 49,
      index: -0.112244,
      awards: -0.36,
      books: -0.3,
      grants: -0.280612,
      grantDol: -0.295714,
      articles: -0.055306,
      citations: -0.211632,
      confProc: 0.107346,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 90,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 27182.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 6,
      observations: 66,
      index: 0.037727,
      awards: -0.310454,
      books: -0.27303,
      grants: -0.236212,
      grantDol: -0.255303,
      articles: 0.048939,
      citations: 0.054545,
      confProc: -0.135757,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 161,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 42127.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 7,
      observations: 71,
      index: 0.064507,
      awards: -0.335774,
      books: -0.224788,
      grants: -0.309718,
      grantDol: -0.286056,
      articles: 0.084647,
      citations: -0.011126,
      confProc: -0.052253,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 144,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 30893.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 8,
      observations: 61,
      index: -0.032295,
      awards: -0.205737,
      books: -0.183278,
      grants: -0.286721,
      grantDol: -0.277377,
      articles: -0.196885,
      citations: -0.06754,
      confProc: -0.098688,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 128,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 34070.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 9,
      observations: 63,
      index: 0.020634,
      awards: -0.345396,
      books: -0.3,
      grants: -0.10746,
      grantDol: -0.085079,
      articles: 0.085873,
      citations: 0.13,
      confProc: -0.207936,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 181,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 104725.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 10,
      observations: 69,
      index: -0.213913,
      awards: -0.19913,
      books: -0.248405,
      grants: -0.125217,
      grantDol: -0.037101,
      articles: -0.087391,
      citations: -0.186521,
      confProc: 0.001159,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 97,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 122393.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 11,
      observations: 82,
      index: 0.090243,
      awards: -0.256585,
      books: -0.169634,
      grants: -0.126097,
      grantDol: -0.112195,
      articles: 0.099146,
      citations: -0.114024,
      confProc: -0.100243,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 116,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 94774.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 12,
      observations: 72,
      index: 0.001944,
      awards: -0.173472,
      books: -0.052777,
      grants: -0.081111,
      grantDol: -0.125555,
      articles: -0.001527,
      citations: 0.045833,
      confProc: 0.081111,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 158,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 89806.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 13,
      observations: 75,
      index: 0.1964,
      awards: 0.032533,
      books: -0.1576,
      grants: 0.125466,
      grantDol: 0.152,
      articles: 0.189866,
      citations: 0.116666,
      confProc: 0.002,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 177,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 191994.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 14,
      observations: 75,
      index: 0.054666,
      awards: -0.088266,
      books: -0.133866,
      grants: -0.029466,
      grantDol: -0.0548,
      articles: 0.192933,
      citations: 0.077333,
      confProc: 0.001866,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 167,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 115860.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 15,
      observations: 60,
      index: 0.419666,
      awards: 0.0575,
      books: -0.151666,
      grants: 0.3385,
      grantDol: 0.229833,
      articles: 0.315666,
      citations: 0.279166,
      confProc: 0.122166,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 220,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 220530.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 16,
      observations: 78,
      index: 0.13641,
      awards: -0.174358,
      books: -0.231538,
      grants: -0.024358,
      grantDol: 0.008333,
      articles: 0.026666,
      citations: 0.052564,
      confProc: -0.217948,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 160,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 139261.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 17,
      observations: 74,
      index: 0.117162,
      awards: -0.09554,
      books: -0.011351,
      grants: 0.077162,
      grantDol: -0.019459,
      articles: 0.206621,
      citations: 0.164729,
      confProc: -0.082972,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 190,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 129132.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 18,
      observations: 76,
      index: 0.153026,
      awards: 0.121973,
      books: -0.08921,
      grants: 0.030526,
      grantDol: 0.046315,
      articles: 0.104605,
      citations: -0.100789,
      confProc: -0.044605,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 120,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 153085.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 19,
      observations: 66,
      index: 0.328333,
      awards: -0.006363,
      books: 0.077575,
      grants: 0.216515,
      grantDol: 0.237121,
      articles: 0.28606,
      citations: 0.302878,
      confProc: 0.037575,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 227,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 223415.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 20,
      observations: 76,
      index: 0.249473,
      awards: -0.009868,
      books: -0.136052,
      grants: 0.063289,
      grantDol: -0.00921,
      articles: 0.052368,
      citations: -0.01671,
      confProc: 0.020131,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 142,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 132624.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 21,
      observations: 80,
      index: 0.15775,
      awards: -0.04475,
      books: 0.0115,
      grants: 0.162125,
      grantDol: 0.229375,
      articles: -0.003625,
      citations: 0.013,
      confProc: -0.055375,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 150,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 220272.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 22,
      observations: 89,
      index: 0.273033,
      awards: 0.032808,
      books: 0.200112,
      grants: 0.342696,
      grantDol: 0.219213,
      articles: 0.17,
      citations: 0.031235,
      confProc: 0.181573,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 155,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 216753.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 23,
      observations: 58,
      index: 0.135862,
      awards: 0.500344,
      books: 0.283103,
      grants: 0.165344,
      grantDol: 0.112413,
      articles: 0.122413,
      citations: 0.121551,
      confProc: 0.248103,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 179,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 177498.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 24,
      observations: 64,
      index: 0.175781,
      awards: 0.137343,
      books: 0.117187,
      grants: 0.354062,
      grantDol: 0.267968,
      articles: 0.190937,
      citations: 0.152812,
      confProc: 0.098125,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 187,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 234622.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 25,
      observations: 57,
      index: 0.157719,
      awards: 0.038421,
      books: 0.137192,
      grants: 0.365438,
      grantDol: 0.355964,
      articles: 0.071052,
      citations: 0.097719,
      confProc: 0.084385,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 172,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 267065.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 26,
      observations: 56,
      index: 0.084107,
      awards: 0.177678,
      books: 0.240357,
      grants: 0.053928,
      grantDol: 0.013392,
      articles: -0.096428,
      citations: -0.076785,
      confProc: -0.02625,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 126,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 141027.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 27,
      observations: 57,
      index: 0.185438,
      awards: -0.04228,
      books: 0.199824,
      grants: 0.234385,
      grantDol: 0.154385,
      articles: 0.096491,
      citations: 0.084736,
      confProc: 0.228245,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 169,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 192791.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 28,
      observations: 54,
      index: -0.026666,
      awards: 0.05037,
      books: 0.095555,
      grants: 0.257037,
      grantDol: 0.11037,
      articles: -0.149444,
      citations: 0.008888,
      confProc: -0.017407,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 149,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 176724.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 29,
      observations: 57,
      index: -0.041403,
      awards: 0.022456,
      books: 0.449649,
      grants: -0.173333,
      grantDol: -0.189824,
      articles: 0.016842,
      citations: -0.111578,
      confProc: 0.055964,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 117,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 66236.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 30,
      observations: 56,
      index: -0.020892,
      awards: 0.178214,
      books: 0.240535,
      grants: 0.098392,
      grantDol: 0.0675,
      articles: -0.041785,
      citations: 0.167321,
      confProc: 0.003214,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 191,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 160955.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 31,
      observations: 52,
      index: 0.125384,
      awards: 0.10673,
      books: 0.07673,
      grants: -0.14673,
      grantDol: -0.163076,
      articles: 0.271346,
      citations: 0.006153,
      confProc: -0.196923,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 148,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 76075.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 32,
      observations: 48,
      index: -0.336875,
      awards: 0.34625,
      books: 0.293541,
      grants: 0.034166,
      grantDol: -0.026458,
      articles: -0.247291,
      citations: -0.277083,
      confProc: -0.055208,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 73,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 126344.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 33,
      observations: 42,
      index: -0.405238,
      awards: 0.184047,
      books: 0.039285,
      grants: -0.153571,
      grantDol: -0.069047,
      articles: -0.32119,
      citations: -0.266666,
      confProc: 0.168571,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 76,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 110760.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 34,
      observations: 38,
      index: -0.101315,
      awards: 0.374473,
      books: 0.215526,
      grants: 0.008684,
      grantDol: 0.123157,
      articles: -0.113157,
      citations: -0.118684,
      confProc: 0.256842,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 115,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 181345.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 35,
      observations: 46,
      index: -0.24326,
      awards: 0.342826,
      books: 0.086956,
      grants: 0.055217,
      grantDol: 0.010434,
      articles: -0.107173,
      citations: 0.057826,
      confProc: -0.26,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 162,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 140097.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 36,
      observations: 51,
      index: -0.063333,
      awards: 0.278627,
      books: 0.153725,
      grants: 0.152156,
      grantDol: 0.388431,
      articles: -0.009215,
      citations: 0.030196,
      confProc: 0.060784,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 154,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 278986.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 37,
      observations: 30,
      index: -0.441333,
      awards: 0.270333,
      books: -0.062666,
      grants: -0.173333,
      grantDol: -0.018,
      articles: -0.315666,
      citations: -0.152666,
      confProc: 0.012666,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 106,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 129415.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 38,
      observations: 29,
      index: -0.258275,
      awards: 0.107586,
      books: 0.006896,
      grants: -0.020689,
      grantDol: -0.091724,
      articles: -0.233103,
      citations: -0.038965,
      confProc: -0.090689,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 136,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 102177.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 39,
      observations: 38,
      index: -0.257894,
      awards: 0.182368,
      books: 0.308947,
      grants: 0.270789,
      grantDol: 0.15421,
      articles: -0.336578,
      citations: -0.138421,
      confProc: -0.216842,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 110,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 192776.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 40,
      observations: 23,
      index: 0.246086,
      awards: 0.501739,
      books: 1.325652,
      grants: -0.016956,
      grantDol: 0.037391,
      articles: 0.183478,
      citations: 0.081304,
      confProc: 0.166956,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 168,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 149894.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 41,
      observations: 22,
      index: -0.462727,
      awards: 0.256818,
      books: 0.185454,
      grants: 0.002727,
      grantDol: -0.025454,
      articles: -0.353636,
      citations: -0.27909,
      confProc: -0.185454,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 72,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 126786.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 42,
      observations: 14,
      index: -0.450714,
      awards: -0.034285,
      books: 0.208571,
      grants: -0.035,
      grantDol: -0.052142,
      articles: -0.417857,
      citations: -0.256428,
      confProc: 0.207857,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 78,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 116756.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 43,
      observations: 12,
      index: -0.290833,
      awards: 0.346666,
      books: 0.293333,
      grants: -0.311666,
      grantDol: -0.174166,
      articles: -0.145,
      citations: 0.325,
      confProc: 0.149166,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 233,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 71962.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 44,
      observations: 17,
      index: 0.118235,
      awards: 0.637647,
      books: 0.642941,
      grants: -0.205882,
      grantDol: -0.031764,
      articles: 0.015882,
      citations: -0.067647,
      confProc: 0.221764,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 129,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 124416.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 45,
      observations: 12,
      index: 0.1475,
      awards: 0.5,
      books: 1.184166,
      grants: -0.104166,
      grantDol: -0.165,
      articles: 0.104166,
      citations: -0.003333,
      confProc: 0.149166,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 145,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 75506.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 46,
      observations: 6,
      index: -0.436666,
      awards: 0.27,
      books: -0.3,
      grants: 0.518333,
      grantDol: 1.44,
      articles: -0.395,
      citations: -0.18,
      confProc: 0.013333,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 98,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 665972.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 47,
      observations: 10,
      index: -0.357,
      awards: 0.423,
      books: 0.412,
      grants: -0.367,
      grantDol: -0.324,
      articles: 0.122,
      citations: 0.625,
      confProc: 0.722,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 312,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 17082.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 48,
      observations: 3,
      index: -0.626666,
      awards: 0.116666,
      books: -0.3,
      grants: -0.45,
      grantDol: -0.37,
      articles: -0.503333,
      citations: -0.306666,
      confProc: -0.26,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 65,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 49,
      observations: 7,
      index: -0.271428,
      awards: 0.422857,
      books: 0.462857,
      grants: 0.142857,
      grantDol: 0.187142,
      articles: -0.11,
      citations: -0.042857,
      confProc: -0.26,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 135,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 204840.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 50,
      observations: 2,
      index: -0.045,
      awards: -0.035,
      books: -0.3,
      grants: 0.795,
      grantDol: 1.105,
      articles: -0.18,
      citations: 0.42,
      confProc: 0.56,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 257,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 542904.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 51,
      observations: 3,
      index: -1.566666,
      awards: -0.186666,
      books: 0.886666,
      grants: -0.45,
      grantDol: -0.37,
      articles: -0.91,
      citations: -0.55,
      confProc: -0.26,
      articlesActual: 1,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 52,
      observations: 2,
      index: 1.4,
      awards: -0.035,
      books: -0.3,
      grants: -0.035,
      grantDol: -0.145,
      articles: 1.65,
      citations: 0.52,
      confProc: -0.26,
      articlesActual: 32,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 283,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 83000.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 53,
      observations: 2,
      index: 0.16,
      awards: 1.335,
      books: 1.48,
      grants: 1.21,
      grantDol: 0.98,
      articles: 1.49,
      citations: 1.69,
      confProc: 3.015,
      articlesActual: 30,
      awardsActual: 2,
      booksActual: 1,
      citationsActual: 594,
      confProcActual: 2,
      grantsActual: 2,
      grantDolActual: 497449.0,
      indexActual: 2.0,
    },
    {
      yearsSincePhD: 54,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 55,
      observations: 1,
      index: -0.15,
      awards: 9.54,
      books: -0.3,
      grants: 0.38,
      grantDol: 0.07,
      articles: -0.59,
      citations: -0.28,
      confProc: -0.26,
      articlesActual: 5,
      awardsActual: 11,
      booksActual: 0,
      citationsActual: 73,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 160374.0,
      indexActual: 0.0,
    },
  ],
  facultyDataPoints: [
    {
      facultyId: 1088625,
      name: 'PANFIL, AMANDA ROBINSON',
      rank: 3,
      awards: -0.5,
      books: -0.3,
      citations: -0.1,
      grants: -0.4,
      grantDol: -0.4,
      articles: -0.2,
      confProc: -0.3,
      yearsSincePhD: 7,
      index: 0.2,
      articlesActual: 10.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 111.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 855824,
      name: 'SHARMA, AMIT',
      rank: 3,
      awards: 0.4,
      books: -0.3,
      citations: -0.3,
      grants: 0.4,
      grantDol: 0.3,
      articles: -0.6,
      confProc: -0.3,
      yearsSincePhD: 7,
      index: -0.2,
      articlesActual: 5.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 75.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 259740.0,
      indexActual: 0.0,
    },
    {
      facultyId: 763522,
      name: 'HUNT, HEATHER ROSE SHIVE',
      rank: 3,
      awards: -0.5,
      books: -0.3,
      citations: -0.4,
      grants: -0.4,
      grantDol: -0.4,
      articles: -0.3,
      confProc: -0.3,
      yearsSincePhD: 9,
      index: -0.2,
      articlesActual: 8.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 55.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 1088624,
      name: 'DAVIES, ALEXANDER E',
      rank: 3,
      awards: -0.5,
      books: -0.3,
      citations: 5.4,
      grants: -0.4,
      grantDol: -0.4,
      articles: -0.8,
      confProc: -0.3,
      yearsSincePhD: 9,
      index: 0.2,
      articlesActual: 3.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 1580.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 855793,
      name: 'MALIMBADA LIYANAGE, NAMAL PRASANNA',
      rank: 3,
      awards: -0.5,
      books: -0.3,
      citations: 0.0,
      grants: -0.4,
      grantDol: -0.4,
      articles: -0.5,
      confProc: -0.3,
      yearsSincePhD: 10,
      index: 0.0,
      articlesActual: 6.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 155.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 779958,
      name: 'KIM, SANGGU',
      rank: 3,
      awards: 2.2,
      books: -0.3,
      citations: -0.4,
      grants: 2.0,
      grantDol: 3.0,
      articles: -0.7,
      confProc: -0.3,
      yearsSincePhD: 12,
      index: -0.5,
      articlesActual: 4.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 27.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 1258232.0,
      indexActual: 0.0,
    },
    {
      facultyId: 106875,
      name: 'SUCHESTON-CAMPBELL, LARA E',
      rank: 2,
      awards: 0.4,
      books: -0.3,
      citations: 4.0,
      grants: -0.4,
      grantDol: -0.4,
      articles: 3.2,
      confProc: -0.3,
      yearsSincePhD: 12,
      index: 1.7,
      articlesActual: 51.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 1208.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 393187,
      name: 'LIU, SHAN-LU',
      rank: 1,
      awards: 0.4,
      books: -0.3,
      citations: 0.8,
      grants: 4.5,
      grantDol: 3.5,
      articles: 1.3,
      confProc: -0.3,
      yearsSincePhD: 16,
      index: 1.7,
      articlesActual: 28.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 357.0,
      confProcActual: 0.0,
      grantsActual: 6.0,
      grantDolActual: 1410210.0,
      indexActual: 0.0,
    },
    {
      facultyId: 301096,
      name: 'LI, JIANRONG',
      rank: 1,
      awards: 0.4,
      books: -0.3,
      citations: 1.8,
      grants: 1.2,
      grantDol: 2.0,
      articles: 1.0,
      confProc: -0.3,
      yearsSincePhD: 17,
      index: 1.7,
      articlesActual: 24.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 616.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 873306.0,
      indexActual: 0.0,
    },
    {
      facultyId: 24838,
      name: 'DAVIS, IAN C',
      rank: 1,
      awards: 2.2,
      books: -0.3,
      citations: 0.0,
      grants: 1.2,
      grantDol: 2.3,
      articles: -0.4,
      confProc: 3.0,
      yearsSincePhD: 19,
      index: 0.5,
      articlesActual: 7.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 149.0,
      confProcActual: 2.0,
      grantsActual: 2.0,
      grantDolActual: 992535.0,
      indexActual: 0.0,
    },
    {
      facultyId: 359396,
      name: 'WU, LI',
      rank: 1,
      awards: 1.3,
      books: 1.5,
      citations: 1.8,
      grants: 7.0,
      grantDol: 7.7,
      articles: 1.3,
      confProc: -0.3,
      yearsSincePhD: 19,
      index: 1.9,
      articlesActual: 28.0,
      awardsActual: 2.0,
      booksActual: 1.0,
      citationsActual: 638.0,
      confProcActual: 0.0,
      grantsActual: 9.0,
      grantDolActual: 2963585.0,
      indexActual: 0.0,
    },
    {
      facultyId: 15268,
      name: 'BURKHARD, MARY JO',
      rank: 2,
      awards: -0.5,
      books: 1.5,
      citations: -0.5,
      grants: -0.4,
      grantDol: -0.4,
      articles: -0.7,
      confProc: -0.3,
      yearsSincePhD: 21,
      index: -1.0,
      articlesActual: 4.0,
      awardsActual: 0.0,
      booksActual: 1.0,
      citationsActual: 16.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 229466,
      name: 'DEORA, RAJENDAR',
      rank: 2,
      awards: -0.5,
      books: -0.3,
      citations: -0.1,
      grants: 1.2,
      grantDol: -0.1,
      articles: 0.1,
      confProc: -0.3,
      yearsSincePhD: 22,
      index: 0.6,
      articlesActual: 13.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 116.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 111829.0,
      indexActual: 0.0,
    },
    {
      facultyId: 24134,
      name: 'CORMET-BOYAKA, ESTELLE',
      rank: 2,
      awards: 0.4,
      books: -0.3,
      citations: 0.5,
      grants: 0.4,
      grantDol: 0.0,
      articles: 0.1,
      confProc: -0.3,
      yearsSincePhD: 22,
      index: 1.1,
      articlesActual: 13.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 287.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 139252.0,
      indexActual: 0.0,
    },
    {
      facultyId: 134495,
      name: 'BOYAKA, PROSPER N',
      rank: 1,
      awards: 1.3,
      books: -0.3,
      citations: 0.3,
      grants: 0.4,
      grantDol: 0.6,
      articles: 0.4,
      confProc: -0.3,
      yearsSincePhD: 25,
      index: 1.2,
      articlesActual: 17.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 229.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 366626.0,
      indexActual: 0.0,
    },
    {
      facultyId: 158384,
      name: 'NIEWIESK, STEFAN',
      rank: 1,
      awards: 0.4,
      books: -0.3,
      citations: -0.1,
      grants: -0.4,
      grantDol: -0.4,
      articles: 0.1,
      confProc: -0.3,
      yearsSincePhD: 28,
      index: 0.6,
      articlesActual: 13.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 116.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 112123,
      name: 'OGLESBEE, MICHAEL JERL',
      rank: 1,
      awards: 1.3,
      books: -0.3,
      citations: -0.1,
      grants: -0.4,
      grantDol: -0.4,
      articles: -0.7,
      confProc: -0.3,
      yearsSincePhD: 31,
      index: -0.1,
      articlesActual: 4.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 126.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 41611,
      name: 'GREEN, PATRICK LEE',
      rank: 1,
      awards: 1.3,
      books: -0.3,
      citations: 0.2,
      grants: 3.7,
      grantDol: 1.1,
      articles: 0.2,
      confProc: -0.3,
      yearsSincePhD: 31,
      index: 1.2,
      articlesActual: 15.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 208.0,
      confProcActual: 0.0,
      grantsActual: 5.0,
      grantDolActual: 525811.0,
      indexActual: 0.0,
    },
    {
      facultyId: 114254,
      name: 'WELLMAN, MAXEY LEE',
      rank: 1,
      awards: -0.5,
      books: 1.5,
      citations: -0.5,
      grants: -0.4,
      grantDol: -0.4,
      articles: -0.4,
      confProc: -0.3,
      yearsSincePhD: 32,
      index: -0.7,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 1.0,
      citationsActual: 18.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 51451,
      name: 'JHIANG, SISSY MEIHUA',
      rank: 1,
      awards: 0.4,
      books: -0.3,
      citations: -0.4,
      grants: -0.4,
      grantDol: -0.4,
      articles: -0.3,
      confProc: -0.3,
      yearsSincePhD: 32,
      index: -0.1,
      articlesActual: 9.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 50.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 59957,
      name: 'INPANBUTR, NONGNUCH',
      rank: 1,
      awards: -0.5,
      books: -0.3,
      citations: -0.6,
      grants: -0.4,
      grantDol: -0.4,
      articles: -1.0,
      confProc: -0.3,
      yearsSincePhD: 34,
      index: -1.7,
      articlesActual: 0.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 2.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 100582,
      name: 'MASTY, JEROME',
      rank: 2,
      awards: 0.4,
      books: -0.3,
      citations: -0.6,
      grants: -0.4,
      grantDol: -0.4,
      articles: -1.0,
      confProc: -0.3,
      yearsSincePhD: 35,
      index: -1.6,
      articlesActual: 0.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 0.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  unit: {
    indexScore: 0.3,
  },
};
export default data;
