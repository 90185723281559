import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import BTable from 'react-bootstrap/Table';
import ChartConversionRatios from './ChartConversionRatios';
import ChartProposalTotals from '../proposalTotals/ChartProposalTotals';
import {
  calculateConversion,
  filterAgencyDataBySelectedYear,
} from './utilsConversions';

// Functions to create editable cells
const EditableNumberCell = ({
  cell: { value },
  row: { index },
  column: { id },
  updateData,
}) => {
  // Convert value of cell to string to remove the leading zero on number input
  let valueToString = value.toString();
  const onChange = e => {
    let cellValue = Number(e.target.value);
    updateData(index, id, cellValue);
  };

  const classNameApply = columnId => {
    if (
      columnId === '2021' ||
      columnId === '2022' ||
      columnId === '2023' ||
      columnId === '2024'
    ) {
      return 'conversion-years-number-input';
    }
    return 'conversion-number-input';
  };
  return (
    <div className="number-cell-wraper">
      <input
        value={valueToString}
        onChange={onChange}
        type="number"
        className={classNameApply(id)}
      />
      <span>%</span>
    </div>
  );
};

const EditableSelectYearCell = ({ setCurrentYear, currentYear }) => {
  const onChange = e => {
    setCurrentYear(Number(e.target.value));
  };
  return (
    <input
      value={currentYear}
      onChange={onChange}
      type="number"
      min="2015"
      max="2020"
      className="conversion-select-input"
    />
  );
};

const EditableIncrementDecrementCell = ({
  cell: { value },
  row: { index },
  column: { id },
  updateData,
  rows,
}) => {
  // Cell value based on column id and row index
  // Plus one for row index to skip the row with +/- buttons
  let newCellValue = Number(rows[index + 1].values[id]);

  const onClickIncrement = e => {
    newCellValue = newCellValue + 1;
    updateData(rows[index + 1].index, id, newCellValue);
  };
  const onClickDecrement = e => {
    newCellValue = newCellValue - 1;
    updateData(rows[index + 1].index, id, newCellValue);
  };
  return (
    <div className="increment-decrement-btn-wraper">
      <span onClick={onClickIncrement} className="span-plus">
        +
      </span>
      /
      <span onClick={onClickDecrement} className="span-minus">
        -
      </span>
    </div>
  );
};

function Table({ columns, data, updateData, setCurrentYear, currentYear }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    // updateData will
    // automatically be available on the instance.
    // That way we can call this function from our
    // cell renderer.
    updateData,
    setCurrentYear,
    currentYear,
  });

  // Render the UI for table
  return (
    <>
      <BTable
        id="conversion-table"
        size="md"
        borderless="true"
        responsive
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <td className="header-column" {...column.getHeaderProps()}>
                  {column.render('Header')}
                </td>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <tr className="tbody-row" {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td className="tbody-cell" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </>
  );
}

function Conversions({
  collegeID,
  currentYear,
  setCurrentYear,
  currChartAgency,
  setCurrChartAgency,
  filteredData,
  setFilteredData,
  proposalYear,
  proposalAgency,
  proposalData,
}) {
  // Checks for header and returns correct cell for it
  const rowCellNameCheck = (row, cell, column, updateData, rows) => {
    let isHeaderYear =
      column.Header === '2021' ||
      column.Header === '2022' ||
      column.Header === '2023' ||
      column.Header === '2024';

    if (row.original.name === 'Directs' || row.original.name === 'Indirects') {
      return EditableNumberCell({
        row,
        cell,
        column,
        updateData,
        rows,
      });
    } else if (isHeaderYear) {
      return EditableIncrementDecrementCell({
        row,
        cell,
        column,
        updateData,
        rows,
      });
    } else {
      return cell.value || ' ';
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: ({ column, updateData, setCurrentYear, currentYear }) => {
          return EditableSelectYearCell({
            column,
            updateData,
            setCurrentYear,
            currentYear,
          });
        },
        accessor: 'name',
        Cell: ({ cell: { value }, row: { index } }) => {
          const onClick = e => {
            setCurrChartAgency({ name: value, index: index });
          };
          // Row Index 0, 3, 6, will have value for agency names -> NIH, OtheFed, NonFed
          if (index === 0 || index === 3 || index === 6) {
            return (
              <div className="button-agency" onClick={onClick}>
                {value}
              </div>
            );
          }
          return value;
        },
      },
      {
        Header: 'Open',
        accessor: 'open',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
      {
        Header: 'Revised',
        accessor: 'revised',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
      {
        Header: 'Transition',
        accessor: 'transition',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
      {
        Header: '2021',
        accessor: '2021',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
      {
        Header: '2022',
        accessor: '2022',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
      {
        Header: '2023',
        accessor: '2023',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
      {
        Header: '2024',
        accessor: '2024',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
    ],
    []
  );

  // calculated CR data
  const [originalAgencyData, setOriginalAgencyData] = useState({});

  const url = process.env.REACT_APP_API_URL;

  // Get all College conversion for NIH Agency.
  useEffect(() => {
    axios
      .get(`${url}/api/colleges/${collegeID}/conversion/4`)
      .then(res => {
        calculateUnitConversionRatio(res.data);
        setCurrChartAgency({
          name: 'NIH',
          index: 0,
        });
      })
      .catch(error => console.log(error.message));
  }, []);

  // calculate Conversion Ratio for each Year for each Agency (Agency, NonFed OtherFed)
  const calculateUnitConversionRatio = data => {
    let convertionRatio = {};
    for (const key in data) {
      convertionRatio[key] = [];
      // object to hold CR data for each year
      let yearObj = {
        2014: 0,
        2015: 0,
        2016: 0,
        2017: 0,
        2018: 0,
        2019: 0,
        2020: 0,
        2021: 0,
      };
      // Loop over all proposals
      for (let p = 0; p < data[key].proposals.length; p++) {
        // Loop over all grants
        if (data[key].grants.length) {
          for (let g = 0; g < data[key].grants.length; g++) {
            // Find if curr Year Proposal has corresponding Year Grant
            if (
              data[key].proposals[p].StartYear === data[key].grants[g].StartYear
            ) {
              yearObj[data[key].proposals[p].StartYear] = calculateConversion(
                data[key].grants[g].TotalGrants,
                data[key].proposals[p].TotalProposals
              );
            }
          }
        }
      }
      convertionRatio[key].push(yearObj);
    }
    setOriginalAgencyData(convertionRatio);
  };

  useEffect(() => {
    setFilteredData(
      filterAgencyDataBySelectedYear(originalAgencyData, currentYear)
    );
  }, [originalAgencyData, currentYear]);

  // When cell renderer calls updateData, pass
  // the rowIndex, columnID and new value to update the
  // original data
  const updateData = (rowIndex, columnID, value) => {
    setFilteredData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnID]: value,
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <div className="conversions-wrapper">
        <div className="table-wrapper">
          <Table
            responsive
            size="sm"
            columns={columns}
            data={filteredData}
            updateData={updateData}
            setCurrentYear={setCurrentYear}
            currentYear={currentYear}
          />
        </div>
        <div className="charts-wrapper">
          <div className="chart-conversions-ratios">
            <ChartConversionRatios
              chartData={filteredData}
              currAgency={currChartAgency}
              currentYear={currentYear}
            />
          </div>
          <div className="chart-proposal-totals">
            <ChartProposalTotals
              chartData={proposalData}
              currAgency={proposalAgency}
              currentYear={proposalYear}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default Conversions;
