import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Route, Switch } from 'react-router-dom';
import Navigation from './components/navigation/Navigation';
import UnitsList from './components/unit/UnitsList';
import UnitProposalsTable from './components/unit/unitProposals/UnitProposalsTable';
import PiGrants from './components/pigrants/PiGrants';
import ProjectionsPlatformNav from './components/unit/unitProjections/projectionsPlatformNav/ProjectionsPlatformNav';
import Unit from './components/unit/Unit';
import Colleges from './components/colleges/Colleges';
import College from './components/colleges/College';

function App() {
  const url = process.env.REACT_APP_API_URL;

  // All Medical Colleges for Ohio State University
  const [colleges, setColleges] = useState([]);
  // currently selected college when User clicks on College Card from Colleges Page
  const [college, setCollege] = useState({ id: null, name: '' });
  // currently selected unit when user clicks on Unit from Unit List
  const [unit, setUnit] = useState({});

  // get all Colleges
  useEffect(() => {
    axios
      .get(`${url}/api/institutions/156/colleges`)
      .then(res => {
        setColleges(res.data);
      })
      .catch(err => {
        console.log(err);
      });
  }, []);

  // Sets Unit in state when User Clicks on Unit Name Link
  // from the Units List
  const handleOnClick = unit => {
    setUnit(unit);
  };

  // Sets College in state when User clicks on College Card
  // from the Colleges Page
  const handleOnClickCollege = college => {
    setCollege({ id: college.Id, name: college.Name });
    if (unit.CollegeId !== college.Id) {
      setUnit({});
    }
  };

  return (
    <>
      <Navigation
        unit={unit}
        colleges={colleges}
        college={college}
        handleOnClickCollege={handleOnClickCollege}
      />
      <Switch>
        <Route
          exact
          path="/"
          component={() => (
            <Colleges
              colleges={colleges}
              handleOnClickCollege={handleOnClickCollege}
            />
          )}
        />
        <Route
          exact
          path="/college/:id"
          component={() => (
            <College handleOnClick={handleOnClick} college={college} />
          )}
        />
        <Route exact path="/units" component={() => <UnitsList />} />
        <Route exact path="/unit/:id" component={() => <Unit unit={unit} />} />
        <Route
          exact
          path="/unit/:id/projections"
          component={() => <ProjectionsPlatformNav unit={unit} />}
        />
        <Route
          exact
          path="/pigrants/:personId"
          component={() => <PiGrants college={college} unit={unit} />}
        />
        <Route
          exact
          path="/unit/:id/proposals"
          component={() => <UnitProposalsTable unit={unit} />}
        />
      </Switch>
    </>
  );
}

export default App;
