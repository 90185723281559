const videosList = [
  {
    header:
      'NIH Message to Applicants and Recipients of NIH Funds on Flexibilities Needed for COVID-19',
    date: 'Tuesday, Mar 17,2020',
    summary:
      'Dr. Mike Lauer, NIH Deputy Director for Extramural Research, provides an update for applicants and recipients of federal funding on flexibilities NIH has put in place to support them and their research through the disruptions caused by COVID-19',
    link: 'https://www.youtube.com/watch?v=jLmBi5wvifk',
    className: 'videos-links',
  },
  {
    header: '2020 HHS Grant Funding Opportunity Announcements',
    date: 'Monday, August 3 2020',
    summary:
      'Stephanie Fertig (HHS Small Business Team Lead) covered the 2020 grant funding opportunity announcements for the SBIR & STTR programs. The Q&A session has been included as well',
    link: 'https://www.youtube.com/watch?v=TH1ng8TaksU',
    className: 'videos-links',
  },
  {
    header: 'Finding and Understanding Funding Opportunity Announcements',
    date: 'Sunday, Oct 11, 2020',
    summary:
      'Learn about the different types of funding opportunity announcements (FOAs) including requests for applications, parent announcements, and program announcements; where to find them; and explore the sections of a sample FOA',
    link: 'https://www.youtube.com/watch?v=yxYYWaRjAMc',
    className: 'videos-links',
  },
];

export default videosList;
