const newsList = [
  {
    header:
      'Guidance for NIH-funded Clinical Trials and Human Subjects Studies Affected by COVID-19',
    date: 'Monday, March 16, 2020',
    summary:
      'NIH Late Application Policy Due to Publc Health Emergency for United States for 2019 Novel Coronavirus (COVID-19)',
    link: 'https://grants.nih.gov/grants/guide/notice-files/NOT-OD-20-087.html',
    className: 'news-hightlights-links',
  },
  {
    header:
      'Flexibilities Available to Applicants and Recipients of Federal Financial Assistance Affected by COVID-19',
    date: 'Monday, March 16, 2020',
    summary:
      'The purpose of this Notice is to alert the community of administrative flexibilities that will apply to NIH applicants and recipients',
    link: 'https://grants.nih.gov/grants/guide/notice-files/NOT-OD-20-086.html',
    className: 'news-hightlights-links',
  },
];

export default newsList;
