/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import React from 'react';
import { Link } from 'react-router-dom';
import styles from './styles/Colleges.module.scss';
import { Card, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';
import NewsHighlights from '../news/newsHighlights/NewsHighlights';
import Videos from '../news/videos/Videos';
import './styles/colleges.css';

const Colleges = props => {
  const formatName = name => {
    if (name.indexOf(',') > -1) {
      return name.split(',').reverse().join(' ');
    }
  };
  return (
    <div className="colleges-view">
      <div className={styles.container}>
        <h1 className={styles.header}>Colleges</h1>
        <div className={styles.cardsContainer} id="colleges-cards-container">
          {props.colleges.map((college, index) => {
            return (
              <>
                <Card className={styles.card} key={index}>
                  <Card.Title className={styles.cardTitle}>
                    {formatName(college.Name)}
                  </Card.Title>
                  <FontAwesomeIcon
                    icon={faUniversity}
                    className={styles.icon}
                  />
                  <Card.Body>
                    <Link
                      to={{
                        pathname: `/college/${college.Id}`,
                      }}
                      onClick={() => props.handleOnClickCollege(college)}
                    >
                      <Button className={styles.cardButton}>
                        View College
                      </Button>
                    </Link>
                  </Card.Body>
                </Card>
              </>
            );
          })}
        </div>

        <div id="news-container">
          <NewsHighlights />
          <Videos />
        </div>
      </div>
    </div>
  );
};

export default Colleges;
