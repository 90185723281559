import React from 'react';
import eventsList from './eventsList';
import EventDetails from './EventDetails';

const UpcomingEvents = () => {
  return (
    <div className="upcoming-events-wrapper">
      <h3 className="upcoming-events-header">Upcoming Events</h3>
      <div>
        {eventsList.map((event, index) => {
          return (
            <div className="event" key={index}>
              <p className="events-date">{event.date}</p>
              <h5 className={event.className}>{event.header}</h5>
              <EventDetails event={event} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default UpcomingEvents;
