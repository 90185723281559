const data = {
  maxObservations: 88,
  dataPoints: [
    {
      yearsSincePhD: 0,
      observations: 13,
      index: -0.443846,
      awards: -0.313846,
      books: -0.28,
      grants: -0.263846,
      grantDol: -0.266923,
      articles: -0.391538,
      citations: -0.170769,
      confProc: 0.047692,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 37,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 24336.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 1,
      observations: 26,
      index: -0.709615,
      awards: -0.42,
      books: -0.28,
      grants: -0.388461,
      grantDol: -0.25923,
      articles: -0.51,
      citations: -0.171538,
      confProc: -0.191923,
      articlesActual: 5,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 40,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 28056.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 2,
      observations: 49,
      index: -0.290204,
      awards: -0.377755,
      books: -0.262653,
      grants: -0.275714,
      grantDol: -0.264081,
      articles: -0.312448,
      citations: -0.15204,
      confProc: -0.074693,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 91,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 25823.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 3,
      observations: 68,
      index: -0.241176,
      awards: -0.34897,
      books: -0.28,
      grants: -0.36647,
      grantDol: -0.314264,
      articles: -0.311029,
      citations: -0.122941,
      confProc: -0.130294,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 168,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 2520.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 4,
      observations: 68,
      index: -0.155,
      awards: -0.318529,
      books: -0.2425,
      grants: -0.271176,
      grantDol: -0.257647,
      articles: -0.194117,
      citations: -0.06647,
      confProc: 0.08147,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 320,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 28696.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 5,
      observations: 68,
      index: 0.103529,
      awards: -0.217352,
      books: -0.205,
      grants: -0.239411,
      grantDol: -0.194558,
      articles: 0.043235,
      citations: 0.214558,
      confProc: -0.194264,
      articlesActual: 17,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 1072,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 57730.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 6,
      observations: 80,
      index: -0.082875,
      awards: -0.290625,
      books: -0.25875,
      grants: -0.3895,
      grantDol: -0.313625,
      articles: -0.128125,
      citations: -0.032625,
      confProc: 0.072125,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 410,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 2952.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 7,
      observations: 71,
      index: -0.193802,
      awards: -0.245352,
      books: -0.268028,
      grants: -0.232394,
      grantDol: -0.204225,
      articles: -0.186056,
      citations: 0.044788,
      confProc: 0.023239,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 618,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 53347.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 8,
      observations: 77,
      index: 0.058571,
      awards: -0.133766,
      books: -0.191688,
      grants: -0.051428,
      grantDol: -0.113376,
      articles: -0.082597,
      citations: -0.085714,
      confProc: 0.002857,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 268,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 95235.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 9,
      observations: 86,
      index: -0.008255,
      awards: -0.21186,
      books: -0.032906,
      grants: 0.009418,
      grantDol: -0.126976,
      articles: -0.018372,
      citations: 0.005116,
      confProc: 0.115581,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 513,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 88807.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 10,
      observations: 72,
      index: 0.114722,
      awards: -0.057083,
      books: -0.043888,
      grants: 0.034861,
      grantDol: 0.032083,
      articles: 0.177361,
      citations: -0.107361,
      confProc: 0.037361,
      articlesActual: 20,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 211,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 162175.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 11,
      observations: 88,
      index: 0.153636,
      awards: -0.247613,
      books: -0.17375,
      grants: 0.122159,
      grantDol: 0.00784,
      articles: 0.0125,
      citations: 0.104886,
      confProc: -0.055909,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 780,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 151012.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 12,
      observations: 62,
      index: 0.050806,
      awards: -0.231129,
      books: -0.23887,
      grants: 0.005322,
      grantDol: -0.053064,
      articles: -0.044032,
      citations: 0.032903,
      confProc: -0.008709,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 585,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 122932.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 13,
      observations: 69,
      index: 0.040869,
      awards: -0.060724,
      books: 0.114057,
      grants: 0.117681,
      grantDol: -0.03971,
      articles: -0.052463,
      citations: 0.164347,
      confProc: -0.068115,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 940,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 128989.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 14,
      observations: 50,
      index: 0.243,
      awards: -0.0756,
      books: -0.144,
      grants: 0.2394,
      grantDol: 0.1908,
      articles: 0.0826,
      citations: 0.0764,
      confProc: -0.0032,
      articlesActual: 18,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 704,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 235390.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 15,
      observations: 53,
      index: 0.303396,
      awards: -0.12132,
      books: 0.056792,
      grants: 0.242452,
      grantDol: 0.407358,
      articles: 0.279056,
      citations: -0.092075,
      confProc: 0.525283,
      articlesActual: 22,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 251,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 335320.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 16,
      observations: 48,
      index: -0.096041,
      awards: -0.176041,
      books: 0.03875,
      grants: -0.025208,
      grantDol: -0.098333,
      articles: -0.130625,
      citations: -0.1325,
      confProc: 0.371458,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 144,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 101886.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 17,
      observations: 54,
      index: 0.237592,
      awards: -0.011666,
      books: 0.097777,
      grants: 0.209814,
      grantDol: 0.107962,
      articles: 0.084444,
      citations: -0.061296,
      confProc: -0.032407,
      articlesActual: 18,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 331,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 196989.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 18,
      observations: 42,
      index: 0.247142,
      awards: 0.13738,
      books: -0.016904,
      grants: 0.443809,
      grantDol: 0.268809,
      articles: 0.237857,
      citations: 0.033571,
      confProc: -0.01738,
      articlesActual: 21,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 587,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 271518.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 19,
      observations: 47,
      index: 0.311489,
      awards: -0.024042,
      books: 0.172127,
      grants: 0.121276,
      grantDol: 0.044255,
      articles: 0.423829,
      citations: -0.043404,
      confProc: -0.107446,
      articlesActual: 25,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 381,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 167834.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 20,
      observations: 53,
      index: 0.107735,
      awards: -0.00415,
      books: -0.183773,
      grants: 0.180943,
      grantDol: 0.122075,
      articles: 0.179433,
      citations: -0.085283,
      confProc: -0.016415,
      articlesActual: 20,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 269,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 203652.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 21,
      observations: 55,
      index: 0.260181,
      awards: 0.355454,
      books: 0.075454,
      grants: 0.198,
      grantDol: 0.136727,
      articles: 0.237818,
      citations: 0.030727,
      confProc: 0.009636,
      articlesActual: 21,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 580,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 210413.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 22,
      observations: 37,
      index: 0.426756,
      awards: 0.49054,
      books: -0.096216,
      grants: 0.474594,
      grantDol: 0.306486,
      articles: 0.54,
      citations: 0.353783,
      confProc: -0.071621,
      articlesActual: 28,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 1446,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 288608.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 23,
      observations: 35,
      index: 0.221714,
      awards: -0.046285,
      books: 0.57,
      grants: 0.341142,
      grantDol: 0.210571,
      articles: 0.286285,
      citations: 0.279428,
      confProc: -0.186857,
      articlesActual: 22,
      awardsActual: 0,
      booksActual: 1,
      citationsActual: 1245,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 244277.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 24,
      observations: 33,
      index: 0.041212,
      awards: 0.495757,
      books: 0.080606,
      grants: -0.07,
      grantDol: -0.06,
      articles: 0.035454,
      citations: -0.043939,
      confProc: -0.145454,
      articlesActual: 17,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 383,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 119649.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 25,
      observations: 27,
      index: 0.018888,
      awards: -0.113703,
      books: 0.349629,
      grants: 0.089629,
      grantDol: 0.111851,
      articles: -0.047777,
      citations: -0.126666,
      confProc: 0.037037,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 158,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 199286.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 26,
      observations: 26,
      index: 0.343846,
      awards: 0.268076,
      books: 0.275769,
      grants: 0.316923,
      grantDol: 0.103076,
      articles: 0.092307,
      citations: -0.04423,
      confProc: 0.6,
      articlesActual: 18,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 377,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 194959.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 27,
      observations: 27,
      index: -0.178518,
      awards: 0.09037,
      books: -0.028148,
      grants: 0.049629,
      grantDol: 0.11037,
      articles: -0.137407,
      citations: -0.079629,
      confProc: -0.217037,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 287,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 198614.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 28,
      observations: 29,
      index: 0.124827,
      awards: 0.102758,
      books: 0.130344,
      grants: 0.38862,
      grantDol: 0.118275,
      articles: 0.016206,
      citations: -0.101034,
      confProc: -0.132758,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 228,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 201856.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 29,
      observations: 34,
      index: 0.138823,
      awards: 0.489411,
      books: 0.344705,
      grants: 0.046176,
      grantDol: -0.028823,
      articles: 0.049117,
      citations: -0.000882,
      confProc: 0.145294,
      articlesActual: 17,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 497,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 133943.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 30,
      observations: 14,
      index: 0.345,
      awards: 0.463571,
      books: 0.448571,
      grants: 0.187142,
      grantDol: 0.117857,
      articles: 0.675,
      citations: 0.719285,
      confProc: -0.017142,
      articlesActual: 31,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 2428,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 201685.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 31,
      observations: 22,
      index: 0.339545,
      awards: 1.172272,
      books: 0.492727,
      grants: 0.40409,
      grantDol: 0.640454,
      articles: 0.298636,
      citations: 0.01,
      confProc: 0.214545,
      articlesActual: 22,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 524,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 442667.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 32,
      observations: 36,
      index: 0.417777,
      awards: 0.649166,
      books: 0.239444,
      grants: 0.619444,
      grantDol: 0.533333,
      articles: 0.505555,
      citations: 0.728333,
      confProc: 0.193333,
      articlesActual: 27,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 2451,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 393599.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 33,
      observations: 19,
      index: -0.165789,
      awards: -0.021052,
      books: 0.346315,
      grants: 0.024736,
      grantDol: -0.010526,
      articles: -0.308947,
      citations: -0.11,
      confProc: -0.109473,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 202,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 142637.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 34,
      observations: 14,
      index: 0.154285,
      awards: 0.120714,
      books: -0.158571,
      grants: -0.044285,
      grantDol: -0.008571,
      articles: 0.285,
      citations: 0.051428,
      confProc: 0.160714,
      articlesActual: 22,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 635,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 143319.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 35,
      observations: 29,
      index: -0.095862,
      awards: 0.220344,
      books: 0.071724,
      grants: 0.313793,
      grantDol: 0.597241,
      articles: 0.202758,
      citations: -0.067586,
      confProc: 0.018275,
      articlesActual: 20,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 318,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 423179.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 36,
      observations: 18,
      index: 0.143333,
      awards: 0.381666,
      books: -0.185555,
      grants: -0.13,
      grantDol: 0.290555,
      articles: 0.546111,
      citations: -0.047222,
      confProc: 0.002222,
      articlesActual: 28,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 376,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 281338.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 37,
      observations: 23,
      index: -0.051304,
      awards: 0.416086,
      books: 0.680869,
      grants: -0.242173,
      grantDol: -0.142608,
      articles: -0.113913,
      citations: -0.132608,
      confProc: -0.023043,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 1,
      citationsActual: 145,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 81633.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 38,
      observations: 23,
      index: 0.15913,
      awards: 0.41826,
      books: 0.163478,
      grants: -0.242173,
      grantDol: -0.071739,
      articles: 0.04913,
      citations: -0.031739,
      confProc: -0.023478,
      articlesActual: 17,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 413,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 114514.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 39,
      observations: 13,
      index: -0.05923,
      awards: 0.32,
      books: 0.504615,
      grants: 0.150769,
      grantDol: 0.116923,
      articles: -0.213076,
      citations: -0.08923,
      confProc: -0.24,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 260,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 201547.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 40,
      observations: 17,
      index: 0.344705,
      awards: 0.752941,
      books: 0.67,
      grants: -0.048823,
      grantDol: 0.078235,
      articles: 0.177647,
      citations: -0.061764,
      confProc: -0.203529,
      articlesActual: 20,
      awardsActual: 1,
      booksActual: 1,
      citationsActual: 332,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 183672.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 41,
      observations: 14,
      index: 0.602857,
      awards: 0.415714,
      books: 0.387857,
      grants: 0.726428,
      grantDol: 1.063571,
      articles: 0.714285,
      citations: 0.556428,
      confProc: -0.195714,
      articlesActual: 31,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 1990,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 638222.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 42,
      observations: 15,
      index: -0.073333,
      awards: 0.589333,
      books: 0.853333,
      grants: -0.214,
      grantDol: -0.192666,
      articles: -0.271333,
      citations: -0.13,
      confProc: -0.198666,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 1,
      citationsActual: 148,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 58664.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 43,
      observations: 13,
      index: 0.271538,
      awards: 0.84923,
      books: 0.243076,
      grants: 0.483846,
      grantDol: 0.690769,
      articles: -0.11923,
      citations: -0.103076,
      confProc: -0.24,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 222,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 466163.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 44,
      observations: 8,
      index: -0.3225,
      awards: 0.61,
      books: 1.10125,
      grants: -0.295,
      grantDol: -0.205,
      articles: 0.66125,
      citations: 0.17875,
      confProc: -0.24,
      articlesActual: 30,
      awardsActual: 1,
      booksActual: 1,
      citationsActual: 979,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 52925.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 45,
      observations: 8,
      index: -0.20375,
      awards: 0.35375,
      books: 0.57,
      grants: -0.43,
      grantDol: -0.32,
      articles: -0.08625,
      citations: -0.1325,
      confProc: -0.24,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 1,
      citationsActual: 143,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 46,
      observations: 11,
      index: 0.248181,
      awards: 0.58,
      books: 0.956363,
      grants: -0.135454,
      grantDol: 0.071818,
      articles: 0.415454,
      citations: 0.010909,
      confProc: 0.611818,
      articlesActual: 25,
      awardsActual: 1,
      booksActual: 1,
      citationsActual: 529,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 180578.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 47,
      observations: 4,
      index: -0.1075,
      awards: 1.295,
      books: -0.28,
      grants: 0.9175,
      grantDol: 0.5325,
      articles: -0.2275,
      citations: -0.1525,
      confProc: -0.24,
      articlesActual: 11,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 87,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 393467.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 48,
      observations: 4,
      index: -0.58,
      awards: -0.075,
      books: 0.145,
      grants: -0.43,
      grantDol: -0.32,
      articles: -0.535,
      citations: -0.1675,
      confProc: -0.085,
      articlesActual: 4,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 45,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 49,
      observations: 2,
      index: 0.73,
      awards: 3.01,
      books: 1.42,
      grants: -0.43,
      grantDol: -0.32,
      articles: 0.08,
      citations: -0.095,
      confProc: -0.24,
      articlesActual: 18,
      awardsActual: 5,
      booksActual: 2,
      citationsActual: 238,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 50,
      observations: 9,
      index: 0.067777,
      awards: 1.106666,
      books: 0.475555,
      grants: -0.43,
      grantDol: -0.32,
      articles: -0.171111,
      citations: -0.102222,
      confProc: -0.24,
      articlesActual: 12,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 221,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 51,
      observations: 2,
      index: -0.16,
      awards: 0.61,
      books: 1.845,
      grants: 0.11,
      grantDol: 2.405,
      articles: -0.42,
      citations: -0.16,
      confProc: -0.24,
      articlesActual: 7,
      awardsActual: 1,
      booksActual: 2,
      citationsActual: 62,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 1256740.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 52,
      observations: 1,
      index: 0.96,
      awards: 4.38,
      books: 4.82,
      grants: -0.43,
      grantDol: -0.32,
      articles: 0.26,
      citations: -0.1,
      confProc: -0.24,
      articlesActual: 22,
      awardsActual: 7,
      booksActual: 6,
      citationsActual: 223,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 53,
      observations: 1,
      index: 1.35,
      awards: 2.33,
      books: -0.28,
      grants: 0.65,
      grantDol: 1.09,
      articles: 0.03,
      citations: 0.36,
      confProc: 4.76,
      articlesActual: 17,
      awardsActual: 4,
      booksActual: 0,
      citationsActual: 1461,
      confProcActual: 8,
      grantsActual: 1,
      grantDolActual: 648058.0,
      indexActual: 8.0,
    },
    {
      yearsSincePhD: 54,
      observations: 1,
      index: -0.6,
      awards: 0.96,
      books: -0.28,
      grants: -0.43,
      grantDol: -0.32,
      articles: -0.56,
      citations: -0.18,
      confProc: -0.24,
      articlesActual: 4,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 15,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 55,
      observations: 1,
      index: -0.91,
      awards: -0.42,
      books: 0.57,
      grants: -0.43,
      grantDol: -0.32,
      articles: -0.65,
      citations: -0.18,
      confProc: -0.24,
      articlesActual: 2,
      awardsActual: 0,
      booksActual: 1,
      citationsActual: 10,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  facultyDataPoints: [
    {
      facultyId: 1088578,
      name: 'MANSFIELD, JULIE ANN',
      rank: 3,
      awards: 1.0,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.6,
      confProc: 2.3,
      yearsSincePhD: 0,
      index: -0.8,
      articlesActual: 3.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 4.0,
      confProcActual: 4.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 1088577,
      name: 'JUCKETT, LISA ANN',
      rank: 9,
      awards: -0.4,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.4,
      confProc: -0.2,
      yearsSincePhD: 0,
      index: -0.6,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 9.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887242,
      name: 'GARNER, JENNIFER',
      rank: 3,
      awards: -0.4,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.4,
      confProc: -0.2,
      yearsSincePhD: 1,
      index: -0.3,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 41.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 855726,
      name: 'HAND, BRITTANY N',
      rank: 3,
      awards: -0.4,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.2,
      confProc: -0.2,
      yearsSincePhD: 3,
      index: 0.4,
      articlesActual: 21.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 70.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 802542,
      name: 'RAYO, MICHAEL FRANCIS',
      rank: 3,
      awards: -0.4,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.0,
      confProc: 2.9,
      yearsSincePhD: 6,
      index: 0.2,
      articlesActual: 16.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 74.0,
      confProcActual: 5.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 855728,
      name: 'SCHOEN, JESSICA LYNN',
      rank: 3,
      awards: -0.4,
      books: -0.3,
      citations: -0.1,
      grants: -0.4,
      grantDol: -0.3,
      articles: 1.0,
      confProc: -0.2,
      yearsSincePhD: 7,
      index: 1.1,
      articlesActual: 38.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 315.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 802529,
      name: 'KANG, YUN SEOK',
      rank: 3,
      awards: -0.4,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.4,
      confProc: 4.8,
      yearsSincePhD: 8,
      index: -0.1,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 82.0,
      confProcActual: 8.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 505908,
      name: 'AGNEW, AMANDA MARIE',
      rank: 2,
      awards: -0.4,
      books: -0.3,
      citations: -0.1,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.0,
      confProc: 2.3,
      yearsSincePhD: 8,
      index: 0.5,
      articlesActual: 16.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 152.0,
      confProcActual: 4.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 505907,
      name: 'SPEES, COLLEEN KELLEHER',
      rank: 2,
      awards: -0.4,
      books: 0.6,
      citations: -0.1,
      grants: 0.6,
      grantDol: 0.1,
      articles: 0.8,
      confProc: -0.2,
      yearsSincePhD: 8,
      index: 0.9,
      articlesActual: 33.0,
      awardsActual: 0.0,
      booksActual: 1.0,
      citationsActual: 144.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 198993.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638685,
      name: 'ROEWER, STEPHANIE DI STASI',
      rank: 3,
      awards: 1.0,
      books: -0.3,
      citations: -0.1,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.2,
      confProc: -0.2,
      yearsSincePhD: 8,
      index: 0.6,
      articlesActual: 13.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 225.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638120,
      name: 'FUNDERBURG, NICHOLAS T',
      rank: 2,
      awards: 0.3,
      books: -0.3,
      citations: 0.0,
      grants: 2.8,
      grantDol: 3.0,
      articles: 1.0,
      confProc: -0.2,
      yearsSincePhD: 12,
      index: 1.6,
      articlesActual: 39.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 507.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 1520560.0,
      indexActual: 0.0,
    },
    {
      facultyId: 52809,
      name: 'HEATHCOCK, JILL CATHLEEN',
      rank: 2,
      awards: 1.6,
      books: 0.6,
      citations: -0.2,
      grants: 0.6,
      grantDol: 0.9,
      articles: -0.5,
      confProc: -0.2,
      yearsSincePhD: 13,
      index: 0.1,
      articlesActual: 6.0,
      awardsActual: 3.0,
      booksActual: 1.0,
      citationsActual: 91.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 542206.0,
      indexActual: 0.0,
    },
    {
      facultyId: 378913,
      name: 'SCHMITT, LAURA CLARE',
      rank: 2,
      awards: 1.6,
      books: -0.3,
      citations: 0.0,
      grants: 1.7,
      grantDol: 0.5,
      articles: 0.6,
      confProc: -0.2,
      yearsSincePhD: 13,
      index: 1.5,
      articlesActual: 30.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 631.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 371943.0,
      indexActual: 0.0,
    },
    {
      facultyId: 855727,
      name: 'QUATMAN-YATES, CATHERINE C',
      rank: 3,
      awards: 1.0,
      books: -0.3,
      citations: -0.1,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.4,
      confProc: -0.2,
      yearsSincePhD: 13,
      index: 1.0,
      articlesActual: 26.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 292.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 391521,
      name: 'ADAMS, INGRID K',
      rank: 2,
      awards: 0.3,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.3,
      confProc: -0.2,
      yearsSincePhD: 13,
      index: -0.3,
      articlesActual: 9.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 24.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 505975,
      name: 'GUERAU, MIREIA',
      rank: 2,
      awards: 1.0,
      books: -0.3,
      citations: 0.0,
      grants: 1.7,
      grantDol: 1.0,
      articles: -0.1,
      confProc: -0.2,
      yearsSincePhD: 14,
      index: 1.1,
      articlesActual: 14.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 589.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 584723.0,
      indexActual: 0.0,
    },
    {
      facultyId: 104346,
      name: 'SISTON, ROBERT ANTHONY',
      rank: 1,
      awards: 1.0,
      books: -0.3,
      citations: -0.1,
      grants: 0.6,
      grantDol: 0.1,
      articles: -0.1,
      confProc: -0.2,
      yearsSincePhD: 14,
      index: 0.6,
      articlesActual: 15.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 134.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 197687.0,
      indexActual: 0.0,
    },
    {
      facultyId: 107995,
      name: 'TAYLOR, CHRISTOPHER ALAN',
      rank: 1,
      awards: 0.3,
      books: -0.3,
      citations: -0.1,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.4,
      confProc: -0.2,
      yearsSincePhD: 15,
      index: 0.9,
      articlesActual: 24.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 193.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 585527,
      name: 'GARVIN, JENNIFER',
      rank: 2,
      awards: 0.3,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.0,
      confProc: 0.4,
      yearsSincePhD: 15,
      index: 0.3,
      articlesActual: 16.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 58.0,
      confProcActual: 1.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 300951,
      name: 'BOLTE, JOHN H',
      rank: 1,
      awards: 0.3,
      books: -0.3,
      citations: -0.2,
      grants: 1.7,
      grantDol: 0.0,
      articles: -0.3,
      confProc: 7.9,
      yearsSincePhD: 15,
      index: 0.2,
      articlesActual: 9.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 82.0,
      confProcActual: 13.0,
      grantsActual: 2.0,
      grantDolActual: 121397.0,
      indexActual: 0.0,
    },
    {
      facultyId: 141406,
      name: 'EVANS, KEVIN DAVID',
      rank: 1,
      awards: 1.6,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.8,
      confProc: 0.4,
      yearsSincePhD: 15,
      index: 0.8,
      articlesActual: 33.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 104.0,
      confProcActual: 1.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 17769,
      name: 'CHAUDHARI, AJIT MOHAN',
      rank: 2,
      awards: -0.4,
      books: -0.3,
      citations: -0.1,
      grants: 1.7,
      grantDol: -0.2,
      articles: 1.4,
      confProc: 0.4,
      yearsSincePhD: 16,
      index: 1.3,
      articlesActual: 48.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 308.0,
      confProcActual: 1.0,
      grantsActual: 2.0,
      grantDolActual: 61802.0,
      indexActual: 0.0,
    },
    {
      facultyId: 347016,
      name: 'ONATE, JAMES ARTHUR',
      rank: 2,
      awards: 1.6,
      books: -0.3,
      citations: 0.0,
      grants: 0.6,
      grantDol: 0.3,
      articles: 0.8,
      confProc: -0.2,
      yearsSincePhD: 17,
      index: 1.4,
      articlesActual: 34.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 356.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 293631.0,
      indexActual: 0.0,
    },
    {
      facultyId: 301691,
      name: 'DARRAGH, AMY ROWNTREE',
      rank: 2,
      awards: 0.3,
      books: -0.3,
      citations: -0.1,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.2,
      confProc: 1.0,
      yearsSincePhD: 18,
      index: 0.3,
      articlesActual: 12.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 114.0,
      confProcActual: 2.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 64686,
      name: 'KATZ, MIRA L',
      rank: 1,
      awards: -0.4,
      books: -0.3,
      citations: -0.1,
      grants: 0.6,
      grantDol: 0.2,
      articles: 0.4,
      confProc: -0.2,
      yearsSincePhD: 19,
      index: 0.9,
      articlesActual: 24.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 216.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 234000.0,
      indexActual: 0.0,
    },
    {
      facultyId: 378899,
      name: 'PATTERSON, EMILY S',
      rank: 2,
      awards: 0.3,
      books: 0.6,
      citations: -0.1,
      grants: 0.6,
      grantDol: -0.1,
      articles: 1.1,
      confProc: 2.9,
      yearsSincePhD: 20,
      index: 1.1,
      articlesActual: 41.0,
      awardsActual: 1.0,
      booksActual: 1.0,
      citationsActual: 154.0,
      confProcActual: 5.0,
      grantsActual: 1.0,
      grantDolActual: 86597.0,
      indexActual: 0.0,
    },
    {
      facultyId: 505906,
      name: 'PAGE, STEPHEN JOHN',
      rank: 1,
      awards: 3.7,
      books: -0.3,
      citations: -0.1,
      grants: 0.6,
      grantDol: 0.8,
      articles: 0.8,
      confProc: -0.2,
      yearsSincePhD: 21,
      index: 1.3,
      articlesActual: 33.0,
      awardsActual: 6.0,
      booksActual: 0.0,
      citationsActual: 261.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 513949.0,
      indexActual: 0.0,
    },
    {
      facultyId: 156287,
      name: 'MERRICK, MARK A',
      rank: 2,
      awards: 1.6,
      books: 0.6,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.6,
      confProc: -0.2,
      yearsSincePhD: 21,
      index: -0.6,
      articlesActual: 3.0,
      awardsActual: 3.0,
      booksActual: 1.0,
      citationsActual: 20.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 172403,
      name: 'WOLF, KAY NEWLON',
      rank: 1,
      awards: -0.4,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.6,
      confProc: -0.2,
      yearsSincePhD: 25,
      index: -0.8,
      articlesActual: 3.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 15.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 166712,
      name: 'SOMMERICH, CAROLYN MARIE',
      rank: 2,
      awards: 0.3,
      books: -0.3,
      citations: -0.1,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.4,
      confProc: 5.4,
      yearsSincePhD: 25,
      index: 0.8,
      articlesActual: 26.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 152.0,
      confProcActual: 9.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 7945,
      name: 'BASSO, D MICHELE',
      rank: 1,
      awards: 1.6,
      books: -0.3,
      citations: -0.1,
      grants: 3.9,
      grantDol: 1.2,
      articles: 0.1,
      confProc: -0.2,
      yearsSincePhD: 28,
      index: 1.2,
      articlesActual: 19.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 339.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 697235.0,
      indexActual: 0.0,
    },
    {
      facultyId: 542061,
      name: 'BUFORD, JOHN ANDERSON',
      rank: 1,
      awards: 1.0,
      books: 0.6,
      citations: -0.1,
      grants: 0.6,
      grantDol: 0.3,
      articles: -0.3,
      confProc: -0.2,
      yearsSincePhD: 29,
      index: 0.5,
      articlesActual: 9.0,
      awardsActual: 2.0,
      booksActual: 1.0,
      citationsActual: 165.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 268314.0,
      indexActual: 0.0,
    },
    {
      facultyId: 93172,
      name: 'WALDMAN, W JAMES',
      rank: 2,
      awards: -0.4,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.7,
      confProc: -0.2,
      yearsSincePhD: 29,
      index: -0.5,
      articlesActual: 1.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 105.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 385347,
      name: 'MERFELD, DANIEL MICHAEL',
      rank: 1,
      awards: 0.3,
      books: -0.3,
      citations: -0.1,
      grants: 3.9,
      grantDol: 2.1,
      articles: -0.2,
      confProc: -0.2,
      yearsSincePhD: 29,
      index: 0.7,
      articlesActual: 11.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 187.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 1105256.0,
      indexActual: 0.0,
    },
    {
      facultyId: 152160,
      name: 'LARSEN, DEBORAH SUE',
      rank: 1,
      awards: 1.6,
      books: 0.6,
      citations: -0.1,
      grants: 0.6,
      grantDol: -0.3,
      articles: -0.4,
      confProc: -0.2,
      yearsSincePhD: 32,
      index: 0.4,
      articlesActual: 8.0,
      awardsActual: 3.0,
      booksActual: 1.0,
      citationsActual: 162.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 11000.0,
      indexActual: 0.0,
    },
    {
      facultyId: 513061,
      name: 'SNYDER, JOHN ROBERT',
      rank: 1,
      awards: 0.3,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.6,
      confProc: -0.2,
      yearsSincePhD: 37,
      index: -0.6,
      articlesActual: 2.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 33.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638728,
      name: 'KEMPER, KATHI J',
      rank: 1,
      awards: -0.4,
      books: 2.3,
      citations: 0.0,
      grants: -0.4,
      grantDol: -0.3,
      articles: 1.0,
      confProc: -0.2,
      yearsSincePhD: 37,
      index: 1.2,
      articlesActual: 39.0,
      awardsActual: 0.0,
      booksActual: 3.0,
      citationsActual: 454.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 92417,
      name: 'PETOSA, RICK ALLEN',
      rank: 1,
      awards: -0.4,
      books: -0.3,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.3,
      confProc: -0.2,
      yearsSincePhD: 39,
      index: -0.2,
      articlesActual: 10.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 39.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384410,
      name: 'MCCOY, KAREN SHARROCK',
      rank: 1,
      awards: -0.4,
      books: -0.3,
      citations: 0.3,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.8,
      confProc: -0.2,
      yearsSincePhD: 44,
      index: 1.4,
      articlesActual: 34.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 1294.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  unit: {
    indexScore: 0.5,
  },
};
export default data;
