const data = {
  maxObservations: 111,
  dataPoints: [
    {
      yearsSincePhD: 0,
      observations: 5,
      index: -0.918,
      awards: -0.35,
      books: -0.26,
      grants: -0.51,
      grantDol: -0.46,
      articles: -0.514,
      citations: -0.346,
      confProc: -0.29,
      articlesActual: 5,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 58,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 1,
      observations: 3,
      index: -0.786666,
      awards: -0.35,
      books: -0.26,
      grants: -0.51,
      grantDol: -0.46,
      articles: -0.446666,
      citations: -0.37,
      confProc: -0.29,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 35,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 2,
      observations: 9,
      index: -0.452222,
      awards: -0.35,
      books: -0.26,
      grants: -0.286666,
      grantDol: -0.371111,
      articles: -0.386666,
      citations: -0.283333,
      confProc: 0.19,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 117,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 49173.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 3,
      observations: 11,
      index: -0.596363,
      awards: -0.35,
      books: -0.26,
      grants: -0.51,
      grantDol: -0.46,
      articles: -0.394545,
      citations: -0.313636,
      confProc: -0.233636,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 88,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 4,
      observations: 21,
      index: -0.245714,
      awards: -0.35,
      books: -0.26,
      grants: -0.414285,
      grantDol: -0.299047,
      articles: -0.287142,
      citations: -0.134761,
      confProc: -0.201904,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 257,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 90975.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 5,
      observations: 52,
      index: -0.463846,
      awards: -0.263461,
      books: -0.206923,
      grants: -0.471346,
      grantDol: -0.440192,
      articles: -0.3425,
      citations: -0.19,
      confProc: -0.230576,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 206,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 11143.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 6,
      observations: 53,
      index: -0.575849,
      awards: -0.321698,
      books: -0.26,
      grants: -0.459433,
      grantDol: -0.433018,
      articles: -0.410754,
      citations: -0.313018,
      confProc: -0.243584,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 88,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 15102.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 7,
      observations: 77,
      index: -0.376753,
      awards: -0.184155,
      books: -0.242077,
      grants: -0.37922,
      grantDol: -0.375324,
      articles: -0.273246,
      citations: -0.146233,
      confProc: -0.041818,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 247,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 47691.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 8,
      observations: 78,
      index: -0.258846,
      awards: -0.157692,
      books: -0.224615,
      grants: -0.312179,
      grantDol: -0.305769,
      articles: -0.314358,
      citations: -0.195128,
      confProc: -0.005769,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 201,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 86767.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 9,
      observations: 92,
      index: -0.2125,
      awards: -0.170434,
      books: -0.215,
      grants: -0.305434,
      grantDol: -0.264565,
      articles: -0.189239,
      citations: -0.013804,
      confProc: 0.024891,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 371,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 110326.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 10,
      observations: 101,
      index: -0.262673,
      awards: -0.216336,
      books: -0.205346,
      grants: -0.297227,
      grantDol: -0.272079,
      articles: -0.225247,
      citations: -0.121089,
      confProc: -0.186138,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 270,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 106054.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 11,
      observations: 99,
      index: 0.013636,
      awards: -0.152727,
      books: -0.148484,
      grants: -0.157272,
      grantDol: -0.139696,
      articles: -0.086464,
      citations: -0.063838,
      confProc: -0.097171,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 324,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 180895.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 12,
      observations: 94,
      index: -0.012553,
      awards: -0.07851,
      books: -0.113191,
      grants: -0.088723,
      grantDol: -0.089042,
      articles: -0.081914,
      citations: -0.081914,
      confProc: -0.119361,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 307,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 209255.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 13,
      observations: 92,
      index: 0.069782,
      awards: 0.041956,
      books: -0.17,
      grants: -0.08663,
      grantDol: -0.113913,
      articles: -0.061521,
      citations: 0.034239,
      confProc: 0.044891,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 417,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 195311.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 14,
      observations: 99,
      index: 0.067373,
      awards: -0.000808,
      books: -0.120606,
      grants: -0.04202,
      grantDol: -0.010101,
      articles: -0.030202,
      citations: 0.035757,
      confProc: -0.047272,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 418,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 254244.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 15,
      observations: 111,
      index: 0.178738,
      awards: -0.065945,
      books: -0.222702,
      grants: 0.065315,
      grantDol: 0.025945,
      articles: 0.089369,
      citations: -0.07,
      confProc: 0.037657,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 318,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 274460.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 16,
      observations: 101,
      index: 0.162178,
      awards: -0.104653,
      books: -0.041386,
      grants: 0.00198,
      grantDol: -0.012871,
      articles: 0.079306,
      citations: -0.045643,
      confProc: 0.039306,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 341,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 252550.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 17,
      observations: 99,
      index: 0.204747,
      awards: 0.006565,
      books: -0.134545,
      grants: 0.386868,
      grantDol: 0.320202,
      articles: 0.017575,
      citations: -0.017777,
      confProc: -0.010101,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 368,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 441007.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 18,
      observations: 94,
      index: 0.265,
      awards: -0.14234,
      books: -0.113191,
      grants: 0.061914,
      grantDol: 0.057978,
      articles: 0.11468,
      citations: 0.015212,
      confProc: -0.040638,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 399,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 292727.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 19,
      observations: 88,
      index: 0.215568,
      awards: 0.110795,
      books: -0.103181,
      grants: 0.192727,
      grantDol: 0.148522,
      articles: 0.244772,
      citations: 0.172727,
      confProc: 0.171704,
      articlesActual: 19,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 548,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 343958.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 20,
      observations: 87,
      index: 0.227011,
      awards: 0.047241,
      books: -0.037931,
      grants: 0.201034,
      grantDol: 0.115287,
      articles: 0.079655,
      citations: -0.062068,
      confProc: 0.276091,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 326,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 324980.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 21,
      observations: 77,
      index: 0.133116,
      awards: -0.028441,
      books: -0.098701,
      grants: 0.231558,
      grantDol: 0.153246,
      articles: 0.043766,
      citations: 0.043506,
      confProc: -0.097792,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 425,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 346280.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 22,
      observations: 66,
      index: 0.409848,
      awards: 0.207878,
      books: -0.071818,
      grants: 0.427575,
      grantDol: 0.498636,
      articles: 0.247878,
      citations: 0.227424,
      confProc: 0.381515,
      articlesActual: 19,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 599,
      confProcActual: 1,
      grantsActual: 1,
      grantDolActual: 542151.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 23,
      observations: 99,
      index: 0.032424,
      awards: -0.038787,
      books: -0.064848,
      grants: 0.203232,
      grantDol: 0.154242,
      articles: 0.094242,
      citations: 0.218484,
      confProc: 0.083333,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 591,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 347144.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 24,
      observations: 91,
      index: 0.11934,
      awards: 0.005164,
      books: 0.11912,
      grants: 0.236923,
      grantDol: 0.170659,
      articles: 0.218901,
      citations: 0.03934,
      confProc: -0.08,
      articlesActual: 18,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 421,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 356778.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 25,
      observations: 98,
      index: 0.103877,
      awards: -0.020612,
      books: -0.062857,
      grants: 0.06602,
      grantDol: 0.168163,
      articles: -0.037653,
      citations: -0.030714,
      confProc: 0.074591,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 355,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 355006.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 26,
      observations: 78,
      index: 0.065384,
      awards: -0.080512,
      books: -0.047692,
      grants: 0.205384,
      grantDol: 0.198974,
      articles: 0.034871,
      citations: -0.042179,
      confProc: -0.02141,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 345,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 372624.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 27,
      observations: 75,
      index: 0.1772,
      awards: -0.019333,
      books: -0.0392,
      grants: 0.2164,
      grantDol: 0.187733,
      articles: 0.083733,
      citations: -0.0888,
      confProc: 0.1124,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 301,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 366125.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 28,
      observations: 83,
      index: 0.12012,
      awards: -0.096626,
      books: 0.02265,
      grants: 0.292409,
      grantDol: 0.236867,
      articles: 0.025301,
      citations: 0.18,
      confProc: -0.067349,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 554,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 394077.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 29,
      observations: 62,
      index: 0.050322,
      awards: 0.074193,
      books: 0.051612,
      grants: 0.140806,
      grantDol: 0.115,
      articles: 0.036451,
      citations: -0.083064,
      confProc: -0.200322,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 305,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 325120.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 30,
      observations: 85,
      index: 0.270941,
      awards: -0.005411,
      books: 0.178352,
      grants: 0.114941,
      grantDol: 0.047411,
      articles: 0.19847,
      citations: 0.279176,
      confProc: 0.253294,
      articlesActual: 18,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 648,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 286759.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 31,
      observations: 78,
      index: 0.217307,
      awards: 0.093205,
      books: 0.111538,
      grants: 0.31782,
      grantDol: 0.239871,
      articles: 0.075769,
      citations: -0.04,
      confProc: -0.021538,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 347,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 395800.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 32,
      observations: 76,
      index: 0.271973,
      awards: 0.262894,
      books: 0.320921,
      grants: 0.543815,
      grantDol: 0.517236,
      articles: 0.249078,
      citations: 0.06171,
      confProc: 0.115394,
      articlesActual: 19,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 443,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 552821.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 33,
      observations: 61,
      index: 0.144754,
      awards: -0.091311,
      books: 0.147213,
      grants: 0.162295,
      grantDol: 0.056885,
      articles: 0.070819,
      citations: 0.056557,
      confProc: 0.002622,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 438,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 292107.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 34,
      observations: 85,
      index: 0.102352,
      awards: -0.111647,
      books: 0.243294,
      grants: -0.051529,
      grantDol: -0.077058,
      articles: 0.466352,
      citations: 0.333294,
      confProc: 0.297058,
      articlesActual: 23,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 699,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 216265.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 35,
      observations: 88,
      index: 0.233409,
      awards: 0.119659,
      books: 0.445681,
      grants: 0.086022,
      grantDol: 0.086931,
      articles: 0.183636,
      citations: 0.020113,
      confProc: 0.080909,
      articlesActual: 18,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 403,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 309152.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 36,
      observations: 88,
      index: 0.29284,
      awards: 0.042386,
      books: 0.17909,
      grants: 0.177613,
      grantDol: 0.155454,
      articles: 0.376931,
      citations: 0.227727,
      confProc: -0.017045,
      articlesActual: 21,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 599,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 348128.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 37,
      observations: 74,
      index: 0.107297,
      awards: -0.03527,
      books: -0.036216,
      grants: 0.089729,
      grantDol: 0.066621,
      articles: 0.066621,
      citations: 0.001081,
      confProc: -0.123108,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 385,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 297630.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 38,
      observations: 81,
      index: -0.039506,
      awards: 0.23432,
      books: 0.234074,
      grants: 0.229012,
      grantDol: 0.205679,
      articles: -0.031358,
      citations: -0.026913,
      confProc: -0.023703,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 359,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 376377.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 39,
      observations: 67,
      index: -0.222388,
      awards: 0.176567,
      books: 0.110746,
      grants: -0.119104,
      grantDol: -0.060447,
      articles: -0.102238,
      citations: -0.054776,
      confProc: 0.004179,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 333,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 225657.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 40,
      observations: 53,
      index: -0.084716,
      awards: 0.188301,
      books: 0.260754,
      grants: -0.193207,
      grantDol: -0.097169,
      articles: 0.231886,
      citations: 0.256603,
      confProc: 0.035283,
      articlesActual: 19,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 627,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 205134.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 41,
      observations: 51,
      index: -0.033921,
      awards: 0.195098,
      books: 0.064705,
      grants: -0.03549,
      grantDol: 0.093921,
      articles: 0.181176,
      citations: 0.103529,
      confProc: 0.01196,
      articlesActual: 18,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 483,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 313329.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 42,
      observations: 57,
      index: 0.09035,
      awards: 0.071754,
      books: 0.2,
      grants: 0.020701,
      grantDol: -0.031578,
      articles: -0.037719,
      citations: -0.047192,
      confProc: 0.012631,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 340,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 241980.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 43,
      observations: 49,
      index: 0.256938,
      awards: 0.339795,
      books: 0.500408,
      grants: -0.043469,
      grantDol: -0.074897,
      articles: 0.283877,
      citations: 0.209591,
      confProc: -0.138775,
      articlesActual: 19,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 583,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 217690.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 44,
      observations: 30,
      index: 0.231333,
      awards: -0.024666,
      books: 0.384,
      grants: -0.152,
      grantDol: -0.094333,
      articles: 0.259666,
      citations: 0.494333,
      confProc: 0.059333,
      articlesActual: 19,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 852,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 206290.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 45,
      observations: 31,
      index: -0.229677,
      awards: -0.010967,
      books: 0.719354,
      grants: -0.119677,
      grantDol: -0.096774,
      articles: -0.230322,
      citations: -0.214516,
      confProc: -0.09129,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 182,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 205117.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 46,
      observations: 26,
      index: 0.104615,
      awards: 1.35423,
      books: -0.047692,
      grants: 0.238846,
      grantDol: 0.297307,
      articles: 0.086923,
      citations: 0.323076,
      confProc: -0.195,
      articlesActual: 16,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 690,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 427147.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 47,
      observations: 23,
      index: -0.032173,
      awards: 0.466086,
      books: 0.28,
      grants: 0.103043,
      grantDol: 0.194347,
      articles: -0.017826,
      citations: -0.166521,
      confProc: -0.12913,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 227,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 369891.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 48,
      observations: 13,
      index: 0.160769,
      awards: -0.176923,
      books: 0.695384,
      grants: -0.146923,
      grantDol: -0.06923,
      articles: -0.003076,
      citations: 0.556923,
      confProc: 0.467692,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 911,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 221128.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 49,
      observations: 13,
      index: -0.242307,
      awards: 1.152307,
      books: -0.26,
      grants: -0.097692,
      grantDol: -0.087692,
      articles: -0.225384,
      citations: -0.214615,
      confProc: -0.147692,
      articlesActual: 10,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 181,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 209838.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 50,
      observations: 19,
      index: 0.016315,
      awards: 0.163684,
      books: 0.103157,
      grants: -0.263157,
      grantDol: -0.295789,
      articles: -0.032631,
      citations: -0.05,
      confProc: -0.257368,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 337,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 92278.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 51,
      observations: 9,
      index: -1.004444,
      awards: -0.1,
      books: 0.046666,
      grants: -0.211111,
      grantDol: -0.208888,
      articles: -0.586666,
      citations: -0.363333,
      confProc: -0.221111,
      articlesActual: 3,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 40,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 142214.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 52,
      observations: 10,
      index: 0.287,
      awards: 1.228,
      books: 0.43,
      grants: -0.04,
      grantDol: -0.055,
      articles: 0.343,
      citations: 0.314,
      confProc: 0.879,
      articlesActual: 21,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 681,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 228176.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 53,
      observations: 9,
      index: -0.123333,
      awards: 0.652222,
      books: -0.106666,
      grants: 0.013333,
      grantDol: -0.117777,
      articles: 0.071111,
      citations: 0.246666,
      confProc: -0.152222,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 619,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 193179.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 54,
      observations: 8,
      index: 0.0125,
      awards: 1.4325,
      books: 0.9475,
      grants: 0.33125,
      grantDol: 0.2925,
      articles: 0.29125,
      citations: 0.70125,
      confProc: -0.13625,
      articlesActual: 20,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 1048,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 425030.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 55,
      observations: 3,
      index: -0.64,
      awards: 0.653333,
      books: -0.26,
      grants: 0.163333,
      grantDol: 0.13,
      articles: -0.533333,
      citations: -0.32,
      confProc: -0.29,
      articlesActual: 4,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 82,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 334330.0,
      indexActual: 0.0,
    },
  ],
  facultyDataPoints: [
    {
      facultyId: 814795,
      name: 'SANTOSO, NETTY',
      rank: 3,
      awards: -0.4,
      books: -0.3,
      citations: 2.6,
      grants: -0.5,
      grantDol: -0.5,
      articles: -0.3,
      confProc: -0.3,
      yearsSincePhD: 8,
      index: 0.7,
      articlesActual: 9.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 2800.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 781211,
      name: 'VILGELM, ANNA',
      rank: 3,
      awards: 0.4,
      books: -0.3,
      citations: -0.2,
      grants: 0.2,
      grantDol: 0.2,
      articles: -0.4,
      confProc: 1.6,
      yearsSincePhD: 8,
      index: 0.2,
      articlesActual: 8.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 168.0,
      confProcActual: 3.0,
      grantsActual: 1.0,
      grantDolActual: 380614.0,
      indexActual: 0.0,
    },
    {
      facultyId: 1088610,
      name: 'AHIRWAR, DINESH KUMAR',
      rank: 3,
      awards: -0.4,
      books: -0.3,
      citations: -0.2,
      grants: -0.5,
      grantDol: -0.5,
      articles: -0.4,
      confProc: 5.2,
      yearsSincePhD: 9,
      index: 0.0,
      articlesActual: 8.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 223.0,
      confProcActual: 9.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 855769,
      name: 'OGHUMU, STEVE',
      rank: 3,
      awards: 0.4,
      books: -0.3,
      citations: -0.1,
      grants: -0.5,
      grantDol: -0.5,
      articles: 0.5,
      confProc: -0.3,
      yearsSincePhD: 9,
      index: 0.9,
      articlesActual: 24.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 326.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 731023,
      name: 'ZHU, JIAN',
      rank: 2,
      awards: -0.4,
      books: -0.3,
      citations: -0.2,
      grants: 2.9,
      grantDol: 2.3,
      articles: -0.1,
      confProc: -0.3,
      yearsSincePhD: 10,
      index: 0.7,
      articlesActual: 12.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 204.0,
      confProcActual: 0.0,
      grantsActual: 5.0,
      grantDolActual: 1545485.0,
      indexActual: 0.0,
    },
    {
      facultyId: 802587,
      name: 'CZEISLER, CATHERINE',
      rank: 3,
      awards: -0.4,
      books: -0.3,
      citations: -0.3,
      grants: -0.5,
      grantDol: -0.5,
      articles: -0.2,
      confProc: -0.3,
      yearsSincePhD: 12,
      index: 0.0,
      articlesActual: 11.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 123.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638221,
      name: 'FREUD, AHARON G',
      rank: 3,
      awards: -0.4,
      books: -0.3,
      citations: 0.3,
      grants: 0.2,
      grantDol: 0.2,
      articles: 0.7,
      confProc: 3.4,
      yearsSincePhD: 13,
      index: 1.3,
      articlesActual: 28.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 678.0,
      confProcActual: 6.0,
      grantsActual: 1.0,
      grantDolActual: 360638.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384569,
      name: 'SARKAR, CHANDRANI',
      rank: 3,
      awards: -0.4,
      books: -0.3,
      citations: -0.4,
      grants: 0.2,
      grantDol: -0.1,
      articles: -0.7,
      confProc: -0.3,
      yearsSincePhD: 13,
      index: -0.9,
      articlesActual: 2.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 41.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 191959.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384084,
      name: 'CHAKROBORTY, DEBANJAN',
      rank: 3,
      awards: -0.4,
      books: -0.3,
      citations: -0.4,
      grants: -0.5,
      grantDol: -0.5,
      articles: -0.7,
      confProc: -0.3,
      yearsSincePhD: 14,
      index: -1.0,
      articlesActual: 2.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 41.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 520724,
      name: 'OTERO, JOSE JAVIER',
      rank: 2,
      awards: -0.4,
      books: -0.3,
      citations: -0.1,
      grants: 0.2,
      grantDol: 0.4,
      articles: 0.6,
      confProc: 0.3,
      yearsSincePhD: 15,
      index: 1.0,
      articlesActual: 25.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 332.0,
      confProcActual: 1.0,
      grantsActual: 1.0,
      grantDolActual: 513227.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384688,
      name: 'WILGUS, TRACI ANN',
      rank: 2,
      awards: -0.4,
      books: -0.3,
      citations: -0.4,
      grants: 2.2,
      grantDol: 0.6,
      articles: -0.4,
      confProc: -0.3,
      yearsSincePhD: 18,
      index: -0.2,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 48.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 599949.0,
      indexActual: 0.0,
    },
    {
      facultyId: 81990,
      name: 'SAHENK, ZARIFE',
      rank: 1,
      awards: -0.4,
      books: -0.3,
      citations: 0.0,
      grants: 0.8,
      grantDol: 1.1,
      articles: 0.4,
      confProc: -0.3,
      yearsSincePhD: 21,
      index: 1.2,
      articlesActual: 22.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 429.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 881212.0,
      indexActual: 0.0,
    },
    {
      facultyId: 1446,
      name: 'ALLEN, HEATHER C',
      rank: 1,
      awards: 4.9,
      books: -0.3,
      citations: 0.0,
      grants: 2.2,
      grantDol: 0.8,
      articles: 0.4,
      confProc: -0.3,
      yearsSincePhD: 22,
      index: 1.3,
      articlesActual: 22.0,
      awardsActual: 7.0,
      booksActual: 0.0,
      citationsActual: 394.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 683737.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384014,
      name: 'BASU, SUJIT',
      rank: 1,
      awards: -0.4,
      books: -0.3,
      citations: -0.3,
      grants: 2.2,
      grantDol: 1.2,
      articles: -0.6,
      confProc: -0.3,
      yearsSincePhD: 23,
      index: -0.2,
      articlesActual: 4.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 85.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 962268.0,
      indexActual: 0.0,
    },
    {
      facultyId: 95979,
      name: 'MACHIRAJU, RAGHU',
      rank: 1,
      awards: 2.7,
      books: -0.3,
      citations: -0.3,
      grants: 2.9,
      grantDol: 0.7,
      articles: -0.3,
      confProc: 2.8,
      yearsSincePhD: 23,
      index: 0.4,
      articlesActual: 9.0,
      awardsActual: 4.0,
      booksActual: 0.0,
      citationsActual: 135.0,
      confProcActual: 5.0,
      grantsActual: 5.0,
      grantDolActual: 672677.0,
      indexActual: 0.0,
    },
    {
      facultyId: 102263,
      name: 'SATOSKAR, ABHAY R',
      rank: 1,
      awards: -0.4,
      books: 1.1,
      citations: 0.2,
      grants: 2.9,
      grantDol: 1.0,
      articles: 1.8,
      confProc: -0.3,
      yearsSincePhD: 27,
      index: 1.6,
      articlesActual: 48.0,
      awardsActual: 0.0,
      booksActual: 1.0,
      citationsActual: 584.0,
      confProcActual: 0.0,
      grantsActual: 5.0,
      grantDolActual: 843375.0,
      indexActual: 0.0,
    },
    {
      facultyId: 93172,
      name: 'WALDMAN, W JAMES',
      rank: 2,
      awards: -0.4,
      books: -0.3,
      citations: -0.3,
      grants: -0.5,
      grantDol: -0.5,
      articles: -0.7,
      confProc: -0.3,
      yearsSincePhD: 29,
      index: -0.8,
      articlesActual: 1.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 105.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 393535,
      name: 'MALLERY, SUSAN REGINA',
      rank: 1,
      awards: 0.4,
      books: -0.3,
      citations: -0.4,
      grants: 2.2,
      grantDol: 2.2,
      articles: -0.5,
      confProc: -0.3,
      yearsSincePhD: 29,
      index: -0.1,
      articlesActual: 6.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 56.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 1532031.0,
      indexActual: 0.0,
    },
    {
      facultyId: 513093,
      name: 'OBERYSZYN, TATIANA MARIA',
      rank: 1,
      awards: -0.4,
      books: -0.3,
      citations: -0.3,
      grants: 0.8,
      grantDol: 0.0,
      articles: -0.5,
      confProc: -0.3,
      yearsSincePhD: 29,
      index: -0.4,
      articlesActual: 6.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 63.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 248006.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384449,
      name: 'NADASDY, TIBOR',
      rank: 1,
      awards: -0.4,
      books: 2.5,
      citations: -0.2,
      grants: -0.5,
      grantDol: -0.5,
      articles: 0.7,
      confProc: -0.3,
      yearsSincePhD: 30,
      index: 0.7,
      articlesActual: 27.0,
      awardsActual: 0.0,
      booksActual: 2.0,
      citationsActual: 225.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 5341,
      name: 'BAI, XUE-FENG',
      rank: 1,
      awards: 1.2,
      books: -0.3,
      citations: -0.3,
      grants: 2.2,
      grantDol: 1.1,
      articles: -0.2,
      confProc: -0.3,
      yearsSincePhD: 31,
      index: 0.4,
      articlesActual: 10.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 101.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 903381.0,
      indexActual: 0.0,
    },
    {
      facultyId: 301018,
      name: 'GHOSHAL, KALPANA',
      rank: 1,
      awards: -0.4,
      books: -0.3,
      citations: 0.1,
      grants: 0.8,
      grantDol: 0.8,
      articles: 0.6,
      confProc: 2.2,
      yearsSincePhD: 31,
      index: 1.2,
      articlesActual: 25.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 464.0,
      confProcActual: 4.0,
      grantsActual: 2.0,
      grantDolActual: 741900.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384169,
      name: 'FRANKEL, WENDY L',
      rank: 1,
      awards: -0.4,
      books: 2.5,
      citations: 0.9,
      grants: -0.5,
      grantDol: -0.5,
      articles: 1.5,
      confProc: 0.3,
      yearsSincePhD: 31,
      index: 1.3,
      articlesActual: 42.0,
      awardsActual: 0.0,
      booksActual: 2.0,
      citationsActual: 1200.0,
      confProcActual: 1.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384178,
      name: 'GANJU, RAMESH',
      rank: 1,
      awards: -0.4,
      books: -0.3,
      citations: -0.1,
      grants: 2.2,
      grantDol: 1.0,
      articles: 0.0,
      confProc: 6.5,
      yearsSincePhD: 33,
      index: 0.9,
      articlesActual: 14.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 325.0,
      confProcActual: 11.0,
      grantsActual: 4.0,
      grantDolActual: 821309.0,
      indexActual: 0.0,
    },
    {
      facultyId: 114394,
      name: 'PANCHOLI, VIJAY',
      rank: 2,
      awards: 0.4,
      books: -0.3,
      citations: -0.4,
      grants: -0.5,
      grantDol: -0.5,
      articles: -0.5,
      confProc: -0.3,
      yearsSincePhD: 35,
      index: -0.7,
      articlesActual: 5.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 40.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638208,
      name: 'ABRUZZO, LYNNE V',
      rank: 1,
      awards: 0.4,
      books: -0.3,
      citations: 0.4,
      grants: -0.5,
      grantDol: -0.5,
      articles: 0.6,
      confProc: 0.9,
      yearsSincePhD: 35,
      index: 1.1,
      articlesActual: 25.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 719.0,
      confProcActual: 2.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 379135,
      name: 'ROVIN, BRAD HARRIS',
      rank: 1,
      awards: -0.4,
      books: -0.3,
      citations: 1.3,
      grants: -0.5,
      grantDol: -0.5,
      articles: 3.1,
      confProc: 1.6,
      yearsSincePhD: 36,
      index: 1.4,
      articlesActual: 72.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 1637.0,
      confProcActual: 3.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384670,
      name: 'WAKELY, PAUL E',
      rank: 1,
      awards: -0.4,
      books: 3.9,
      citations: 5.0,
      grants: -0.5,
      grantDol: -0.5,
      articles: 2.6,
      confProc: -0.3,
      yearsSincePhD: 44,
      index: 1.5,
      articlesActual: 63.0,
      awardsActual: 0.0,
      booksActual: 3.0,
      citationsActual: 5112.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 43538,
      name: 'HARRIS, RANDALL EDWARD',
      rank: 1,
      awards: 0.4,
      books: 5.3,
      citations: -0.3,
      grants: -0.5,
      grantDol: -0.5,
      articles: -0.4,
      confProc: -0.3,
      yearsSincePhD: 45,
      index: -0.2,
      articlesActual: 8.0,
      awardsActual: 1.0,
      booksActual: 4.0,
      citationsActual: 93.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384237,
      name: 'HEEREMA, NYLA A',
      rank: 1,
      awards: 0.4,
      books: -0.3,
      citations: 2.5,
      grants: -0.5,
      grantDol: -0.5,
      articles: 2.5,
      confProc: -0.3,
      yearsSincePhD: 52,
      index: 1.6,
      articlesActual: 62.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 2735.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  unit: {
    indexScore: 0.5,
  },
};
export default data;
