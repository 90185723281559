import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTable } from 'react-table';
import BTable from 'react-bootstrap/Table';
import ChartProposalTotals from './ChartProposalTotals';
import ChartConversionRatios from '../conversionRatios/ChartConversionRatios';
import { filterAgencyDataBySelectedYear } from './utilsProposalTotals';

// Functions to create editable cells
const EditableNumberCell = ({
  cell: { value },
  row: { index },
  column: { id },
  updateData,
}) => {
  const [isEditing, setIsEditing] = useState(false);

  // Convert value of cell to string to remove the leading zero on number input
  let valueToString = value.toString();

  const onChange = e => {
    let cellValue = Number(e.target.value);
    updateData(index, id, cellValue);
  };

  const toggleEditing = () => {
    setIsEditing(!isEditing);
  };

  const formatCurrency = number => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(number);
  };

  return (
    <div>
      {isEditing ? (
        <input
          value={valueToString}
          step="10000"
          onChange={onChange}
          onBlur={toggleEditing}
          type="number"
          className="proposal-number-input"
        />
      ) : (
        <input
          type="text"
          value={formatCurrency(valueToString)}
          onFocus={toggleEditing}
          readOnly
          className="proposal-number-input"
        />
      )}
    </div>
  );
};

const EditableSelectYearCell = ({ setCurrentYear, currentYear }) => {
  const onChange = e => {
    setCurrentYear(e.target.value);
  };

  return (
    <input
      value={currentYear}
      onChange={onChange}
      type="number"
      min="2015"
      max="2020"
      className="proposal-select-input"
    />
  );
};

const EditableIncrementDecrementCell = ({
  cell: { value },
  row: { index, values },
  column: { id },
  updateData,
  column,
  rows,
}) => {
  // Cell value based on column id and row index
  // Plus one for row index to skip the row with +/- buttons
  let newCellValue = Number(rows[index + 1].values[id]);

  const onClickIncrement = e => {
    newCellValue = newCellValue + 10000;
    updateData(rows[index + 1].index, id, newCellValue);
  };
  const onClickDecrement = e => {
    newCellValue = newCellValue - 10000;
    updateData(rows[index + 1].index, id, newCellValue);
  };
  return (
    <div className="increment-decrement-btn-wraper">
      <span onClick={onClickIncrement} className="span-plus">
        +
      </span>
      /
      <span onClick={onClickDecrement} className="span-minus">
        -
      </span>
    </div>
  );
};

function Table({ columns, data, updateData, setCurrentYear, currentYear }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  } = useTable({
    columns,
    data,
    // updateData will
    // automatically be available on the instance.
    // That way we can call this function from our
    // cell renderer.
    updateData,
    setCurrentYear,
    currentYear,
  });

  // Render the UI for table
  return (
    <>
      <BTable
        classname="conversion-table"
        size="md"
        borderless="true"
        responsive
        {...getTableProps()}
      >
        <thead>
          {headerGroups.map(headerGroup => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map(column => (
                <th className="header-column" {...column.getHeaderProps()}>
                  {column.render('Header')}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, i) => {
            prepareRow(row);

            return (
              <tr className="tbody-row" {...row.getRowProps()}>
                {row.cells.map(cell => {
                  return (
                    <td className="tbody-cell" {...cell.getCellProps()}>
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </BTable>
    </>
  );
}

function ProposalTotals({
  collegeID,
  currentYear,
  setCurrentYear,
  currChartAgency,
  setCurrChartAgency,
  filteredData,
  setFilteredData,
  converionYear,
  conversionAgency,
  conversionData,
}) {
  // Checks for header and returns correct cell for it to render
  const rowCellNameCheck = (row, cell, column, updateData, rows) => {
    let isColumnHeaderYear =
      column.Header === '2021' ||
      column.Header === '2022' ||
      column.Header === '2023' ||
      column.Header === '2024';

    if (row.original.name === 'Directs' || row.original.name === 'Indirects') {
      return EditableNumberCell({
        row,
        cell,
        column,
        updateData,
        rows,
      });
    } else if (isColumnHeaderYear) {
      return EditableIncrementDecrementCell({
        row,
        cell,
        column,
        updateData,
        rows,
      });
    } else {
      return cell.value || ' ';
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: ({ column, updateData, setCurrentYear, currentYear }) => {
          return EditableSelectYearCell({
            column,
            updateData,

            setCurrentYear,
            currentYear,
          });
        },
        accessor: 'name',
        Cell: ({ cell: { value }, row: { index } }) => {
          const onClick = e => {
            setCurrChartAgency({ name: value, index: index });
          };
          // Row Index 0, 3, 6, will have value for agency names -> NIH, OtheFed, NonFed
          if (index === 0 || index === 3 || index === 6) {
            return (
              <button className="button-agency" onClick={onClick}>
                {value}
              </button>
            );
          }
          return value;
        },
      },
      {
        Header: 'Open',
        accessor: 'open',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
      {
        Header: 'Revised',
        accessor: 'revised',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
      {
        Header: 'Transition',
        accessor: 'transition',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
      {
        Header: '2021',
        accessor: '2021',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
      {
        Header: '2022',
        accessor: '2022',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
      {
        Header: '2023',
        accessor: '2023',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
      {
        Header: '2024',
        accessor: '2024',
        Cell: ({ row, cell, column, updateData, rows }) =>
          rowCellNameCheck(row, cell, column, updateData, rows),
      },
    ],
    []
  );

  // all Agency, Fed and NonFed data for 2014-2021 from api
  const [originalAgencyData, setOriginalAgencyData] = useState({});

  const url = process.env.REACT_APP_API_URL;

  // Get all College proposals for NIH Agency.
  useEffect(() => {
    axios
      .get(`${url}/api/colleges/${collegeID}/proposals/4`)
      .then(res => {
        setOriginalAgencyData(res.data);
        setCurrChartAgency({
          name: 'NIH',
          index: 0,
        });
      })
      .catch(error => console.log(error.message));
  }, []);

  useEffect(() => {
    setFilteredData(
      filterAgencyDataBySelectedYear(originalAgencyData, currentYear)
    );
  }, [originalAgencyData, currentYear]);

  // When cell renderer calls updateData, pass
  // the rowIndex, columnID and new value to update the
  // original data
  const updateData = (rowIndex, columnID, value) => {
    setFilteredData(old =>
      old.map((row, index) => {
        if (index === rowIndex) {
          return {
            ...old[rowIndex],
            [columnID]: value,
          };
        }
        return row;
      })
    );
  };

  return (
    <>
      <div className="proposal-totals-wrapper">
        <div className="table-wrapper">
          <Table
            columns={columns}
            data={filteredData}
            updateData={updateData}
            setCurrentYear={setCurrentYear}
            currentYear={currentYear}
          />
        </div>
        <div className="charts-wrapper">
          <div className="chart-proposal-totals">
            <ChartProposalTotals
              chartData={filteredData}
              currAgency={currChartAgency}
              currentYear={currentYear}
            />
          </div>
          <div className="chart-conversions-ratios">
            <ChartConversionRatios
              chartData={conversionData}
              currAgency={conversionAgency}
              currentYear={converionYear}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProposalTotals;
