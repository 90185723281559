import React from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useTable, useSortBy } from 'react-table';
import BTable from 'react-bootstrap/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import '../styles/collegeProposalsTable.css';

function Table({ columns, data }) {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  );

  const applyClassNameStyles = cell => {
    let classNameStyle = '';
    const isCellNegative = cell.value < 0;
    const cellHeaderCheck =
      cell.column.Header === 'Directs' ||
      cell.column.Header === 'Indirects' ||
      cell.column.Header === 'Total';
    if (cellHeaderCheck && isCellNegative) {
      classNameStyle = 'negative-number';
    }
    return classNameStyle;
  };

  return (
    <BTable
      size="md"
      borderless="true"
      className="college-proposals-table"
      responsive
      {...getTableProps()}
    >
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <td {...column.getHeaderProps(column.getSortByToggleProps())}>
                {column.render('Header')}
                {column.depth > 0 ? (
                  <span className="caret-icon">
                    {column.isSorted ? (
                      column.isSortedDesc ? (
                        <FontAwesomeIcon icon={faCaretDown} />
                      ) : (
                        <FontAwesomeIcon icon={faCaretUp} />
                      )
                    ) : (
                      <FontAwesomeIcon icon={faCaretDown} />
                    )}
                  </span>
                ) : (
                  ''
                )}
              </td>
            ))}
          </tr>
        ))}
      </thead>
      <tbody>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td
                    className={applyClassNameStyles(cell)}
                    {...cell.getCellProps()}
                  >
                    {cell.render('Cell')}
                  </td>
                );
              })}
            </tr>
          );
        })}
      </tbody>
    </BTable>
  );
}

function CollegeProposalsTable(props) {
  const formatNumber = num => {
    let strNumWithSeparators = '';
    let strNumNegative = '';
    if (num < 0) {
      strNumWithSeparators = new Intl.NumberFormat().format(num);
      strNumNegative = strNumWithSeparators.substring(1);
      strNumNegative = `($${strNumNegative})`;
      return strNumNegative;
    } else {
      strNumWithSeparators = new Intl.NumberFormat().format(num);
      strNumWithSeparators = `$${strNumWithSeparators}`;
      return strNumWithSeparators;
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: ' ',
        columns: [
          {
            Header: 'Name',
            accessor: 'fullName',
            Cell: ({ row, cell }) => {
              return (
                <Link to={`/pigrants/${row.original.id}`} key={row.original.id}>
                  {cell.value}
                </Link>
              );
            },
          },
        ],
      },
      {
        Header: 'Proposals 2015 to 2019',
        columns: [
          {
            Header: 'Avg/Year',
            accessor: 'avgYear',
          },
          {
            Header: 'Funded',
            accessor: 'funded',
          },
          {
            Header: 'Not Funded',
            accessor: 'notFunded',
          },
          {
            Header: 'Hit Rate',
            accessor: 'hitRate',
            Cell: ({ cell: { value } }) => (value ? `${value}%` : '-'),
          },
        ],
      },
      {
        Header: 'Actuals 2019',
        columns: [
          {
            Header: 'Directs',
            accessor: 'directs',
            Cell: ({ cell: { value } }) => formatNumber(value),
          },
          {
            Header: 'Indirects',
            accessor: 'indirects',
            Cell: ({ cell: { value } }) => formatNumber(value),
          },
          {
            Header: 'Total',
            accessor: 'total',
            Cell: ({ cell: { value } }) => formatNumber(value),
          },
        ],
      },
    ],
    []
  );

  return (
    <>
      {props.collegeID ? (
        <div>
          <div className="college-proposals-table-wrapper">
            {props.proposals && (
              <Table columns={columns} data={props.proposals} />
            )}
          </div>
        </div>
      ) : (
        <Redirect to="/" />
      )}
    </>
  );
}

export default CollegeProposalsTable;
