export const filterAgencyDataBySelectedYear = (agencyData, currYear = 2020) => {
  currYear = Number(currYear);
  let pastYear = currYear - 1;
  let nextYear = currYear + 1;
  let agenciesWithDirectsIndirects = [];
  const yearKeyMap = {};
  yearKeyMap[pastYear] = 'open';
  yearKeyMap[currYear] = 'revised';
  yearKeyMap[nextYear] = 'transition';
  // Loop through 3 agencies Data to build 3 objects for each agency
  // one for Name , Direct , Indirect
  for (const agency in agencyData) {
    let agencyObj = [
      {
        name: '',
        open: '',
        revised: '',
        transition: '',
        2021: '',
        2022: '',
        2023: '',
        2024: '',
      },
      {
        name: 'Directs',
        open: 0,
        revised: 0,
        transition: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
      {
        name: 'Indirects',
        open: 0,
        revised: 0,
        transition: 0,
        2021: 0,
        2022: 0,
        2023: 0,
        2024: 0,
      },
    ];
    // Add agency name
    const agencyNameKeyMap = {
      otherFed: 'Other Fed',
      nonFed: 'Non Fed',
    };
    agencyObj[0].name = agency === 'agency' ? 'NIH' : agencyNameKeyMap[agency];
    // loop through data in agency key to add data to Direct and Indirect and to corresponding year key
    // open = pastYear revised = currYear nextYear = transition
    for (let i = 0; i < agencyData[agency].length; i++) {
      if (agencyData[agency][i].StartYear === pastYear) {
        agencyObj[1][yearKeyMap[pastYear]] = Number(
          agencyData[agency][i].DirectTotal
        );
        agencyObj[2][yearKeyMap[pastYear]] = Number(
          agencyData[agency][i].IndirectTotal
        );
      }
      if (agencyData[agency][i].StartYear === currYear) {
        agencyObj[1][yearKeyMap[currYear]] = Number(
          agencyData[agency][i].DirectTotal
        );
        agencyObj[2][yearKeyMap[currYear]] = Number(
          agencyData[agency][i].IndirectTotal
        );
      }
      if (agencyData[agency][i].StartYear === nextYear) {
        agencyObj[1][yearKeyMap[nextYear]] = Number(
          agencyData[agency][i].DirectTotal
        );
        agencyObj[2][yearKeyMap[nextYear]] = Number(
          agencyData[agency][i].IndirectTotal
        );
      }
    }

    agenciesWithDirectsIndirects.push(...agencyObj);
  }
  return agenciesWithDirectsIndirects;
};
