const data = {
  maxObservations: 197,
  dataPoints: [
    {
      yearsSincePhD: 0,
      observations: 56,
      index: -0.779285,
      awards: -0.5,
      books: -0.170178,
      grants: -0.333035,
      grantDol: -0.263035,
      articles: -0.528214,
      citations: -0.220178,
      confProc: -0.1,
      articlesActual: 1,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 14,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 4902.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 1,
      observations: 122,
      index: -0.487786,
      awards: -0.462295,
      books: -0.171803,
      grants: -0.322868,
      grantDol: -0.257459,
      articles: -0.392049,
      citations: -0.220409,
      confProc: -0.05295,
      articlesActual: 3,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 15,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 6503.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 2,
      observations: 152,
      index: -0.284736,
      awards: -0.445526,
      books: -0.182697,
      grants: -0.270592,
      grantDol: -0.265657,
      articles: -0.26046,
      citations: -0.157828,
      confProc: -0.077302,
      articlesActual: 4,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 30,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 4144.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 3,
      observations: 190,
      index: -0.292947,
      awards: -0.393473,
      books: -0.178315,
      grants: -0.288473,
      grantDol: -0.259473,
      articles: -0.255,
      citations: -0.169842,
      confProc: -0.081894,
      articlesActual: 4,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 27,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 5913.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 4,
      observations: 195,
      index: -0.180307,
      awards: -0.363179,
      books: -0.16723,
      grants: -0.290307,
      grantDol: -0.25482,
      articles: -0.174564,
      citations: -0.127897,
      confProc: -0.03523,
      articlesActual: 5,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 38,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 7304.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 5,
      observations: 176,
      index: -0.067613,
      awards: -0.337954,
      books: -0.183693,
      grants: -0.188409,
      grantDol: -0.201306,
      articles: -0.128352,
      citations: -0.083693,
      confProc: -0.04125,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 48,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 22712.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 6,
      observations: 197,
      index: -0.019796,
      awards: -0.266548,
      books: -0.111167,
      grants: -0.06873,
      grantDol: -0.033502,
      articles: -0.097208,
      citations: -0.091015,
      confProc: 0.022284,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 47,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 70926.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 7,
      observations: 197,
      index: 0.041776,
      awards: -0.252487,
      books: -0.128071,
      grants: -0.091725,
      grantDol: -0.079137,
      articles: -0.006345,
      citations: -0.035583,
      confProc: 0.19137,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 60,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 57872.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 8,
      observations: 179,
      index: 0.150111,
      awards: -0.063184,
      books: -0.146592,
      grants: 0.03648,
      grantDol: -0.017541,
      articles: 0.020893,
      citations: -0.039441,
      confProc: 0.015418,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 59,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 75652.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 9,
      observations: 184,
      index: -0.096304,
      awards: -0.135108,
      books: -0.039347,
      grants: 0.025706,
      grantDol: 0.127771,
      articles: -0.185597,
      citations: -0.100815,
      confProc: -0.025217,
      articlesActual: 5,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 44,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 117324.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 10,
      observations: 158,
      index: 0.094746,
      awards: -0.040253,
      books: -0.105759,
      grants: 0.07962,
      grantDol: 0.055886,
      articles: 0.053291,
      citations: -0.003797,
      confProc: 0.016202,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 68,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 96670.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 11,
      observations: 172,
      index: 0.072093,
      awards: -0.066976,
      books: -0.099709,
      grants: -0.017616,
      grantDol: 0.002616,
      articles: 0.014825,
      citations: -0.053488,
      confProc: 0.000058,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 56,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 81345.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 12,
      observations: 139,
      index: 0.230071,
      awards: 0.055755,
      books: -0.022446,
      grants: 0.150575,
      grantDol: 0.132949,
      articles: 0.157482,
      citations: 0.084676,
      confProc: 0.065107,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 90,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 118934.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 13,
      observations: 130,
      index: 0.283846,
      awards: 0.129692,
      books: 0.066,
      grants: 0.185923,
      grantDol: 0.101923,
      articles: 0.163461,
      citations: 0.072769,
      confProc: 0.023615,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 87,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 109898.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 14,
      observations: 120,
      index: 0.01425,
      awards: 0.044166,
      books: 0.12425,
      grants: 0.0175,
      grantDol: 0.074666,
      articles: -0.05925,
      citations: -0.070666,
      confProc: -0.013833,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 52,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 102101.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 15,
      observations: 101,
      index: 0.249405,
      awards: 0.173861,
      books: -0.069108,
      grants: 0.25297,
      grantDol: 0.275148,
      articles: 0.151188,
      citations: 0.080099,
      confProc: -0.020495,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 89,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 159724.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 16,
      observations: 95,
      index: 0.410105,
      awards: 0.303578,
      books: -0.03821,
      grants: 0.196315,
      grantDol: 0.194631,
      articles: 0.304421,
      citations: 0.794421,
      confProc: 0.081157,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 266,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 136574.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 17,
      observations: 109,
      index: 0.39688,
      awards: 0.217247,
      books: 0.003302,
      grants: 0.554311,
      grantDol: 0.299816,
      articles: 0.329449,
      citations: 0.148715,
      confProc: 0.02633,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 106,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 166897.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 18,
      observations: 85,
      index: 0.25447,
      awards: 0.289764,
      books: 0.044823,
      grants: 0.190588,
      grantDol: 0.206941,
      articles: 0.328,
      citations: 0.141647,
      confProc: -0.005529,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 104,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 139976.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 19,
      observations: 75,
      index: 0.496533,
      awards: 0.284533,
      books: 0.046533,
      grants: 0.3044,
      grantDol: 0.192133,
      articles: 0.789066,
      citations: 0.352933,
      confProc: 0.068266,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 156,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 135943.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 20,
      observations: 79,
      index: 0.605443,
      awards: 0.454303,
      books: 0.146835,
      grants: 0.404556,
      grantDol: 0.334683,
      articles: 0.547341,
      citations: 0.344556,
      confProc: 0.030632,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 154,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 176802.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 21,
      observations: 96,
      index: 0.30375,
      awards: 0.304687,
      books: 0.21427,
      grants: 0.221979,
      grantDol: 0.121145,
      articles: 0.23552,
      citations: 0.274479,
      confProc: 0.103229,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 137,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 115408.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 22,
      observations: 67,
      index: 0.450746,
      awards: 0.31,
      books: 0.025223,
      grants: 0.361194,
      grantDol: 0.454776,
      articles: 0.448805,
      citations: 0.240895,
      confProc: 0.448208,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 129,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 211403.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 23,
      observations: 67,
      index: 0.3,
      awards: 0.337164,
      books: 0.190597,
      grants: 0.406268,
      grantDol: 0.434925,
      articles: 0.324925,
      citations: 0.135223,
      confProc: -0.1,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 103,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 205706.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 24,
      observations: 52,
      index: 0.323653,
      awards: 0.278269,
      books: 0.300384,
      grants: 0.278846,
      grantDol: 0.293461,
      articles: 0.269423,
      citations: 0.090769,
      confProc: 0.010192,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 92,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 165111.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 25,
      observations: 60,
      index: 0.3975,
      awards: 0.434833,
      books: 0.0135,
      grants: 0.395,
      grantDol: 0.200166,
      articles: 0.258666,
      citations: 0.105666,
      confProc: 0.1105,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 95,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 138273.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 26,
      observations: 59,
      index: 0.159152,
      awards: 0.310677,
      books: 0.260847,
      grants: 0.331016,
      grantDol: 0.067796,
      articles: 0.032033,
      citations: 0.051355,
      confProc: 0.113728,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 82,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 100235.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 27,
      observations: 73,
      index: 0.276438,
      awards: 0.846986,
      books: 0.50863,
      grants: 0.012328,
      grantDol: 0.170547,
      articles: 0.291095,
      citations: 0.205753,
      confProc: -0.052876,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 120,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 129673.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 28,
      observations: 58,
      index: 0.201896,
      awards: 0.530517,
      books: 0.058448,
      grants: 0.134655,
      grantDol: 0.11431,
      articles: 0.156551,
      citations: 0.132413,
      confProc: -0.060344,
      articlesActual: 9,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 102,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 113538.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 29,
      observations: 72,
      index: 0.310138,
      awards: 0.573055,
      books: 0.425972,
      grants: 0.20625,
      grantDol: 0.376666,
      articles: 0.437777,
      citations: 0.205694,
      confProc: 0.09125,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 120,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 188877.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 30,
      observations: 38,
      index: 0.223684,
      awards: 0.757368,
      books: 0.014473,
      grants: 0.156578,
      grantDol: 0.165526,
      articles: 0.381052,
      citations: 0.138421,
      confProc: -0.069736,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 103,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 128228.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 31,
      observations: 44,
      index: 0.3025,
      awards: 0.690909,
      books: 0.1375,
      grants: 0.326363,
      grantDol: 0.143863,
      articles: 0.233181,
      citations: 0.036363,
      confProc: -0.1,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 78,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 122054.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 32,
      observations: 41,
      index: 0.256829,
      awards: 0.441951,
      books: 0.756341,
      grants: -0.102195,
      grantDol: -0.008048,
      articles: 0.172195,
      citations: 0.02317,
      confProc: -0.043902,
      articlesActual: 9,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 75,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 78279.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 33,
      observations: 37,
      index: 0.269189,
      awards: 0.468918,
      books: 0.438918,
      grants: -0.033513,
      grantDol: -0.052162,
      articles: 0.251351,
      citations: 0.133513,
      confProc: -0.1,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 102,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 65643.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 34,
      observations: 36,
      index: 0.003611,
      awards: 0.368333,
      books: 0.210555,
      grants: -0.066388,
      grantDol: 0.085833,
      articles: -0.091666,
      citations: -0.051944,
      confProc: 0.091111,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 56,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 105320.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 35,
      observations: 22,
      index: 0.406363,
      awards: 0.837727,
      books: 0.213636,
      grants: -0.15409,
      grantDol: -0.074545,
      articles: 0.243636,
      citations: 0.062727,
      confProc: -0.047727,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 85,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 59168.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 36,
      observations: 23,
      index: 0.46826,
      awards: 0.65913,
      books: -0.093478,
      grants: 0.361739,
      grantDol: 0.423913,
      articles: 0.40826,
      citations: 0.100869,
      confProc: -0.05,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 94,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 202400.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 37,
      observations: 29,
      index: 0.195517,
      awards: 0.893793,
      books: 1.033448,
      grants: -0.047586,
      grantDol: 0.034482,
      articles: 0.19,
      citations: 0.096206,
      confProc: -0.060344,
      articlesActual: 9,
      awardsActual: 1,
      booksActual: 1,
      citationsActual: 93,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 90468.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 38,
      observations: 20,
      index: 0.337,
      awards: 1.246,
      books: 2.0285,
      grants: 0.848,
      grantDol: 0.613,
      articles: 0.696,
      citations: 0.532,
      confProc: 0.072,
      articlesActual: 15,
      awardsActual: 1,
      booksActual: 2,
      citationsActual: 201,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 257046.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 39,
      observations: 14,
      index: 0.175714,
      awards: 0.419285,
      books: 1.315,
      grants: -0.144285,
      grantDol: -0.168571,
      articles: 0.293571,
      citations: 0.053571,
      confProc: -0.017857,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 1,
      citationsActual: 82,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 32202.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 40,
      observations: 14,
      index: 0.245714,
      awards: 0.55,
      books: -0.031428,
      grants: -0.036428,
      grantDol: 0.015714,
      articles: 0.349285,
      citations: 0.312142,
      confProc: -0.1,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 146,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 84963.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 41,
      observations: 7,
      index: 0.297142,
      awards: -0.105714,
      books: 0.442857,
      grants: -0.144285,
      grantDol: -0.167142,
      articles: 0.01,
      citations: 0.158571,
      confProc: 0.882857,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 108,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 32705.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 42,
      observations: 8,
      index: 0.81125,
      awards: 0.76375,
      books: 0.0875,
      grants: 0.58375,
      grantDol: 0.37,
      articles: 0.1575,
      citations: 0.3225,
      confProc: 0.18625,
      articlesActual: 9,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 149,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 186999.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 43,
      observations: 7,
      index: -0.44,
      awards: -0.105714,
      books: 0.284285,
      grants: -0.36,
      grantDol: -0.28,
      articles: -0.268571,
      citations: -0.165714,
      confProc: -0.1,
      articlesActual: 4,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 28,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 44,
      observations: 7,
      index: 0.935714,
      awards: 1.207142,
      books: 3.452857,
      grants: 0.287142,
      grantDol: 0.342857,
      articles: 1.345714,
      citations: 0.34,
      confProc: -0.1,
      articlesActual: 22,
      awardsActual: 1,
      booksActual: 3,
      citationsActual: 154,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 178946.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 45,
      observations: 4,
      index: 0.225,
      awards: 0.19,
      books: 0.365,
      grants: 0.395,
      grantDol: 0.465,
      articles: 0.2675,
      citations: -0.015,
      confProc: -0.1,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 65,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 214695.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 46,
      observations: 3,
      index: 0.673333,
      awards: 3.786666,
      books: -0.19,
      grants: 0.143333,
      grantDol: 0.383333,
      articles: 0.93,
      citations: 1.533333,
      confProc: -0.1,
      articlesActual: 18,
      awardsActual: 4,
      booksActual: 0,
      citationsActual: 449,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 191127.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 47,
      observations: 4,
      index: -0.695,
      awards: 0.6475,
      books: 0.0875,
      grants: -0.36,
      grantDol: -0.28,
      articles: -0.5275,
      citations: -0.255,
      confProc: -0.1,
      articlesActual: 1,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 6,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 48,
      observations: 3,
      index: 0.936666,
      awards: 0.726666,
      books: 0.92,
      grants: -0.36,
      grantDol: -0.28,
      articles: 0.193333,
      citations: 0.136666,
      confProc: -0.1,
      articlesActual: 9,
      awardsActual: 1,
      booksActual: 1,
      citationsActual: 104,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 49,
      observations: 2,
      index: -0.035,
      awards: 1.34,
      books: 1.47,
      grants: -0.36,
      grantDol: -0.28,
      articles: -0.22,
      citations: -0.16,
      confProc: -0.1,
      articlesActual: 5,
      awardsActual: 2,
      booksActual: 1,
      citationsActual: 29,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 50,
      observations: 2,
      index: -0.22,
      awards: 6.385,
      books: -0.19,
      grants: -0.36,
      grantDol: -0.28,
      articles: -0.13,
      citations: -0.24,
      confProc: -0.1,
      articlesActual: 6,
      awardsActual: 7,
      booksActual: 0,
      citationsActual: 10,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 51,
      observations: 1,
      index: 1.56,
      awards: 0.42,
      books: -0.19,
      grants: 2.66,
      grantDol: 5.26,
      articles: 1.11,
      citations: 0.16,
      confProc: -0.1,
      articlesActual: 20,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 110,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 1592492.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 52,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 53,
      observations: 1,
      index: 0.62,
      awards: 3.17,
      books: 8.68,
      grants: -0.36,
      grantDol: -0.28,
      articles: 0.05,
      citations: -0.16,
      confProc: -0.1,
      articlesActual: 8,
      awardsActual: 4,
      booksActual: 8,
      citationsActual: 30,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 54,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 55,
      observations: 1,
      index: -0.85,
      awards: -0.5,
      books: -0.19,
      grants: -0.36,
      grantDol: -0.28,
      articles: -0.57,
      citations: -0.28,
      confProc: -0.1,
      articlesActual: 1,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 1,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  facultyDataPoints: [
    {
      facultyId: 1088608,
      name: 'HERBELL, KAYLA S',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.2,
      confProc: -0.1,
      yearsSincePhD: 1,
      index: 0.4,
      articlesActual: 10.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 17.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887263,
      name: 'MOSS, KAREN',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.1,
      confProc: -0.1,
      yearsSincePhD: 3,
      index: 0.4,
      articlesActual: 9.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 25.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 535240,
      name: 'NOLAN, TIMIYA S',
      rank: 3,
      awards: 0.4,
      books: -0.2,
      citations: -0.1,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.8,
      confProc: 1.0,
      yearsSincePhD: 3,
      index: 1.0,
      articlesActual: 16.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 40.0,
      confProcActual: 1.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887262,
      name: 'GORR, MATTHEW W',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 0.1,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.5,
      confProc: -0.1,
      yearsSincePhD: 4,
      index: 0.3,
      articlesActual: 2.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 103.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 802577,
      name: 'GILLESPIE, SHANNON LYNN',
      rank: 3,
      awards: 0.4,
      books: -0.2,
      citations: 0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.8,
      confProc: -0.1,
      yearsSincePhD: 4,
      index: 1.3,
      articlesActual: 16.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 123.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 769819,
      name: 'THRANE, SUSAN E',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.1,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.0,
      confProc: 1.0,
      yearsSincePhD: 4,
      index: 0.5,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 51.0,
      confProcActual: 1.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 769817,
      name: 'STEPHENS, JANNA D',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 0.9,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.2,
      confProc: -0.1,
      yearsSincePhD: 4,
      index: 0.9,
      articlesActual: 5.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 292.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 802582,
      name: 'MILITELLO, LISA K',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 0.0,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.3,
      confProc: 1.0,
      yearsSincePhD: 5,
      index: 0.9,
      articlesActual: 11.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 77.0,
      confProcActual: 1.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 765987,
      name: "O'BRIEN, TARA R",
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 0.0,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.0,
      confProc: -0.1,
      yearsSincePhD: 6,
      index: 0.6,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 58.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638205,
      name: 'TATE, JUDITH A',
      rank: 3,
      awards: 0.4,
      books: -0.2,
      citations: 0.2,
      grants: 1.2,
      grantDol: 0.3,
      articles: 0.4,
      confProc: 2.2,
      yearsSincePhD: 6,
      index: 1.3,
      articlesActual: 12.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 118.0,
      confProcActual: 2.0,
      grantsActual: 1.0,
      grantDolActual: 162782.0,
      indexActual: 0.0,
    },
    {
      facultyId: 758752,
      name: 'WRIGHT, KATHY D',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.2,
      confProc: -0.1,
      yearsSincePhD: 6,
      index: 0.5,
      articlesActual: 10.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 28.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 769814,
      name: 'FORTNEY, CHRISTINE ARLEA',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 0.0,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.5,
      confProc: -0.1,
      yearsSincePhD: 7,
      index: 0.9,
      articlesActual: 13.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 69.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 542165,
      name: 'KUE, JENNIFER',
      rank: 2,
      awards: 1.3,
      books: -0.2,
      citations: 0.0,
      grants: 1.2,
      grantDol: -0.1,
      articles: 0.3,
      confProc: -0.1,
      yearsSincePhD: 8,
      index: 1.0,
      articlesActual: 11.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 57.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 52757.0,
      indexActual: 0.0,
    },
    {
      facultyId: 789527,
      name: 'TUBBS COOLEY, HEATHER L',
      rank: 2,
      awards: 0.4,
      books: -0.2,
      citations: 0.3,
      grants: 1.2,
      grantDol: 0.2,
      articles: 0.7,
      confProc: -0.1,
      yearsSincePhD: 9,
      index: 1.4,
      articlesActual: 15.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 147.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 152572.0,
      indexActual: 0.0,
    },
    {
      facultyId: 624150,
      name: 'MONROE, TODD',
      rank: 2,
      awards: 2.3,
      books: -0.2,
      citations: 0.1,
      grants: 4.2,
      grantDol: 3.7,
      articles: 0.5,
      confProc: -0.1,
      yearsSincePhD: 9,
      index: 1.4,
      articlesActual: 13.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 98.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 1139904.0,
      indexActual: 0.0,
    },
    {
      facultyId: 762162,
      name: 'BREITENSTEIN, SUSAN',
      rank: 2,
      awards: 1.3,
      books: -0.2,
      citations: 0.0,
      grants: 1.2,
      grantDol: 0.4,
      articles: 1.1,
      confProc: -0.1,
      yearsSincePhD: 10,
      index: 1.4,
      articlesActual: 20.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 81.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 210238.0,
      indexActual: 0.0,
    },
    {
      facultyId: 513031,
      name: 'LANDERS, TIMOTHY FRANCIS',
      rank: 2,
      awards: 1.3,
      books: -0.2,
      citations: 0.0,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.3,
      confProc: -0.1,
      yearsSincePhD: 11,
      index: 1.0,
      articlesActual: 11.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 66.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384230,
      name: 'HARRISON, TONDI MARIE',
      rank: 2,
      awards: 0.4,
      books: -0.2,
      citations: 0.1,
      grants: 1.2,
      grantDol: 0.5,
      articles: 0.9,
      confProc: -0.1,
      yearsSincePhD: 11,
      index: 1.4,
      articlesActual: 18.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 84.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 233589.0,
      indexActual: 0.0,
    },
    {
      facultyId: 76305,
      name: 'MCDANIEL, JODI C',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: 2.7,
      grantDol: 2.4,
      articles: -0.1,
      confProc: -0.1,
      yearsSincePhD: 12,
      index: 0.5,
      articlesActual: 6.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 33.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 777408.0,
      indexActual: 0.0,
    },
    {
      facultyId: 98930,
      name: 'ROSE, KAREN MOOMAW',
      rank: 1,
      awards: 1.3,
      books: -0.2,
      citations: 0.1,
      grants: 4.2,
      grantDol: 1.1,
      articles: 1.1,
      confProc: -0.1,
      yearsSincePhD: 13,
      index: 1.5,
      articlesActual: 20.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 84.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 386866.0,
      indexActual: 0.0,
    },
    {
      facultyId: 346986,
      name: 'FORD, JODI L',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: 0.1,
      grants: 1.2,
      grantDol: 0.5,
      articles: 0.8,
      confProc: -0.1,
      yearsSincePhD: 13,
      index: 1.2,
      articlesActual: 16.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 90.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 213492.0,
      indexActual: 0.0,
    },
    {
      facultyId: 609573,
      name: 'BALAS, MICHELE CHRISTINA',
      rank: 2,
      awards: 1.3,
      books: -0.2,
      citations: 3.6,
      grants: 1.2,
      grantDol: 1.1,
      articles: 0.8,
      confProc: 1.0,
      yearsSincePhD: 14,
      index: 1.8,
      articlesActual: 16.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 964.0,
      confProcActual: 1.0,
      grantsActual: 1.0,
      grantDolActual: 405062.0,
      indexActual: 0.0,
    },
    {
      facultyId: 168395,
      name: 'TAN, ALAI',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: 1.7,
      grants: -0.4,
      grantDol: -0.3,
      articles: 2.2,
      confProc: 2.2,
      yearsSincePhD: 15,
      index: 1.7,
      articlesActual: 33.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 482.0,
      confProcActual: 2.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 769813,
      name: 'CHANG, MEI-WEI',
      rank: 2,
      awards: 0.4,
      books: -0.2,
      citations: 0.0,
      grants: 1.2,
      grantDol: 1.6,
      articles: 0.4,
      confProc: -0.1,
      yearsSincePhD: 16,
      index: 1.2,
      articlesActual: 12.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 81.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 545476.0,
      indexActual: 0.0,
    },
    {
      facultyId: 609572,
      name: 'ANDERSON, CINDY M',
      rank: 1,
      awards: 1.3,
      books: 3.1,
      citations: 0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.9,
      confProc: -0.1,
      yearsSincePhD: 16,
      index: 1.5,
      articlesActual: 18.0,
      awardsActual: 2.0,
      booksActual: 3.0,
      citationsActual: 130.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384697,
      name: 'WOLD, LOREN EUGENE',
      rank: 1,
      awards: 1.3,
      books: -0.2,
      citations: 0.9,
      grants: 5.7,
      grantDol: 6.9,
      articles: 0.5,
      confProc: -0.1,
      yearsSincePhD: 16,
      index: 1.7,
      articlesActual: 13.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 298.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 2072083.0,
      indexActual: 0.0,
    },
    {
      facultyId: 85992,
      name: 'SMITH, LAUREEN H',
      rank: 2,
      awards: 0.4,
      books: -0.2,
      citations: -0.2,
      grants: 1.2,
      grantDol: 0.9,
      articles: 0.2,
      confProc: -0.1,
      yearsSincePhD: 16,
      index: 0.7,
      articlesActual: 10.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 29.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 343054.0,
      indexActual: 0.0,
    },
    {
      facultyId: 48589,
      name: 'HU, JIE',
      rank: 2,
      awards: 0.4,
      books: 0.9,
      citations: 0.0,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.1,
      confProc: -0.1,
      yearsSincePhD: 19,
      index: 0.9,
      articlesActual: 9.0,
      awardsActual: 1.0,
      booksActual: 1.0,
      citationsActual: 75.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 542129,
      name: 'HAPP, MARY ELIZABETH',
      rank: 1,
      awards: 2.3,
      books: -0.2,
      citations: 0.2,
      grants: 1.2,
      grantDol: 1.0,
      articles: 1.6,
      confProc: 2.2,
      yearsSincePhD: 21,
      index: 1.7,
      articlesActual: 25.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 129.0,
      confProcActual: 2.0,
      grantsActual: 1.0,
      grantDolActual: 377399.0,
      indexActual: 0.0,
    },
    {
      facultyId: 769820,
      name: 'WILLIAMS, KAREN PATRICIA',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: 0.0,
      grants: 1.2,
      grantDol: 1.3,
      articles: 0.5,
      confProc: 1.0,
      yearsSincePhD: 21,
      index: 1.1,
      articlesActual: 13.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 70.0,
      confProcActual: 1.0,
      grantsActual: 1.0,
      grantDolActual: 442122.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638192,
      name: 'DUFFY, SONIA A',
      rank: 1,
      awards: 1.3,
      books: -0.2,
      citations: 0.6,
      grants: -0.4,
      grantDol: -0.3,
      articles: 1.6,
      confProc: -0.1,
      yearsSincePhD: 21,
      index: 1.7,
      articlesActual: 26.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 230.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 301910,
      name: 'WILLS, CELIA EMILY',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: 0.2,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.6,
      confProc: 1.0,
      yearsSincePhD: 24,
      index: 1.2,
      articlesActual: 14.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 123.0,
      confProcActual: 1.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 336799,
      name: 'MORRISON-BEEDY, DIANNE',
      rank: 1,
      awards: 5.9,
      books: 2.0,
      citations: 0.0,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.7,
      confProc: -0.1,
      yearsSincePhD: 26,
      index: 1.2,
      articlesActual: 15.0,
      awardsActual: 7.0,
      booksActual: 2.0,
      citationsActual: 63.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 505950,
      name: 'MELNYK, BERNADETTE',
      rank: 1,
      awards: 6.8,
      books: 12.0,
      citations: 1.6,
      grants: -0.4,
      grantDol: -0.3,
      articles: 3.5,
      confProc: -0.1,
      yearsSincePhD: 27,
      index: 1.9,
      articlesActual: 47.0,
      awardsActual: 8.0,
      booksActual: 11.0,
      citationsActual: 457.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 228986,
      name: 'MION, LORRAINE CECILIA',
      rank: 1,
      awards: 1.3,
      books: -0.2,
      citations: 0.8,
      grants: -0.4,
      grantDol: -0.3,
      articles: 2.1,
      confProc: 2.2,
      yearsSincePhD: 27,
      index: 1.7,
      articlesActual: 31.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 258.0,
      confProcActual: 2.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 76765,
      name: 'PICKLER, RITA HUNDLEY',
      rank: 1,
      awards: 1.3,
      books: -0.2,
      citations: 0.8,
      grants: 1.2,
      grantDol: 1.1,
      articles: 2.5,
      confProc: -0.1,
      yearsSincePhD: 29,
      index: 1.9,
      articlesActual: 36.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 277.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 402357.0,
      indexActual: 0.0,
    },
    {
      facultyId: 47231,
      name: 'GRAHAM, MARGARET CLARK',
      rank: 2,
      awards: 4.1,
      books: -0.2,
      citations: -0.2,
      grants: 2.7,
      grantDol: 3.2,
      articles: -0.4,
      confProc: -0.1,
      yearsSincePhD: 30,
      index: 0.4,
      articlesActual: 3.0,
      awardsActual: 5.0,
      booksActual: 0.0,
      citationsActual: 29.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 1007344.0,
      indexActual: 0.0,
    },
    {
      facultyId: 855766,
      name: "O'MATHUNA, DONAL PADRAIG",
      rank: 2,
      awards: -0.5,
      books: 0.9,
      citations: 0.0,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.2,
      confProc: -0.1,
      yearsSincePhD: 31,
      index: 0.4,
      articlesActual: 5.0,
      awardsActual: 0.0,
      booksActual: 1.0,
      citationsActual: 61.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  unit: {
    indexScore: 1.1,
  },
};
export default data;
