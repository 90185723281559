const data = {
  maxObservations: 33,
  dataPoints: [
    {
      yearsSincePhD: 0,
      observations: 2,
      index: -1.17,
      awards: -0.5,
      books: -0.21,
      grants: -0.66,
      grantDol: -0.55,
      articles: -0.685,
      citations: -0.395,
      confProc: -0.25,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 74,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 1,
      observations: 4,
      index: -1.13,
      awards: -0.3625,
      books: -0.21,
      grants: -0.66,
      grantDol: -0.55,
      articles: -0.6025,
      citations: -0.41,
      confProc: -0.14,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 55,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 2,
      observations: 10,
      index: -0.143,
      awards: -0.445,
      books: -0.21,
      grants: -0.524,
      grantDol: -0.451,
      articles: -0.333,
      citations: 0.064,
      confProc: -0.032,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 798,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 53523.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 3,
      observations: 11,
      index: -0.671818,
      awards: -0.35,
      books: -0.21,
      grants: -0.536363,
      grantDol: -0.405454,
      articles: -0.497272,
      citations: -0.303636,
      confProc: 0.03909,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 221,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 77855.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 4,
      observations: 15,
      index: -0.699333,
      awards: -0.463333,
      books: -0.146666,
      grants: -0.478666,
      grantDol: -0.421333,
      articles: -0.41,
      citations: -0.363333,
      confProc: 0.181333,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 128,
      confProcActual: 2,
      grantsActual: 0,
      grantDolActual: 68921.0,
      indexActual: 2.0,
    },
    {
      yearsSincePhD: 5,
      observations: 17,
      index: -0.261176,
      awards: -0.37,
      books: 0.014117,
      grants: -0.42,
      grantDol: -0.401176,
      articles: -0.335882,
      citations: -0.075294,
      confProc: -0.05,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 577,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 79935.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 6,
      observations: 16,
      index: -0.414375,
      awards: -0.2575,
      books: -0.21,
      grants: -0.3225,
      grantDol: -0.325,
      articles: -0.25375,
      citations: 0.091875,
      confProc: -0.126875,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 843,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 120426.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 7,
      observations: 21,
      index: -0.33238,
      awards: -0.315714,
      books: -0.164761,
      grants: -0.177619,
      grantDol: -0.185714,
      articles: -0.298571,
      citations: -0.01619,
      confProc: 0.037142,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 673,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 195348.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 8,
      observations: 28,
      index: 0.136785,
      awards: -0.064642,
      books: -0.142142,
      grants: -0.033214,
      grantDol: -0.105714,
      articles: -0.103928,
      citations: -0.111071,
      confProc: 0.070714,
      articlesActual: 19,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 524,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 238590.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 9,
      observations: 22,
      index: -0.257272,
      awards: -0.071363,
      books: -0.166818,
      grants: -0.045454,
      grantDol: -0.101363,
      articles: -0.373181,
      citations: -0.227272,
      confProc: -0.060454,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 342,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 241109.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 10,
      observations: 33,
      index: 0.364848,
      awards: 0.020909,
      books: -0.152424,
      grants: 0.300909,
      grantDol: 0.244242,
      articles: 0.035454,
      citations: 0.209393,
      confProc: 0.118181,
      articlesActual: 23,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 1028,
      confProcActual: 2,
      grantsActual: 1,
      grantDolActual: 426169.0,
      indexActual: 2.0,
    },
    {
      yearsSincePhD: 11,
      observations: 33,
      index: 0.412424,
      awards: -0.265454,
      books: -0.21,
      grants: 0.117878,
      grantDol: 0.178484,
      articles: 0.278181,
      citations: 0.287272,
      confProc: -0.113636,
      articlesActual: 28,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 1148,
      confProcActual: 1,
      grantsActual: 1,
      grantDolActual: 390729.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 12,
      observations: 30,
      index: 0.304666,
      awards: -0.242,
      books: -0.115,
      grants: 0.083,
      grantDol: 0.009,
      articles: 0.131333,
      citations: -0.080666,
      confProc: 0.189,
      articlesActual: 25,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 572,
      confProcActual: 2,
      grantsActual: 1,
      grantDolActual: 299994.0,
      indexActual: 2.0,
    },
    {
      yearsSincePhD: 13,
      observations: 27,
      index: 0.191851,
      awards: -0.192592,
      books: -0.174814,
      grants: 0.164444,
      grantDol: 0.168518,
      articles: -0.062592,
      citations: 0.128888,
      confProc: -0.083333,
      articlesActual: 20,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 900,
      confProcActual: 1,
      grantsActual: 1,
      grantDolActual: 385644.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 14,
      observations: 27,
      index: -0.045555,
      awards: -0.212962,
      books: -0.104074,
      grants: 0.162962,
      grantDol: 0.08,
      articles: -0.164444,
      citations: -0.082962,
      confProc: 0.557037,
      articlesActual: 18,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 568,
      confProcActual: 4,
      grantsActual: 1,
      grantDolActual: 337842.0,
      indexActual: 4.0,
    },
    {
      yearsSincePhD: 15,
      observations: 20,
      index: 0.1325,
      awards: -0.2235,
      books: 0.1235,
      grants: -0.153,
      grantDol: -0.129,
      articles: 0.0875,
      citations: 0.225,
      confProc: 0.5385,
      articlesActual: 24,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 1053,
      confProcActual: 4,
      grantsActual: 0,
      grantDolActual: 226254.0,
      indexActual: 4.0,
    },
    {
      yearsSincePhD: 16,
      observations: 21,
      index: 0.276666,
      awards: -0.103809,
      books: 0.016666,
      grants: -0.112857,
      grantDol: -0.177142,
      articles: 0.61238,
      citations: 0.104761,
      confProc: -0.035714,
      articlesActual: 36,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 864,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 199776.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 17,
      observations: 27,
      index: 0.408148,
      awards: 0.095925,
      books: -0.068888,
      grants: 0.438518,
      grantDol: 0.276666,
      articles: 0.438518,
      citations: 0.088518,
      confProc: -0.067407,
      articlesActual: 32,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 837,
      confProcActual: 1,
      grantsActual: 1,
      grantDolActual: 443234.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 18,
      observations: 31,
      index: 0.657741,
      awards: 0.107419,
      books: -0.087096,
      grants: 0.448387,
      grantDol: 0.428064,
      articles: 0.701612,
      citations: 0.58258,
      confProc: 0.058064,
      articlesActual: 38,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 1612,
      confProcActual: 1,
      grantsActual: 1,
      grantDolActual: 524845.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 19,
      observations: 20,
      index: 0.135,
      awards: 0.2765,
      books: 0.028,
      grants: 0.653,
      grantDol: 0.626,
      articles: 0.1325,
      citations: 0.0385,
      confProc: 0.9215,
      articlesActual: 25,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 759,
      confProcActual: 5,
      grantsActual: 1,
      grantDolActual: 631280.0,
      indexActual: 5.0,
    },
    {
      yearsSincePhD: 20,
      observations: 21,
      index: 0.015238,
      awards: -0.209047,
      books: -0.21,
      grants: -0.018095,
      grantDol: -0.142857,
      articles: 0.010476,
      citations: -0.155238,
      confProc: 0.140952,
      articlesActual: 22,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 453,
      confProcActual: 2,
      grantsActual: 0,
      grantDolActual: 218748.0,
      indexActual: 2.0,
    },
    {
      yearsSincePhD: 21,
      observations: 15,
      index: 0.063333,
      awards: -0.242,
      books: -0.146666,
      grants: -0.434666,
      grantDol: -0.406666,
      articles: -0.008,
      citations: 0.082,
      confProc: -0.213333,
      articlesActual: 22,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 827,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 77094.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 22,
      observations: 19,
      index: 0.361578,
      awards: 0.024736,
      books: -0.06,
      grants: -0.089473,
      grantDol: -0.037894,
      articles: 0.494736,
      citations: -0.072631,
      confProc: -0.197894,
      articlesActual: 33,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 582,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 274913.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 23,
      observations: 15,
      index: 0.239333,
      awards: 0.349333,
      books: -0.02,
      grants: 0.822666,
      grantDol: 0.576666,
      articles: 0.018,
      citations: -0.08,
      confProc: -0.125333,
      articlesActual: 22,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 574,
      confProcActual: 1,
      grantsActual: 2,
      grantDolActual: 605302.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 24,
      observations: 14,
      index: 0.489285,
      awards: 0.65,
      books: 0.130714,
      grants: 0.734285,
      grantDol: 0.606428,
      articles: 0.544285,
      citations: 0.221428,
      confProc: -0.108571,
      articlesActual: 34,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 1047,
      confProcActual: 1,
      grantsActual: 2,
      grantDolActual: 620057.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 25,
      observations: 8,
      index: 0.27125,
      awards: 0.125,
      books: 0.385,
      grants: -0.49125,
      grantDol: -0.44125,
      articles: 0.41,
      citations: 0.07,
      confProc: -0.085,
      articlesActual: 31,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 806,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 58444.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 26,
      observations: 9,
      index: -0.321111,
      awards: 0.116666,
      books: -0.104444,
      grants: -0.285555,
      grantDol: -0.181111,
      articles: -0.05,
      citations: -0.27,
      confProc: -0.262222,
      articlesActual: 21,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 274,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 197955.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 27,
      observations: 11,
      index: 0.317272,
      awards: -0.047272,
      books: -0.123636,
      grants: -0.23,
      grantDol: -0.288181,
      articles: 0.50909,
      citations: 0.104545,
      confProc: -0.14,
      articlesActual: 34,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 864,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 140708.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 28,
      observations: 15,
      index: -0.561333,
      awards: -0.204666,
      books: -0.21,
      grants: -0.164666,
      grantDol: -0.264,
      articles: -0.470666,
      citations: -0.327333,
      confProc: -0.301333,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 185,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 153219.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 29,
      observations: 14,
      index: -0.024285,
      awards: 0.095,
      books: 0.13,
      grants: -0.033571,
      grantDol: -0.117857,
      articles: 0.201428,
      citations: -0.104285,
      confProc: -0.140714,
      articlesActual: 27,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 535,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 231910.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 30,
      observations: 16,
      index: -0.260625,
      awards: 0.123125,
      books: -0.09125,
      grants: 0.099375,
      grantDol: 0.368125,
      articles: -0.081875,
      citations: -0.171875,
      confProc: -0.25,
      articlesActual: 20,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 429,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 492580.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 31,
      observations: 7,
      index: -0.254285,
      awards: -0.262857,
      books: 0.061428,
      grants: -0.084285,
      grantDol: 0.011428,
      articles: -0.188571,
      citations: -0.087142,
      confProc: -0.328571,
      articlesActual: 18,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 560,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 300805.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 32,
      observations: 14,
      index: 0.121428,
      awards: 0.213571,
      books: 0.47,
      grants: 0.207857,
      grantDol: 0.252142,
      articles: 0.066428,
      citations: -0.085714,
      confProc: -0.281428,
      articlesActual: 23,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 564,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 430412.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 33,
      observations: 5,
      index: 0.416,
      awards: 0.166,
      books: -0.02,
      grants: 0.42,
      grantDol: 0.312,
      articles: 0.198,
      citations: 0.042,
      confProc: -0.316,
      articlesActual: 27,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 766,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 461696.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 34,
      observations: 16,
      index: 0.00875,
      awards: 0.610625,
      books: 2.17,
      grants: -0.068125,
      grantDol: -0.0525,
      articles: -0.056875,
      citations: -0.126875,
      confProc: 0.065,
      articlesActual: 21,
      awardsActual: 2,
      booksActual: 2,
      citationsActual: 499,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 267451.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 35,
      observations: 8,
      index: -0.22,
      awards: -0.015,
      books: 0.14625,
      grants: -0.32125,
      grantDol: -0.37875,
      articles: -0.0875,
      citations: -0.22,
      confProc: -0.195,
      articlesActual: 20,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 354,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 91994.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 36,
      observations: 3,
      index: -0.836666,
      awards: -0.5,
      books: -0.21,
      grants: 0.013333,
      grantDol: 0.16,
      articles: -0.783333,
      citations: -0.34,
      confProc: -0.36,
      articlesActual: 4,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 166,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 381566.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 37,
      observations: 9,
      index: -0.307777,
      awards: 1.166666,
      books: -0.104444,
      grants: -0.134444,
      grantDol: -0.013333,
      articles: -0.283333,
      citations: 0.703333,
      confProc: -0.115555,
      articlesActual: 15,
      awardsActual: 3,
      booksActual: 0,
      citationsActual: 1799,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 287673.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 38,
      observations: 5,
      index: -0.664,
      awards: 0.164,
      books: 0.552,
      grants: -0.252,
      grantDol: -0.334,
      articles: -0.482,
      citations: -0.354,
      confProc: -0.008,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 141,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 115706.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 39,
      observations: 4,
      index: 0.2225,
      awards: 1.165,
      books: 0.505,
      grants: 0.35,
      grantDol: 0.405,
      articles: 0.0175,
      citations: 1.135,
      confProc: -0.36,
      articlesActual: 22,
      awardsActual: 3,
      booksActual: 0,
      citationsActual: 2476,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 511793.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 40,
      observations: 8,
      index: -0.22875,
      awards: 0.39875,
      books: -0.09125,
      grants: -0.32375,
      grantDol: -0.19125,
      articles: -0.07625,
      citations: -0.2125,
      confProc: -0.2775,
      articlesActual: 20,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 365,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 192048.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 41,
      observations: 3,
      index: -0.353333,
      awards: 0.24,
      books: 0.106666,
      grants: 1.133333,
      grantDol: 2.96,
      articles: -0.336666,
      citations: -0.35,
      confProc: -0.36,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 145,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 1881256.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 42,
      observations: 6,
      index: -1.018333,
      awards: -0.408333,
      books: -0.051666,
      grants: -0.323333,
      grantDol: -0.146666,
      articles: -0.638333,
      citations: -0.24,
      confProc: -0.213333,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 326,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 216100.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 43,
      observations: 2,
      index: -1.175,
      awards: 0.335,
      books: -0.21,
      grants: -0.66,
      grantDol: -0.55,
      articles: -0.58,
      citations: -0.435,
      confProc: -0.36,
      articlesActual: 9,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 17,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 44,
      observations: 6,
      index: 0.225,
      awards: 0.146666,
      books: -0.051666,
      grants: 0.351666,
      grantDol: 0.636666,
      articles: -0.153333,
      citations: -0.156666,
      confProc: -0.323333,
      articlesActual: 18,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 453,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 636774.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 45,
      observations: 1,
      index: 2.15,
      awards: 5.06,
      books: -0.21,
      grants: 1.36,
      grantDol: 1.02,
      articles: 5.1,
      citations: 2.35,
      confProc: -0.14,
      articlesActual: 140,
      awardsActual: 10,
      booksActual: 0,
      citationsActual: 4388,
      confProcActual: 1,
      grantsActual: 3,
      grantDolActual: 843055.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 46,
      observations: 3,
      index: -0.466666,
      awards: 1.91,
      books: -0.21,
      grants: -0.433333,
      grantDol: -0.07,
      articles: -0.536666,
      citations: -0.276666,
      confProc: 0.223333,
      articlesActual: 10,
      awardsActual: 4,
      booksActual: 0,
      citationsActual: 264,
      confProcActual: 2,
      grantsActual: 0,
      grantDolActual: 257179.0,
      indexActual: 2.0,
    },
    {
      yearsSincePhD: 47,
      observations: 4,
      index: 0.325,
      awards: 1.5825,
      books: 0.505,
      grants: 0.18,
      grantDol: 0.465,
      articles: 0.015,
      citations: 0.05,
      confProc: -0.36,
      articlesActual: 22,
      awardsActual: 3,
      booksActual: 0,
      citationsActual: 780,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 544328.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 48,
      observations: 2,
      index: -0.855,
      awards: 0.61,
      books: 1.22,
      grants: -0.32,
      grantDol: -0.04,
      articles: -0.665,
      citations: -0.395,
      confProc: -0.36,
      articlesActual: 7,
      awardsActual: 2,
      booksActual: 1,
      citationsActual: 78,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 272443.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 49,
      observations: 3,
      index: -0.55,
      awards: 1.166666,
      books: 0.106666,
      grants: -0.66,
      grantDol: -0.55,
      articles: -0.19,
      citations: -0.236666,
      confProc: -0.36,
      articlesActual: 18,
      awardsActual: 3,
      booksActual: 0,
      citationsActual: 329,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 50,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 51,
      observations: 1,
      index: 1.61,
      awards: 5.62,
      books: -0.21,
      grants: 1.36,
      grantDol: 0.84,
      articles: 0.63,
      citations: 0.21,
      confProc: -0.14,
      articlesActual: 37,
      awardsActual: 11,
      booksActual: 0,
      citationsActual: 1025,
      confProcActual: 1,
      grantsActual: 3,
      grantDolActual: 745889.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 52,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 53,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 54,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 55,
      observations: 1,
      index: 0.82,
      awards: 5.06,
      books: -0.21,
      grants: 0.02,
      grantDol: 0.32,
      articles: -0.02,
      citations: -0.17,
      confProc: 0.96,
      articlesActual: 22,
      awardsActual: 10,
      booksActual: 0,
      citationsActual: 436,
      confProcActual: 6,
      grantsActual: 1,
      grantDolActual: 465003.0,
      indexActual: 6.0,
    },
  ],
  facultyDataPoints: [
    {
      facultyId: 887218,
      name: 'ROTHWELL, CLAYTON',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.6,
      confProc: 0.5,
      yearsSincePhD: 1,
      index: -1.3,
      articlesActual: 9.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 15.0,
      confProcActual: 4.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887219,
      name: 'ZHANG, PENGYUE',
      rank: 9,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.4,
      confProc: -0.4,
      yearsSincePhD: 3,
      index: -0.7,
      articlesActual: 14.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 86.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 1088544,
      name: 'RAHURKAR, SAURABH ARUN',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.8,
      confProc: -0.4,
      yearsSincePhD: 4,
      index: -1.0,
      articlesActual: 5.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 150.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887214,
      name: 'KLINE, DAVID MICHAEL',
      rank: 3,
      awards: 0.0,
      books: -0.2,
      citations: -0.4,
      grants: 0.0,
      grantDol: -0.1,
      articles: 0.3,
      confProc: -0.4,
      yearsSincePhD: 4,
      index: 0.2,
      articlesActual: 30.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 119.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 240273.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887213,
      name: 'BAI, SHASHA',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: -0.7,
      grantDol: -0.6,
      articles: 0.8,
      confProc: -0.1,
      yearsSincePhD: 5,
      index: 0.4,
      articlesActual: 41.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 317.0,
      confProcActual: 1.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 626658,
      name: 'FAREED, NALEEF',
      rank: 3,
      awards: 0.0,
      books: -0.2,
      citations: -0.4,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.6,
      confProc: 0.1,
      yearsSincePhD: 6,
      index: -0.8,
      articlesActual: 8.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 121.0,
      confProcActual: 2.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 760635,
      name: 'NING, XIA',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: 0.7,
      grantDol: -0.4,
      articles: -0.7,
      confProc: 1.8,
      yearsSincePhD: 7,
      index: -1.0,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 37.0,
      confProcActual: 10.0,
      grantsActual: 2.0,
      grantDolActual: 97709.0,
      indexActual: 0.0,
    },
    {
      facultyId: 779948,
      name: 'ZHANG, YAN',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 3.7,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.6,
      confProc: 0.1,
      yearsSincePhD: 7,
      index: 0.2,
      articlesActual: 9.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 6463.0,
      confProcActual: 2.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 855670,
      name: 'CHENG, LIJUN',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.1,
      confProc: -0.4,
      yearsSincePhD: 7,
      index: 0.0,
      articlesActual: 21.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 305.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887220,
      name: 'ZHANG, PING',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.7,
      confProc: 2.3,
      yearsSincePhD: 7,
      index: -0.7,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 266.0,
      confProcActual: 12.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 515906,
      name: 'NANDI, ARNAB',
      rank: 2,
      awards: 1.2,
      books: -0.2,
      citations: -0.4,
      grants: 2.0,
      grantDol: 0.3,
      articles: -0.7,
      confProc: 3.8,
      yearsSincePhD: 8,
      index: -0.4,
      articlesActual: 7.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 99.0,
      confProcActual: 19.0,
      grantsActual: 4.0,
      grantDolActual: 468376.0,
      indexActual: 0.0,
    },
    {
      facultyId: 757318,
      name: 'MA, QIN',
      rank: 2,
      awards: 0.0,
      books: -0.2,
      citations: -0.1,
      grants: 0.0,
      grantDol: 0.0,
      articles: 1.7,
      confProc: -0.4,
      yearsSincePhD: 9,
      index: 1.2,
      articlesActual: 61.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 531.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 286267.0,
      indexActual: 0.0,
    },
    {
      facultyId: 855672,
      name: 'GREGORY, MEGAN',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.4,
      confProc: -0.4,
      yearsSincePhD: 9,
      index: -0.5,
      articlesActual: 12.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 218.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 789663,
      name: 'AU, KIN FAI',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: 0.4,
      grants: 0.0,
      grantDol: 0.2,
      articles: -0.3,
      confProc: -0.4,
      yearsSincePhD: 10,
      index: 0.8,
      articlesActual: 16.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 1372.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 405044.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887216,
      name: 'OZER, HATICE GULCIN',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.4,
      confProc: -0.1,
      yearsSincePhD: 11,
      index: -0.2,
      articlesActual: 12.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 396.0,
      confProcActual: 1.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887217,
      name: 'PIETRZAK, MACIEJ',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.4,
      confProc: -0.1,
      yearsSincePhD: 11,
      index: -0.6,
      articlesActual: 12.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 173.0,
      confProcActual: 1.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638069,
      name: 'WEI, LAI',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 0.0,
      grants: -0.7,
      grantDol: -0.6,
      articles: 1.5,
      confProc: 0.1,
      yearsSincePhD: 11,
      index: 0.8,
      articlesActual: 58.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 686.0,
      confProcActual: 2.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 769947,
      name: 'OAKES, CHRISTOPHER CHARLES',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 0.1,
      grants: -0.7,
      grantDol: -0.6,
      articles: 0.5,
      confProc: 0.1,
      yearsSincePhD: 12,
      index: 0.7,
      articlesActual: 34.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 788.0,
      confProcActual: 2.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638429,
      name: 'BLACHLY, JAMES STEWART',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 0.0,
      grants: -0.7,
      grantDol: -0.6,
      articles: 1.1,
      confProc: 0.3,
      yearsSincePhD: 12,
      index: 0.8,
      articlesActual: 48.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 780.0,
      confProcActual: 3.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 769747,
      name: 'MATHE, EWY AUDREY',
      rank: 3,
      awards: -0.5,
      books: 0.7,
      citations: -0.3,
      grants: 0.0,
      grantDol: -0.4,
      articles: -0.2,
      confProc: 0.1,
      yearsSincePhD: 13,
      index: 0.1,
      articlesActual: 17.0,
      awardsActual: 0.0,
      booksActual: 1.0,
      citationsActual: 294.0,
      confProcActual: 2.0,
      grantsActual: 1.0,
      grantDolActual: 80956.0,
      indexActual: 0.0,
    },
    {
      facultyId: 1088546,
      name: 'ZHANG, XIAOLI',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.8,
      confProc: -0.4,
      yearsSincePhD: 13,
      index: -1.0,
      articlesActual: 5.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 149.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 379187,
      name: 'YU, LIANBO',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 0.3,
      grants: -0.7,
      grantDol: -0.6,
      articles: 1.5,
      confProc: 0.3,
      yearsSincePhD: 13,
      index: 1.0,
      articlesActual: 56.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 1109.0,
      confProcActual: 3.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638068,
      name: 'HEBERT, COURTNEY L',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: 0.7,
      grantDol: 1.1,
      articles: -0.5,
      confProc: -0.1,
      yearsSincePhD: 14,
      index: -0.2,
      articlesActual: 11.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 129.0,
      confProcActual: 1.0,
      grantsActual: 2.0,
      grantDolActual: 892818.0,
      indexActual: 0.0,
    },
    {
      facultyId: 365953,
      name: 'WIENER, JUDITH ANN',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.9,
      confProc: -0.4,
      yearsSincePhD: 14,
      index: -1.8,
      articlesActual: 1.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 0.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 585527,
      name: 'GARVIN, JENNIFER',
      rank: 2,
      awards: 0.0,
      books: -0.2,
      citations: -0.4,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.3,
      confProc: -0.1,
      yearsSincePhD: 15,
      index: -0.6,
      articlesActual: 16.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 58.0,
      confProcActual: 1.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 13903,
      name: 'BROCK, GUY',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: 0.0,
      grantDol: -0.4,
      articles: 1.1,
      confProc: -0.4,
      yearsSincePhD: 16,
      index: 0.8,
      articlesActual: 48.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 390.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 84219.0,
      indexActual: 0.0,
    },
    {
      facultyId: 333871,
      name: 'HUERTA, TIMOTHY',
      rank: 1,
      awards: 1.2,
      books: 0.7,
      citations: -0.2,
      grants: -0.7,
      grantDol: -0.6,
      articles: 0.9,
      confProc: 0.3,
      yearsSincePhD: 16,
      index: 0.7,
      articlesActual: 44.0,
      awardsActual: 3.0,
      booksActual: 1.0,
      citationsActual: 422.0,
      confProcActual: 3.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 13110,
      name: 'BRIDGES, JOHN F P',
      rank: 1,
      awards: 0.6,
      books: -0.2,
      citations: 0.2,
      grants: -0.7,
      grantDol: -0.6,
      articles: 3.0,
      confProc: -0.4,
      yearsSincePhD: 17,
      index: 1.2,
      articlesActual: 91.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 1081.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 379156,
      name: 'HADE, ERINN MELISSA',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: -0.7,
      grantDol: -0.6,
      articles: 1.4,
      confProc: -0.4,
      yearsSincePhD: 17,
      index: 0.5,
      articlesActual: 54.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 343.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 1088543,
      name: 'MO, XIAOKUI',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 0.4,
      grants: -0.7,
      grantDol: -0.6,
      articles: 3.1,
      confProc: 0.1,
      yearsSincePhD: 17,
      index: 1.2,
      articlesActual: 93.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 1360.0,
      confProcActual: 2.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 301709,
      name: 'FERNANDEZ, SOLEDAD A',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: -0.7,
      grantDol: -0.6,
      articles: 0.2,
      confProc: -0.4,
      yearsSincePhD: 18,
      index: 0.2,
      articlesActual: 27.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 343.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 495140,
      name: 'LI, LANG',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.1,
      grants: 0.7,
      grantDol: 0.8,
      articles: 1.1,
      confProc: 1.0,
      yearsSincePhD: 18,
      index: 1.2,
      articlesActual: 47.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 537.0,
      confProcActual: 6.0,
      grantsActual: 2.0,
      grantDolActual: 750980.0,
      indexActual: 0.0,
    },
    {
      facultyId: 378890,
      name: 'MOFFATT-BRUCE, SUSAN D',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: -0.7,
      grantDol: -0.6,
      articles: 3.3,
      confProc: -0.4,
      yearsSincePhD: 18,
      index: 0.8,
      articlesActual: 98.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 447.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 115102,
      name: 'PARTHASARATHY, SRINIVASAN',
      rank: 1,
      awards: 0.6,
      books: -0.2,
      citations: -0.3,
      grants: 2.7,
      grantDol: 0.7,
      articles: -0.5,
      confProc: 6.9,
      yearsSincePhD: 19,
      index: 0.2,
      articlesActual: 11.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 223.0,
      confProcActual: 33.0,
      grantsActual: 5.0,
      grantDolActual: 681210.0,
      indexActual: 0.0,
    },
    {
      facultyId: 142493,
      name: 'FOSLER-LUSSIER, JOHN ERIC',
      rank: 1,
      awards: 0.6,
      books: -0.2,
      citations: -0.4,
      grants: 0.7,
      grantDol: -0.1,
      articles: -0.8,
      confProc: 2.9,
      yearsSincePhD: 20,
      index: -0.8,
      articlesActual: 3.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 79.0,
      confProcActual: 15.0,
      grantsActual: 2.0,
      grantDolActual: 259873.0,
      indexActual: 0.0,
    },
    {
      facultyId: 150964,
      name: 'KNOPP, MICHAEL',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.1,
      grants: 0.0,
      grantDol: -0.1,
      articles: 1.2,
      confProc: -0.1,
      yearsSincePhD: 22,
      index: 1.0,
      articlesActual: 51.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 567.0,
      confProcActual: 1.0,
      grantsActual: 1.0,
      grantDolActual: 241560.0,
      indexActual: 0.0,
    },
    {
      facultyId: 301712,
      name: 'FREITAS, MICHAEL ALAN',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: 0.7,
      grantDol: 1.1,
      articles: -0.2,
      confProc: -0.1,
      yearsSincePhD: 22,
      index: 0.4,
      articlesActual: 19.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 220.0,
      confProcActual: 1.0,
      grantsActual: 2.0,
      grantDolActual: 891192.0,
      indexActual: 0.0,
    },
    {
      facultyId: 95979,
      name: 'MACHIRAJU, RAGHU',
      rank: 1,
      awards: 1.7,
      books: -0.2,
      citations: -0.4,
      grants: 2.7,
      grantDol: 0.7,
      articles: -0.6,
      confProc: 0.7,
      yearsSincePhD: 23,
      index: 0.0,
      articlesActual: 9.0,
      awardsActual: 4.0,
      booksActual: 0.0,
      citationsActual: 135.0,
      confProcActual: 5.0,
      grantsActual: 5.0,
      grantDolActual: 672677.0,
      indexActual: 0.0,
    },
    {
      facultyId: 95805,
      name: 'RAMAN, SUBHA VENKAT',
      rank: 1,
      awards: 0.0,
      books: 0.7,
      citations: 0.1,
      grants: 0.7,
      grantDol: 1.0,
      articles: 1.5,
      confProc: -0.1,
      yearsSincePhD: 23,
      index: 1.6,
      articlesActual: 57.0,
      awardsActual: 1.0,
      booksActual: 1.0,
      citationsActual: 897.0,
      confProcActual: 1.0,
      grantsActual: 2.0,
      grantDolActual: 849765.0,
      indexActual: 0.0,
    },
    {
      facultyId: 67874,
      name: 'MCALEARNEY, ANN SCHECK',
      rank: 1,
      awards: 0.6,
      books: 2.6,
      citations: -0.2,
      grants: 1.4,
      grantDol: 0.9,
      articles: 1.2,
      confProc: 0.7,
      yearsSincePhD: 24,
      index: 1.4,
      articlesActual: 51.0,
      awardsActual: 2.0,
      booksActual: 3.0,
      citationsActual: 429.0,
      confProcActual: 5.0,
      grantsActual: 3.0,
      grantDolActual: 791927.0,
      indexActual: 0.0,
    },
    {
      facultyId: 373056,
      name: 'WIET, GREGORY JAMES',
      rank: 1,
      awards: 0.0,
      books: -0.2,
      citations: -0.4,
      grants: 0.0,
      grantDol: 0.2,
      articles: -0.4,
      confProc: -0.4,
      yearsSincePhD: 30,
      index: -0.4,
      articlesActual: 12.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 75.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 382237.0,
      indexActual: 0.0,
    },
    {
      facultyId: 379130,
      name: 'PARVIN, JEFFREY',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: 1.4,
      grantDol: 1.3,
      articles: -0.3,
      confProc: -0.4,
      yearsSincePhD: 32,
      index: 0.6,
      articlesActual: 15.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 431.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 1013558.0,
      indexActual: 0.0,
    },
    {
      facultyId: 22435,
      name: 'COOMBES, KEVIN R',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: 0.2,
      grants: 0.0,
      grantDol: -0.4,
      articles: 0.9,
      confProc: -0.4,
      yearsSincePhD: 33,
      index: 1.1,
      articlesActual: 43.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 985.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 81391.0,
      indexActual: 0.0,
    },
    {
      facultyId: 365945,
      name: 'SCHNELL, ERIC H',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.7,
      grantDol: -0.6,
      articles: -1.0,
      confProc: -0.4,
      yearsSincePhD: 37,
      index: -1.8,
      articlesActual: 0.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 0.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 489875,
      name: 'RETCHIN, SHELDON M',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.4,
      confProc: -0.4,
      yearsSincePhD: 43,
      index: -1.0,
      articlesActual: 13.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 20.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 365892,
      name: 'CHEEK, FERN MITCHELL',
      rank: 2,
      awards: 0.0,
      books: -0.2,
      citations: -0.4,
      grants: -0.7,
      grantDol: -0.6,
      articles: -0.8,
      confProc: -0.4,
      yearsSincePhD: 44,
      index: -1.2,
      articlesActual: 3.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 96.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  unit: {
    indexScore: 0.0,
  },
};
export default data;
