const data = {
  maxObservations: 54,
  dataPoints: [
    {
      yearsSincePhD: 0,
      observations: 6,
      index: -0.071666,
      awards: -0.136666,
      books: -0.17,
      grants: -0.183333,
      grantDol: -0.28,
      articles: -0.035,
      citations: -0.345,
      confProc: -0.11,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 19,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 6661.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 1,
      observations: 4,
      index: -0.33,
      awards: 0.015,
      books: -0.17,
      grants: -0.36,
      grantDol: -0.3,
      articles: -0.2175,
      citations: -0.295,
      confProc: -0.11,
      articlesActual: 7,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 38,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 2,
      observations: 14,
      index: -0.762142,
      awards: -0.375,
      books: -0.17,
      grants: -0.36,
      grantDol: -0.3,
      articles: -0.500714,
      citations: -0.331428,
      confProc: -0.076428,
      articlesActual: 3,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 23,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 3,
      observations: 27,
      index: -0.615185,
      awards: -0.44,
      books: -0.17,
      grants: -0.36,
      grantDol: -0.3,
      articles: -0.456296,
      citations: -0.33074,
      confProc: -0.092592,
      articlesActual: 4,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 24,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 4,
      observations: 39,
      index: -0.355641,
      awards: -0.37,
      books: -0.142051,
      grants: -0.278461,
      grantDol: -0.259743,
      articles: -0.319743,
      citations: -0.115128,
      confProc: -0.097948,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 110,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 12672.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 5,
      observations: 43,
      index: -0.095813,
      awards: -0.376511,
      books: -0.144651,
      grants: -0.310697,
      grantDol: -0.285116,
      articles: 0.00372,
      citations: -0.161162,
      confProc: 0.021162,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 92,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 4724.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 6,
      observations: 47,
      index: -0.030638,
      awards: -0.227021,
      books: -0.146808,
      grants: -0.314893,
      grantDol: -0.238085,
      articles: 0.005106,
      citations: -0.123404,
      confProc: -0.1,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 106,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 19441.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 7,
      observations: 52,
      index: 0.209807,
      awards: -0.335192,
      books: -0.149038,
      grants: -0.278269,
      grantDol: -0.216923,
      articles: 0.197115,
      citations: -0.060384,
      confProc: -0.019615,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 132,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 26082.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 8,
      observations: 47,
      index: 0.035744,
      awards: -0.169148,
      books: -0.077234,
      grants: -0.179148,
      grantDol: -0.127659,
      articles: -0.021914,
      citations: 0.157021,
      confProc: -0.11,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 219,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 54051.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 9,
      observations: 40,
      index: 0.2325,
      awards: -0.03075,
      books: -0.1155,
      grants: 0.01175,
      grantDol: 0.09525,
      articles: -0.077,
      citations: -0.02775,
      confProc: -0.07475,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 145,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 123908.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 10,
      observations: 54,
      index: -0.082777,
      awards: -0.271481,
      books: -0.17,
      grants: -0.123888,
      grantDol: -0.103518,
      articles: -0.124444,
      citations: -0.159814,
      confProc: -0.101296,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 92,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 61606.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 11,
      observations: 45,
      index: 0.009777,
      awards: -0.177111,
      books: -0.121333,
      grants: -0.076666,
      grantDol: -0.085777,
      articles: -0.146,
      citations: -0.085333,
      confProc: -0.068222,
      articlesActual: 8,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 122,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 67348.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 12,
      observations: 51,
      index: 0.269019,
      awards: -0.03,
      books: -0.10549,
      grants: -0.089215,
      grantDol: -0.071372,
      articles: 0.163921,
      citations: 0.070196,
      confProc: 0.046666,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 184,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 71730.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 13,
      observations: 38,
      index: 0.16,
      awards: -0.105,
      books: -0.141315,
      grants: 0.003684,
      grantDol: -0.073421,
      articles: 0.034736,
      citations: -0.025,
      confProc: -0.011052,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 146,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 71230.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 14,
      observations: 43,
      index: 0.38372,
      awards: -0.080232,
      books: -0.093953,
      grants: 0.356744,
      grantDol: 0.295348,
      articles: 0.23186,
      citations: 0.020697,
      confProc: -0.011627,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 164,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 186649.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 15,
      observations: 42,
      index: 0.325,
      awards: 0.187619,
      books: -0.039761,
      grants: 0.424761,
      grantDol: 0.269047,
      articles: 0.331666,
      citations: 0.34619,
      confProc: -0.042857,
      articlesActual: 15,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 295,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 178343.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 16,
      observations: 39,
      index: 0.011794,
      awards: -0.16,
      books: -0.114102,
      grants: 0.021794,
      grantDol: -0.029743,
      articles: -0.074358,
      citations: -0.057692,
      confProc: -0.049743,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 133,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 84807.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 17,
      observations: 43,
      index: 0.304418,
      awards: -0.080465,
      books: 0.084418,
      grants: 0.18372,
      grantDol: 0.084186,
      articles: 0.243255,
      citations: 0.127441,
      confProc: -0.033488,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 207,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 120536.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 18,
      observations: 48,
      index: 0.281458,
      awards: -0.022916,
      books: 0.035416,
      grants: 0.348541,
      grantDol: 0.299583,
      articles: 0.568958,
      citations: 0.144583,
      confProc: 0.554166,
      articlesActual: 19,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 214,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 187902.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 19,
      observations: 30,
      index: 0.114,
      awards: 0.166,
      books: -0.060666,
      grants: 0.242333,
      grantDol: 0.224333,
      articles: 0.127,
      citations: 0.040333,
      confProc: -0.047333,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 172,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 164254.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 20,
      observations: 36,
      index: 0.144722,
      awards: 0.065277,
      books: 0.073055,
      grants: 0.053333,
      grantDol: -0.016944,
      articles: -0.040277,
      citations: -0.125833,
      confProc: -0.070833,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 106,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 88981.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 21,
      observations: 39,
      index: -0.026153,
      awards: -0.136923,
      books: -0.085897,
      grants: 0.239743,
      grantDol: 0.066923,
      articles: -0.096666,
      citations: 0.215641,
      confProc: -0.073846,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 242,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 115152.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 22,
      observations: 36,
      index: 0.235555,
      awards: 0.115833,
      books: -0.018333,
      grants: 0.053333,
      grantDol: 0.021666,
      articles: 0.078055,
      citations: 0.010833,
      confProc: 0.020555,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 160,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 100836.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 23,
      observations: 40,
      index: 0.1615,
      awards: 0.151,
      books: 0.131,
      grants: 0.1975,
      grantDol: 0.1805,
      articles: 0.0565,
      citations: 0.0915,
      confProc: -0.05125,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 193,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 150545.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 24,
      observations: 21,
      index: 0.18619,
      awards: 0.03619,
      books: -0.065714,
      grants: 0.298095,
      grantDol: 0.279047,
      articles: 0.069047,
      citations: 0.138095,
      confProc: -0.020476,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 212,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 181597.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 25,
      observations: 30,
      index: 0.245666,
      awards: 0.347333,
      books: 0.012,
      grants: 0.171,
      grantDol: 0.097,
      articles: 0.260333,
      citations: 0.185333,
      confProc: -0.11,
      articlesActual: 14,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 230,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 124511.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 26,
      observations: 34,
      index: 0.181176,
      awards: 0.227941,
      books: 0.377941,
      grants: 0.109117,
      grantDol: 0.136764,
      articles: 0.154411,
      citations: 0.108235,
      confProc: 0.78647,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 200,
      confProcActual: 1,
      grantsActual: 0,
      grantDolActual: 136975.0,
      indexActual: 1.0,
    },
    {
      yearsSincePhD: 27,
      observations: 26,
      index: -0.01423,
      awards: -0.02,
      books: -0.17,
      grants: -0.033076,
      grantDol: -0.015,
      articles: -0.042692,
      citations: -0.026153,
      confProc: 0.03423,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 146,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 89385.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 28,
      observations: 24,
      index: 0.002083,
      awards: 0.0525,
      books: -0.124583,
      grants: 0.127083,
      grantDol: 0.10875,
      articles: -0.104583,
      citations: -0.085,
      confProc: 0.04625,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 122,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 128141.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 29,
      observations: 22,
      index: 0.316363,
      awards: 1.25409,
      books: 0.177727,
      grants: 0.123181,
      grantDol: 0.161363,
      articles: 0.031818,
      citations: 0.252727,
      confProc: -0.088636,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 257,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 144678.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 30,
      observations: 24,
      index: 0.613333,
      awards: 0.582916,
      books: 0.9725,
      grants: 0.48125,
      grantDol: 0.36125,
      articles: 0.728333,
      citations: 0.50125,
      confProc: -0.070833,
      articlesActual: 21,
      awardsActual: 1,
      booksActual: 1,
      citationsActual: 357,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 207235.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 31,
      observations: 22,
      index: -0.095454,
      awards: 0.345,
      books: 0.128636,
      grants: -0.021818,
      grantDol: -0.089545,
      articles: -0.075,
      citations: 0.016363,
      confProc: -0.088636,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 163,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 66057.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 32,
      observations: 19,
      index: 0.410526,
      awards: 0.898947,
      books: 0.117894,
      grants: 1.036842,
      grantDol: 1.241578,
      articles: 0.499473,
      citations: 0.4,
      confProc: 0.112105,
      articlesActual: 18,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 316,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 482641.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 33,
      observations: 19,
      index: 0.367894,
      awards: 0.277894,
      books: 0.117368,
      grants: 0.255263,
      grantDol: 0.188947,
      articles: 0.390526,
      citations: 0.29,
      confProc: 0.013684,
      articlesActual: 16,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 272,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 153344.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 34,
      observations: 21,
      index: -0.303333,
      awards: -0.266666,
      books: 0.038571,
      grants: -0.056666,
      grantDol: -0.111428,
      articles: -0.265238,
      citations: -0.210952,
      confProc: 0.001904,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 72,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 59071.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 35,
      observations: 29,
      index: 0.176896,
      awards: 0.468275,
      books: 0.434827,
      grants: 0.153793,
      grantDol: 0.23,
      articles: 0.106551,
      citations: 0.227586,
      confProc: -0.061379,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 247,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 166054.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 36,
      observations: 18,
      index: -0.125555,
      awards: 0.519444,
      books: -0.109444,
      grants: 0.348333,
      grantDol: 0.363333,
      articles: 0.001666,
      citations: 0.558333,
      confProc: -0.11,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 380,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 208070.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 37,
      observations: 14,
      index: -0.036428,
      awards: 0.662857,
      books: 0.533571,
      grants: 0.246428,
      grantDol: 0.327142,
      articles: -0.100714,
      citations: -0.139285,
      confProc: -0.11,
      articlesActual: 9,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 101,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 196556.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 38,
      observations: 10,
      index: 0.699,
      awards: 1.649,
      books: 0.376,
      grants: 0.597,
      grantDol: 0.807,
      articles: 0.829,
      citations: 0.956,
      confProc: -0.063,
      articlesActual: 22,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 539,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 347157.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 39,
      observations: 18,
      index: 0.262222,
      awards: 0.418333,
      books: 0.134444,
      grants: 0.111666,
      grantDol: 0.017777,
      articles: 0.050555,
      citations: -0.033888,
      confProc: -0.057777,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 143,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 99750.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 40,
      observations: 15,
      index: -0.268666,
      awards: 0.468666,
      books: -0.024,
      grants: -0.289333,
      grantDol: -0.289333,
      articles: -0.08,
      citations: 0.056,
      confProc: -0.11,
      articlesActual: 9,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 178,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 3333.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 41,
      observations: 12,
      index: -0.31,
      awards: 0.014166,
      books: 0.103333,
      grants: -0.094166,
      grantDol: 0.0575,
      articles: -0.116666,
      citations: -0.15,
      confProc: 0.124166,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 96,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 111992.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 42,
      observations: 9,
      index: -0.424444,
      awards: 0.165555,
      books: 1.781111,
      grants: -0.36,
      grantDol: -0.3,
      articles: -0.457777,
      citations: -0.173333,
      confProc: -0.11,
      articlesActual: 4,
      awardsActual: 0,
      booksActual: 1,
      citationsActual: 87,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 43,
      observations: 3,
      index: -1.156666,
      awards: -0.136666,
      books: -0.17,
      grants: -0.36,
      grantDol: -0.3,
      articles: -0.706666,
      citations: -0.386666,
      confProc: -0.11,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 2,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 44,
      observations: 4,
      index: 0.0675,
      awards: -0.2125,
      books: 0.65,
      grants: 0.4375,
      grantDol: 0.51,
      articles: -0.0625,
      citations: -0.2075,
      confProc: 1.885,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 74,
      confProcActual: 4,
      grantsActual: 0,
      grantDolActual: 254138.0,
      indexActual: 4.0,
    },
    {
      yearsSincePhD: 45,
      observations: 6,
      index: -0.106666,
      awards: 0.468333,
      books: 0.195,
      grants: 0.171666,
      grantDol: 0.196666,
      articles: 0.211666,
      citations: 0.92,
      confProc: 0.281666,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 525,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 155686.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 46,
      observations: 6,
      index: 0.013333,
      awards: 0.015,
      books: -0.17,
      grants: -0.183333,
      grantDol: 0.198333,
      articles: -0.07,
      citations: 0.065,
      confProc: -0.11,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 182,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 155976.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 47,
      observations: 6,
      index: -0.551666,
      awards: 0.468333,
      books: -0.17,
      grants: -0.005,
      grantDol: 1.158333,
      articles: -0.365,
      citations: -0.258333,
      confProc: -0.11,
      articlesActual: 5,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 52,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 456568.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 48,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 49,
      observations: 1,
      index: 1.82,
      awards: 3.19,
      books: -0.17,
      grants: 0.7,
      grantDol: 0.55,
      articles: 3.22,
      citations: 1.09,
      confProc: -0.11,
      articlesActual: 58,
      awardsActual: 4,
      booksActual: 0,
      citationsActual: 592,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 265986.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 50,
      observations: 3,
      index: -0.356666,
      awards: 1.68,
      books: 3.486666,
      grants: -0.36,
      grantDol: -0.3,
      articles: -0.14,
      citations: -0.343333,
      confProc: 0.826666,
      articlesActual: 8,
      awardsActual: 2,
      booksActual: 3,
      citationsActual: 19,
      confProcActual: 2,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 2.0,
    },
    {
      yearsSincePhD: 51,
      observations: 3,
      index: 0.623333,
      awards: 1.98,
      books: -0.17,
      grants: 0.703333,
      grantDol: 0.81,
      articles: 1.763333,
      citations: 0.98,
      confProc: -0.11,
      articlesActual: 36,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 549,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 347944.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 52,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 53,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 54,
      observations: 1,
      index: 0.74,
      awards: -0.44,
      books: 0.92,
      grants: 1.77,
      grantDol: 1.17,
      articles: 0.09,
      citations: -0.15,
      confProc: -0.11,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 1,
      citationsActual: 96,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 460449.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 55,
      observations: 2,
      index: -0.75,
      awards: 0.015,
      books: -0.17,
      grants: -0.36,
      grantDol: -0.3,
      articles: -0.56,
      citations: -0.385,
      confProc: -0.11,
      articlesActual: 2,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 4,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  facultyDataPoints: [
    {
      facultyId: 802610,
      name: 'DONNEYONG, MACARIUS MWINISUNGEE',
      rank: 3,
      awards: -0.4,
      books: -0.2,
      citations: 0.1,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.3,
      confProc: -0.1,
      yearsSincePhD: 6,
      index: 0.9,
      articlesActual: 15.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 208.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 638239,
      name: 'RADWANSKI, PRZEMYSLAW',
      rank: 3,
      awards: 1.4,
      books: -0.2,
      citations: 0.1,
      grants: 0.7,
      grantDol: 0.5,
      articles: 0.0,
      confProc: -0.1,
      yearsSincePhD: 9,
      index: 1.0,
      articlesActual: 11.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 190.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 253850.0,
      indexActual: 0.0,
    },
    {
      facultyId: 106875,
      name: 'SUCHESTON-CAMPBELL, LARA E',
      rank: 2,
      awards: 0.5,
      books: -0.2,
      citations: 2.6,
      grants: -0.4,
      grantDol: -0.3,
      articles: 2.7,
      confProc: -0.1,
      yearsSincePhD: 12,
      index: 1.8,
      articlesActual: 51.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 1208.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 17834,
      name: 'CARNES, CYNTHIA ANN',
      rank: 1,
      awards: 0.5,
      books: -0.2,
      citations: 0.0,
      grants: -0.4,
      grantDol: -0.3,
      articles: 0.0,
      confProc: -0.1,
      yearsSincePhD: 23,
      index: 0.8,
      articlesActual: 10.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 159.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 155448,
      name: 'MCAULEY, JAMES W',
      rank: 1,
      awards: 1.4,
      books: -0.2,
      citations: -0.3,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.3,
      confProc: -0.1,
      yearsSincePhD: 26,
      index: 0.2,
      articlesActual: 6.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 47.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 609576,
      name: 'MANN, HENRY JACKSON',
      rank: 1,
      awards: 1.4,
      books: -0.2,
      citations: -0.4,
      grants: -0.4,
      grantDol: -0.3,
      articles: -0.7,
      confProc: -0.1,
      yearsSincePhD: 39,
      index: -0.9,
      articlesActual: 1.0,
      awardsActual: 2.0,
      booksActual: 0.0,
      citationsActual: 2.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
  ],
  unit: {
    indexScore: 0.6,
  },
};
export default data;
