const data = {
  maxObservations: 137,
  dataPoints: [
    {
      yearsSincePhD: 0,
      observations: 0,
      index: 0.0,
      awards: 0.0,
      books: 0.0,
      grants: 0.0,
      grantDol: 0.0,
      articles: 0.0,
      citations: 0.0,
      confProc: 0.0,
      articlesActual: 0,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 0,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 1,
      observations: 3,
      index: -0.67,
      awards: -0.53,
      books: -0.21,
      grants: -0.88,
      grantDol: -0.58,
      articles: -0.413333,
      citations: -0.296666,
      confProc: -0.25,
      articlesActual: 6,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 139,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 2,
      observations: 5,
      index: -0.592,
      awards: -0.53,
      books: -0.21,
      grants: -0.88,
      grantDol: -0.58,
      articles: -0.19,
      citations: -0.016,
      confProc: -0.25,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 360,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 3,
      observations: 16,
      index: -0.4275,
      awards: -0.331875,
      books: -0.21,
      grants: -0.806875,
      grantDol: -0.540625,
      articles: -0.21375,
      citations: 0.0225,
      confProc: 0.00125,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 391,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 27818.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 4,
      observations: 32,
      index: -0.329062,
      awards: -0.430937,
      books: -0.174062,
      grants: -0.752812,
      grantDol: -0.510625,
      articles: -0.250625,
      citations: -0.040312,
      confProc: -0.103437,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 341,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 49608.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 5,
      observations: 47,
      index: -0.185744,
      awards: -0.308297,
      books: -0.136595,
      grants: -0.744042,
      grantDol: -0.513191,
      articles: -0.213404,
      citations: 0.102765,
      confProc: -0.135957,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 453,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 47324.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 6,
      observations: 71,
      index: -0.165915,
      awards: -0.293661,
      books: -0.177605,
      grants: -0.675211,
      grantDol: -0.445915,
      articles: -0.21183,
      citations: 0.092112,
      confProc: -0.155633,
      articlesActual: 9,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 444,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 95109.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 7,
      observations: 82,
      index: -0.03378,
      awards: -0.147317,
      books: -0.181951,
      grants: -0.50378,
      grantDol: -0.338414,
      articles: -0.110975,
      citations: 0.048902,
      confProc: -0.086585,
      articlesActual: 11,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 411,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 172035.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 8,
      observations: 84,
      index: 0.054285,
      awards: -0.074761,
      books: -0.196309,
      grants: -0.3875,
      grantDol: -0.322619,
      articles: -0.095119,
      citations: 0.169285,
      confProc: 0.061071,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 506,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 183172.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 9,
      observations: 89,
      index: 0.047865,
      awards: -0.014269,
      books: -0.145393,
      grants: -0.25146,
      grantDol: -0.213033,
      articles: -0.139101,
      citations: -0.029325,
      confProc: 0.013483,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 349,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 261393.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 10,
      observations: 107,
      index: -0.010747,
      awards: -0.126635,
      books: -0.167009,
      grants: -0.313738,
      grantDol: -0.214953,
      articles: -0.150841,
      citations: -0.069906,
      confProc: -0.087196,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 318,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 259968.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 11,
      observations: 101,
      index: 0.054455,
      awards: -0.134356,
      books: -0.187227,
      grants: -0.146831,
      grantDol: -0.108118,
      articles: -0.174158,
      citations: -0.068118,
      confProc: -0.110693,
      articlesActual: 10,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 319,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 336356.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 12,
      observations: 104,
      index: 0.051538,
      awards: -0.145384,
      books: -0.176826,
      grants: -0.179326,
      grantDol: -0.111442,
      articles: -0.074807,
      citations: -0.031826,
      confProc: 0.033461,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 348,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 333817.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 13,
      observations: 124,
      index: 0.287661,
      awards: -0.049677,
      books: -0.14508,
      grants: 0.098064,
      grantDol: 0.029919,
      articles: -0.005645,
      citations: -0.033306,
      confProc: 0.03637,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 347,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 434445.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 14,
      observations: 131,
      index: 0.127557,
      awards: -0.002061,
      books: -0.148549,
      grants: 0.076946,
      grantDol: 0.015038,
      articles: 0.069847,
      citations: -0.017251,
      confProc: 0.005725,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 359,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 424149.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 15,
      observations: 94,
      index: 0.082446,
      awards: -0.133191,
      books: -0.063191,
      grants: -0.011489,
      grantDol: -0.047553,
      articles: -0.06734,
      citations: -0.11734,
      confProc: 0.056489,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 281,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 379278.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 16,
      observations: 125,
      index: 0.2252,
      awards: -0.04624,
      books: -0.1548,
      grants: 0.16072,
      grantDol: 0.0868,
      articles: 0.00744,
      citations: -0.08592,
      confProc: -0.09992,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 305,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 475388.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 17,
      observations: 121,
      index: 0.337603,
      awards: -0.014793,
      books: -0.086446,
      grants: 0.252396,
      grantDol: 0.12628,
      articles: 0.245206,
      citations: 0.2919,
      confProc: 0.082231,
      articlesActual: 17,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 601,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 503553.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 18,
      observations: 123,
      index: 0.143577,
      awards: -0.02691,
      books: -0.163252,
      grants: 0.323983,
      grantDol: 0.195609,
      articles: 0.076991,
      citations: -0.066585,
      confProc: 0.082195,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 320,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 553249.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 19,
      observations: 129,
      index: 0.163798,
      awards: -0.138682,
      books: -0.067364,
      grants: 0.177829,
      grantDol: -0.006511,
      articles: 0.030697,
      citations: -0.068759,
      confProc: -0.057829,
      articlesActual: 13,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 319,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 408611.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 20,
      observations: 104,
      index: 0.335384,
      awards: 0.025576,
      books: -0.121538,
      grants: 0.342403,
      grantDol: 0.232019,
      articles: 0.258653,
      citations: 0.337788,
      confProc: 0.110769,
      articlesActual: 17,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 637,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 579206.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 21,
      observations: 137,
      index: 0.077956,
      awards: -0.181824,
      books: -0.092481,
      grants: 0.141678,
      grantDol: 0.061532,
      articles: -0.051751,
      citations: -0.098905,
      confProc: -0.005474,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 295,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 457704.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 22,
      observations: 136,
      index: 0.131617,
      awards: -0.030808,
      books: 0.06897,
      grants: 0.234852,
      grantDol: 0.077647,
      articles: 0.162132,
      citations: 0.081617,
      confProc: -0.028308,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 437,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 468793.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 23,
      observations: 122,
      index: 0.112131,
      awards: -0.056475,
      books: 0.176311,
      grants: 0.229016,
      grantDol: 0.052377,
      articles: 0.179836,
      citations: 0.190573,
      confProc: -0.00836,
      articlesActual: 16,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 522,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 450703.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 24,
      observations: 120,
      index: 0.106666,
      awards: -0.06,
      books: -0.056666,
      grants: 0.345,
      grantDol: 0.209583,
      articles: 0.068666,
      citations: 0.1135,
      confProc: 0.006833,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 462,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 563137.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 25,
      observations: 119,
      index: 0.014201,
      awards: -0.124873,
      books: 0.050924,
      grants: 0.164201,
      grantDol: 0.097899,
      articles: -0.065042,
      citations: -0.114621,
      confProc: 0.037142,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 283,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 483293.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 26,
      observations: 112,
      index: 0.130446,
      awards: 0.022946,
      books: -0.01491,
      grants: 0.249732,
      grantDol: 0.10741,
      articles: 0.09241,
      citations: 0.154553,
      confProc: -0.088482,
      articlesActual: 15,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 494,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 489993.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 27,
      observations: 98,
      index: -0.138265,
      awards: -0.051428,
      books: 0.001224,
      grants: 0.125612,
      grantDol: 0.070714,
      articles: -0.169795,
      citations: -0.173571,
      confProc: -0.154285,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 237,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 464269.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 28,
      observations: 109,
      index: 0.023853,
      awards: -0.074954,
      books: -0.062293,
      grants: 0.2111,
      grantDol: 0.502018,
      articles: 0.094036,
      citations: 0.045596,
      confProc: 0.155688,
      articlesActual: 15,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 409,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 771897.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 29,
      observations: 118,
      index: -0.16322,
      awards: -0.129152,
      books: 0.004406,
      grants: -0.00983,
      grantDol: -0.109067,
      articles: -0.045084,
      citations: -0.080762,
      confProc: -0.011525,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 310,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 335601.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 30,
      observations: 120,
      index: -0.028833,
      awards: 0.036166,
      books: -0.047083,
      grants: 0.22275,
      grantDol: 0.092,
      articles: 0.11325,
      citations: 0.03175,
      confProc: 0.018,
      articlesActual: 15,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 397,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 478887.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 31,
      observations: 109,
      index: -0.018073,
      awards: -0.028623,
      books: -0.030642,
      grants: 0.087981,
      grantDol: 0.052477,
      articles: 0.0711,
      citations: -0.039266,
      confProc: 0.124862,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 342,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 450875.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 32,
      observations: 97,
      index: -0.089793,
      awards: -0.112474,
      books: -0.067731,
      grants: 0.015567,
      grantDol: -0.028144,
      articles: -0.057525,
      citations: -0.136082,
      confProc: -0.042783,
      articlesActual: 12,
      awardsActual: 0,
      booksActual: 0,
      citationsActual: 266,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 393160.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 33,
      observations: 114,
      index: -0.187631,
      awards: -0.067456,
      books: -0.058684,
      grants: 0.101842,
      grantDol: 0.173947,
      articles: -0.170087,
      citations: -0.191228,
      confProc: -0.10307,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 223,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 537736.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 34,
      observations: 95,
      index: -0.129157,
      awards: 0.064526,
      books: 0.104736,
      grants: -0.002526,
      grantDol: 0.06421,
      articles: 0.030421,
      citations: 0.120105,
      confProc: 0.010947,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 467,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 459418.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 35,
      observations: 91,
      index: -0.28934,
      awards: 0.060879,
      books: -0.058351,
      grants: -0.033956,
      grantDol: -0.045824,
      articles: -0.143076,
      citations: -0.158901,
      confProc: 0.007582,
      articlesActual: 10,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 249,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 381054.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 36,
      observations: 78,
      index: -0.23423,
      awards: 0.013205,
      books: 0.276538,
      grants: -0.147051,
      grantDol: -0.069358,
      articles: -0.115769,
      citations: -0.049615,
      confProc: 0.041923,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 334,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 364223.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 37,
      observations: 92,
      index: -0.20663,
      awards: -0.025108,
      books: 0.0525,
      grants: 0.064456,
      grantDol: 0.065869,
      articles: -0.125108,
      citations: -0.102608,
      confProc: 0.084891,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 293,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 460791.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 38,
      observations: 72,
      index: -0.169305,
      awards: 0.407777,
      books: 0.045555,
      grants: 0.002916,
      grantDol: 0.019305,
      articles: -0.097361,
      citations: -0.115833,
      confProc: 0.187222,
      articlesActual: 11,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 282,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 427560.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 39,
      observations: 75,
      index: -0.218,
      awards: -0.062933,
      books: 0.127333,
      grants: -0.0632,
      grantDol: -0.068533,
      articles: -0.124666,
      citations: -0.088666,
      confProc: -0.026666,
      articlesActual: 11,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 303,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 364429.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 40,
      observations: 54,
      index: 0.160925,
      awards: 0.568148,
      books: 0.365,
      grants: -0.102407,
      grantDol: 0.086666,
      articles: 0.304259,
      citations: 0.338888,
      confProc: -0.039074,
      articlesActual: 18,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 638,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 475474.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 41,
      observations: 44,
      index: -0.092954,
      awards: 0.225681,
      books: 0.338863,
      grants: 0.07409,
      grantDol: 0.075454,
      articles: -0.038409,
      citations: 0.051818,
      confProc: 0.085,
      articlesActual: 12,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 413,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 466934.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 42,
      observations: 65,
      index: -0.04723,
      awards: 0.150153,
      books: 0.161538,
      grants: 0.188153,
      grantDol: 0.21323,
      articles: -0.023538,
      citations: -0.082307,
      confProc: 0.275538,
      articlesActual: 13,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 309,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 565852.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 43,
      observations: 37,
      index: 0.022972,
      awards: 0.122702,
      books: 0.194054,
      grants: 0.286486,
      grantDol: 0.331081,
      articles: 0.278648,
      citations: 0.164324,
      confProc: -0.177567,
      articlesActual: 18,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 501,
      confProcActual: 0,
      grantsActual: 2,
      grantDolActual: 649986.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 44,
      observations: 47,
      index: 0.18468,
      awards: 0.605531,
      books: 0.377234,
      grants: -0.085957,
      grantDol: 0.012765,
      articles: 0.929361,
      citations: 0.339148,
      confProc: -0.093191,
      articlesActual: 29,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 638,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 422978.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 45,
      observations: 38,
      index: 0.143684,
      awards: 0.55,
      books: 0.27421,
      grants: -0.081842,
      grantDol: -0.082894,
      articles: -0.072631,
      citations: -0.142368,
      confProc: -0.020789,
      articlesActual: 12,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 261,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 354287.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 46,
      observations: 35,
      index: -0.169428,
      awards: 0.355714,
      books: 0.315428,
      grants: -0.163428,
      grantDol: -0.054857,
      articles: 0.047428,
      citations: -0.097142,
      confProc: -0.077714,
      articlesActual: 14,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 297,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 374124.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 47,
      observations: 27,
      index: 0.039629,
      awards: 0.517777,
      books: 0.556666,
      grants: -0.232222,
      grantDol: -0.107037,
      articles: 0.14037,
      citations: -0.008888,
      confProc: 0.147037,
      articlesActual: 15,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 366,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 336650.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 48,
      observations: 19,
      index: -0.151578,
      awards: 0.743157,
      books: 0.153157,
      grants: -0.051052,
      grantDol: 0.181052,
      articles: -0.278421,
      citations: -0.071052,
      confProc: -0.179473,
      articlesActual: 8,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 318,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 543121.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 49,
      observations: 35,
      index: -0.067714,
      awards: 0.814,
      books: 0.348571,
      grants: -0.180285,
      grantDol: 0.030571,
      articles: 0.017142,
      citations: 0.060857,
      confProc: -0.135142,
      articlesActual: 13,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 420,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 435051.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 50,
      observations: 20,
      index: -0.1165,
      awards: 0.5425,
      books: 0.595,
      grants: -0.1215,
      grantDol: 0.024,
      articles: 0.1385,
      citations: -0.046,
      confProc: -0.049,
      articlesActual: 15,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 337,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 430106.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 51,
      observations: 27,
      index: 0.517777,
      awards: 1.279629,
      books: 0.556666,
      grants: 0.004814,
      grantDol: 0.015185,
      articles: 1.024814,
      citations: 0.78037,
      confProc: -0.20037,
      articlesActual: 31,
      awardsActual: 3,
      booksActual: 0,
      citationsActual: 984,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 424201.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 52,
      observations: 20,
      index: 0.132,
      awards: 1.1365,
      books: 2.491,
      grants: 0.0525,
      grantDol: 0.398,
      articles: 0.0525,
      citations: -0.0295,
      confProc: 0.152,
      articlesActual: 14,
      awardsActual: 3,
      booksActual: 2,
      citationsActual: 349,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 697865.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 53,
      observations: 16,
      index: -0.2675,
      awards: 0.61,
      books: 0.365,
      grants: -0.078125,
      grantDol: -0.08,
      articles: -0.20625,
      citations: -0.18125,
      confProc: 0.210625,
      articlesActual: 9,
      awardsActual: 2,
      booksActual: 0,
      citationsActual: 230,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 356662.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 54,
      observations: 9,
      index: -0.666666,
      awards: -0.074444,
      books: -0.21,
      grants: -0.622222,
      grantDol: -0.377777,
      articles: -0.372222,
      citations: -0.336666,
      confProc: -0.026666,
      articlesActual: 7,
      awardsActual: 1,
      booksActual: 0,
      citationsActual: 109,
      confProcActual: 0,
      grantsActual: 0,
      grantDolActual: 143611.0,
      indexActual: 0.0,
    },
    {
      yearsSincePhD: 55,
      observations: 6,
      index: 0.103333,
      awards: 1.98,
      books: 0.365,
      grants: -0.2,
      grantDol: 0.07,
      articles: 0.455,
      citations: 0.248333,
      confProc: 0.085,
      articlesActual: 21,
      awardsActual: 5,
      booksActual: 0,
      citationsActual: 567,
      confProcActual: 0,
      grantsActual: 1,
      grantDolActual: 463692.0,
      indexActual: 0.0,
    },
  ],
  facultyDataPoints: [
    {
      facultyId: 1088537,
      name: 'TU, LI-CHUN',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: 0.0,
      grants: -0.3,
      grantDol: -0.2,
      articles: -0.2,
      confProc: -0.2,
      yearsSincePhD: 6,
      index: 0.3,
      articlesActual: 10.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 358.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 259826.0,
      indexActual: 0.0,
    },
    {
      facultyId: 1088536,
      name: 'KEARSE, MICHAEL G',
      rank: 3,
      awards: -0.1,
      books: -0.2,
      citations: -0.1,
      grants: -0.3,
      grantDol: -0.2,
      articles: -0.3,
      confProc: -0.2,
      yearsSincePhD: 6,
      index: 0.2,
      articlesActual: 8.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 277.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 271636.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887211,
      name: 'TANG, WEN',
      rank: 3,
      awards: -0.1,
      books: -0.2,
      citations: -0.2,
      grants: -0.3,
      grantDol: -0.2,
      articles: -0.5,
      confProc: -0.2,
      yearsSincePhD: 7,
      index: -0.2,
      articlesActual: 5.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 175.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 246833.0,
      indexActual: 0.0,
    },
    {
      facultyId: 887265,
      name: 'BASKIN, KEDRYN KJESTINE',
      rank: 3,
      awards: -0.1,
      books: -0.2,
      citations: -0.2,
      grants: -0.9,
      grantDol: -0.6,
      articles: -0.4,
      confProc: -0.2,
      yearsSincePhD: 7,
      index: -0.5,
      articlesActual: 6.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 174.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 832256,
      name: 'GOLDMAN, JOSEPH A',
      rank: 3,
      awards: -0.1,
      books: -0.2,
      citations: -0.3,
      grants: -0.9,
      grantDol: -0.6,
      articles: -0.6,
      confProc: -0.2,
      yearsSincePhD: 9,
      index: -0.8,
      articlesActual: 3.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 156.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 802570,
      name: 'JU, KOU-SAN',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.1,
      grants: -0.9,
      grantDol: -0.6,
      articles: -0.1,
      confProc: -0.2,
      yearsSincePhD: 10,
      index: 0.0,
      articlesActual: 12.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 298.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 846606,
      name: 'KYRIAZIS, GEORGIOS',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: 0.3,
      grantDol: 0.1,
      articles: -0.6,
      confProc: -0.2,
      yearsSincePhD: 11,
      index: -0.8,
      articlesActual: 3.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 35.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 478799.0,
      indexActual: 0.0,
    },
    {
      facultyId: 769837,
      name: 'ACCORNERO, FEDERICA',
      rank: 3,
      awards: 0.8,
      books: -0.2,
      citations: -0.2,
      grants: 0.3,
      grantDol: 0.2,
      articles: 0.1,
      confProc: -0.2,
      yearsSincePhD: 12,
      index: 0.8,
      articlesActual: 15.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 229.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 570690.0,
      indexActual: 0.0,
    },
    {
      facultyId: 542064,
      name: 'BURD, CRAIG JOSEPH',
      rank: 3,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.3,
      grantDol: -0.2,
      articles: -0.6,
      confProc: -0.2,
      yearsSincePhD: 13,
      index: -0.8,
      articlesActual: 4.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 50.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 250100.0,
      indexActual: 0.0,
    },
    {
      facultyId: 301841,
      name: 'PHELPS, A MITCHELL',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: 0.4,
      grants: 0.3,
      grantDol: 0.3,
      articles: 1.6,
      confProc: -0.2,
      yearsSincePhD: 14,
      index: 1.4,
      articlesActual: 41.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 674.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 640464.0,
      indexActual: 0.0,
    },
    {
      facultyId: 542283,
      name: 'WEISLEDER, NOAH L',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: -0.1,
      grants: 1.4,
      grantDol: 1.4,
      articles: 0.2,
      confProc: -0.2,
      yearsSincePhD: 16,
      index: 1.1,
      articlesActual: 16.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 302.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 1425433.0,
      indexActual: 0.0,
    },
    {
      facultyId: 145110,
      name: 'GU, CHEN',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: 0.9,
      grantDol: 0.5,
      articles: -0.2,
      confProc: -0.2,
      yearsSincePhD: 18,
      index: 0.1,
      articlesActual: 10.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 74.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 750851.0,
      indexActual: 0.0,
    },
    {
      facultyId: 130067,
      name: 'WU, JIAN-QIU',
      rank: 1,
      awards: -0.1,
      books: 0.9,
      citations: -0.2,
      grants: 0.3,
      grantDol: 0.2,
      articles: -0.1,
      confProc: -0.2,
      yearsSincePhD: 18,
      index: 0.5,
      articlesActual: 11.0,
      awardsActual: 1.0,
      booksActual: 1.0,
      citationsActual: 235.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 538284.0,
      indexActual: 0.0,
    },
    {
      facultyId: 42539,
      name: 'HAMEL, PATRICE P',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.3,
      grantDol: -0.4,
      articles: -0.6,
      confProc: -0.2,
      yearsSincePhD: 20,
      index: -1.0,
      articlesActual: 4.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 43.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 109830.0,
      indexActual: 0.0,
    },
    {
      facultyId: 110040,
      name: 'MYKYTYN, KIRK A',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: 0.3,
      grantDol: 0.0,
      articles: -0.6,
      confProc: -0.2,
      yearsSincePhD: 20,
      index: -0.3,
      articlesActual: 4.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 128.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 388000.0,
      indexActual: 0.0,
    },
    {
      facultyId: 109624,
      name: 'TRIDANDAPANI, SUSHEELA',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.1,
      grants: 0.3,
      grantDol: 0.3,
      articles: 0.6,
      confProc: -0.2,
      yearsSincePhD: 21,
      index: 1.0,
      articlesActual: 23.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 327.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 623300.0,
      indexActual: 0.0,
    },
    {
      facultyId: 378861,
      name: 'KOLB, STEPHEN J',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: 0.4,
      grants: -0.9,
      grantDol: -0.6,
      articles: 0.5,
      confProc: -0.2,
      yearsSincePhD: 21,
      index: 0.6,
      articlesActual: 22.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 650.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 301712,
      name: 'FREITAS, MICHAEL ALAN',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.2,
      grants: 0.3,
      grantDol: 0.7,
      articles: 0.3,
      confProc: 0.4,
      yearsSincePhD: 22,
      index: 0.8,
      articlesActual: 19.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 220.0,
      confProcActual: 1.0,
      grantsActual: 2.0,
      grantDolActual: 891192.0,
      indexActual: 0.0,
    },
    {
      facultyId: 170348,
      name: 'VILLAMENA, FREDERICK AMAN',
      rank: 2,
      awards: -0.5,
      books: 2.1,
      citations: -0.1,
      grants: -0.9,
      grantDol: -0.6,
      articles: 0.2,
      confProc: -0.2,
      yearsSincePhD: 22,
      index: 0.2,
      articlesActual: 16.0,
      awardsActual: 0.0,
      booksActual: 2.0,
      citationsActual: 308.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 7901,
      name: 'BELL, CHARLES E',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: 0.3,
      grantDol: -0.2,
      articles: -0.6,
      confProc: -0.2,
      yearsSincePhD: 23,
      index: -0.8,
      articlesActual: 4.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 34.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 277534.0,
      indexActual: 0.0,
    },
    {
      facultyId: 161484,
      name: 'RAFAEL-FORTNEY, JILL A',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: 0.0,
      grants: 0.9,
      grantDol: 0.8,
      articles: -0.1,
      confProc: -0.2,
      yearsSincePhD: 23,
      index: 0.8,
      articlesActual: 12.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 350.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 947241.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384358,
      name: 'LILLY, BRENDA',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: -0.3,
      grantDol: 0.0,
      articles: -0.5,
      confProc: -0.2,
      yearsSincePhD: 24,
      index: -0.2,
      articlesActual: 5.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 170.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 437445.0,
      indexActual: 0.0,
    },
    {
      facultyId: 542035,
      name: 'AMACHER, SHARON L',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: -0.2,
      grants: 0.9,
      grantDol: 0.4,
      articles: -0.4,
      confProc: -0.2,
      yearsSincePhD: 26,
      index: 0.4,
      articlesActual: 7.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 208.0,
      confProcActual: 0.0,
      grantsActual: 3.0,
      grantDolActual: 661021.0,
      indexActual: 0.0,
    },
    {
      facultyId: 42767,
      name: 'GU, HOWARD HAOGANG',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.9,
      grantDol: -0.6,
      articles: -0.7,
      confProc: -0.2,
      yearsSincePhD: 27,
      index: -1.5,
      articlesActual: 1.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 17.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 115103,
      name: 'PARTHUN, MARK ROBERT',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.3,
      grantDol: -0.2,
      articles: -0.6,
      confProc: -0.2,
      yearsSincePhD: 27,
      index: -0.7,
      articlesActual: 4.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 71.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 287776.0,
      indexActual: 0.0,
    },
    {
      facultyId: 135201,
      name: 'BRUSCHWEILER, RAFAEL P',
      rank: 1,
      awards: 1.3,
      books: -0.2,
      citations: 0.7,
      grants: 1.4,
      grantDol: 5.2,
      articles: 1.1,
      confProc: -0.2,
      yearsSincePhD: 28,
      index: 1.8,
      articlesActual: 33.0,
      awardsActual: 4.0,
      booksActual: 0.0,
      citationsActual: 956.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 4156616.0,
      indexActual: 0.0,
    },
    {
      facultyId: 301647,
      name: 'ANAND, RENE',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.5,
      grants: -0.9,
      grantDol: -0.6,
      articles: -0.8,
      confProc: -0.2,
      yearsSincePhD: 30,
      index: -1.6,
      articlesActual: 0.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 10.0,
      confProcActual: 0.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 384701,
      name: 'WU, LAI CHU',
      rank: 2,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: -0.9,
      grantDol: -0.6,
      articles: -0.3,
      confProc: 0.4,
      yearsSincePhD: 32,
      index: -0.4,
      articlesActual: 9.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 140.0,
      confProcActual: 1.0,
      grantsActual: 0.0,
      grantDolActual: 0.0,
      indexActual: 0.0,
    },
    {
      facultyId: 43871,
      name: 'HAI, TSONWIN',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: 0.0,
      grants: 0.3,
      grantDol: 0.1,
      articles: 0.0,
      confProc: -0.2,
      yearsSincePhD: 32,
      index: 0.8,
      articlesActual: 13.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 392.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 501811.0,
      indexActual: 0.0,
    },
    {
      facultyId: 130505,
      name: 'YOON, SUNG OK',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: -0.3,
      grants: 1.4,
      grantDol: 1.8,
      articles: -0.4,
      confProc: -0.2,
      yearsSincePhD: 33,
      index: 0.4,
      articlesActual: 7.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 167.0,
      confProcActual: 0.0,
      grantsActual: 4.0,
      grantDolActual: 1696880.0,
      indexActual: 0.0,
    },
    {
      facultyId: 101947,
      name: 'ZWEIER, JAY LOUIS',
      rank: 1,
      awards: -0.1,
      books: 0.9,
      citations: 0.4,
      grants: 0.9,
      grantDol: 1.7,
      articles: 1.0,
      confProc: 0.4,
      yearsSincePhD: 33,
      index: 1.6,
      articlesActual: 31.0,
      awardsActual: 1.0,
      booksActual: 1.0,
      citationsActual: 708.0,
      confProcActual: 1.0,
      grantsActual: 3.0,
      grantDolActual: 1620674.0,
      indexActual: 0.0,
    },
    {
      facultyId: 858412,
      name: 'LEWANDOWSKI, EDWARD',
      rank: 1,
      awards: 0.8,
      books: -0.2,
      citations: 0.2,
      grants: 2.6,
      grantDol: 3.1,
      articles: 0.0,
      confProc: -0.2,
      yearsSincePhD: 33,
      index: 1.3,
      articlesActual: 13.0,
      awardsActual: 3.0,
      booksActual: 0.0,
      citationsActual: 504.0,
      confProcActual: 0.0,
      grantsActual: 6.0,
      grantDolActual: 2655751.0,
      indexActual: 0.0,
    },
    {
      facultyId: 106368,
      name: 'MEHTA, KAMAL D',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: -0.5,
      grants: -0.3,
      grantDol: 0.0,
      articles: -0.7,
      confProc: -0.2,
      yearsSincePhD: 34,
      index: -1.0,
      articlesActual: 2.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 7.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 400285.0,
      indexActual: 0.0,
    },
    {
      facultyId: 63509,
      name: 'KURET, JEFFREY ALLAN',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: 0.9,
      grantDol: 0.5,
      articles: -0.4,
      confProc: 0.4,
      yearsSincePhD: 35,
      index: -0.3,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 20.0,
      confProcActual: 1.0,
      grantsActual: 3.0,
      grantDolActual: 784069.0,
      indexActual: 0.0,
    },
    {
      facultyId: 135430,
      name: 'BURGHES, ARTHUR HARRY MACLEO',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: 1.0,
      grants: 0.3,
      grantDol: 0.2,
      articles: 0.6,
      confProc: 0.4,
      yearsSincePhD: 35,
      index: 1.3,
      articlesActual: 23.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 1128.0,
      confProcActual: 1.0,
      grantsActual: 2.0,
      grantDolActual: 551464.0,
      indexActual: 0.0,
    },
    {
      facultyId: 17423,
      name: 'CHANG, LONG-SHENG',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.4,
      grants: -0.3,
      grantDol: -0.5,
      articles: -0.4,
      confProc: -0.2,
      yearsSincePhD: 35,
      index: -0.6,
      articlesActual: 7.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 72.0,
      confProcActual: 0.0,
      grantsActual: 1.0,
      grantDolActual: 78000.0,
      indexActual: 0.0,
    },
    {
      facultyId: 393565,
      name: 'YALOWICH, JACK CHARLES',
      rank: 1,
      awards: -0.5,
      books: -0.2,
      citations: -0.3,
      grants: -0.3,
      grantDol: -0.1,
      articles: -0.3,
      confProc: 1.1,
      yearsSincePhD: 39,
      index: -0.2,
      articlesActual: 8.0,
      awardsActual: 0.0,
      booksActual: 0.0,
      citationsActual: 107.0,
      confProcActual: 2.0,
      grantsActual: 1.0,
      grantDolActual: 350271.0,
      indexActual: 0.0,
    },
    {
      facultyId: 102723,
      name: 'SCHOENBERG, DANIEL ROBERT',
      rank: 1,
      awards: -0.1,
      books: -0.2,
      citations: -0.4,
      grants: 0.3,
      grantDol: 0.2,
      articles: -0.3,
      confProc: -0.2,
      yearsSincePhD: 42,
      index: 0.1,
      articlesActual: 9.0,
      awardsActual: 1.0,
      booksActual: 0.0,
      citationsActual: 89.0,
      confProcActual: 0.0,
      grantsActual: 2.0,
      grantDolActual: 593577.0,
      indexActual: 0.0,
    },
  ],
  unit: {
    indexScore: 0.1,
  },
};
export default data;
